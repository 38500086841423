import React, { Component } from 'react';
import ThreeScene from '../webGL/WebGLThreeScene';
import { WebGLStore } from "../webGL/WebGLStore";
//import {app.utils.nK,  DBGeneric} from "../data/Utils";
import {app} from "../ahome/App";
import "./Geometry.css";

export class GeometryTool extends Component {
  refModal; //: any;
  refFormGL; //: any;
  refSideBar; //: any;
  fReaderJSON = new FileReader();
  refFileJSON; //: any;
  listComps = [];
  selectFirstComp = true;

  //___________________________________________________________________________
  constructor(props) {
    super(props);

    this.state = {
      valSelZone: -6,
      valSelComp: -1,
    };

    this.webGL = new WebGLStore();
    this.webGL.inr = 1;
    this.webGL.variantJSON = JSON.parse(JSON.stringify(app.tem.variantJSON)); //temporarilly

    this.refFormGL = React.createRef();
    this.refSideBar = React.createRef();
    this.refModal = React.createRef();

    this.windowResized = this.windowResized.bind(this);
    this.buttonAcceptClicked = this.buttonAcceptClicked.bind(this);
    this.bottonCancelClicked = this.bottonCancelClicked.bind(this);
    this.onChangeFileJSON = this.onChangeFileJSON.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.buttonLoadWPSClick = this.buttonLoadWPSClick.bind(this);
    this.createListBZones = this.createListBZones.bind(this);
    this.selZoneChanged = this.selZoneChanged.bind(this);
    this.selCompChanged = this.selCompChanged.bind(this);
    this.pickedInWebGL = this.pickedInWebGL.bind(this);
    this.onAfterGeomConvert = this.onAfterGeomConvert.bind(this);

    this.refFileJSON = React.createRef(); //must be here
  }

  //___________________________________________________________________________
  componentDidMount() {
    this.webGL = app.TS[1].webGL;
    this.getDimenstions();
    window.addEventListener("resize", this.windowResized);
    
    this.webGL.newVariant(JSON.parse(JSON.stringify(app.tem.variantJSON)));
    this.webGL.pickedInWebGL = this.pickedInWebGL;
  }

  /*/__________________________________________________________________________
  importGeometry() {  //importing to main
    //app.importVar(this.idData.variant);
    let avar = app.actVariantJSON,
        varI = this.idData.variant,
        countZ = varI.building.lZone.length,
        countC = varI.building.lComponent.length,
        countZA = avar.building.lZone.length,
        countCA = avar.building.lComponent.length, 
        merge = (avar.building.ibdm === 1),  //merge data
        lz = [],
        lc = [];
    
    avar.geom = varI.geom;

    for (let iz = 0; iz < countZ; iz++){
      let z = undefined,
          zi = varI.building.lZone[iz];  //imported
      if (iz < countZA){
        if (merge){
          z = avar.building.lZone[iz];
        }
      }
      if (z === undefined) z = JSON.parse(JSON.stringify(zoneJSON)); 
      z.id = zi.id;
    
      lz.push(z);
    }
      
    for (let ic = 0; ic < countC; ic++){
      let c = undefined,
          ci = varI.building.lComponent[ic];  //imported
      if (ic < countCA){
        if (merge){
          c = avar.building.lComponent[ic];
        }
      }
      if (c === undefined) c = JSON.parse(JSON.stringify(componentJSON)); 
      c.id = ci.id;
      c.idIC = ci.idIC;
      c.idEC = ci.idEC;
      c.typeC = ci.typeC;
      c.idPolyC = ci.idPolyC;
      c.idColorIC = ci.idColorIC;
      c.idColorEC = ci.idColorEC;
      lc.push(c);
    }
    avar.building.lZone = lz
    avar.building.lComponent = lc;
    
    app.TS[0].webGL.removeAllFromSceneGL();
    app.TS[0].webGL.newVariant(avar);
    app.TV[0].createNewNodes();*/

    /*avar.building.lComponent.forEach((comp) => {   should be done on server
      let a = 0;
      comp.idPolyC.forEach((idPol) => {
        let poly = avar.geom.lPoly.find((pol) => pol.id === idPol);
        if (poly) a += poly.areaP;
      });
      comp.areaC = roundVal(a, 4);
    });
  }*/

  //___________________________________________________________________________
  buttonAcceptClicked(e) {
    if (this.webGL.variantJSON.building.lComponent.length > 0){
      //this.importGeometry();
      this.props.importVar(this.idData.Variant);
      this.props.cancelModal();
    }
    else alert(app.utils.nK("s_accNPC"));   //"Acception not possible, no components are defined.");
  }

  //___________________________________________________________________________
  bottonCancelClicked(e) {
    this.props.cancelModal();
  }

  //__________________________________________________________________________
  buttonLoadWPSClick(e) {
    this.fReaderJSON.onload = this.onLoad;
    this.refFileJSON.current.click(e);
  }

  //____________________________________________________________________________
  onChangeFileJSON(e) {
    this.fReaderJSON.readAsText(e.target.files[0]);
  }

  //____________________________________________________________________________
  onAfterGeomConvert(data){  //converted on server
    if (data){    //console.log("onAfterGeomConvertt",data);
      this.idData = data; //data imported to import selectivily
      let vLoc = JSON.parse(JSON.stringify(app.tem.variantJSON));
      vLoc.building = data.Variant.building;
      vLoc.geom = data.Variant.geom;

      //console.log("before newVariant",vLoc);

      app.TS[1].webGL.newVariant(vLoc);  //console.log("newVariant",vLoc);
      this.setState({ valSelZone: -1 });
    }
    else this.props.cancelModal();
  }

  onLoad(event) {
    if (this.fReaderJSON.result) {

      let s = this.fReaderJSON.result, // as string,
          ss = s.replace(/\r\n/g, "\r").replace(/\n/g, "\r").split(/\r/),
          jo = {data:ss, igsa:app.actVariantJSON.building.igsa};

      app.DBGeneric("BGener/SKP",  jo, this.onAfterGeomConvert); 

      //this.importSKP();  //app.TS[1].webGL.newVariant(variantLoc); --- continue !!!!
      //this.setState({ valSelZone: -1 });
    } else alert(app.utils.nK("s_elf"));//"Error when loading file");
  }

  //___________________________________________________________________________
  selZoneChanged(e) {
    this.selectFirstComp = true;
    this.setState({ valSelZone: Number(e.target.value) }, () =>{
    this.setFirstCompSelected()
      this.afterSelChanged();}
    );
  }

  //___________________________________________________________________________
  selCompChanged(e) {
    this.setState({ valSelComp: Number(e.target.value) }, () =>
      this.afterSelChanged()
    );
  }

  afterSelChanged() {
    let lGroupId = [];
    lGroupId.push([]); // 1 - opaque
    lGroupId.push([]); // 2-opaque group yellow
    lGroupId.push([]); // 3- opaque group red

    if (this.state.valSelZone < 0) {
      //building
      this.webGL.variantJSON.building.lComponent.forEach((c) => {
        if (c.id === this.state.valSelComp) lGroupId[2].push(c.id);
        else lGroupId[0].push(c.id);
      });
    } else {
      this.webGL.variantJSON.building.lComponent.forEach((c) => {
        if (c.id === this.state.valSelComp) lGroupId[2].push(c.id);
        else if (c.idIC === this.state.valSelZone || (c.idEC === this.state.valSelZone && app.pref.showAllCompsZone)) lGroupId[0].push(c.id);
      });
    }
    this.webGL.ObjectSelectedExtern(lGroupId);
  }

  pickedInWebGL(id, type) {
    let comp = this.webGL.variantJSON.building.lComponent.find(
      (c) => c.id === id
    );
    if (comp) this.setState({ valSelZone: comp.idIC });
    this.setState({ valSelComp: id });
  }

  //___________________________________________________________________________
  createListBZones() {
    let l = [],
      i = 0;
    if (this.webGL.variantJSON.building.lZone.length > 0) {
      l.push(
        <option value={-1} key="b">
          Building
        </option>
      );

      this.webGL.variantJSON.building.lZone.forEach((e) => {
        l.push(
          <option value={e.id} key={(i++).toString()}>
            {e.n}
          </option>
        );
      });
    }
    return l;
  }

  //___________________________________________________________________________
  createListComps() {
    this.listComps = [];
    let l = [],
      i = 0;

    if (this.webGL.variantJSON.building.lComponent.length > 0) {
      this.webGL.variantJSON.building.lComponent.forEach((e) => {
        if (this.state.valSelZone === -1 || e.idIC === this.state.valSelZone) {
          this.listComps.push(e);
          l.push(
            <option value={e.id} key={(i++).toString()}>
              {e.n}
            </option>
          );
        }
      });
    }
    return l;
  }

  //____________________________________________________________________________
  setFirstCompSelected(){
    if (this.listComps.length > 0 && this.selectFirstComp) {

      if (this.state.valSelComp !== this.listComps[0].id){
        this.setState({ valSelComp: this.listComps[0].id }, () => this.afterSelChanged());  
      }    
    }
    this.selectFirstComp = false;
  }

  //____________________________________________________________________________
  windowResized() {
    this.getDimenstions();
    //app.TS[1].reRender();
  }

  //____________________________________________________________________________
  getDimenstions() {
    if (this.refFormGL && this.refFormGL.current) {
      app.TS[1].mW = this.refFormGL.current.clientWidth;
      app.TS[1].mH = this.refFormGL.current.clientHeight;
    }

  }

  /*/____________________________________________________________________________________________________
  indexIE(s, CountZones) {
    let i = -1;
    if (s.startsWith("Zone")) {
      let ii = Number(s.substring(5));
      if (ii > CountZones) ii = 0;
      i = ii - 1;
    } else if (s === "Ground") i = -2;
    else if (s === "Adiabate") i = -3;
    return i;
  }*/

  CenterAxis(variant) {
    let coFirst = true,
      Xmin = 0,
      Xmax = 0,
      Ymin = 0,
      Ymax = 0,
      Zmin = 0,
      Zmax = 0;
    variant.geom.lIDXYZ.forEach((p) => {
      if (coFirst) {
        Xmin = p[1];
        Xmax = Xmin;
        Ymin = p[2];
        Ymax = Ymin;
        Zmin = p[3];
        Zmax = Zmin;
        coFirst = false;
      } else {
        if (Xmin > p[1]) Xmin = p[1];
        if (Xmax < p[1]) Xmax = p[1];
        if (Ymin > p[2]) Ymin = p[2];
        if (Ymax < p[2]) Ymax = p[2];
        if (Zmin > p[3]) Zmin = p[3];
        if (Zmax < p[3]) Zmax = p[3];
      }
    });
    Xmin = 0.5 * (Xmin + Xmax);
    Ymin = 0.5 * (Ymin + Ymax);
    Zmin = 0.5 * (Zmin + Zmax);
    if (
      Math.abs(Xmin) > 0.001 ||
      Math.abs(Ymin) > 0.001 ||
      Math.abs(Zmin) > 0.001
    ) {
      variant.geom.lIDXYZ.forEach((p) => {
        p[1] -= Xmin;
        p[2] -= Ymin;
        p[3] -= Zmin;
      });
    }
  }

  /*/____________________________________________________________________________________________________
  importSKP() {
    let irow = 0,
      //iNr = 0,
      idPoly = 0,
      idVertex = 1,
      s = this.fReaderJSON.result, // as string,
      ss = s.replace(/\r\n/g, "\r").replace(/\n/g, "\r").split(/\r/),
      L = ss.length - 1;

    if (L > 3) {
      if (ss[0] === "WUFIplus geometric data exported from SketchUp") {
        let countZones = parseInt(ss[1], 10),
          countPolys = parseInt(ss[2], 10);

        if (countZones === 0) return;

        if (!Number.isNaN(countZones) && !Number.isNaN(countPolys)) {
          irow = 3;
          var variantLoc = JSON.parse(JSON.stringify(variantJSON)),//this.props.inputDataModal(),//App has this always current
              geomJSON = variantLoc.geom;

          geomJSON.lIDXYZ = [];
          let indexIDredundant = [],
              maxCompId = -1; 

          variantLoc.id = 1;
          for (let iz = 0; iz < countZones; iz++) {
            let sn = app.utils.nK("tvZ") + " " + (iz + 1).toString(), //"Zone"
              z = JSON.parse(JSON.stringify(zoneJSON));
            z.n = sn;
            z.id = iz + 1;
            variantLoc.building.lZone.push(z);
          }

          while (irow < L) {
            let ssline = ss[irow++].split(";"),
              componentType = 1;

            if (ssline[0] === "Transparent") componentType = 2;
            else if (ssline[0] === "Opening") componentType = 3;

            let indexI = this.indexIE(ssline[1], countZones),
              indexE = this.indexIE(ssline[2], countZones),
              compId = Number(ssline[3]),
              nV = [];

            if (compId > maxCompId) maxCompId = compId;
            if (variantLoc.building.lComponent.find((c) => c.id === compId))  indexIDredundant.push(variantLoc.building.lComponent.length);

            if (indexI < 0 && indexE >= 0) {
              let iii = indexE;
              indexE = indexI;
              indexI = iii;
            }
            for (let i = 0; i < 3; i++) nV.push(Number(ssline[i + 4]));

            let CountLoops = Number(ss[irow++]);
            for (let il = 0; il < CountLoops; il++) {
              let polyJS = {};
              polyJS["id"] = ++idPoly;
              polyJS["idVert"] = [];
              polyJS["idPolyI"] = [];
              polyJS["nVec"] = [];
              for (let i = 0; i < 3; i++) polyJS["nVec"].push(nV[i]);

              let comp = JSON.parse(JSON.stringify(componentJSON));
              if (il === 0 ) variantLoc.building.lComponent.push(comp);
              //else console.log("compId", compId);
              comp.typeC = componentType;
              comp.idPolyC.push(idPoly);
              comp.colorEC = [200, 0, 0, 255];
              comp.colorIC = [200, 0, 0, 255];
              comp.idIC = indexI + 1; 
              comp.idEC = indexE;
              comp.id = compId;

              comp.n =
                app.utils.nK("tvC") + //"Component" +
                " " +
              variantLoc.building.lComponent.length.toString();

              let CountVertices = Number(ss[irow++]);
              for (let iv = 0; iv < CountVertices; iv++) {
                ssline = ss[irow++].split(";");
                polyJS.idVert.push(idVertex);
                let vertex = [idVertex++];
                for (let i = 0; i < 3; i++) {
                  vertex.push(Number(ssline[i]));
                }
                if (il === 0) geomJSON.lIDXYZ.push(vertex);
              }
              if (il === 0) geomJSON["lPoly"].push(polyJS);
              componentType = 3;
            }
          }

          indexIDredundant.forEach(
            (idC) => {variantLoc.building.lComponent[idC].id = ++maxCompId;  
            });

          this.checkPolygonIncluding(variantLoc);  //must be here
          this.CenterAxis(variantLoc);
          app.TS[1].webGL.newVariant(variantLoc);
          
        }
      }
    }
  }*/

  /*/____________________________________________________________________________________________________
  checkPolygonIncluding(variant) {

    this.webGL.newVariantGeometryImport(variant);

    let L = this.webGL.listPolygonGL.length;
    for (let i1 = 0; i1 < L; i1++) {
      let p0 = this.webGL.listPolygonGL[i1];
      for (let i2 = 0; i2 < L; i2++) {
        if (i1 !== i2) {
          let p = this.webGL.listPolygonGL[i2];
          if (p0.polGinside(p)) {
            p0.polyJSON.idPolyI.push(p.polyJSON.id); 
          }
        }
      }
    }
  }*/

  //___________________________________________________________________________
  render() {
    return (
      <div ref={this.refModal} id="mainXYZ" className="Modal">
        <div ref={this.refSideBar} className="geometry-sidebar-container">
          <input
            type="file"
            accept={".wps"}
            ref={this.refFileJSON}
            onChange={this.onChangeFileJSON}
            className="geometry-input-data"
          />

          <button
            className="geometry-sidebar-btn"
            onClick={this.buttonLoadWPSClick}
          >
            Load wps file
          </button>

          <label className="geometry-lbl-sidebar">
            Building/Zones:
            <select
              className="geometry-inpt-select"
              name="BZones"
              id="zb-select"
              value={this.state.valSelZone}
              onChange={this.selZoneChanged}
            >
              {this.createListBZones()}
            </select>
          </label>

          <label className="geometry-lbl-sidebar">
            Components:
            <select
              className="geometry-inpt-select"
              name="Comps"
              id="comp-select"
              value={this.state.valSelComp}
              onChange={this.selCompChanged}
            >
              {this.createListComps()}
            </select>
          </label>

          <button
            className="geometry-sidebar-btn"
            onClick={this.buttonAcceptClicked}
          >
            {app.utils.nK("b_Acc")}
          </button>
          <button
            className="geometry-sidebar-btn"
            onClick={this.bottonCancelClicked}
          >
            {app.utils.nK("b_Canc")}
          </button>
        </div>

        <div className="threeScene" ref={this.refFormGL} key="divFormGL">
          <ThreeScene inr = {1}/>
        </div>
      </div>
    );
  }
}

export default GeometryTool;
