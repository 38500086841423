import React, { Component } from 'react';
import {FormEdit} from "../editors/FormEdit"; 
import {WaitSpin} from "../editors/WaitSpin"; 
import {app} from "./App";
import ThreeScene from '../webGL/WebGLThreeScene';
import "./Tools.css";
//
export class Tools extends Component {
  
  //___________________________________________________________________________
  constructor(props) {
    super(props);  
    this.state =  {wait : false};
    this.refFE = React.createRef();
    this.refFormGL = React.createRef();

    this.windowResized = this.windowResized.bind(this);
    this.buttonClicked = this.buttonClicked.bind(this);
    this.updateDim = this.updateDim.bind(this);
    this.SSwait = this.SSwait.bind(this);  //Start/Stop

    if (this.props.nrTool === 1) this.btnKeys = [["b_GB", false], ["b_Acc", true], ["b_Canc", false]];
    else if (this.props.nrTool === 2) this.btnKeys = [["b_ip",true],["b_ipn",true],["b_Close",false]];
    else this.btnKeys = [];
  }

  //___________________________________________________________________________
  SSwait(co){  //Stop/Start
    this.setState({wait : co});  
  }

  //___________________________________________________________________________
  componentDidMount() {
    this.FE = FormEdit.FEs;  console.log("BBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBBB",this.FE.form);
    
    window.addEventListener("resize", this.windowResized);  
    this.updateDim();
    //this.FE.reRender();
    // if (this.FE.form){
    //   let btnkeys = this.FE.form.getBtnKeys();  
    //   if (btnkeys) this.setState({btnKeys:btnkeys});  
    // }
    
  }

  //___________________________________________________________________________
  componentWillUnmount(){
    window.removeEventListener("resize", this.windowResized); 
  }

  //____________________________________________________________________________
  windowResized() {
    this.updateDim();
    //if (this.FE) this.FE.reScale();
    if (this.props.nrTool === 1) app.TS[1].wResized();
  }

  //____________________________________________________________________________
  buttonClicked(sk){
    this.FE.ButtonClicked(sk);
  }

  //____________________________________________________________________________
	updateDim()	{
    let i = this.props.inr;   
    app.FE[i].mW = this.refFE.current.offsetWidth - 5;
    app.FE[i].mH = this.refFE.current.offsetHeight -30;

    if (this.refFormGL && this.refFormGL.current) {
      app.TS[1].mW = this.refFormGL.current.clientWidth;
      app.TS[1].mH = this.refFormGL.current.clientHeight;
    }  
    app.FE[i].setState({mw:app.FE[i].mW, mH: app.FE[i].mH });
	}

  //___________________________________________________________________________
  render() {
    let sn = app.utils.nK("s_tool") + ": " + this.props.sT  + "     ";
    const sL = {  marginLeft: 5};
    return (
      <>
      <div className="tool-container"  id = "ToolsMain"  key={'divFormEditTool_12'}> 

        <div className="tool-header"> 
          <label style = {sL}>{sn}</label>
          {this.btnKeys.map((b, i) => (
            <button disabled={(b[1])?this.state.wait:false} className = "tool-edt" key = {b+i} onClick={() => {this.buttonClicked(b[0])}} >{app.utils.nK(b[0])}</button>
          ))}
        </div>

        <div ref={this.refFE} className="tool-main" key={'divFormEditTool'} > 
			    <FormEdit 
            inr = {this.props.inr}  
            t = {this.props.t}
            coDB = {false}
            nrTool = {this.props.nrTool} 
            cancelModal={this.props.cancelModal} 
            SSwait = {this.SSwait}

            importVar = {this.props.importVar}
            setActProject = {this.props.setActProject} 
            >
          </FormEdit>
		      {
            this.props.nrTool === 1 ? (
              <div  ref={this.refFormGL} key="divFormGL" className="three"><ThreeScene wait = {this.state.wait} inr = {1}/> </div>
            ) :null
          }
        </div> 

      {this.state.wait ? (<WaitSpin size = {0.4} />) : (null)}
    </div> 
    </>
    );
  }
}

export default Tools;

/*
.spinner-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}*/

/*
.spinner-container {
   display: block;
  position: absolute;
  top: 150px;
  left: 555px;
}
*/