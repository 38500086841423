export const authConfig = {
    auth: {
      clientId: "30c0936c-1464-46ab-ab9f-6112e4a44921",
      authority:"https://c3rro.b2clogin.com/c3rro.onmicrosoft.com/B2C_1_sing_up_sign_in",
      redirectUri: "/",
      postLogoutRedirectUri: "/logout",
      knownAuthorities: ["c3rro.b2clogin.com"]
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
    },
  };
export const scope = ["https://c3rro.onmicrosoft.com/webapi/access_as_user"];

