//import { DataType } from "../data/KeyDefJSON";
//import { oOK, px } from "../data/Utils"
//import { actData } from "../data/ActData";
//import { Settings } from "../data/Settings";
//import { EditableSelect } from "react-editable-select";
//import { getTableText, getTableListChoice, getTableValueEdit } from "../data/Data";
import { createElsvg, createElinput } from "./EditHelpers";
import {app} from "../ahome/App";

//import React from "react";


//________________________________________________________________________________________________________
export class EditorInput {
  fo; //: any[];         //foreignObjects 0 - DataType.text_listChoice

  //ed; //: any[];       //editors 0 - DataType.countSimpleData
  refText; // : any;   //reference to text
  refRect; // : any;

  actDataType = app.utils.dType.undef;
  parentSVG; //: any;
  indexData = -1;
  indexColumn = -1;
  indexRow = -1;

  pT = null; //parentTable if not null than belongs to table
  pO = null; //parent object another object eg. TreeView

  MinMaxStep = { m: undefined, M: undefined, step: undefined };

  actE = null;

  //not explicit----------------------
	//noBorder: bool  current mode set at constructor
  //valChanged - callback (value) called when value changed
  

  //_______________________________________________________________________________
  constructor(noBorder) {
    let el, // : any,
        i, //: number;
        dtt = app.utils.dType;

    this.noBorder = noBorder;
    this.inputChange = this.inputChange.bind(this);
    this.onFocusEl = this.onFocusEl.bind(this);

    this.fo = [];

    for (i = 0; i < dtt.countSimpleData; i++) {
      if (i === dtt.text) {
        el = createElinput("input", "type", "text", "autoComplete",'off');
        this.addSettings(el, i, 0);
      } 
      else if (i === dtt.number) {
        el = createElinput("input", "type", "number", /*"step",  0.01,*/  "min",    0,    "max",  999, "autoComplete","off");
        this.addSettings(el, i, 0);
      } 
      else if (i === dtt.listChoice) {
        el = createElinput("select");
        this.addSettings(el, i, 0);
      } 
      else if (i === dtt.boolean) {
        el = createElinput("input", "type", "checkbox");
        this.addSettings(el, i, 0);
      } 
      else if (i === dtt.color) {
        el = createElinput("input", "type", "color");
        this.addSettings(el, i, 0);

      } 
      else if (i === dtt.date) {
        el = createElinput("input", "type", "date");
        el.style.fontFamily = "Arial";
        this.addSettings(el, i, 0);

      } 
      else if (i === dtt.time) {
        el = createElinput("input", "type", "time");
        el.style.fontFamily = "Arial";
        this.addSettings(el, i, 0);
      } 
      else if (i === dtt.datetime) {
        el = createElinput("input", "type", "datetime-local");
        this.addSettings(el, i, 0);
      } 
//-------------------------------------------------------------------------------------
      else if (i === dtt.TextlistChoice) {
        el = createElinput("select");
        this.addSettings(el, i, 0);
        el.setAttribute("edt", app.utils.dType.listChoice);
        el.setAttribute("indexEditor", 1);
      
        el = createElinput("input", "type", "text", "autoComplete","off");//,
        this.addSettings(el, i, 1);
        el.setAttribute("edt", app.utils.dType.text);
        el.setAttribute("indexEditor", 0);
        this.fo[i].appendChild(el);
      }
    }
  }

  //_______________________________________________________________________________
  setReadOnly(ro){
    if (this.actE){
      if (ro) this.actE.setAttribute("readonly", "readonly");
      else this.actE.removeAttribute("readonly");
    }
  }
  //_______________________________________________________________________________
  addSettings(el, inr, ielnr){ //ielnr index editor on foreignObject
    el.style.fontFamily = "Arial";
    el.style.fontSize = app.utils.settings.Table.sFontSize;

    if (inr === app.utils.dType.boolean) {
      el.style.height = (24 - 8).toString() + "px"; //(Settings.Tab.heightRow - 8).toString() + "px";
      el.style.verticalAlign = "-14%";
      
    } 
    else el.style.height = "100%";


    if (this.noBorder){
      el.style.border = "none";//"1px solid red";//"none";
      el.style.outline = "none";
    }
    else{
      el.style.border = "1px solid black";
      el.style.outline = "none";
      el.autofocus = true;
    }
   
    
    el.style.fontFamily = "Arial";
    el.style.fontSize = app.utils.settings.Table.fontSize;
    if (this.noBorder) {
      el.key = "input" + inr.toString();
      el.id = "input"; //important
    }
    else {
      el.key = "input" + (inr + 122).toString();
      el.id = "inputX"; //important
    }

    if (inr === app.utils.dType.TextlistChoice) {
      if (ielnr === 0) el.style.width = "100%";
      else {
        el.style.position = 'absolute';
        el.style.left = app.utils.px(0);
        el.style.top = app.utils.px(0);
        el.style.width = "calc(100% - " + app.utils.settings.Table.widthColSelCombi.toString() + "px)";
      }   
    } 
    else {
      el.setAttribute("edt", inr);
      el.style.width = "100%";
    }
    el.oninput = this.inputChange;
    //el.onchange = this.inputChange; ??? just observe
    if (this.noBorder) el.onfocus = this.onFocusEl;
    el.ondblclick = this.dblClick;

    if (ielnr === 0) {
      let eFO = createElsvg(
        "foreignObject",
        "x", 0,
        "y", 0,
        "width", "24px", //Settings.Table.heightRow,
        "height", app.utils.px(24 - 2), //Settings.Table.heightRow - 2,
        "position",  "absolute",
        "key",  "inputFO" + inr.toString()
      );
      eFO.appendChild(el);
      this.fo.push(eFO);   
    }
  }

  //________________________________________________________________________________________________________
  dblClick(e) {
    
    e.preventDefault();
    //console.log("double click");
  }

  //________________________________________________________________________________________________________
  inputChange(e) {  // console.log("checkDataRep(data)+++++++++++++++++++", app.projectJSON);
    this.liR = undefined;
    e.preventDefault();  
    let pTT = this.pT;
    if (pTT) {
        var dt = pTT.getDataType(this.indexData, this.indexColumn,1),
        edt = Number(e.target.getAttribute("edt")),
        res = e.target.value, //this.ed[dt].value,
        s = "",
        indexEditor = 0;

      if (dt === app.utils.dType.TextlistChoice) {
        indexEditor = Number(e.target.getAttribute("indexEditor"));
      };

      //if (edt === DataType.listChoice) { //this is now in this.pT.setValueEdit(this.indexData,   this.indexColumn,  indexEditor,   res);
        //res = parseInt(e.target.value);
        //if (isNaN(res)) return;
      //}

      if (edt === app.utils.dType.number) {
        if (res.length === 0) res = null;
        else res = Number(res);
      }
      if (edt === app.utils.dType.boolean) res = e.target.checked;//this.ed[dt].checked;

      pTT.setValueEdit(this.indexData,   this.indexColumn,  indexEditor,   res);
      //???? ers happens here !!!if (!this.PT) return;
      if (edt === app.utils.dType.boolean) {
        this.liR = this.indexRow;
        this.liC = this.indexColumn;
        this.lpT = pTT;//this.pT;
        pTT.updateRowCol(this.indexRow, this.indexColumn);  
      }
      else if (edt === app.utils.dType.color) {
        if (this.refRect) this.refRect.style.fill = res;
      } 
      else {
        s = pTT.getText(this.indexData, this.indexColumn,1);
        if (edt === app.utils.dType.boolean && pTT.NoTextBoolean) s = "";
        if (this.refText) this.refText.textContent = s;
      }
      if (dt === app.utils.dType.TextlistChoice){
        if (indexEditor > 0){ //listChoice
          if (!pTT.isReadOnly(this.indexData, this.indexColumn, indexEditor,1)) {
            //this.fo[dt].children[0].focus(); //currently only
            pTT.findEditAbleAndSetEdit(this.indexRow, this.indexColumn, true);
          }
        }
      }
      pTT.inputChangedAfter(this.indexData,   this.indexColumn,  indexEditor,1);
    }
    else {
      if (this.refText) this.refText.textContent = e.target.value;
      if (this.valChanged) this.valChanged(e.target.value);
    }


    


    if (this.cTimerimer) app.SD.cTimer();  
  }


  //________________________________________________________________________________________________________
  onFocusEl(e) {
    if (app.utils.oOK(e)) e.preventDefault();
    if (this.pT && app.utils.oOK(this.indexData, this.indexColumn)) {
      var dt = this.actDataType,
          el,// = e.target,
          edt = Number(e.target.getAttribute("edt")),
          indexEditor = 0;

      if (dt === app.utils.dType.TextlistChoice) {
        indexEditor = Number(e.target.getAttribute("indexEditor"));
        if (indexEditor === 0) el = this.fo[dt].children[1];
        else el = this.fo[dt].children[0];
      } 
      else {
        el = this.fo[dt].children[0];
      }

      // if (edt === app.utils.dType.listChoice) {  onFocus not called for this control ?? but not remove yet 8.07.2024
      //   while (el.options.length) el.remove(0);
      //   var lc = this.pT.getListChoice(this.indexData,this.indexColumn,1);  
      //   if (lc) {
      //     lc.forEach((ell) => {
      //     el.appendChild(new Option(ell[1], ell[0]));  
      //     });
      //    } 
      // }

      let val = this.pT.getValueEdit(this.indexData, this.indexColumn,  indexEditor, 1);  

      if (app.utils.oOK(val)) {
        if (edt === app.utils.dType.boolean) el.checked = val;
        else if (dt === app.utils.dType.TextlistChoice) {
          el.value = val;
          if (indexEditor === 1) {  //list
            this.fo[dt].children[1].value = this.pT.getValueEdit(this.indexData, this.indexColumn,  0,  1);
          }
        }
        else el.value = val;   

        
        if (edt === app.utils.dType.number) {
          this.MinMaxStep.min = undefined;
          this.MinMaxStep.max = undefined;
          this.MinMaxStep.step = undefined;
          this.pT.getMinMaxStep(this.indexData, this.indexColumn, indexEditor, this.MinMaxStep);
          if (this.MinMaxStep.min) el.setAttributeNS(null, "min", this.MinMaxStep.min);
          else el.setAttributeNS(null, "min", -1e20);
          if (this.MinMaxStep.max)
            el.setAttributeNS(null, "max", this.MinMaxStep.max);
          else el.setAttributeNS(null, "max", 1e20);
          /*if (this.MinMaxStep && this.MinMaxStep.step)
            el.setAttributeNS(null, "step", this.MinMaxStep.step);
          else el.step = 1;*/
        }
      } 
      else {
        if (edt === app.utils.dType.boolean) el.checked = false;
        else el.value = "";
      }
      if(el) this.actE = el;
    }
  }

  //________________________________________________________________________________________________________
  updataData() {
    //to be supplemented !!!!!!!!!!!!!!!
  }

  //________________________________________________________________________________________________________
  hide() {
  if (this.parentSVG) {
      for (var i = 0; i < this.fo.length; i++) {
        if (this.parentSVG.contains(this.fo[i])) this.parentSVG.removeChild(this.fo[i]);
      }
    }
    this.actDataType = app.utils.dType.undef;
    this.pT = null;
  }

  //________________________________________________________________________________________________________
  setGeom(x,y,w){  //can be called from extern
    if (this.actDataType === app.utils.dType.undef) return;
    let dt = this.actDataType,
      dx = 3,
      dy = 1,
      dw = -6;

      if (dt === app.utils.dType.listChoice) {
        dx = 2;
        dw = -5;
      } 
      else if (dt === app.utils.dType.color) {
        dx = 0;
        dw = -4;
      } 
      else if (dt === app.utils.dType.boolean) {
        dy = 1; dx = -1;
      } 
      else if (dt === app.utils.dType.text_listChoice) {
        dx = 0;
        dw = -5;
      }  
      this.fo[dt].setAttribute("x", x + dx);
      this.fo[dt].setAttribute("y", y + dy);
      this.fo[dt].setAttribute("width", w + dw);
      this.fo[dt].setAttribute("height", this.pT.heightRow - 2);
  }

  //________________________________________________________________________________________________________
  setDataTable(t, indexData, indexRow, indexColumn,  parentSVG,  refData,  refRect,  x,  y,  w) {  
    if (!app.utils.oOK(t)) return;

    if (this.liR) this.lpT.updateRowCol(this.liR, this.liC);  
    
    let dt = t.getDataType(indexData, indexColumn,1),
        reject = (indexData === this.indexData && indexColumn === this.indexColumn && indexRow === this.indexRow);   

    if (dt === app.utils.dType.undef) return;

    if (!app.utils.oOK(this.pT)) reject = false;
    else{
      if (reject) reject = (dt >= app.utils.dType.text && dt < app.utils.dType.countSimpleData); 
      if (reject) reject = (this.pT.idNr === t.idNr && this.actDataType === dt);
    }
    reject = false; //??
    if (reject) this.fo[this.actDataType].children[0].focus();
    else /*if (t)*/ {
      this.hide();

      this.indexData = indexData;
      this.indexColumn = indexColumn;
      this.indexRow = indexRow;

      this.pT = t;
      this.actDataType = dt;

      this.parentSVG = parentSVG;
      this.refText = refData;
      this.refRect = refRect;

      this.setGeom(x,y,w);
      this.parentSVG.appendChild(this.fo[dt]);

      // if (dt === app.utils.dType.TextlistChoice){
      //   let co0 = t.isReadOnly(indexData, indexColumn, 0,1),
      //       el0 = this.fo[dt].children[0],
      //       el1 = this.fo[dt].children[1];
      //   if (co0) {
      //     el1.style.backgroundColor = app.utils.settings.Table.colorBKG;
      //     el1.readOnly = true;  
      //     el0.focus();
      //   } 
      //   else {
      //     el1.style.backgroundColor = 'white';
      //     el1.readOnly = false;
      //     el1.focus();
      //   }
      // }
      // else this.fo[dt].children[0].focus();



      if (dt === app.utils.dType.listChoice){  //this will not focuse, must be done extra
        let el = this.fo[dt].children[0];
        while (el.options.length) el.remove(0);
        var lc = this.pT.getListChoice(this.indexData,this.indexColumn,1);  
        if (lc) {lc.forEach((ell) => {el.appendChild(new Option(ell[1], ell[0])); }); } 
        let val = this.pT.getValueEdit(this.indexData, this.indexColumn,  0, 1);  

        if (app.utils.oOK(val)) el.value = val;     //console.log("dt === app.utils.dType.listChoice",lc,val);
      }
      else this.fo[dt].children[0].focus();
    }
  }

//________________________________________________________________________________________________________
  setTextEdt(parentO,  dt, parentSVG,  refData,  x,  y,  w, value) {
    this.hide();
    this.parentSVG = parentSVG;
    this.refText = refData;
    this.refRect = null;

    this.pT = null;
    this.pO = parentO;
    this.actDataType = dt;  

    this.fo[dt].setAttribute("x", x);
    this.fo[dt].setAttribute("y", y);
    this.fo[dt].setAttribute("width", w);
    this.fo[dt].setAttribute("height", app.utils.settings.Table.heightRow - 2);
    this.parentSVG.appendChild(this.fo[dt]);

    setTimeout(() => {
      this.fo[dt].children[0].value = value;
      this.fo[dt].children[0].focus();
      this.fo[dt].children[0].select();
     }, 0); 
  }
}

        