
//________________________________________________________________________________
export class DataCheck {
    OK = false;
    nameKey = "";
    nameData = "";
    dataKey = "";
    errorKey = "";
    itemKey = "s_Item"; //can be changed from extern
    nrItem = -1;

    

    //____________________________________________________________________________
    constructor(app){
      this.app = app;
      this.utils = app.utils;
    }

    //____________________________________________________________________________
    init(){
        this.OK = true;
        this.nameKey = "";
        this.dataKey = "";
        this.nameData = "";
        this.errorKey = "";
        this.itemKey = "s_Item"; //can be changed from extern after init
        
        this.nrItem = -1;
    }

    //____________________________________________________________________________
    setError(nameKey, dataKey, errorKey, nrItem) {  //nameKey optional e.g. material function   //setError(nameKey: string, dataKey: string, errorKey: string, nrItem: number)
        if (this.OK) {
            this.nameKey = nameKey;
            this.dataKey = dataKey;
            this.errorKey = errorKey;
            this.nrItem = nrItem;
            this.OK = false;
        }
    }

    

    //____________________________________________________________________________
    getMinMax(dataKey){
        let mm = {coMin: false, coMax: false, m:0, M:0};
        if (this.app.def.hasOwnProperty(dataKey)) {
            let def = this.app.def[dataKey];

            if (def.hasOwnProperty("min")) {
                mm.coMin = true;
                mm.Min = this.app.def[dataKey]["min"];
            }
            if (def.hasOwnProperty("max")) {
                mm.coMax = true;
                mm.Max = this.app.def[dataKey]["max"];
            }
        }
        return mm;
    }

    

    //________________________________________________________________________________
    checkValMM(val, nameKey, dataKey, nrItem, mm) {     //dataOKval   // checkValMM(val: any, nameKey: string, dataKey: string, nrItem: number, mm: any)
      let co = this.utils.valOK(val);
      //if (val === undefined || val === null || isNaN(val)) co = false;
      if (co) {
        if (mm.coMin) {
          co = val >= this.app.def[dataKey]["min"];
          if (!co) this.setError(nameKey, dataKey, "s_vLow", nrItem);
        }
        if (mm.coMax) {
          co = val <= this.app.def[dataKey]["max"];
          if (!co) this.setError(nameKey, dataKey, "s_vHigh", nrItem);
        }
      } else this.setError(nameKey, dataKey, "s_ndeff", nrItem);
      return co;
    }

    //________________________________________________________________________________
    checkVal(val, nameKey, dataKey, nrItem) {     //dataOKval  //checkVal(val: any, nameKey: string, dataKey: string, nrItem: number)
      return this.checkValMM(val, nameKey, dataKey,  nrItem, this.getMinMax(dataKey));
    }

    //________________________________________________________________________________
    checkJS(js, nameKey, skeys) {    //dataOK //checkJS(js: any, nameKey:string, skeys: string[])
      let co = true;
      skeys.forEach((s) => {
        if (co) {
          co = js.hasOwnProperty(s);
          if (co) {
            let val = js[s];
            if (co) co = this.checkVal(val, nameKey, s, -1);
          } else this.setError(nameKey, s, "s_nProp", -1);
        }
      });
      return co;
    }

    //______________________________________________________________________________
    checkValsArrN(vals, nameKey, dataKey, mustGrow, indexFirst) {
      //vals is N dim array, N taken from dataKey  checkValsArrN(vals: any, nameKey: string, dataKey: string[], mustGrow: boolean[], indexFirst: number)
      let co = true,
        i = 0,
        j = 0,
        L = dataKey.length,
        coOne = L === 1,
        valp,//: any,
        valprev = [],
        mm = [];

      for (j = 0; j < L; j++) {
        valprev.push(0);
        mm.push(this.getMinMax(dataKey[j]));
      }

      vals.forEach((val) => {
        if (co) {
          i++;
          if (i > indexFirst) {
            for (j = 0; j < L; j++) {
              if (coOne) valp = val;
              else valp = val[j];
              co = this.checkValMM(valp, nameKey, dataKey[j], i, mm[j]);
              if (mustGrow[j] && co) {
                if (i > 1) {
                  if (valprev[j] >= valp) {
                    this.setError(nameKey, dataKey[j], "s_nAsc", i);
                    co = false;
                  }
                }
              }
              valprev[j] = valp;
            }
          }
        }
      });
      return co;
    }

  //____________________________________________________________________________
    setErrorName(nameData, errorKey, nrItem) {  //nameKey optional e.g. material function   //setError(nameKey: string, dataKey: string, errorKey: string, nrItem: number)
        if (this.OK) {
            this.nameData = nameData;
            this.errorKey = errorKey;
            this.nrItem = nrItem;
            this.OK = false;
        }
    }

    //______________________________________________________________________________
    checkValsArrMM(vals, vmin, vmax, nameData, mustGrow, indexFirst, indexLast) {
      if (indexFirst > indexLast){
        this.setErrorName(nameData, "s_Nvd", -1);
      }
      let valp;
      for (var i = indexFirst; i <= indexLast; i++){
        if (this.OK) {
          let val = vals[i];
          if (this.utils.valOK(val)){
            if (val < vmin) this.setErrorName(nameData, "s_vLow", i);
            else if (val > vmax) this.setErrorName(nameData, "s_vHigh", i);
            if (mustGrow){
              if (this.OK) {
                if (i > indexFirst){
                  if (val <= valp)  this.setErrorName(nameData, "s_nAsc", i);
                }  
                valp = val;
              }
            }
          }
          else this.setErrorName(nameData, "s_ndeff", i);
        }
      }
      return this.OK;
    }

    //______________________________________________________________________________
    paintStatus(ctx) {
        let x = 2 * this.utils.settings.Diagr.minDist,
            y = x;

        ctx.beginPath();
        ctx.textAlign = "left";
        ctx.textBaseline = "top";
        ctx.font = this.utils.settings.Diagr.sFont;

        if (this.nameKey.length > 0) {
            ctx.fillText(this.utils.nK(this.nameKey), x, y);
            y += this.utils.settings.Diagr.heightRow;
        }

        if (this.dataKey.length > 0) this.nameData = this.utils.nK(this.dataKey);

        if (this.nameData.length > 0) {
            ctx.fillText(this.nameData, x, y);
            y += this.utils.settings.Diagr.heightRow;
        }
        if (this.errorKey.length > 0) {
            ctx.fillText(this.utils.nK(this.errorKey), x, y);
            y += this.utils.settings.Diagr.heightRow;
        }
        if (this.nrItem > -1) {
            ctx.fillText(this.utils.nK(this.itemKey) + " " + (this.nrItem + 1).toString(), x, y);
            y += this.utils.settings.Diagr.heightRow;
        }
        ctx.stroke();
    }
}

//export var dCheck = new DataCheck();