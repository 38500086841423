import { createElsvg, createEltext } from "./EditHelpers"
import { getContextTabViewNode } from "../data/FormInfo"
//import { app.utils.settings } from "../data/app.utils.settings"
//import { textWidth } from "../data/Utils"
import {app} from "../ahome/App"

//_______________________________________________________________________________
export class TabViewNode {
    children = [];
    svgN;//: any;
    refSVGN;//: any;  //not used so far
    refText;//: any;  //not used so far
    refRect;//: any;  //not used so far
    refRectSelTop;//: any;  //not used so far
    level;//: number; 
    nrHor;//: number;  //consecutive number
    left = 0;
    top = 0;
    width = 100;
    nr = 0;   //consecutive number to find - "tabN": nr
    context = "";  
    isSelected = false;

    //_______________________________________________________________________________
    constructor(nrHor, level, left, top, scontext, FE) {     
      this.nrHor = nrHor;
      this.level = level; ////main has level = -1 is not displayed
      this.left = left;
      this.top = top;
      this.context = scontext;
      app.utils.settings.Tab.nr++;
      this.nr = app.utils.settings.Tab.nr;

      if (level < 0) {
        this.top = -app.utils.settings.Tab.heightRow + 1;
      } 
      else {
        var sn = this.getName(),
        sColorBKG = app.utils.settings.Tab.colorBKG,
        sColorBKGTop = app.utils.settings.Tab.colorBKG,
        sColorText = app.utils.settings.Tab.colorTextNotSel,
        sHeight = "100%";

        this.isSelected = app.def[this.context]["sel"];  

        if (this.isSelected) {
          FE.contextTab = scontext;
          sColorBKG = app.utils.settings.Tab.colorBKGSel;
          sColorBKGTop = app.utils.settings.Tab.colorTopLineSel;
          sColorText = "black";
          sHeight = (app.utils.settings.Tab.heightRow + 1).toString();
        }
        this.width = Math.max(50, app.utils.textWidth(sn, app.utils.settings.Tab.fontSize) + 10);

        this.svgN = createElsvg("svg",
          "x",  this.left,
          "y",  this.top,
          "width",  this.width,
          "height",   app.utils.settings.Tab.heightRow
        );

        this.refRect = this.svgN.appendChild(
          createElsvg("rect", //colorTopLineSel,
            "x",  0,
            "y",  0,
            "width", "100%",
            "height", sHeight,
            "fill",  sColorBKG,
            "stroke",  app.utils.settings.Tab.colorLines,
            "strokeWidth", "1px",
            "tabN",  this.nr,
            "key",  this.context
          )
        );

        this.refRectSelTop = this.svgN.appendChild(
          createElsvg("rect", //colorTopLineSel,  refRectSelTop
            "x",  1,
            "y", 1,
            "width",  this.width - 2,
            "height",  app.utils.settings.Tab.heightTopRectSel,
            "fill",  sColorBKGTop,
            "stroke", "none",
            "strokeWidth",  1,
            "tabN",   this.nr,
            "key",  this.context
          )
        );

        this.refText = this.svgN.appendChild(
          createEltext( sn,
            "x",  this.width / 2,
            "y",  app.utils.settings.Tab.heightRow / 2 + app.utils.settings.Tab.fontSize / 2 - 1,
            "fill", sColorText,
            "font-size", app.utils.settings.Tab.fontSize,
            "font-weight",  "normal",
            "text-anchor",   "middle",
            "cursor",   "default",
            "tabN",   this.nr,
            "key",  this.context
          )
        );
        this.refText.style.fontFamily = "arial";
        //this.refText.style.fontSize = app.utils.settings.Tab.fontSize;
      }
      if (this.level > -1) this.createChildren(FE);
    }

    //_______________________________________________________________________________
    createChildren(FE){
        if (!this.isSelected && this.level > -1) return;

        var levelp1 = this.level + 1,
            contexts = getContextTabViewNode(this.context, FE),//levelp1, this.nrHor),
            L = contexts.length;

        if (L > 0){
          let yy = this.top + app.utils.settings.Tab.heightRow,
              xx = 0,
              nrH = 0,
              cs = 0;   

            contexts.forEach(c => {
              if (cs === 1) app.def[c]["sel"] = false;
              if (app.def[c]["sel"]) cs++;
            }); 
            if (cs === 0) app.def[contexts[0]]["sel"] = true;

            if (levelp1 > 0) yy += app.utils.settings.Tab.heightBetweenRows;
            for (var  i = 0; i < L; i++){   
                let tabv = new TabViewNode(nrH, levelp1, xx, yy, contexts[i], FE);
                this.children.push(tabv);
                nrH++;
                xx += tabv.width;
            }
        }
    }

    //_______________________________________________________________________________
    getName() {
        return app.def[this.context]["n"];
    }

    //_______________________________________________________________________________
    findTabNode(nrTreeNode) {
        
    }
}