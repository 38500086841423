import React, { useState } from "react";
import { DataType } from "../data/KeyDefJSON";
import { app } from "../ahome/App";



export function EdtDef(props) {

  function oC() { }

  const [data, setData] = useState({});

  //var sL = {marginLeft: {props.mLeft ? props.mLet : 5} };
  //let ml = 5;
  let oc;
  const sE = {
    marginLeft: '2px',
    marginRight: '5px'
  };
  //if (props.mLeft) ml = props.mLeft;
  if (props.onChange) oc = props.onChange;
  else oc = oC;
  var sL = { marginLeft: props.ml };
  if (props.ojs && app.def[props.skey].t === DataType.listChoice) {
    return (
      <>
        <label style={sL}>{app.def[props.skey].n}</label>

        <select style={sE}
          value={props.ojs[props.skey]} disabled={props.disabled ? props.disabled : false}
          onChange={(e) => {
            setData({ [props.skey]: e.target.value });
            props.ojs[props.skey] = Number(e.target.value);
            oc();
          }}
        >
          {app.def[props.skey].sel.map((el, i) => (
            <option value={el[0].toString()} key={i}>{el[1]}</option>
          ))}
        </select>
      </>
    );
  }
  else if (props.ojs && app.def[props.skey].t === DataType.boolean) {
    const sCB = {
      display: "flex",
      alignItems: "center",
      marginLeft: '2px',
      marginRight: '5px',
      position: 'relative',
    };
    return (
      <>

        <label style={sCB}>
          <input
            type="checkbox"
            checked={props.ojs[props.skey] ? true : false} disabled={props.disabled ? props.disabled : false}
            onChange={(e) => {
              setData({ [props.skey]: e.target.value });
              props.ojs[props.skey] = Boolean(e.target.checked);
              oc();
            }}
          />

          {app.def[props.skey].n}
        </label>

      </>
    );
  }
  else return null;
}
