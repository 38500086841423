//__________________________________________________________________________________
export  const TreeViewType = {  //TreeViewNode type
  project: "tvP", //gets whole JSON projectData
  setWufi: "tvSs",
  sharedObj: "tvSo",
  cases: "tvCs",
  case: "tvC",
  loc: "tvLC",
  building: "tvB",
  simZones: "tvSz",
  attZones: "tvAz",
  zone: "tvZ",
  zoneTB: "tvZTb", //thermal bridges
  zoneLoads: "tvZIl",
  zoneDC: "tvZDc",
  zoneVent: "tvZV",
  zoneOP: "tvZTc", //other params
  visComp: "tvZVc",
  nvisComp: "tvZNvc",
  comp: "tvCo",
  obj3Ds: "tv3Ds",
  obj3D: "tv3D",
  hvac: "tvHSs",
  hvacS: "tvHS",
  hvacD: "tvHD",
  rEs: "tvRe",
  rE: "tvE",

  casesPH: "tvPHCs",
  casePH: "tvPHC",
  zoneVRPH: "tvZVr",

  cat: ""  //Catalogue
}; 


//_________________________________________________________________________________
export const DataType = {
    text: 0,
    number:1,     //1
    listChoice:2, //2
    boolean:3,    //3
    color:4 ,     //4
    date: 5,       //5
    time: 6,       //6
    datetime:7,   //7

    countSimpleData:8,

    text_listChoice:9, //edit text with preselection, so far not predefined in keys ?!
    menu: 10,

    rowTitle: 101,
    textInfo: 102,
    undef: 9999
}
