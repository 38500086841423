import { createElsvg, createEltext } from "./EditHelpers";
//import { TreeViewType } from "../data/KeyDefJSON";
//import { textWidth, getValueFromSel, nK, oOK } from "../data/Utils";
//import {  scopeMETR, scopePlus } from "../data/UtilsProj";
//import { Settings } from "../data/Settings"; 
import {app} from "../ahome/App"; 

import imgPlus from "../Img/plusLP.svg";
import imgMinus from "../Img/minusLP.svg";

import catGt from "../Img/catGt.svg";
import catG from "../Img/catG.svg";
import catYt from "../Img/catYt.svg";
import catY from "../Img/catY.svg";

import iHaMT from "../Img/hamt.svg"
import iBuilding from "../Img/building.svg"
import iCases from "../Img/cases.svg"
import iDesCond from "../Img/desCond.svg"
import iIntLoads from "../Img/iLoads.svg"
import iProj from "../Img/project.svg"
import iNvisComps from "../Img/nVisComps.svg"
import iO3D from "../Img/object3D.svg" 
import iComfort from "../Img/tComfort.svg"
import iRemEl from "../Img/remEl.svg"
import iRemEls from "../Img/remEles.svg"
import iSimZones from "../Img/simZones.svg"
import iTBs from "../Img/tBridges.svg"
import iVent from "../Img/vent.svg"
import iVisComps from "../Img/visComps.svg"
import iPointLoc from "../Img/pointLoc.svg"
import iZone from "../Img/zone.svg"
import zoneRooms from "../Img/zoneRooms.svg"
import aZones from "../Img/attZones.svg"
import iSharedO from "../Img/sharedO.svg"
import iPHcases from "../Img/phCases.svg"
import iPHcase from "../Img/phCase.svg"

import sSystem from "../Img/sSystem.svg"
import sSystems from "../Img/sSystems.svg"
import catYRt from "../Img/catYRt.svg"

import evCooler from "../Img/evCooler.svg"
import dHeat from "../Img/dHeat.svg"
import dWaterHR from "../Img/dWaterHR.svg"
import elHeater from "../Img/elHeater.svg"
import userDef from "../Img/userDef.svg"

import sHP from "../Img/sHP.svg"
import sBoiler from "../Img/sBoiler.svg"
import sMVent from "../Img/sMVent.svg"
import sSolarS from "../Img/sSolarS.svg"
import sWaterS from "../Img/sWaterS.svg"
import sSplit from "../Img/sSplit.svg"
import sPV from "../Img/sPV.svg"

import cRoof from "../Img/cRoof.svg"
import cInnerV from "../Img/cInnerV.svg"
import cRoofF from "../Img/cRoofF.svg"
import cRemain from "../Img/cRemain.svg"

import cOutVert from "..//Img/cOutVert.svg"
import cGroundV from "..//Img/cGroundV.svg"
import cGroundH from "..//Img/cGroundH.svg"
import cWindow from "../Img/cWindow.svg"
import cOpening from "../Img/opening.svg"
import coverAir from "../Img/overAir.svg"
//import door from "../Img/door.svg"
import cInnerH from "../Img/cInnerH.svg"



//----------------------------------------------------------------------------
export class ObjTreeNode {
    oJSON;//: any;
    
    parentO;//: ObjTreeNode; //beside "pD" always ObjTreeNode
    type;//: any;//TreeViewType;
    children;//: any[];
    svgN;//: any;
    refSVGN;//: any;
    refText;//: any;
    refPlus;//: any;
    refMinus;//: any;
    refIcon;//: any;
    refIconPM;//: any;
    refRect;//: any;
    nr = 0;  //consecutive global number, find   "treeN": nr
    nrLocal = 0;  //consecutive number for naming
    x = 2;
    y = 2;
    width = 100;
    wtext = 0;  //text width
    coExpanded = false;
    coPM = true;   //plus minus
    keyName = "";

    static idOsel = undefined; //currentlu CObject selected, for actualizing catalogue icons
    //not explicit--------------------------------------------------
    //oJSONDB - supporting catalogues name editing
    //idIcluded - bool for getting  not cataloged ids

    //----------------------------------------------------------------------------
    constructor(coPM, coExpanded, type, parentO, oJSON, FE, coSubNs) {  //constructor(coPM: boolean, coExpanded: boolean, type: any, parentO: any, oJSON: object)
        this.coPM = coPM;
        this.type = type;  //key
        this.parentO = parentO;
        this.oJSON = oJSON;
        this.children = [];
        //Settings.Tree.nr += 1;
        FE.nrTN += 1;
        this.nr = FE.nrTN;//Settings.Tree.nr;
        this.coExpanded = coExpanded;
        this.x = 2;  

        let sets = app.utils.settings;

        //if (!oJSON.hasOwnProperty("tv")) oJSON.tv = [];
        if (app.utils.oOK(parentO)) {
            if (parentO.oJSON !== oJSON) oJSON.tv = [];
        }
        else oJSON.tv = [];

        //oJSON.TV[type] = this.nr; 
        oJSON.tv.push(this.nr);


        if (parentO) {
            parentO.children.push(this);
            this.nrLocal = parentO.children.length;
            if (parentO.coPM) this.x = parentO.x + sets.Tree.heightTreeNode;
        }
        this.svgN = createElsvg('svg',
            'x', this.x,
            'y', 1,
            'width', "100%",
            'height', sets.Tree.heightTreeNode + 2,
            'fill', 'none',
             'stroke', 'none',
        );
        
        this.refRect = this.svgN.appendChild(createElsvg('rect',
            'x', 1,
            'y', 1,
            'width', '10',
            'height', '100%',
            'fill', 'white',
            "treeN", this.nr,
            'key', this.getID("R")));
        this.refText = this.svgN.appendChild(createEltext(this.getName(),
            'x', 1,
            'y', sets.Tree.heightTreeNode / 2 + sets.Tree.fontSize / 2 - 2,
            'fill', 'black',
            'font-weight', 'normal',
            //'alignment-baseline', 'central',
            'cursor', 'default',
            "treeN", this.nr,
            'key', this.getID("T")
        ));
        this.refText.style.fontFamily = 'arial';
        this.refText.style.fontSize = sets.Tree.sfontSize;

        if (this.coPM) {
            

            this.refPlus = this.svgN.appendChild(createElsvg('image',
                "href", imgPlus,
                'x', sets.Tree.xyRect,
                'y', sets.Tree.xyRect,
                'width', sets.Tree.whRect,
                'height', sets.Tree.whRect,
                'pm', 'P',
                "treeN", this.nr,
                'key', this.getID("P")));

            this.refMinus = this.svgN.appendChild(createElsvg('image',
                "href", imgMinus,
                'x', sets.Tree.xyRect,
                'y', sets.Tree.xyRect,
                'width', sets.Tree.whRect,
                'height', sets.Tree.whRect,
                'pm', 'M',
                "treeN", this.nr,
                'key', this.getID("M")));
        };

        let ic = this.getIconTVN();

        if (ic) {
            this.refIcon = this.svgN.appendChild(createElsvg('image',
                "href", ic,
                'x', sets.Tree.xyIcon,
                'y', sets.Tree.xyIcon,
                'width', sets.Tree.heightIcon,
                'height', sets.Tree.heightIcon,
                "treeN", this.nr,
                'key', this.getID("I")));
        }
        else {
            this.refIcon = this.svgN.appendChild(createElsvg('rect',
                'x', sets.Tree.xyIcon,
                'y', sets.Tree.xyIcon,
                'width', sets.Tree.heightIcon,
                'height', sets.Tree.heightIcon,
                'fill', "rgb(220,220,220)",
                'stroke', "rgb(10,10,60)",
                'stroke-width', '1px',
                'icon', 'i',
                "treeN", this.nr,
                'key', this.getID("I")));
        }
        if (coSubNs) this.createSubNodes(FE);
    } 

    //___________________________________________________________________________________________________________
    actualizeIcon() {
        this.refIcon.setAttributeNS(null, "href", this.getIconTVN());
    }

    //___________________________________________________________________________________________________________
    AddRemoveIdCODB(add, id){
        //if (id < 0) return;
        if (this.oJSON.co === undefined || this.oJSON.co === null) this.oJSON.co = [];
        if (add ) this.oJSON.co.push(id);
        else {
            if (this.oJSON.co.includes(id)) this.oJSON.co.splice(this.oJSON.co.indexOf(id), 1);
        }
        this.oJSON.coC = true;
    }

    //_________________________________________________________________________________
	RemoveCOidsDB(){
        let coI = this.oJSON.co.includes(ObjTreeNode.idOsel);
        if (coI) this.AddRemoveIdCODB(false, ObjTreeNode.idOsel);//this.oJSON.co.splice(this.oJSON.co.indexOf(ObjTreeNode.idOsel), 1);
        this.children.forEach(c => c.RemoveCOidsDB());
        
	}

//_________________________________________________________________________________
	checkNotCataloged(){
        if (this.oJSON.id !== -1 && this.oJSON.id !== -2){
            ObjTreeNode.idIcluded = this.oJSON.co.includes(ObjTreeNode.idOsel);// ObjTreeNode.idIcluded = true;
        }
        this.children.forEach(c => c.checkNotCataloged());
        
	}

    //_________________________________________________________________________________
    CheckIconDB(coGet){
        if (ObjTreeNode.idOsel){ 
            if (this.oJSON.id === -1){
            }
            else if (this.oJSON.id === -2){
                
            }
            else {
                let ic1, ic2;
                if (coGet){
                    ic1 = catYt;
                    ic2 = catY;
                }
                else{
                    ic1 = catGt;
                    ic2 = catG;
                }
                  

                if (this.oJSON.co.includes(ObjTreeNode.idOsel)) this.refIcon.setAttributeNS(null, "href", ic1);
                else this.refIcon.setAttributeNS(null, "href", ic2);
            
            } 
            this.children.forEach(c => c.CheckIconDB(coGet));
        }
    }

    //_________________________________________________________________________________
    getID(prefix)  //getID(prefix: string)
    {
        return "n" + this.nr.toString() + prefix;
    }

    //_________________________________________________________________________________
    getNameS() {//short - without name
        let n = app.utils.nK(this.type),//getBaseNameTreeViewNode(this.type);
            tvt = app.utils.treeVType;
        if (//this.type === TreeViewType.case ||
            this.type === tvt.zone ||
            this.type === tvt.comp ||
            this.type === tvt.hvacS ||
            this.type === tvt.hvacD ||
            this.type === tvt.casePH) {
            if (tvt.comp) n += this.nrLocal;
            else n += " " + this.nrLocal;
        
            if (this.oJSON) {
                if (this.type === tvt.hvacD){
                    n += " (" + app.utils.getValueFromSel("typeDev", this.oJSON.typeDev, 1) + ")";
                }
                if (this.oJSON.n) n += ": " + this.oJSON.n;
            }
        }
        if (this.type === tvt.case) {
            var i = this.oJSON.lVariant.findIndex(v => v.id === app.projectJSON.idVar) + 1;
            if (!app.utils.oOK(i)) i = 1;

            n += " " + i + "/" + this.oJSON.lVariant.length;  
            if (app.actVariantJSON.n) n += ": " + app.actVariantJSON.n;
        }
        return n;
    }

    //_________________________________________________________________________________
    getName() {
        if (this.type === app.utils.treeVType.cat)  return this.oJSON.n;
        let n = this.getNameS();
        //if (this.oJSON.n) n += ": " + this.oJSON.n;
        return n;
    }

    //_________________________________________________________________________________
    createSubNodes(FE) {
        let otn,//: ObjTreeNode,
            idz,//: number,
            d,
            tvt = app.utils.treeVType;

        switch (this.type) {
            case tvt.project:  //gets whole JSON projectData

                var ojson = this.oJSON;             
                this.keyName = "projD";
                this.oJSON = this.oJSON[this.keyName]; //exceptionally
            
                otn = new ObjTreeNode(true, false,  tvt.sharedObj, this, ojson, FE, false);
                ojson.tvSO = otn.nr;

                otn = new ObjTreeNode(false, true,  tvt.case, this, ojson, FE, true);  //Case/Variant
                break;
            
            case tvt.case: 
                otn = new ObjTreeNode(true, false, tvt.loc, this, app.actVariantJSON.cliLoc, FE, false);
                otn.keyName = "climateLoc";

                if (app.utils.scopePlus()){
                    otn = new ObjTreeNode(true, true, tvt.setWufi, this, app.actVariantJSON.HaMT, FE, false);
                }
                else if (app.utils.scopeMETR()) {
                    otn = new ObjTreeNode(true, true, tvt.casesPH, this, app.actVariantJSON.PHIUS, FE, true);

                }

                

                otn = new ObjTreeNode(true, true, tvt.building, this, app.actVariantJSON.building, FE,true);

                otn = new ObjTreeNode(true, true, tvt.hvac, this, app.actVariantJSON.HVAC, FE,true);
                break;


            /*case TreeViewtype.cases:  dont remove Later USE Cases!!
                this.keyName = "cases";
                this.oJSON.forEach((el: any) => {
                    otn = new ObjTreeNode(true, TreeViewtype.case, this, el);
                    otn.createSubNodes();
                });
                break;
            case TreeViewtype.case:
                this.keyName = "case";
                otn = new ObjTreeNode(true, TreeViewtype.loc, this, this.oJSON["cliLoc"]);
                otn.keyName = "climateLoc";

                otn = new ObjTreeNode(true, TreeViewtype.building, this, this.oJSON["building"]);
                otn.createSubNodes();

                otn = new ObjTreeNode(true, TreeViewtype.hvac, this, this.oJSON["HVAC"]);
                otn.createSubNodes();
                break;*/



            case tvt.building:
                this.keyName = "building";   

                
                otn = new ObjTreeNode(true, true, tvt.simZones, this, this.oJSON, FE,true); //also building

                otn = new ObjTreeNode(true, false,tvt.attZones, this, this.oJSON, FE,true);

                    //otn = new ObjTreeNode(true, TreeViewType.obj3Ds, this, this.oJSON);  do not remove
                    //otn.createSubNodes();

                otn = new ObjTreeNode(true, false, tvt.rEs, this, this.oJSON, FE,true);               

                break;

            case tvt.casesPH: 
                if (this.oJSON.lCase.length === 0) this.oJSON.lCase.push(JSON.parse(JSON.stringify(app.tem.casePH_JSON)));
                this.oJSON.lCase.forEach((el) => {
                    otn = new ObjTreeNode(true, true, tvt.casePH, this, el, FE, false);
                    //otn.createSubNodes(FE);
                });
                break;    
            /*case TreeViewType.casePH:
                this.parentO.oJSON.lZone.forEach((el: any) => {
                    if (el["idBpPH"] === this.oJSON.id && el["typeZ"] === 1) {
                        otn = new ObjTreeNode(true, TreeViewType.zone, this, el);
                        otn.createSubNodes();
                    }
                });
                break;*/
            case tvt.simZones:
                this.oJSON.lZone.forEach((el) => {
                    if (el.typeZ === 1) {
                        otn = new ObjTreeNode(true, false, tvt.zone, this, el, FE, true);
                        //otn.createSubNodes(FE);
                    }
                });
                break;
            case tvt.attZones:
                this.oJSON["lZone"].forEach((el) => {
                    if (el["typeZ"] === 2) {
                        otn = new ObjTreeNode(true, false, tvt.zone, this, el, FE,true);
                    }
                });
                break;
            case tvt.zone:
                this.keyName = "zone";
                otn = new ObjTreeNode(true, false, tvt.visComp, this, this.oJSON, FE,true); 

                otn = new ObjTreeNode(true, false, tvt.nvisComp, this, this.oJSON, FE,true); 

                if (this.oJSON.typeZ === 1){
                    otn = new ObjTreeNode(true, false,  tvt.zoneTB, this, this.oJSON, FE,true);  

                    otn = new ObjTreeNode(true, false, tvt.zoneLoads, this, this.oJSON, FE,true);

                    if (app.utils.scopeMETR()){
                        //ventilation rooms  zoneVRPH,
                        otn = new ObjTreeNode(true, false, tvt.zoneVRPH, this, this.oJSON, FE);
                    }
                    else {
                        otn = new ObjTreeNode(true, false, tvt.zoneVent, this, this.oJSON, FE,true);

                        otn = new ObjTreeNode(true, false,  tvt.zoneDC, this, this.oJSON, FE,true);

                        otn = new ObjTreeNode(true, false, tvt.zoneOP, this, this.oJSON, FE,true);
                    }
                }
                break;
            case tvt.visComp:
                idz = this.oJSON["id"];
                if (app.utils.scopeMETR()) d = this.parentO.parentO.parentO.oJSON["lComponent"];
                else d = this.parentO.parentO.oJSON["lComponent"];
                d.forEach((el) => {
                    if (el["idIC"] === idz && el["visC"]) otn = new ObjTreeNode(true, false, tvt.comp, this, el, FE, false);
                });
                break;
            case tvt.nvisComp:
                idz = this.oJSON.id;
                if (app.utils.scopeMETR()) d = this.parentO.parentO.parentO.oJSON["lComponent"];
                else d = this.parentO.parentO.oJSON["lComponent"];
                d.forEach((el) => {
                    if (el["idIC"] === idz && (!el["visC"])) otn = new ObjTreeNode(true, false,tvt.comp, this, el, FE, false);
                });
                break;
            case tvt.obj3Ds:
                break;
            case tvt.hvac:
                d = this.oJSON["lSystem"];
                d.forEach((el) => {
                    otn = new ObjTreeNode(true, false, tvt.hvacS, this, el, FE,true);
                    //otn.createSubNodes();
                });
                break;
            case tvt.hvacS:
                d = this.oJSON["lDevice"];
                d.forEach((el) => {
                    otn = new ObjTreeNode(true, false, tvt.hvacD, this, el, FE, false);
                });
                break;
            case tvt.hvacD:

                break;
            case tvt.rEs:
                d = this.oJSON["lComponent"];
                d.forEach((el) => {
                    if (el["idIC"] < 0 && el["idEC"] < 0) otn = new ObjTreeNode(true, false, tvt.comp, this, el, FE, false);
                });
                break;
            case tvt.cat:

                break;
            default:  //catalogue

                break;
        }
    }

    //_________________________________________________________________________________
    createSubNodesCatalogues(DB,FE) {   //DB- list catalogues
        if (this.oJSON){
            var otn,//: ObjTreeNode,
                ch;
                
            if (this.oJSON.id === -1){
                ch = [];
                DB.forEach(o => { //childrens of main
                    let co = true;
				    DB.forEach(oo => {if (oo.ch && oo.ch.includes(o.id)) co = false;});
                    if (co) ch.push(o.id);  //console.log(o.id, ch);}
				});
            }
            else{
                let dbb = DB.find(o => o.id === this.oJSON.id);
                if (dbb) ch = dbb.ch;
            }
            if (ch){
                ch.forEach(cid => {
                    let db = DB.find(o => o.id === cid);  //db - catalogue
                    if (db) {
                        if (db.co === undefined || db.co === null) db.co = [];
                        otn = new ObjTreeNode(true, true, app.utils.treeVType.cat, this, {n:db.n, id:db.id, co:db.co},FE, false);
                        otn.createSubNodesCatalogues(DB,FE);
                    }
                });
            } 
        }
    }

//_________________________________________________________________________________
	CObjectSelected(){  //only in DB, selected in form as CObject
        if (this.oJSON.id === -2) return;
        if (this.oJSON.id !== -1){
            if (this.oJSON.co.includes(ObjTreeNode.idOsel)) this.refIcon.setAttributeNS(null, "href", catGt);
            else this.refIcon.setAttributeNS(null, "href", catG);
        }
		this.children.forEach(c => c.CObjectSelected());
	}

    //_________________________________________________________________________________
    addDBres(DBres) {
        let id = this.oJSON.id;   
            
        if (id < -2 || this.oJSON.nC || this.oJSON.chC || this.oJSON.coC){ 
            if (this.oJSON.n && this.oJSON.n !== "" && this.oJSON.n !== " "){
                let cj = {id: id};
                if (this.oJSON.nC) cj.N = this.oJSON.n;
                //, n: this.oJSON.n, ch: [], NC: this.oJSON.nC, ChC: this.oJSON.chC }
                if (this.oJSON.chC) {
                    cj.ch = [];
                    this.children.forEach(c => cj.ch.push(c.oJSON.id));
                }
                if (this.oJSON.coC) cj.CO = this.oJSON.co;
                
                DBres.Cats.push(cj); 
            }  
        }
        this.children.forEach(c => c.addDBres(DBres));
    }

    //___________________________________________________________________________________________________________
    findParentZoneID() {
        let otn = this,//: ObjTreeNode = this;
        tvt = app.utils.treeVType;
        while (otn && (otn.type !== tvt.zone)) {
            otn = otn.parentO;
        }
        if (otn.type === tvt.zone) return otn.oJSON["id"];
        else return -1;
    }

    //___________________________________________________________________________________________________________
    actualizeText(){
        let s = this.getName();
        this.wtext = app.utils.textWidth(s, app.utils.settings.Tree.fontSize) + 5;//+ this.x + 10;
        this.refText.innerHTML = s;
        this.svgN.setAttributeNS(null, "width", this.getPosRight().toString());
        this.refRect.setAttributeNS(null, "width", this.wtext.toString());  
        this.refText.setAttributeNS(null, "width", this.wtext.toString());
    }

    //___________________________________________________________________________________________________________
    getPosRight(){
        let w = this.wtext + this.x + 6 + app.utils.settings.Tree.xyIcon + 
                app.utils.settings.Tree.heightIcon;
        if (this.coPM) w += app.utils.settings.Tree.xyRect + 
                            app.utils.settings.Tree.whRect + 3;
        return w; 
    }

    

    //___________________________________________________________________________________________________________
    getJsonArrayChildren(){
        let js = [];
        this.children.forEach(el => {js.push(el.oJSON)});
        return js;
    }

    //___________________________________________________________________________________________________________
    createChoiceListChildren(exceptId){//}: number) {
    let res = [],
        nr = 0,
        s = "";
    this.children.forEach((el) => {
        nr++;
        if (el.oJSON["id"] !== exceptId) {
            s = app.utils.nK(this.type)/*getBaseNameTreeViewNode(el.type)*/ + " " + nr.toString();
            if (el.oJSON["n"]) s += ": " + el.oJSON["n"];
            res.push([el.oJSON["id"], s]);
        } });
        return res;
    } 

    //___________________________________________________________________________________________________________
    renumChildren(){
        for (let i = 0; i < this.children.length; i++) {
            this.children[i].nrLocal = i+1;
            this.children[i].actualizeText();
        }
    }

    //__________________________________________________________________________________
    getIconTVN(){//}: ObjTreeNode): any {
    let ic = null,
    tvt = app.utils.treeVType;
    switch (this.type) {
        case tvt.project: ic = iProj; break; //gets whole JSON projectData
        //case TreeViewtype.sharedObj: ic = "Shared objects"; break;
        case tvt.cases: ic = iCases; break;
        case tvt.setWufi: ic = iHaMT; break;
        case tvt.zoneOP: ic = iComfort; break;
        case tvt.loc: ic = iPointLoc; break;
        case tvt.sharedObj: ic = iSharedO; break;
        case tvt.building: ic = iBuilding; break;
        case tvt.casePH: ic = iPHcase; break;
        case tvt.casesPH: ic = iPHcases; break;
        case tvt.simZones: ic = iSimZones; break;
        case tvt.attZones: ic = aZones; break;
        case tvt.zone:     ic = iZone; break;
        case tvt.zoneVRPH: ic = zoneRooms; break;
        case tvt.zoneTB: ic = iTBs; break; //thermal bridges
        case tvt.zoneLoads: ic = iIntLoads; break;
        case tvt.zoneDC: ic = iDesCond; break;
        case tvt.zoneVent: ic = iVent; break;
        case tvt.visComp: ic = iVisComps; break;
        case tvt.nvisComp: ic = iNvisComps; break;
        case tvt.obj3D: ic = iO3D; break;
        case tvt.hvac: ic = sSystems; break;//iSystems; break;
        case tvt.hvacS: ic = sSystem; break;
        case tvt.hvacD: ic = this.getIconDevice(); break;
        case tvt.rEs: ic = iRemEls; break;
        case tvt.rE: ic = iRemEl; break;
        case tvt.comp: ic = this.getIconComp(); break;

        case tvt.cat: ic = catYRt; break;
        default: ic = iCases; break;
    }   
    return ic;
}

getIconComp(){//: ObjTreeNode): any {
    var ic = null,
        comp = this.oJSON,
        coVisual = comp.visC,
        incl;//: number;

    if (coVisual){
        let jsGeomVisual = app.utils.getPolyData(comp, undefined, false);
        incl = jsGeomVisual.inclTMP; 
    }
    else incl = comp.inclC; 

    ic = cOutVert;
    if (comp){  
        let coZ = (comp.idIC >= 0),
            coZZ = (comp.idEC >= 0);
        if (comp.typeC === 2) ic = cWindow;
        else if (comp.typeC === 3) ic = cOpening;
        else if (coZ){  //inner to heated zone
            if (incl > 89 && incl < 91) { //vertical
                if (coZZ) ic = cInnerV;
                else if (comp.idEC === -2) ic = cGroundV;
                else if (comp.idEC === -1){} ic = cOutVert;
            }
            else if (incl < 1 || incl > 179) {  //horizontal
                if (coZZ) ic = cInnerH;
                else if (comp.idEC === -2) ic = cGroundH;
                else if (comp.idEC === -1) {
                    if (incl > 179) ic = coverAir;
                    else ic = cRoofF;
                }
            }
            else {  //sloped roof
                if (comp.idEC === -1) ic = cRoof;
            }
        }
        else ic = cRemain;
    } 
    return ic;
}

//__________________________________________________________________________________
getIconDevice(){//}: ObjTreeNode): any {
    var ic = null,
        dev = this.oJSON; 

    if (dev.typeDev === 1) ic = sMVent;
    else if (dev.typeDev === 2) ic = elHeater; //electric resistance
    else if (dev.typeDev === 3) ic = sBoiler; 
    else if (dev.typeDev === 4) ic = dHeat;   //district heating
    else if (dev.typeDev === 5) ic = sHP; 
    else if (dev.typeDev === 6) ic = sSolarS; 
    else if (dev.typeDev === 7) ic = userDef;   //user defined
    else if (dev.typeDev === 8) ic = sWaterS; 
    else if (dev.typeDev === 10) ic = sPV; //photovoltaics
    else if (dev.typeDev === 11) ic = evCooler;  //evaporative coolear
    else if (dev.typeDev === 12) ic = sSplit; 
    else if (dev.typeDev === 13) ic = dWaterHR;   //drain water heat recovery
    return ic;
}


}