//__________________________________________________________________________________
export var Settings = {
    Tree: {
        //nr: 0, //used to giv nr to nodes
        y: 2,
        //sRectPlus: "",
        //sRectMinus: "",
        heightTreeNode: 20,
        distTreeNode: 3,
        heightIcon: 18,
        xyIcon: 1,
        whRect: 12,
        xyRect: 4,
        xRect: 10,
        yRect: 5,
        fontSize: 13,
        sfontSize: "13px",
        sLinesPath: ""
    },
    Table: {
        heightRow: 24,
        heightTitleHalf: 10,
        heightTitle1Row : 25,
        heightTitle2Rows: 38,
        heightTitle3Rows: 58,
        heightTitle4Rows: 72,
        heightTitle5Rows: 88,
        witdhColNr: 28,
        widthColSelCombi: 20,
        widthColumnButtons: 50,
        distOtherTable: 2,
        widthScroll: 13,
        fontSize: 13,
        sFontSize: "13px",
        colorBKG: "rgb(245,245,245)",
        colorArBKG:[245,245,245],
        colorLines: "rgb(220,220,220)",
        colorRectSel: "rgb(125,50,50)",
        colorText: "rgb(0 ,0, 0)",
        colorTextTitle: "rgb(50, 50, 100)",
        colorTextRowTitle: "rgb(70, 70, 180)",
        whTick: 15
    },

    Tab: {
        nr: 0, //used to giv nr to nodes
        levelMax: -1,
        heightRow: 25,
        heightTopRectSel: 4,
        heightBetweenRows: 4,
        
        fontSize: 13,
        colorBKG: "rgb(255,250,240)",//"rgb(250,240,190)",
        colorBKGrest: "rgb(230,230,230)",
        colorBKGSel: "rgb(255,250,240)",
        colorTopLineSel: "rgb(240,190,80)",
        colorLines: "rgb(200, 200, 200)",
        colorTextNotSel: "rgb(110, 110, 110)"},
    
    Button: {
        heightButton: 24,
        textMargin: 4,
        fontSize: 13,
        sFontSize: "13px",
    },    
    form: {
        defaultLeft: 5,
        defaultDist: 6  //between edits
    },
    Diagr: {
        sFont: "12px Arial",
        heightBoxText: 12,
        minDist: 3,
        heightRow: 15
    },
    TS:{
        colorBKG: "rgb(217,216,205)"
    },
    SR:{h:30}  //StatusResult
}

/*/__________________________________________________________________________________
export var ActDim = {
    widthPanelTreeView: 0,
    heightPanelTreeView: 0,
    widthPanelEdit: 0,
    heightPanelEdit: 0,
    widthPanelGL: 0,
    heightPanelGL: 0,
    widthPanelResults: 0,
    heightPanelResults: 0,

    wTVdb:0,
    hdb:0,
    wFEdb:0
}*/