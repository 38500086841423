//import * as React from "react";
import { createElsvg, createPath } from "./EditHelpers";

export class TableScroll {
  nrTable = 0;
  colorBKG = "";
  colorScroll = "rgb(200,200,200)";

  yScrollArea = 0;
  heightScrollArea = 0;
  widthScrollArea = 0;
  heightScrollBar = 0;

  refs = [];
  lastIndexMouseDown = -1;
  lastYmouse = 0;

  scrollMax = 0;
  scrolled = 0;

  timerZeroUpDown = 0; //1-up, 2-down
  dummy = true;  // - shhould not react
  //not explicite-----------------------------
  //parentTable; //: any;
  

  constructor(nrTable){ //(nrTable: number)
  this.nrTable = nrTable;
  this.timerUpDown = this.timerUpDown.bind(this);
}

  //______________________________________________________________________________
  getId(nr) {   //nr: number
    return "t" + this.nrTable + "s" + nr;
  }

  //______________________________________________________________________________
  createRect( y,  h,  id)//: number //id - 1 top rech, 2,3,4 middle, 5- bottom rect
  {
    let sid = this.getId(id),
        colorLok = this.colorBKG;
    if (id === 2 && this.scrollMax > 0) colorLok = this.colorScroll;

    return createElsvg("rect",
      "x", 0,
      "y",  y,
      "width",this.width - 1,
      "height",h,
      "table", this.nrTable,
      "scroll", id,
      "fill", colorLok,
      "id", sid,
      "key", sid
    );
  }

  //______________________________________________________________________________
  getHeightRects() {
    if (this.scrollMax > 0) this.heightScrollBar = this.heightScrollArea / (this.scrollMax + 1);
    else this.heightScrollBar = Math.round(this.heightScrollArea / 3);
    return [
      this.widthScrollArea - 1,
      this.heightScrollBar * this.scrolled,
      this.heightScrollBar,
      Math.max(
        this.heightScrollArea - this.heightScrollBar * (this.scrolled + 1),
        0
      ),
      this.widthScrollArea - 1,
    ];
  }

  //______________________________________________________________________________
  createScroll( svgTable, x,  y,   width,   height,   colorBKG,   colorLines ) {

    this.dummy = (width < 1 || height< 1);
    if (this.dummy) return;

    this.colorBKG = colorBKG;  
    this.width = width;

    this.svgT = createElsvg('svg', 
      'x', x,
      'y', y,
      'width',width,
      'height', height,
      "shape-rendering", "crispEdges",
      "id", "scr" + this.nrTable,
      'overflow', 'hidden'
      );

    /*this.svgT.appendChild(createElsvg('rect',
      'x', 0,
      'y', 0,
      'width', width - 1,
      'height', height,
      'fill', this.colorBKG,
      'stroke', Settings.Table.colorLines,
      'stroke-width', '1px',
      "shape-rendering", "crispEdges",
      'id', this.getId(99)
    ));*/

      let ylok = 1,
          dx = width / 2 - 2,
          dy = 0.7 * dx,
          xlok = width / 2, 
          dh,
          ref,//: any,
          s = "";

    this.widthScrollArea = width;
    this.yScrollArea = width;
    this.heightScrollArea = height - 2 * width - 2;

    this.refs.length = 0;
    dh = this.getHeightRects();
    for (let i = 0; i < 5; i++) {
      ref = this.createRect(Math.round(ylok), dh[i], i);
      this.refs.push(ref);
      if (this.parentTable.scroller) this.svgT.appendChild(ref);
      ylok += dh[i];
    }
    ylok = width / 2;
    s = createPath(
      "M", xlok - dx,  ylok + dy,
      "L", xlok, ylok - dy,
      "L", xlok + dx,  ylok + dy,
      "Z"
    );
    if (this.parentTable.scroller){
      this.svgT.appendChild(createElsvg("path",
          "d", s,
          "fill",  this.colorScroll,
          "table",  this.nrTable,
          "scroll",  "0",
          "id",   "p" + this.getId(0)
        )
      );
    }
    ylok = height - width / 2;
    s = createPath(
      "M", xlok - dx, ylok - dy,
      "L", xlok, ylok + dy,
      "L",  xlok + dx, ylok - dy,
      "Z"
    );
    if (this.parentTable.scroller)
      this.svgT.appendChild(
        createElsvg("path",
          "d",  s,
          "fill",   this.colorScroll,
          //'stroke', this.colorScroll,
          "table",  this.nrTable,
          "scroll",  "4",
          "id", "p" + this.getId(4)
        )
      );
    svgTable.appendChild(this.svgT);

  }

  //______________________________________________________________________________
  adjustToScrolled() { 
    if (this.dummy) return;
    let dh = this.getHeightRects();

    this.refs[1].setAttributeNS(null, "height", dh[1]);
    this.refs[2].setAttributeNS(null, "height", dh[2]);
    this.refs[3].setAttributeNS(null, "height", dh[3]);
    this.refs[2].setAttributeNS(null, "y", this.yScrollArea + dh[1]);
    this.refs[3].setAttributeNS(null, "y", this.yScrollArea + dh[1] + dh[2]);

    if (this.scrollMax > 0) this.refs[2].setAttributeNS(null, "fill", this.colorScroll);
    else this.refs[2].setAttributeNS(null, "fill", this.colorBKG);
  }

  //______________________________________________________________________________
  adjustMouseEvent(dy) {
    if (this.dummy) return;
    let y1 = Number(this.refs[1].getAttribute("y")),
      h1 = Number(this.refs[1].getAttribute("height")),
      h2 = Number(this.refs[2].getAttribute("height")),
      h3 = Number(this.refs[3].getAttribute("height")),
      suh = h1 + h2 + h3,
      h1New = Math.min(Math.max(h1 + dy, 0), suh - h2),
      h3New = Math.max(suh - h1New - h2, 0);

    this.refs[1].setAttributeNS(null, "height", h1New);
    this.refs[3].setAttributeNS(null, "y", y1 + h1New + h2);
    this.refs[3].setAttributeNS(null, "height", h3New);
    this.refs[2].setAttributeNS(null, "y", y1 + h1New);

    let i = 0;
    while (i * this.heightScrollBar <= h1New) i++;
    i--;
    if (i !== this.scrolled && i >= 0 && i <= this.scrollMax) {
      this.scrolled = i;
      this.parentTable.scrolledChanged();
    }
  }

  //______________________________________________________________________
  mouseEvent(e) {
    if (this.dummy) return;
    if (!this.parentTable.scroller) return;

    if (e.type === "mouseup") {
      this.lastIndexMouseDown = -1;
      this.timerZeroUpDown = 0;
      return;
    }

    if (this.scrollMax < 1) return;

    if (e.type === 'wheel') { //no wheel in scroll !!
     // console.log('WHEEL',e.type, this.scrolled , this.scrollMax, e.deltaY);
            if (e.deltaY > 0)
            {
                if (this.scrolled < this.scrollMax) 
                {
                    this.scrolled++;
                    this.adjustToScrolled();
                    this.parentTable.scrolledChanged();
                }  
            }
            else if (e.deltaY < 0)
            {
                if (this.scrolled > 0) 
                {
                    this.scrolled--;
                    this.adjustToScrolled();
                    this.parentTable.scrolledChanged();
                }
            }
    }
    if (e.type === "mousedown") {
      this.lastIndexMouseDown = -1;
      let coOverThis =
        e.target.hasAttribute("table") && e.target.hasAttribute("scroll");
      if (coOverThis)
        coOverThis = e.target.getAttribute("table") === this.nrTable.toString();
      let nr = -1;
      if (coOverThis) nr = Number(e.target.getAttribute("scroll"));
      if (nr === 4) {
        if (this.scrolled < this.scrollMax) {
          this.scrolled++;
          this.adjustToScrolled(); 
          this.setTimerUpDown(2);
        }
      } else if (nr === 0) {
        if (this.scrolled > 0) {
          this.scrolled--;
          this.adjustToScrolled(); 
          this.setTimerUpDown(1);
        }
      } else if (nr === 1) {
        if (this.scrolled > 0) {
          this.scrolled = 0;
          this.adjustToScrolled(); 
        }
      }
      if (nr === 3) {
        if (this.scrolled < this.scrollMax) {
          this.scrolled = this.scrollMax;
          this.adjustToScrolled(); 
        }
      }
      this.lastIndexMouseDown = nr;
      if (nr === 2) {
        this.lastYmouse = Number(e.pageY);
      } else if (nr > -1) this.parentTable.scrolledChanged();
      return;
    }
    if (this.lastIndexMouseDown < 0) return;

    if (e.type === "mousemove" &&  e.buttons === 1 &&   this.lastIndexMouseDown === 2) {
      this.adjustMouseEvent(Number(e.pageY) - this.lastYmouse);
      this.lastYmouse = Number(e.pageY);
    }
    
  }

  //______________________________________________________________________________
  setTimerUpDown(SetTimerZeroUpDown) {  //SetTimerZeroUpDown: number
    this.timerZeroUpDown = SetTimerZeroUpDown;
    if (this.timerZeroUpDown) window.setTimeout(this.timerUpDown, 300);
  }

  //______________________________________________________________________________
  timerUpDown() {
    //console.log("mouse timer", this.scrolled, this.scrollMax);
    if (this.timerZeroUpDown === 1) {
      if (this.scrolled > 0) {
        this.scrolled--;
        this.adjustToScrolled(); 
        this.parentTable.scrolledChanged();
        if (this.scrolled > 0) this.setTimerUpDown(this.timerZeroUpDown);
        else this.timerZeroUpDown = 0;
      }
    } else if (this.timerZeroUpDown === 2) {
      if (this.scrolled < this.scrollMax) {
        this.scrolled++;
        this.adjustToScrolled(); 
        this.parentTable.scrolledChanged();
        if (this.scrolled < this.scrollMax)
          this.setTimerUpDown(this.timerZeroUpDown);
        else this.timerZeroUpDown = 0;
      }
    }
  }

  //______________________________________________________________________
  scrollTo(sc){
    this.scrolled = sc;
    this.adjustToScrolled(); 
    this.parentTable.scrolledChanged();
  }
}
