//import { app.utils.settings } from "../data/app.utils.settings";
import { createElsvg, createEltext } from "./EditHelpers";
//import { textWidth } from "../data/Utils"
import {app} from "../ahome/App"

//________________________________________________________________________________________________________
export class Otext {
    left = app.utils.settings.form.defaultLeft;
    top = -1;
    height = 0;
    width = 0;
    right = 0;
    bottom = 0;
    idNr = -1;
    coItalic = false;

    svgOwn;//: any;  
    skey;//: string;
    stext;//: string;
    shint;//: string;

    //____________________________________________________________________________________________________
    constructor(idNr, sKey) {//idNr: number, sKey: string
        this.idNr = idNr;
        this.skey = sKey;
        this.stext = app.def[sKey]["n"];
        this.shint = app.def[sKey]["hint"];
    }

    //____________________________________________________________________________________________________
    createObj() {
        this.createText();
    }

    //____________________________________________________________________________________________________
    createText() {
        if (this.width === 0) this.width = app.utils.textWidth(this.stext, app.utils.settings.Button.fontSize + 1) + 2 * app.utils.settings.Button.textMargin;
        if (this.height === 0) this.height = app.utils.settings.Button.heightButton;
        this.right = this.left + this.width;
        this.bottom = this.top + this.height;

        this.svgOwn = createElsvg('svg',
            //'x', this.left,
            //'y', this.top,
            'width', this.width,
            'height', this.height,
            "key", "Otext" + this.idNr.toString(),
            "id", "objTop" + this.idNr.toString(),
            'overflow', 'hidden',
            "Otext", this.idNr
        );

        this.svgOwn.style.position = "absolute";
        this.svgOwn.style.top = this.top + 'px';
        this.svgOwn.style.left = this.left + "px";

        var el = this.svgOwn.appendChild(createEltext(this.stext,
            'x', 0,
            'y', app.utils.settings.Table.fontSize,
            'font-size', app.utils.settings.Table.fontSize,
            'width', this.width,
            'id', this.idNr,
            'key', this.skey
            ));
        el.style.fontFamily = 'Arial';
        if (this.coItalic) el.style.fontStyle = 'italic';
    }

    //____________________________________________________________________________________________________
    getObjToAppend() {
        return this.svgOwn;
    }

    //_____________________________________________________________________________________________________
    onMouseEvent(e) { }

    //_____________________________________________________________________________________________________
    afterMounted() { }
}