//import { app.utils.settings } from "../data/app.utils.settings";
import { createElsvg, createEltext, createPath } from "./EditHelpers";
import {app} from "../ahome/App";
//import { px, textWidth, nK, app.utils.app.utils.ph, app.utils.app.utils.phh } from "../data/Utils";
import { GG } from "../report/ReportGener";

//________________________________________________________________________________________________________
export class GraphEl {
    type = -1;
    
    height = 0;
    width = 0;
    right = 0;
    bottom = 0;
    idNr = -1;
    hasCanvas = false;

    leftC = 0;
    topC = 0;
    widthC = 0;
    heightC = 0;

    imgs = []; //imaged

    svgOwn;//: any;
    //p - params;//: any;  not exolicite
    canv;// : any;
    ctx;// : any;
    nrFo = 0;
    WH = {W:0, H:0};


    //____________________________________________________________________________________________________
    constructor(idNr, type, createCanvas, p) {  //p - params is json object including value pairs 
        this.left = app.utils.settings.form.defaultLeft;
        this.top = app.utils.settings.form.defaultDist;

        this.idNr = idNr;  
        this.type = type; //0 - none (only canvas), 1-groupBox, 2 - shape, 3 - text
        this.hasCanvas = createCanvas;
        this.p = p; 

        function h(k){ return p.hasOwnProperty(k); }
 
        if (p){
            if (h('left')) this.left = p.left;
            if (h('top')) this.top = p.top;
            if (h('w')) this.width = p.w;
            if (h('h')) this.height = p.h;
            if (h('ad')) this.ad = p.ad;  //adjust dimensions to content
            if (h('HeW')) this.HeW = p.HeW; //number 1 - Height equals Width, 2-Width equals H
            if (h('MW')) this.MW = p.MW;
            if (h('mw')) this.mw = p.mw;
        }
    }

    //____________________________________________________________________________________________________
    setGeom(left, top, width, height){
        this.left = left;
        this.top = top;
        this.width = width;
        this.height = height;
    }

    //____________________________________________________________________________________________________
    setGeomCanvas(left, top, width, height) {
        this.leftC = left;
        this.topC = top;
        this.widthC = width;
        this.heightC = height;
    }

    //____________________________________________________________________________________________________
    addImg(x,y,w,h, href){  //href: string
        this.imgs.push({tg:1, x:x,y:y,w:w,h:h, href:href});  //t - type graphics = 1
    }

    //____________________________________________________________________________________________________
    addImgJS(js, key){
        this.imgs.push({tg:2, js:js, k:key});
    }

    //____________________________________________________________________________________________________
    addGG(gg, k){  //n = node = React.createElement('svg' ....
        this.imgs.push({tg:3, gg:gg, kgg: k});  //report
    }

    //____________________________________________________________________________________________________
    createObj() {
        this.createGraph();
    }

    //___________________________________________________________________________________________________
    createFO(x,y,w,h){
        this.nrFo++;
        this.fo = createElsvg('foreignObject',
            'x', x,
            'y', y,
            'width', w + "px",
            'height', h + "px",
            'position', 'absolute',
            'key', 'inputFO' + this.skey + this.nrFo + '_' + this.idNr,
            "id", "objTop" + this.idNr);
    }

    //____________________________________________________________________________________________________
    createGraph() {
        var widthContainer = this.FE.mW - 2 - this.left,
            heightContainer = this.FE.mH - this.FE.tabH - 1 - this.top;

        if (this.height < 1.01) this.height = this.height * heightContainer;
        if (this.width < 1.01) this.width = this.width * widthContainer;

        if (this.MW) this.width = Math.min(this.width, this.MW);
        if (this.mw) this.width = Math.max(this.width, this.mw);

        if (this.HeW){
            if (this.HeW === 1) this.height = this.width;
            if (this.HeW === 2) this.width = this.height;
        }
        //this.WH = {W:this.width, H:this.height};

        this.svgOwn = createElsvg('svg',
            //'x', this.left + 'px',
            //'y', this.top + 'px',// + 2*app.utils.settings.Table.fontSize,
            'width', this.width + 'px',
            'height', this.height + 'px',
            "key", "GraphEl" + this.idNr.toString(),
            "id", "objTop" + this.idNr.toString(),
            'overflow', 'hidden',
            "GraphEl", this.idNr
        );
        this.svgOwn.style.position = "absolute";
        this.svgOwn.style.top = this.top + 'px';
        this.svgOwn.style.left = this.left + 'px';
        
        if (this.imgs.length > 0){
            let ik = 0;
            this.imgs.forEach(im =>{
                if (im.tg === 1){
                    this.svgOwn.appendChild(createElsvg('image',  
                    "href", im.href,
                    'x', im.x,
                    'y', im.y,
                    'width',im.w,
                    'height', im.h,
                    'key', 'GraphEl_IMG' + ik));  
                }
                else if (im.tg === 2){
                    let js = im.js;
                    if (js && js[im.k]){
                        let w = 0,
                            h = 0, 
                            tfo = 1.5*app.utils.settings.Table.fontSize,
                            ww = this.width - 2,
                            hh = this.height - tfo - 2,
                        img = document.createElement('img');
                
                        img.addEventListener('load', () => {   
                            w = img.width;
                            h = img.height; 
                            if (w && h) {
                                let coef = 1;
                                if (h > w) {
                                    coef = w / h;
                                    w = ww * coef;
                                    h = hh;
                                }
                                else {
                                    coef = h / w;
                                    h = hh * coef;
                                    w = ww;
                                }
                                w--;
                                h--;
                                this.createFO((ww - w)/2, tfo + (hh - h)/2, w, h);
                                img.style.height = "100%";
                                img.style.width = "100%";
                                this.fo.appendChild(img); 
                                this.svgOwn.appendChild(this.fo);    
                            }           
                        });
                        img.src = js[im.k];  
                    }
                }
                else if (im.tg === 3){   
                    GG(2, 1.5*app.utils.settings.Table.fontSize,this.idNr, im.kgg, im.gg, this.svgOwn, null);
                    if (im.gg.WH){ 
                        if (im.gg.WH.H){
                            if (this.WH.H < im.gg.WH.H) this.WH.H = im.gg.WH.H; 
                        }
                        if (im.gg.WH.W){
                            if (this.WH.W < im.gg.WH.W) this.WH.W = im.gg.WH.W; 
                        }
                    }
                }
                ik++;
            });
        }

        if (this.ad){
            this.width = this.WH.W + 2;
            this.height = this.WH.H + 2; 
        }  

        if (this.hasCanvas){
            if (this.leftC === 0) this.leftC = 1;
            if (this.topC === 0) this.topC = app.utils.settings.Table.heightTitleHalf;
            if (this.widthC === 0) this.widthC = this.width - 2;
            if (this.heightC === 0) this.heightC = this.height - app.utils.settings.Table.heightTitleHalf - 1;

            this.createFO(this.leftC,this.topC,this.widthC,this.heightC);
            /*let fo = createElsvg('foreignObject',
                'x', this.leftC,
                'y', this.topC,
                'width', this.widthC,//app.utils.settings.Table.heightRow,
                'height', this.heightC,//app.utils.settings.Table.heightRow - 2,
                'position', 'absolute',
                'key', 'inputFO' + this.idNr.toString());   */

                /*const div = document.createElement("div");
                div.id = "divobjTop" + this.idNr.toString();
                div.setAttribute('width', px(this.widthC));
                div.setAttribute('height', px(this.heightC));
                div.setAttribute('graphEl', this.idNr.toString());
                div.setAttribute('style', "position:fixed");*/
            this.canv = document.createElement("canvas");
            this.canv.id = "objTop" + this.idNr.toString();
            this.canv.setAttribute('width', app.utils.px(this.widthC));
            this.canv.setAttribute('height', app.utils.px(this.heightC));

            this.canv.setAttribute('graphEl', this.idNr.toString());

            this.fo.appendChild(this.canv);
            //div.appendChild(this.canv);
            this.svgOwn.appendChild(this.fo);
            this.ctx = this.canv.getContext("2d",{antialias: false, depth: false});
        }
        
        
        this.createEl();

        this.right = this.left + this.width;
        this.bottom = this.top + this.height;
    }
    //____________________________________________________________________________________________________
    createEl() {
        if (this.type === 1){  //0 - none (only canvas), 1-groupBox, 2 - shape, 3 - text
            let coTitle = false,
                sTitle;
            if (this.p && this.p.t){
                sTitle = app.utils.nK(this.p.t);
                coTitle = true;
                if (this.p.Nr) {
                    if (this.p.tNr) sTitle += ", " + app.utils.nK(this.p.tNr); 
                    sTitle += " " + this.p.Nr;
                }
            }

            let x = 0; 
            if (coTitle) x =  app.utils.textWidth(sTitle, app.utils.settings.Table.fontSize) + 8;

            let s = createPath('M', x, app.utils.settings.Table.heightTitleHalf, 
                        'H', this.width, 'V', this.height - 1,
                        'H', 1,'V', app.utils.settings.Table.heightTitleHalf,
                        'h', 5);

            this.svgOwn.appendChild(createElsvg('path',
                        "d", s,
                        "fill", 'none',
                        "stroke", app.utils.settings.Table.colorLines,
                        "shape-rendering", "crispEdges"
                    ));
            if (coTitle){
                let el = this.svgOwn.appendChild(createEltext(sTitle,
                    'x', 5,//this.left + 5,
                    'y', 15,
                    'text-anchor', 'start',
                    'text-overflow', 'ellipsis',
                    "padding", "0px 0px 0px 0px",
                    //"margin", "0px",
                    'width', this.width - 2,
                    'cursor', 'default',
                    'fill', app.utils.settings.Table.colorTextTitle));

                el.style.fontFamily = 'Arial';
                el.style.fontSize = app.utils.settings.Table.sFontSize;
                el.style.fontWeight = "600";
            }

            if (this.leftC === 0) this.leftC = 1; 
            if (this.topC === 0) this.topC = app.utils.settings.Table.heightTitleHalf;
            if (this.widthC === 0) this.widthC = this.width - 2;
            if (this.heightC === 0) this.heightC = this.height - app.utils.settings.Table.heightTitleHalf - 1; 
        }
        else if (this.type === 2){//shape
            if (this.p.s){
                let stype = this.p.s,
                    lt = 1,  //line thickness
                    c = 'gray',
                    f = 'none';
                if (this.p.lt) lt = this.p.lt;  
                if (this.p.hasOwnProperty('c')) c = this.p.c;
                if (this.p.hasOwnProperty('f')) f = this.p.f;
                if (this.p.hasOwnProperty('lt')) lt = this.p.lt;
                if (stype === 'rect'){
                    let el = this.svgOwn.appendChild(createElsvg(stype,
                        'x',lt,
                        'y',lt,
                        'width',this.width - 2*lt,
                        'height',this.height - 2*lt,
                        'stroke',c,
                        'fill',f,
                        'stroke-width',lt
                        ));

                    if (this.p.hasOwnProperty('rxy')){
                        el.style.rx = this.p.rxy;
                        el.style.ry = this.p.rxy;
                    }    
                }
                else if (stype === 'circle'){
                    this.svgOwn.appendChild(createElsvg(stype,
                        'cx',this.p.cx,
                        'cy',this.p.cy,
                        'r',this.p.r,
                        'stroke',c,
                        'fill',f,
                        'stroke-width',lt
                        ));   
                }
            }
        }
        else  if (this.type === 3){//text
            let el = this.svgOwn.appendChild(createEltext(this.p.t,
                'x', 0,
                'y', this.height/2 + 0.1*app.utils.settings.Table.fontSize,
                'text-anchor', 'start',
                'alignment-baseline', 'middle',
                'text-overflow', 'ellipsis',
                "padding", "0px 0px 0px 0px",
                //"margin", "0px",
                'width', this.width,
                'cursor', 'default',
                'fill', app.utils.settings.Table.colorTextTitle));

            el.style.fontFamily = 'Arial';
            el.style.fontSize = app.utils.settings.Table.sFontSize;
            el.style.fontWeight = "600";
        }
        
    }

    //____________________________________________________________________________________________________
    paint(){
        if (this.hasCanvas){
            if (this.form.GraphElPaint) this.form.GraphElPaint(this, this.ctx, this.widthC, this.heightC);
        }    
    }

    //____________________________________________________________________________________________________
    getObjToAppend() {
        return this.svgOwn;
    }

    //_____________________________________________________________________________________________________
    onMouseEvent(e) {
        
        if (e.target.hasAttribute("GraphEl")) {
            if (e.target.getAttribute("GraphEl") === this.idNr.toString()) this.FE.GraphElMouseEvent(this, e);
        }
    }

    //_____________________________________________________________________________________________________
    getPixel(x,y){
        return this.ctx.getImageData(x, y, 1, 1);
    }

    //_____________________________________________________________________________________________________
    afterMounted() {
        this.paint();
    }

    //________________________________________________________________________________________
    reScale(p){ 
    }

    //___________________________________________________________________________________
    trbl(i){  
        let d = app.utils.settings.form.defaultDist;
        if (i === 0) return this.top;
        else if (i === 1) return this.right + d;
        else if (i === 2) return this.bottom + d;
        else return this.left - d;
    }

    //________________________________________________________________________________________________________
    rectG(x, y, w, h, ct) {  //called from forms
    //x: number, y: number, w: number, h: number, ctx: any
    let c = this.ctx;
    if (ct) c = ct;   
    c.strokeRect(app.utils.phh(x), app.utils.phh(y), app.utils.ph(w), app.utils.ph(h));
  }
  
  //________________________________________________________________________________________________________
    rectFillG(x, y, w, h, color, ct) {  //called from forms
    //(x: number, y: number, w: number, h: number, color: string, ctx: any
    let c = this.ctx;
    if (ct) c = ct; 
    c.fillStyle = color;
    c.fillRect(app.utils.phh(x), app.utils.phh(y), app.utils.ph(w), app.utils.ph(h));
  }

//   //________________________________________________________________________________________________________
//   clear(){
//     if (this.svgOwn){
//         while (this.svgOwn.lastChild) {
//             this.svgOwn.removeChild(this.svgOwn.lastChild);
//         }
//     }
//   }

}


