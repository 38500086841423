import {app} from "../ahome/App";


export class objectGL {
  oRender;
  existsInScene;

  //======================================================
  constructor(N) {
    this.oRender = new Array(N);
    this.existsInScene = new Array(N);
    for (var i = 0; i < N; i++) {
      this.oRender[i] = null;
      this.existsInScene[i] = false;
    }
  }

  //======================================================
  disposeGL() {
    for (let i = 0; i < this.oRender.length; i++) {
      if (this.oRender[i] && this.oRender[i].geometry) {
        this.oRender[i].geometry.dispose();
      }
      this.oRender[i] = null;
    }
  }

  //======================================================
  removeFromSceneGL(threeScene) {
    for (let i = 0; i < this.oRender.length; i++) {
      if (this.existsInScene[i]) {
        threeScene.scene.remove(this.oRender[i]);
        this.existsInScene[i] = false;
      }
    }
  }

  //_____________________________________________________
  removeT(threeScene){  //remove with texture
    for (let i = 2; i < 4; i++){
       if (app.utils.oOK(this.oRender[i])){
          if (this.existsInScene[i]) threeScene.scene.remove(this.oRender[i]);
          this.existsInScene[i] = false; 
          if (app.utils.oOK(this.oRender[i].geometry)) this.oRender[i].geometry.dispose();
          this.oRender[i] = null;
        }
    }
  }
}