import * as THREE from "three";
import {PolygonGL, colorLineBasicGroupSelected} from "./PolygonGL"
import { objectGL } from "./ObjectGL"
//import { ColorRGBtoString, oOK} from "../data/Utils"
//import {getPolyData, equal, nextPick } from "../data/Utils"
import {app} from "../ahome/App";

//import { TextGeometry } from "three/src/geometries/TextGeometry.js"
//import {FontLoader} from "three/src/loaders/FontLoader.js"  don't remove
//import tfa from "../static/helvetiker_regular.typeface.json"

//__________________________________________________________________________
export class WebGLStore {

  variantJSON; //: any = null;

  materialLineBasicGroupSelected; //: any[];
  colorsDefault = []; //: number[] = [];

  minmaxVertex; //: number[][];
  maxXYZ = 10; //: number = 10;

  static pixelSize_m = 0.01; //m / pixel : 1 pixel = pixelSize_m in meter
  scalar = 1;

  listPolygonGL = []; //: PolygonGL[] = [];
  listBorderGL = []; //: BorderGL[] = [];
  oGL; //: objectGL;

  threeScene; //: ThreeScene;

  listPolygonGLsel = []; //: PolygonGL[] = [];

  pickedInWebGL; //set from outside = function(id, type) id - selected, type = 0 - future use

  //__________________________________________________________________________________
  constructor() {
    this.ors = [];
    this.oGL = new objectGL(3); //0 - lines basic, 1 -  grouped, 2 - lines selected
    this.minmaxVertex = []; //new Array();
    for (let i = 0; i < 3; i++) this.minmaxVertex.push([0.0, 0.0]);

    this.materialLineBasicGroupSelected = new Array(4);
    for (let i = 0; i < 4; i++) {
      if (i === 0)
        this.materialLineBasicGroupSelected[i] = new THREE.LineBasicMaterial({
          color: app.utils.ColorRGBtoString(colorLineBasicGroupSelected[i]),
          linewidth: 1,
        });
      else
        this.materialLineBasicGroupSelected[i] = new THREE.MeshBasicMaterial({
          color: app.utils.ColorRGBtoString(colorLineBasicGroupSelected[i]),
          side: THREE.DoubleSide,
        });
    }
  }

  //-----------------------------------------------------------------------------
  getMaterial(LineBasicGroupSelected) {
    return this.materialLineBasicGroupSelected[LineBasicGroupSelected];
  }


  //-----------------------------------------------------------------------------
  createNewGLObjects() {
    this.listBorderGL = [];
    this.listPolygonGL = [];

    for (let i = 0; i < 3; i++) {
      this.minmaxVertex[i][0] = 0.0;
      this.minmaxVertex[i][1] = 0.0;
    }
    let coFirst = true;

    this.variantJSON.geom.lIDXYZ.forEach((p) => {
      if (coFirst) {
        this.minmaxVertex[0][0] = p[1];
        this.minmaxVertex[0][1] = p[1];
        this.minmaxVertex[1][0] = p[2];
        this.minmaxVertex[1][1] = p[2];
        this.minmaxVertex[2][0] = p[3];
        this.minmaxVertex[2][1] = p[3];
        coFirst = false;
      } else {
        if (p[1] < this.minmaxVertex[0][0]) this.minmaxVertex[0][0] = p[1];
        if (p[1] > this.minmaxVertex[0][1]) this.minmaxVertex[0][1] = p[1];
        if (p[2] < this.minmaxVertex[1][0]) this.minmaxVertex[1][0] = p[2];
        if (p[2] > this.minmaxVertex[1][1]) this.minmaxVertex[1][1] = p[2];
        if (p[3] < this.minmaxVertex[2][0]) this.minmaxVertex[2][0] = p[3];
        if (p[3] > this.minmaxVertex[2][1]) this.minmaxVertex[2][1] = p[3];
      }
    });
    this.maxXYZ = this.minmaxVertex[0][0];
    for (let i = 0; i < 3; i++) {
      let val = Math.max(
        Math.abs(this.minmaxVertex[i][0]),
        Math.abs(this.minmaxVertex[i][1])
      );
      this.maxXYZ = Math.max(val, this.maxXYZ);
    }
    if (this.maxXYZ === 0) this.maxXYZ = 10;

    let rgb = [1,1,1];
    this.variantJSON.geom.lPoly.forEach((p) => {
      let poly = new PolygonGL(p, this.variantJSON, this, true);
      app.utils.nextPick(rgb, poly.colorPick);
    });
    

  }

  //-----------------------------------------------------------------------------
  polygonGLPicked(polC) {
    let idInnerAttach = -99;
    this.listPolygonGLsel = [];
    if (polC.componentJSON) {
      idInnerAttach = polC.componentJSON.idIC;
      polC.componentJSON.idpolsel = polC.polyJSON.id;  //selected polygon
    }
    this.listPolygonGL.forEach((poly) => {
      poly.stateLineBasicGroupSelected = 0;
      if (poly.componentJSON) {
        if (poly.componentJSON.idIC === idInnerAttach || (poly.componentJSON.idEC === idInnerAttach && app.pref.showAllCompsZone))
          poly.stateLineBasicGroupSelected = 1;
        if (polC.componentJSON) {
          if (polC.componentJSON.idPolyC.includes(poly.polyJSON.id)) {
            poly.stateLineBasicGroupSelected = 2;

            if (poly.polyJSON.id !== polC.polyJSON.id)
              this.listPolygonGLsel.push(poly);
          }
        }
      }
    });

    this.listPolygonGLsel.splice(0, 0, polC);
    polC.stateLineBasicGroupSelected = 3;
    if (this.pickedInWebGL) this.pickedInWebGL(polC.componentJSON.id, 0);

    app.pref.lastChoice = 2;  
		app.lastDataChoice = polC;
  }

  //-----------------------------------------------------------------------------
  ObjectSelectedExtern(lGroupId){  //lGroupId: array[3] 1-3 //0 - only line contour, 1 - opaque , 2-opaque group yellow , 3 - opaque selected red contour
    this.listPolygonGLsel = [];
    let coList = lGroupId && lGroupId.length > 0,
        bl = 3,
        polyC = null;
    this.listPolygonGL.forEach((poly) => {
      poly.stateLineBasicGroupSelected = 0;
      if (coList){
        if (poly.componentJSON){
          if (lGroupId[0].includes(poly.componentJSON.id)) poly.stateLineBasicGroupSelected = 1;
          else if (lGroupId[1].includes(poly.componentJSON.id)) {
            poly.stateLineBasicGroupSelected = 2;
            this.listPolygonGLsel.push(poly);
          }
          else if (lGroupId[2].includes(poly.componentJSON.id)) {
            poly.stateLineBasicGroupSelected = bl;
            if (bl === 3) polyC = poly;
            else this.listPolygonGLsel.push(poly);
            bl = 2;
          }
        }
      }
    });

    if (polyC) this.listPolygonGLsel.splice(0, 0, polyC);

    this.createNewSceneGL(false);
    this.threeScene.reRender();  
  }

  //-----------------------------------------------------------------------------
  removeAllFromSceneGL() {
    this.listPolygonGL.forEach((poly) => {
      poly.oGL.removeFromSceneGL(this.threeScene);
    });
    this.oGL.removeFromSceneGL(this.threeScene);
  }

  //-----------------------------------------------------------------------------
  disposeBorderGL() {
    this.oGL.disposeGL();
  }

  //-----------------------------------------------------------------------------
  disposeAllGL() {
    this.listPolygonGL.forEach((poly) => {
      if (poly.oGL) poly.oGL.disposeGL();
    });
    if (this.oGL) this.oGL.disposeGL();
  }

  //_____________________________________________________________________________________________
  createNewSceneGL(coPicking) {  
    this.removeAllFromSceneGL();
    this.disposeBorderGL();

    if (coPicking) {
      this.listPolygonGL.forEach((poly) => {
        if (poly.componentJSON && poly.stateLineBasicGroupSelected > 0) {
          poly.addToGLscene(true);
        }
      });
      return;
    }

    this.listPolygonGL.forEach((poly) => {
      if (poly.componentJSON) {
        if (poly.stateLineBasicGroupSelected > 0) poly.addToGLscene(false);
      }
    });
    var lVerts = [];
    for (let i = 0; i < 3; i++) lVerts[i] = [];

    this.listBorderGL.forEach((b) => {
      let istate = b.getStateBasicGroupedChecked(),
          xyz = b.getPointsXYZ();
      xyz.forEach((x) => lVerts[istate].push(x));

    

    });

    for (let i = 0; i < 3; i++) {
      if (lVerts[i].length > 1) {
        let geometry = new THREE.BufferGeometry();
        geometry.setAttribute(
          "position",
          new THREE.BufferAttribute(Float32Array.from(lVerts[i]), 3)
        );
        this.oGL.oRender[i] = new THREE.LineSegments(
          geometry,
          this.getMaterial(i + 1)
        );

        this.threeScene.scene.add(this.oGL.oRender[i]);
        this.oGL.existsInScene[i] = true;
      }
           

      //----
     // var textGeo = new TextGeometry( "klklklklk", {

        //let font =  "three/examples/fonts/helvetiker_regular.typeface.json";
        /*const loader = new FontLoader();  console.log("TFA", tfa);
        let mmm;
        loader.load('https://threejs.org/examples/fonts/helvetiker_regular.typeface.json',  (font) => {
           const geometry = new THREE.TextGeometry('Hello Three.js!', {
              font: font,
              size: 2,
              height: 0.1,
              curveSegments: 6,
              bevelEnabled: false,
              bevelThickness: 0.5,
              bevelSize: 0.3,
              bevelOffset: 0,
              bevelSegments: 5,
           })

           const material = new THREE.MeshFaceMaterial([
            new THREE.MeshPhongMaterial({
               color: 0xff22cc,
               flatShading: true,
            }), // front
            new THREE.MeshPhongMaterial({
               color: 0xffcc22
            }), // side
         ])
         const mesh = new THREE.Mesh(geometry, material)
         mesh.name = 'text';
         //this.addMesh(mesh);
         this.threeScene.scene.add(mesh);
        })*/


       

      //-------------------------------
      this.Orient(false);

      
    }
  }

  

  //______________________________________________________________________________________________
  newVariant(variantJSON) { //}: any) { //called extern
    if (this.threeScene) { 
      this.removeAllFromSceneGL();
      this.variantJSON = variantJSON;
            
      this.createNewGLObjects();
      this.calcGdat();
      

      this.threeScene.scalar = 1;
      this.threeScene.sumXTranslated = 0;
      this.threeScene.sumYTranslated = 0;
      this.threeScene.displayWidth = -1;

      this.threeScene.setDefRot();

      this.threeScene.scene.scale.setScalar(this.threeScene.scalar);

      this.createNewSceneGL(false);
      this.threeScene.displayWidth = -1;
      this.threeScene.checkGeometry();

      this.threeScene.reRender();  //console.log("newVariant GL");
    }
  }

  //_____________________________________________________________________________________________
  getSelectedPolyData(addOrient) {
    let pol0; //: PolygonGL;
    if (this.listPolygonGLsel.length > 0) {
      pol0 = this.listPolygonGLsel[0]; //selected polygon always first!
      return app.utils.getPolyData(pol0.componentJSON, pol0.polyJSON, addOrient);
    } 
    else return {};  
  }

  //_____________________________________________________________________________________________
  calcGdat(){  //calculate geometrical data , data resulting from geometry
    this.listPolygonGL.forEach(pl => {
      pl.calc();
      pl.calcMM3()});
  }
  //_____________________________________________________________________________________________
  static groupComp(pV){ //GroupComponensOfTheSameType
  let lc = pV.building.lComponent;
  lc.forEach(c => c.co = true);
  lc.forEach(c => {
    c.co = false;
    lc.forEach(cc => {
      if (cc.co)
      {
        if ((cc.idIC === c.idIC) && (cc.idEC === c.idEC) && (cc.typeC === c.typeC) && app.utils.equal(cc.inclC, c.inclC, 1)) // (Math.Abs(dcc.Inclination() - dc.Inclination()) < 1.0))
        {
          cc.idPolyC.forEach(i => {
            if (!c.idPolyC.includes(i)) c.idPolyC.push(i);
          });
          cc.idPolyC = [];
          cc.co = false;
        }
      }
    });
  }); 
  let co = true,
      cc;
  while (co){
    cc = (lc.find(c => c.idPolyC.length === 0));
    if (app.utils.oOK(cc)) lc.splice(lc.indexOf(cc),1);
    else co = false;
    }
    
  }

  //_____________________________________________________________________________________________
  pp(rot, x,y,z, col, XYZ){
    let L = XYZ.length,
        L1,
        xyz,
        i = 0,
        j,
        p;
    while (i < L){
      xyz = XYZ[i++];
      L1 = xyz.length;
      j = 0;
      p = [];
      while(j < L1){
        p.push(new THREE.Vector3( xyz[j][0], xyz[j][1], xyz[j++][2] ));
      }
      const material = new THREE.LineBasicMaterial({color: col });
      const geometry = new THREE.BufferGeometry().setFromPoints( p ); 
      const line = new THREE.Line( geometry, material );

      if (rot){
          line.applyQuaternion(this.Mrev);
          line.position.set(x,y,z);
        }

      this.threeScene.scene.add( line );
      this.ors.push(line);
    }
  }

  //_____________________________________________________________________________________________
  Orient(rer){ 
    this.ors.forEach(a => {if (app.utils.oOK(a)) this.threeScene.scene.remove(a)});
    this.ors = [];
    this.Mrev = this.threeScene.scene.quaternion.clone().conjugate();

      let S = [4,1,  3,0,  1,0,  0,1,  0,3,  1,4,  3,4,  4,5,  4,7,  3,8,  1,8,  0,7],  //must be from last to first point
          N = [4,0,  4,8,  0,0,  0,8],
          W = [0,8,  0,0,  2,4,  4,0,  4,8],  //upside down
          E = [0,0, 4,0, 4,4,  0,4,  4,4,  4,8,  0,8],
          col = [ 0xFF0000,  //S
                  0xFFA07A,  //SW
                  0x40E0D0,  //W
                  0x87CEFA,  //N-W
                  0x00008B,  //N
                  0x87CEFA,  //N_E
                  0x40E0D0,  //E
                  0xFFA07A]; //S-E

      let r = 1.3*this.maxXYZ,
          from = new THREE.Vector3( 0,r,0 ),
          dir = new THREE.Vector3(0,1,0),
          angle = this.variantJSON.building.azimN - 180,
          div = this.threeScene.scalar,
          x,y,z,dx,
          fh = r*0.008/div,//WebGLStore.pixelSize_m * 6, //font height
          sin, cos, 
          L,L1,
          i,j,k,
          xyz, XZL, xzl, XYZ; //arrays
      let MF = new THREE.ArrowHelper(dir, from, 0.25*r/div, 0xff0000, 0.1*r/div,0.03*r/div);
      this.threeScene.scene.add(MF);
      this.ors.push(MF); 

      for (i = 0; i < 8; i++)
      {
          sin = Math.sin(angle * Math.PI / 180);
          cos = Math.cos(angle * Math.PI / 180);

          y = r*cos;
          x = r*sin;
          z = 0;
          xyz = [];      
          xyz.push([0,0,0],[x,y,z]);
          this.pp(false,0,0,0, col[i], [xyz]);  

          XZL = [];
          if (i === 0) XZL.push(S);
          else if (i === 1) XZL.push(S,E);
          else if (i === 2) XZL.push(E);
          else if (i === 3) XZL.push(N,E);
          else if (i === 4) XZL.push(N);
          else if (i === 5) XZL.push(N,W);
          else if (i === 6) XZL.push(W);
          else if (i === 7) XZL.push(S,W);

          L = XZL.length;
          
          if (L > 0){
            j = 0;
            XYZ = [];
            dx = -(L-1)*fh*5;
            while(j < L){
              xzl = XZL[j++];//*this.threeScene.scalar;
              L1 = xzl.length;
              k = 0;
              xyz = [];
              while(k < L1){
                xyz.push([(2 - xzl[k++]) * fh + dx ,(xzl[k++] - 4)*fh  ,0]);
              }
              
              XYZ.push(xyz);
              if (j < L) {
                dx += 10*fh;
                XYZ.push([[-1.5*fh,0,0],[1.5*fh,0,0]]);
              }
            }
            this.pp(true, x, y, 5*fh, 0x000000, XYZ); 
          }
          angle += 45;
      }
      //this.threeScene.scene.add(new THREE.AxesHelper(2*r));//axis x,y,z not relevant ?
      if (rer) this.threeScene.reRender();
    }
}
