//import { vecMove,  newVecVert, crossProduct, vecLength } from "../data/Utils"
import {app} from "../ahome/App";
import {WebGLStore} from "./WebGLStore"


export class BorderGL {

    
    //_______________________________________________
    constructor(p1, p2, pol) {
        this.thicLine = false;

        this.pv1 = p1;
        this.pv2 = p2;
        this.pols = [];
        this.pols.push(pol);
    }

    //_______________________________________________
    addPol(pol) {
        let o = this.pols.find((p) => p.polyJSON.id === pol.polyJSON.id);
        if (!o) this.pols.push(pol);
    }

    //________________________________________________
    equivalentToVertices(p1, p2) { 
        return ((p1[0] === this.pv1[0] && p2[0] === this.pv2[0]) || (p1[0] === this.pv2[0] && p2[0] === this.pv1[0]));
    }

    //________________________________________________
    getStateBasicGroupedChecked() {
        let iState = 0;
        this.pols.forEach(pol => { if (pol.stateLineBasicGroupSelected > iState) iState = pol.stateLineBasicGroupSelected; this.thicLine = (pol.stateLineBasicGroupSelected > 0) });
        if (iState > 0) iState--;
        return iState;
    }

    //________________________________________________
    getPointsXYZ() {
        let a = [];
  
        a.push(this.pv1[1]);
        a.push(-this.pv1[2]);
        a.push(this.pv1[3]);

        a.push(this.pv2[1]);
        a.push(-this.pv2[2]);
        a.push(this.pv2[3]);    

        this.thicLine = false;
        if (this.thicLine){
        let p0 = this.pols[0],
            pv1 = app.utils.newVecVert(this.pv1),
            pv2 = app.utils.newVecVert(this.pv2),
            c = 0.25*WebGLStore.pixelSize_m,  //0.25
            dc = 0.15*c;  //0.5
        
        for (let k = 0; k < 2; k++) {
            for (let i = 1; i < this.pols.length; i++){
                let l = app.utils.vecLength(app.utils.crossProduct(this.pols[i].polyJSON.nVec, p0.polyJSON.nVec));
                if (l > 0.001){
                    for (let j = 0; j < 3; j++){  
                        let p = app.utils.vecMove(app.utils.vecMove(pv1, this.pols[i].polyJSON.nVec, c), p0.polyJSON.nVec, c);
                        a.push(p[0]);
                        a.push(-p[1]);
                        a.push(p[2]);
                        p = app.utils.vecMove(app.utils.vecMove(pv2, this.pols[i].polyJSON.nVec, c), p0.polyJSON.nVec, c);
                        a.push(p[0]);
                        a.push(-p[1]);
                        a.push(p[2]);
                        c += dc;
                    }
                }
            }
            c = -0.25*WebGLStore.pixelSize_m;  //0.25
            dc = 0.15*c;  //0.5
        }
    }
        return a;
    };
}

