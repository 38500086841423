import { TabViewNode } from "./TabViewNode"
import { Settings } from "../data/Settings"
import { createPath, createElsvg } from "./EditHelpers"
import {app} from "../ahome/App"

//_______________________________________________________________________________________
export class TabView {
    coFoundTabNode = false;
    //not explicite----------------------
    //tabMainNode;//: TabViewNode;
    //selectedTabNode;//: TabViewNode;
    
    //svgTabView;//: any;
    //FE;
    //___________________________________________________________________________________
    constructor() {
        this.tabMainNode = new TabViewNode(0, -1, 0, 0, "undef");
        this.tabMainNode.nr = -1;
        this.selectedTabNode = this.tabMainNode;
    }

    //___________________________________________________________________________________
    findTabNode(tvn, nr) {   //findTabNode(tvn: TabViewNode, nr: number) {
        if (!this.coFoundTabNode) {
            if (tvn.nr === nr) {
                this.selectedTabNode = tvn;
                this.coFoundTabNode = true;
            }
            else {
                tvn.children.forEach(el => this.findTabNode(el, nr));
            }
        }
    }

    //___________________________________________________________________________________
    findTabNodeSelected(tvn, level) {   //findTabNodeSelected(tvn: TabViewNode, level: number) {
        if (!this.coFoundTabNode) {
            if (tvn.isSelected && tvn.level === level) {
                this.selectedTabNode = tvn;
                this.coFoundTabNode = true;
            }
            else {
                tvn.children.forEach(el => this.findTabNodeSelected(el, level));
            }
        }
    }

    //___________________________________________________________________________________
    onMouseEvent(e) {
        if (e.target.hasAttribute("tabN") && e.type === 'mousedown') {
            this.coFoundTabNode = false;
            let nrTreeNode = Number(e.target.getAttribute("tabN"));
            this.findTabNode(this.tabMainNode, nrTreeNode);
            if (this.coFoundTabNode) {
                let sn = this.selectedTabNode;
                if (!this.selectedTabNode.isSelected) {
                    (app.def)[this.selectedTabNode.context]["sel"] = true;
                    this.coFoundTabNode = false;
                    this.findTabNodeSelected(this.tabMainNode, this.selectedTabNode.level);
                    if (this.coFoundTabNode){
                        
                        (app.def)[this.selectedTabNode.context]["sel"] = false;
                    }
                    if (this.FE) this.FE.tabNodeChanged(sn);
                }

                
            }
        }
    }

    //___________________________________________________________________________________
    AddTabNodeSVG(tan, coGiveLevelMax) {   //AddTabNodeSVG(tvn: TabViewNode, coGiveLevelMax: boolean) {
        if (coGiveLevelMax) {
            if (tan.level > Settings.Tab.levelMax)  Settings.Tab.levelMax = tan.level;
        }  
        else{
            this.svgTabView.appendChild(tan.svgN);
        }
        if (tan.isSelected) {
            tan.children.forEach((el) => this.AddTabNodeSVG(el, coGiveLevelMax));
        }
    }

    //___________________________________________________________________________________
    createTabView() {
        var //contexts = getContextTabViewNode(-1, 0),
            s,//: string,
            yy;// : number;
        this.FE.contextTab = "undef";
        Settings.Tab.levelMax = -1;
        Settings.Tab.nr = 0;
    
        this.tabMainNode.children = [];
        this.tabMainNode.createChildren(this.FE);

        this.tabMainNode.children.forEach(el => this.AddTabNodeSVG(el, true));
        this.FE.tabH = Math.max(Settings.Tab.levelMax + 1) * Settings.Tab.heightRow + 1 + Settings.Tab.levelMax * Settings.Tab.heightBetweenRows;
        if (this.FE.tabH < 0) this.FE.tabH = 0;

        this.svgTabView = createElsvg('svg',
            'x', 0,
            'y', 0,
            'width', '100%',
            'height', this.FE.tabH
        );

        this.svgTabView.appendChild(createElsvg('rect',
            'x', 0,
            'y', 0,
            'width', "100%",
            'height', "100%",
            "fill", Settings.Tab.colorBKGrest,
            'stroke', Settings.Tab.colorLines,
            'stroke-width', '1px',
            "shape-rendering", "crispEdges" 
        ));

        yy = 0;
        for (var i = 0; i <= Settings.Tab.levelMax -1; i++){  //lines
            yy += Settings.Tab.heightRow;
            s = createPath('M', 0, yy,  'h', this.FE.mW);

            this.svgTabView.appendChild(createElsvg('path',
                'd', s,
                'fill', 'none',
                'stroke-width', '1px',
                'stroke', Settings.Tab.colorLines,
                "shape-rendering","crispEdges"
                ));
            yy += Settings.Tab.heightBetweenRows;
        }
        this.tabMainNode.children.forEach(el => this.AddTabNodeSVG(el, false));
        // let tabSelLast = null;
        // this.tabMainNode.children.forEach(el => {this.AddTabNodeSVG(el, false); if (el.isSelected) tabSelLast = el});
        // this.FE.tabNodeChanged(tabSelLast);
    }

    // //___________________________________________________________________________________________
    // getLastSelected()
    // {
    //     let tabSelLast = null;
    //     this.tabMainNode.children.forEach(el => { if (el.isSelected) tabSelLast = el});
    //     return tabSelLast;
    // }
}