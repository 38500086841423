//import { app.utils.settings } from "../data/app.utils.settings";
import {  createElinput } from "./EditHelpers"
//import { oOK, textWidth, app.utils.app.utils.px } from "../data/Utils"
import {app} from "../ahome/App"

//________________________________________________________________________________________________________
export class Button {
    left = app.utils.settings.form.defaultLeft;
    top = app.utils.settings.form.defaultDist;
    height = app.utils.settings.Button.heightButton;
    width = 0;
    right = 0;
    bottom = 0;
    idNr = -1;

    //fo;//: any;  // foreignObject  
    skey;//: string;
    stext;//: string;
    shint;//: string;
    imgS;//: any;

    //____________________________________________________________________________________________________
    constructor(idNr, sKey, imgS, js) {  //constructor(idNr: number, sKey: string, imgS: any)

        this.idNr = idNr;
        this.skey = sKey;
        this.stext = app.def[sKey]['n'];
        this.shint = app.def[sKey]['hint'];  
        this.imgS = imgS;
        
        this.buttonClicked = this.buttonClicked.bind(this);

        if (app.utils.oOK(js)){
            if (js.hasOwnProperty('top')) this.top = js.top;
            if (js.hasOwnProperty('left')) this.left = js.left;
            if (js.hasOwnProperty('w')) this.width = js.w;
            if (js.hasOwnProperty('h')) this.height = js.h;
        }
    }

    //___________________________________________________________________________________
    trbl(i){  
        let d = app.utils.settings.form.defaultDist;
        if (i === 0) return this.top;
        else if (i === 1) return this.right + d;
        else if (i === 2) return this.bottom + d;
        else return this.left - d;
    }

    //____________________________________________________________________________________________________
    createObj(){
        this.createButton();
    }

    //____________________________________________________________________________________________________
    createButton(){
        if (this.width === 0) this.width = app.utils.textWidth(this.stext, app.utils.settings.Button.fontSize + 1) + 2 * app.utils.settings.Button.textMargin + 2;
        if (this.height === 0) this.height = app.utils.settings.Button.heightButton;
        this.right = this.left + this.width;
        this.bottom = this.top + this.height;  

        /*this.fo = createElsvg('foreignObject',
            'x', this.left,
            'y', this.top,
            'width', this.width,
            'height', this.height,
            'position', 'absolute',
            "id", "objTop" + this.idNr.toString(),
            'key', 'buttonFO_' + this.skey);  */  

        var el = createElinput("button",
            'font-family', 'Arial',
            'title', this.shint, 
            "id", "objTop" + this.idNr.toString(),
            'key', 'button' + this.skey 
            //'key', 'button_' + this.skey
        ); 
        el.style.position = "absolute";
        el.style.left = app.utils.px(this.left);
        el.style.top = app.utils.px(this.top);
        el.style.width = app.utils.px(this.width);
        el.style.height = app.utils.px(this.height);
        el.innerHTML = this.stext;
        el.style.fontSize = app.utils.settings.Button.sFontSize;

        el.style.margin = 0;
        el.style.padding = 0;

        el.onclick = this.buttonClicked;    

   

        if (this.imgS){
            let img = document.createElement('img');
            img.src = this.imgS;

            //img.setAttribute('float', 'float');

            img.style.display = 'inline-block';
            img.style.position = 'absolute';
            img.style.left = '5px';
            img.style.top = '8px';
            img.float = 'left';
             img.style.margin = 'auto';
            el.appendChild(img);  
        }
        this.el = el;
        
        //this.fo.appendChild(el);
    }

    //____________________________________________________________________________________________________
    buttonClicked(e) {
        this.FE.ButtonClicked(this.skey, e);
    }

    //____________________________________________________________________________________________________
    getObjToAppend()  {
        //return this.fo;
        return this.el;
    }

    //_____________________________________________________________________________________________________
    onMouseEvent(e) {}

    //_____________________________________________________________________________________________________
    afterMounted(){}

    //____________________________________________________________________________________________________
    setEnabled(coEnabledDisaled){
        this.fo.children[0].disabled = !coEnabledDisaled;
    }

    //___________________________________________________________________________________
    reScale(p){}
}