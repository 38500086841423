
//_____________________________________________________________________________
export function createPath(...args) 
{
    var s = '';
    for (var i = 0; i < args.length; i++) 
    {
        if (typeof (arguments[i] === 'string')) s += arguments[i];
        else s += arguments[i].toString();//Math.round(arguments[i]).toString();
        s += ' ';
    }
    return s;
}

//_____________________________________________________________________________
export function createElsvg(type, ...attrs) {
  //createElsvg(type: string, ...attrs: any)
  var el = document.createElementNS("http://www.w3.org/2000/svg", type);
  setAttributes(true, el, attrs);
  //el.classList.add('nonScalingStroke');

  return el;
}

//_____________________________________________________________________________
export function createEltext(stext, ...attrs) {
  //createEltext(stext: string, ...attrs: any)
  var el = document.createElementNS("http://www.w3.org/2000/svg", "text");
  setAttributes(true, el, attrs);

  el.textContent = stext;
  return el;
}

//_____________________________________________________________________________
export function createElinput(type, ...attrs) {
  //createElinput(type: string, ...attrs: any)
  var el = document.createElement(type);
  setAttributes(false, el, attrs);
  return el;
}

//_____________________________________________________________________________
export function setAttributes(coNS, el, attrs) {
  //setAttributes(coNS: boolean, el: any, attrs: any[])
  var L = attrs.length,
    i = 0,
    a;
  while (i < L) {
    if (typeof attrs[i + 1] === "number") a = attrs[i + 1].toString();
    else a = attrs[i + 1];
    if (coNS) el.setAttributeNS(null, attrs[i], a);  
    else el.setAttribute(attrs[i], a);  //console.log(i, "a", attrs[i], a);
    i += 2;
  }
}



