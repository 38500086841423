import * as React from "react";
import "./ButtonDropDown.css";
import {app} from "../ahome/App"

//_____________________________________________________________________________________
export class ButtonDropDown extends React.Component {
	state = { open: false, };
	

	//_________________________________________________________________________________
	constructor(props) {
		super(props);
		this.container = React.createRef();

		this.handleButtonClick = this.handleButtonClick.bind(this);
		this.handleMenuClick = this.handleMenuClick.bind(this);
	}

	//_________________________________________________________________________________
	handleButtonClick = (e) => {
    	this.setState((state) => {
      	return {open: !state.open };
   	 });
  	};

//_________________________________________________________________________________
	handleMenuClick = (e) => {
		
    	this.setState((state) => {
      	return {open: !state.open };
   	 	});
		e.persist();
  		setTimeout(() => {  
			if (this.props.handleBtnClick) this.props.handleBtnClick(Number(e.target.id));
  		}, 10);
  	};

	//_________________________________________________________________________________
	componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
	}

	//_________________________________________________________________________________
	componentWillUnmount() {
  		document.removeEventListener("mousedown", this.handleClickOutside);	
	}

	//_________________________________________________________________________________
	handleClickOutside = (event) => {
  		if (this.container.current &&  	!this.container.current.contains(event.target)) {
   	 		this.setState({
      open: false,
    });
  }
};


	//_________________________________________________________________________________
	render = () =>   {
		let sel;
		if (this.props.sel) sel = this.props.sel;
		else sel = app.def[this.props.skey].sel;
		return (
		<div className="container" ref={this.container}>
            <button type="button" className="button" 
			 onClick={this.handleButtonClick}  disabled = {this.props.disabled ? this.props.disabled : false}>
				{app.def[this.props.skey].n}
			 
			 </button>
            {this.state.open && (
    	<div className="dropdown" >
      		<ul>
			{sel.map((el, i) => (<li onClick={this.handleMenuClick} id={el[0]} key = {i}>{el[1]}</li>))}
      		</ul>
    	</div>
  		)}
        </div>
	    );
    }
}