import React, { Suspense } from 'react';
import {app} from "../ahome/App";

import {WaitText} from "../editors/WaitSpin"; 

// import { useState } from 'react';
// import { MyWindowPortal } from '../editors/WindowPortal';
// import { DBTool } from "../modalDB/DBTool"
//import { useState } from 'react';

const FormProjData = React.lazy(() => import("../forms/formsCommon/FormProjData")),
      FormProjDataPicture = React.lazy(() => import("../forms/formsCommon/FormProjDataPicture")),
      FormVariants = React.lazy(() => import("../forms/formsCommon/FormVariants")),

      FormMaterials  = React.lazy(() => import("../forms/formsCommon/FormMaterials")),
      FormAssemblies  = React.lazy(() => import("../forms/formsCommon/FormAssemblies")),

      FormTimePeriodes  = React.lazy(() => import("../forms/formsCommon/FormTimePeriodes")),
      FormTimePatternsDaily = React.lazy(() => import("../forms/formsCommon/FormTimePatternsDaily")),

      FormWindowT  = React.lazy(() => import("../forms/formsCommon/FormWindowT")),
      FormClimateMain  = React.lazy(() => import("../forms/formsCommon/FormClimateMain")),
      FormClimateOpt  = React.lazy(() => import("../forms/formsCommon/FormClimateOpt")),

      FormPHCliData = React.lazy(() => import("../forms/formsCommon/FormPHCliData")),
      FormPHCliSECO2 = React.lazy(() => import("../forms/formsCommon/FormPHCliSECO2")),
      FormZones  = React.lazy(() => import("../forms/formsCommon/FormZones")),
      FormZone  = React.lazy(() => import("../forms/formsCommon/FormZone")),
      FormZoneSettings  = React.lazy(() => import("../forms/formsCommon/FormZoneSettings")),
      FormZoneHVACcontrol = React.lazy(() => import("../forms/formsCommon/FormZoneHVACcontrol")),
      FormComp  = React.lazy(() => import("../forms/formsCommon/FormComp")),
      FormSurfaceT  = React.lazy(() => import("../forms/formsCommon/FormSurfaceT"  )),
      FormSurfaceM  = React.lazy(() => import("../forms/formsCommon/FormSurfaceM")),
      FormIniC  = React.lazy(() => import("../forms/formsCommon/FormIniC")),
      FormCompNum  = React.lazy(() => import("../forms/formsCommon/FormCompNum")),

      FormCompSolPG = React.lazy(() => import("../forms/formsCommon/FormCompSolPG")),
      FormShadingDev = React.lazy(() => import("../forms/formsCommon/FormShadingDev")),
      FormOverhang = React.lazy(() => import("../forms/formsCommon/FormOverhang.js")),
      FormCompSolPMMS = React.lazy(() => import("../forms/formsCommon/FormCompSolPMMS")),

      FormBuilding  = React.lazy(() => import("../forms/formsCommon/FormBuilding")),
      FormBuildOrient = React.lazy(() => import("../forms/formsCommon/FormBuildOrient")),
      FormBuildingNum  = React.lazy(() => import("../forms/formsCommon/FormBuildingNum")),
      FormTBzone  = React.lazy(() => import("../forms/formsCommon/FormTBzone")),
      FormTP  = React.lazy(() => import("../forms/formsCommon/FormTP")),
      FormHVAC  = React.lazy(() => import("../forms/formsCommon/FormHVAC")),
      FormHVACsys  = React.lazy(() => import("../forms/formsCommon/FormHVACsys")),
      FormHVACsysShareZ  = React.lazy(() => import("../forms/formsCommon/FormHVACsysShareZ")),
      FormHVACdev  = React.lazy(() => import("../forms/formsCommon/FormHVACdev")),

      FormPHcases  = React.lazy(() => import("../forms/formsCommon/FormPHcases")),
      FormPHcaseG  = React.lazy(() => import("../forms/formsCommon/FormPHcaseG")),
      FormPHcaseA  = React.lazy(() => import("../forms/formsCommon/FormPHcaseA")),
      FormPHcaseF  = React.lazy(() => import("../forms/formsCommon/FormPHcaseF")),
      FormPHutilpatVent  = React.lazy(() => import("../forms/formsCommon/FormPHutilpatVent")),
      FormPHutilpatLoads  = React.lazy(() => import("../forms/formsCommon/FormPHutilpatLoads")),
      FormPHloadsOcc = React.lazy(() => import("../forms/formsCommon/FormPHloadsOcc")),
      FormPHloadsOffice  = React.lazy(() => import("../forms/formsCommon/FormPHloadsOffice")),
      FormPHloadsLight  = React.lazy(() => import("../forms/formsCommon/FormPHloadsLight")),
      FormPHloadsProcess  = React.lazy(() => import("../forms/formsCommon/FormPHloadsProcess")),

      FormPHvr  = React.lazy(() => import("../forms/formsCommon/FormPHvr")),
      FormPHsv  = React.lazy(() => import("../forms/formsCommon/FormPHsv")),
      FormComps  = React.lazy(() => import("../forms/formsCommon/FormComps")),
      FormPHexhV  = React.lazy(() => import("../forms/formsCommon/FormPHexhV")),
      FormPHsysDistrWp = React.lazy(() => import("../forms/formsCommon/FormPHsysDistrWp")),
      FormPHsysDistrV  = React.lazy(() => import("../forms/formsCommon/FormPHsysDistrV")),
      FormPHsysDistrH  = React.lazy(() => import("../forms/formsCommon/FormPHsysDistrH")),
      FormPHsysDistrWg  = React.lazy(() => import("../forms/formsCommon/FormPHsysDistrWg")),
      FormPHsysDistrC  = React.lazy(() => import("../forms/formsCommon/FormPHsysDistrC")),
      FormPHsysDistrSD  = React.lazy(() => import("../forms/formsCommon/FormPHsysDistrSD")),

      FormWPsettings = React.lazy(() => import("../forms/formsCommon/FormWPsettings")),
      FormPHloadsKitchen = React.lazy(() => import("../forms/formsCommon/FormPHloadsKitchen")),
      FormPHloadsRes = React.lazy(() => import("../forms/formsCommon/FormPHloadsRes")),

      FormBuildingWizzard = React.lazy(() => import("../forms/formsCommon/FormBuildingWizzard")),
      FormLoadProjectGlob = React.lazy(() => import("../forms/formsCommon/FormLoadProjectGlob")),

      //from formsPH 
      FormClimateDB = React.lazy(() => import("../forms/formsPH/FormClimateDBPH"))
      
//_______________________________________________________________________________
export function getContextTabViewNode(keyParent, FE) {
   let oj = FE.TV.selP.oJSON,
       tvn = FE.TV.selP,
       key = tvn.type,
       tvt = app.utils.treeVType,
       ut = app.utils;

    if (key === tvt.comp){
      if (keyParent === "undef") {
        if (oj.idIC < 0 && oj.idEC) return ["taCG"];
        else if (ut.scopePlus()) { 
          if (ut.equalsTo(oj, "typeC", 1, true))          return ["taCG", "taCA", "taCS", "taCIc", "taCN"];
          else if (ut.equalsTo(oj, "typeC", 2, false))    return ["taCG", "taCA", "taCS"];
          else if (ut.equalsTo(oj, "typeC", 3, false))    return ["taCG"];
        } 
        else if (ut.scopeMETR()) {
          if (ut.equalsTo(oj, "typeC", 1, true))          return ["taCG", "taCA", "taCS"];
          else if (ut.equalsTo(oj, "typeC", 2, false))  {
              if (ut.oOK(oj.idEC) && oj.idEC === -1) return ["taCG", "taCA", "taCSp"];
              else return ["taCG", "taCA"];
          }
          else if (ut.equalsTo(oj, "typeC", 3, false))    return ["taCG"];
        }
      }
      //else if (keyParent === "taCS") return ["taCST","taCSM"];
    }
    else if  (key === tvt.building){
      if (keyParent === "undef") return ["taOr","taG"];
    }
    else if  (key === tvt.zone){
      if (ut.scopePlus()) {
        if (keyParent === "undef") {
          if (oj.typeZ === 1)   return ["taZG", "taZS"];
          else return ["taZG"];
        }
      } else if (ut.scopeMETR()) {
        if (keyParent === "undef") {
          if (oj.typeZ === 1)   return ["taZG", "taZFi"];
          else return ["taZG"];
        }
      }
    }
    else if (key === tvt.zoneLoads){
      if (ut.scopeMETR()) {  
        if (keyParent === "undef"){
        let idPHcase = tvn.oJSON.idPHcase;
        if (ut.oOK(idPHcase)){
          let phius = app.actVariantJSON.PHIUS;
          if (phius){
            if (phius.lCase){  
              let ph = phius.lCase.find(p => p.id === idPHcase);
              if (ph){
                if (ph.bCatResnR === 2) return ["taZLO", "taZLOe", "taZLKe", "taZLL", "taZLPl"];
              }
            }
          }
        }
      }
        return[];
      }
    }
    else if (key === tvt.hvacD){
       if (keyParent === "undef") {
        let ks = ["taHDG"];
        if (ut.scopePlus()) {
          if (oj.uHWCVHD[0]) ks.push("capH");
          if (oj.uHWCVHD[2]) ks.push("capC");
          if (oj.uHWCVHD[3]) ks.push("capV");
          if (oj.uHWCVHD[4]) ks.push("capHu");
          if (oj.uHWCVHD[5]) ks.push("capDeh");
        }
        else if (ut.scopeMETR()){

        }
        return ks;
      }
    }

    if (keyParent === "undef") key = FE.TV.selP.type;
    else key = keyParent;
    let jo = app.def[key];

    if (jo.hasOwnProperty("ta")) return jo.ta;
    else {
      let k;
      if (ut.scopePlus()) k = "tw"; else k = "thp";
      if (jo.hasOwnProperty(k)) return jo[k];
      else return [];
    }
  }



//____________________________________________________________________________________________________________________
export function CreateForm(props){
  
  let tvp, ct, selP,oj;

  //const [mwp, setMWP] = useState(false);
  //______________________________________
  function CreateFormED(p){   
    if (!p.fe) return null;
    if (!p.fe.TV) return null;  

    ct = p.fe.contextTab;
    selP = p.fe.TV.selP;
    let  tvt = app.utils.treeVType;
  
        if (ct && selP){
          tvp = p.fe.TV.selP.type;
          oj = p.fe.TV.selP.oJSON;     
        }
            
      if (ct === "undef") { 
          if (tvp === tvt.casesPH) return<FormPHcases fe={p.fe}/>;    
          else if (tvp === tvt.simZones) return<FormZones p={true} fe={p.fe}/>;  //p,p1 - parameters
          else if (tvp === tvt.attZones) return<FormZones p={false} fe={p.fe}/>;
          else if (tvp === tvt.case) return <FormVariants fe={p.fe}/>;
  
          else if (tvp === tvt.zone) return<FormZone fe={p.fe}/>;
  
          else if (tvp === tvt.visComp) return<FormComps p={0} fe={p.fe}/>;
          else if (tvp === tvt.nvisComp) return<FormComps p={1} fe={p.fe}/>;
          else if (tvp === tvt.rEs) return<FormComps p={2} fe={p.fe}/>;   
          else if (tvp === tvt.zoneTB) return<FormTBzone fe={p.fe}/>;
          else if (tvp === tvt.zoneLoads) return<FormPHloadsRes fe={p.fe}/>;
          else if (tvp === tvt.hvac) return<FormHVAC fe={p.fe}/>; 
      }
      else{
          if (ct === "taPD")  return <FormProjData fe={p.fe}/>; 
          else if (ct === "taPP")  return <FormProjDataPicture fe={p.fe}/>; 
  
          else if (ct === "taOr") return<FormBuildOrient fe={p.fe}/>;
          else if (ct === "taG") return<FormBuilding fe={p.fe}/>;
      
          else if (ct === "taSoM") return<FormMaterials fe={p.fe}/>;
          else if (ct === "taSoA") return<FormAssemblies p={false} fe={p.fe}/>;
          else if (ct === "taSoW") return<FormWindowT p={false} fe={p.fe}/>;
  
          else if (ct === "taSoSd") return<FormShadingDev p={false} fe={p.fe}/>;
          else if (ct === "taSoWo") return<FormOverhang p={false}  fe={p.fe}/>;
          else if (ct === "mmShade") return<FormCompSolPMMS fe={p.fe}/>;
  
          else if (ct === "taSoDp") return<FormTimePatternsDaily fe={p.fe}/>;
          else if (ct === "taSoTps") return<FormTimePeriodes fe={p.fe}/>;
  
          else if (ct === "taLL") return<FormClimateMain fe={p.fe}/>;  
          else if (ct === "taLOc")  return<FormClimateOpt fe={p.fe}/>;
  
          else if (ct === "taLWd")  return<FormPHCliData fe={p.fe}/>;
          else if (ct === "taLSeCO")  return<FormPHCliSECO2 fe={p.fe}/>;
  
          else if (ct === "taSsG") return<FormWPsettings fe={p.fe}/>;
          else if (ct === "taSsN") return<FormBuildingNum fe={p.fe}/>;
      
          else if (ct === "taZG")  return<FormZone fe={p.fe}/>;
          else if (ct === "taZS") return< FormZoneSettings fe={p.fe}/>;
  
          else if (ct === "taCG") return<FormComp fe={p.fe}/>;
          else if (ct === "taCA") {
              if (oj.typeC === 1) return<FormAssemblies p={true} fe={p.fe}/>;
              else return<FormWindowT p={true} fe={p.fe}/>;
          }
          else if (ct === "taCS" && app.utils.scopeMETR()) return<FormSurfaceT fe={p.fe}/>;
          else if (ct === "taCST") return<FormSurfaceT fe={p.fe}/>;
          else if (ct === "taCSM") return<FormSurfaceM fe={p.fe}/>;
  
          else if (ct === "taCIc") return<FormIniC fe={p.fe}/>;
          else if (ct === "taCN") return<FormCompNum fe={p.fe}/>;
  
          else if (ct === "taCSpG") return<FormCompSolPG fe={p.fe}/>;
          else if (ct === "taCSpSd") return<FormShadingDev p={true} fe={p.fe}/>;
          else if (ct === "taCSpWo") return<FormOverhang p={true}  fe={p.fe}/>;
  
          else if (ct === "taZLC") return<FormTP p={"zlHC"} p1={oj.loadsZ.hcLz} fe={p.fe}/>;
          else if (ct === "taZLR") return<FormTP p={"zlHR"} p1={oj.loadsZ.hrLz} fe={p.fe}/>;
          else if (ct === "taZLM") return<FormTP p={"zlM"} p1={oj.loadsZ.mLz} fe={p.fe}/>;
          else if (ct === "taZLCO2") return<FormTP p={"zlCO2"} p1={oj.loadsZ.CO2Lz} fe={p.fe}/>;
  
          else if (ct === "taZFi") return<FormPHcaseF fe={p.fe}/>;    
  
          else if (ct === "taZDcmT") return<FormTP p={"zT"} p1={oj.desCondZ.minTZ} fe={p.fe}/>;
          else if (ct === "taZDcMT") return<FormTP p={"zTm"} p1={oj.desCondZ.maxTZ} fe={p.fe}/>;
          else if (ct === "taZDcmRh") return<FormTP p={"zRH"} p1={oj.desCondZ.minRHZ} fe={p.fe}/>;
          else if (ct === "taZDcMRh") return<FormTP p={"zRHm"} p1={oj.desCondZ.maxRHZ} fe={p.fe}/>;
          else if (ct === "taZDcMCO2") return<FormTP p={"zCO2c"} p1={oj.desCondZ.maxCO2Z} fe={p.fe}/>;
  
          else if (ct === "taZDcHc") return<FormZoneHVACcontrol fe={p.fe}/>;
  
          else if (ct === "taZVN") return<FormTP p={"zV"} p1={oj.desCondZ.ventZ} fe={p.fe}/>;
          else if (ct === "taZVM") return<FormTP p={"zMV"} p1={oj.desCondZ.mVentZ} fe={p.fe}/>;
  
          else if (ct === "taZTcHa") return<FormTP p={"zlHA"} p1={oj.loadsZ.haLz} fe={p.fe}/>;
          else if (ct === "taZTcC") return<FormTP p={"zlClo"} p1={oj.loadsZ.cloLz} fe={p.fe}/>;
          else if (ct === "taZTcAv") return<FormTP p={"zlAV"} p1={oj.loadsZ.avLz} fe={p.fe}/>;
  
          else if (ct === "taHSG") return<FormHVACsys fe={p.fe}/>;
          else if (ct === "taHSSz") return<FormHVACsysShareZ fe={p.fe}/>;
  
          else if (ct === "taHSHh") return<FormPHsysDistrH fe={p.fe}/>;
          else if (ct === "taHSV") return<FormPHsysDistrV fe={p.fe}/>;
          else if (ct === "taHSC") return<FormPHsysDistrC fe={p.fe}/>;
          else if (ct === "taHSSd") return<FormPHsysDistrSD fe={p.fe}/>;
  
      
          else if (ct === "taHSG2") return<FormPHsysDistrWg fe={p.fe}/>;
          else if (ct === "taHSHwp") return<FormPHsysDistrWp fe={p.fe}/>;
              
          else if (ct === "taHDG") return<FormHVACdev fe={p.fe}/>;
          else if (ct === "capH") return<FormTP p={"cH"} p1={oj.capH} fe={p.fe}/>;
          else if (ct === "capC") return<FormTP p={"cC"} p1={oj.capC} fe={p.fe}/>;
          else if (ct === "capV") return<FormTP p={"cV"} p1={oj.capV} fe={p.fe}/>;
          else if (ct === "capHu") return<FormTP p={"cHu"} p1={oj.capHu} fe={p.fe}/>;
          else if (ct === "capDeh") return<FormTP p={"cDe"} p1={oj.capDeh} fe={p.fe}/>;
  
          //PH----------------------------------------------------------------------------------------------------------------------------
          else if (ct === "taPHCG") return<FormPHcaseG fe={p.fe}/>;
          else if (ct === "taPHCAd") return<FormPHcaseA fe={p.fe}/>;
          
  
          else if (ct === "taSoRv") return<FormPHutilpatVent fe={p.fe}/>; 
          else if (ct === "taSoIl") return<FormPHutilpatLoads fe={p.fe}/>;
  
          else if (ct === "taZLO") return<FormPHloadsOcc fe={p.fe}/>;
          else if (ct === "taZLOe") return<FormPHloadsOffice fe={p.fe}/>;
          else if (ct === "taZLKe") return<FormPHloadsKitchen fe={p.fe}/>;
          else if (ct === "taZLL") return<FormPHloadsLight fe={p.fe}/>;
          else if (ct === "taZLPl") return<FormPHloadsProcess fe={p.fe}/>;
  
          //else if (ct === "zlrPH00") return<FormPHloadsRes fe={p.fe}/>;
          else if (ct === "taZVrRv") return<FormPHvr fe={p.fe}/>;
          else if (ct === "taZVrSv") return<FormPHsv fe={p.fe}/>;
          else if (ct === "taZVrEv") return<FormPHexhV fe={p.fe}/>;

          else return null;
      }   
    }
    

  //____________________________________
  function CreateFormDB(p){
    tvp = null;
    ct = "undef";
    selP = null;            
    if (p.fe.TV && p.fe.TV.selP){
      selP = p.fe.TV.selP;
      tvp = p.fe.TV.selP.type;   
    }

      if (p.fe.idDB ===  1) return<FormMaterials fe={p.fe}/>;
      else if (p.fe.idDB ===  2) return<FormAssemblies p={false} fe={p.fe}/>;
      else if (p.fe.idDB ===  3) return<FormWindowT p={false} fe={p.fe}/>;
      else if (p.fe.idDB ===  5) return<FormTimePatternsDaily fe={p.fe}/>;
      else if (p.fe.idDB === 6) return<FormHVACdev fe={p.fe}/>;
      else if (p.fe.idDB === 7) return<FormClimateDB fe={p.fe}/>;
    
    else return null;
  }

  //___________________________________________________________________
  function CreateFormTool(p){console.log("CreateFormTool(p)",p.fe.nrTool)
    if (p.fe.nrTool ===  1) return <FormBuildingWizzard fe={p.fe}/>;
    else if (p.fe.nrTool ===  2) return<FormLoadProjectGlob fe={p.fe}/>;
  }
  //___________________________________
  function CreateForms(p){
    if (p.fe.coMain) return CreateFormED(p);
    else if (p.fe.coTool) return CreateFormTool(p);
    else if (p.fe.coDB) return CreateFormDB(p);
  }
 
    

  if (props.fe){
    return (
      <>
      <Suspense fallback={<WaitText/>}>
      <CreateForms fe={props.fe} ct={ct} selP = {selP}/>
      </Suspense>
      </>
      )
    }
    else return null;
}

//<Suspense fallback={<div>Loading...</div>}>