import {app} from "../ahome/App"; 
import {  createElsvg, createEltext,  setAttributes } from "../editors/EditHelpers";
//import { oOK} from "../data/Utils";
//import { textWidth} from "../data/Utils";
/*
codes of arrays - first element
-1 - color line
-2 - color fill
-3 - line thickness
-4 - //text: 0-font height, 1-text font weight 0/1,  2-text align hor, 3-tva text align vert
-5 - total height

1 - lines
2 - rectangles, 1 - type - 1 - only border, 2 - only filled, 3 - border + filled 
3 - circles                        -//-
4 - texts


*/ 


//___________________________________________________________________________________
export function GG(x,y, id, kgg, gg, SV, ErLoc){
    if (!gg) return;
    let relWidth_cm = 17,
        coef = 750/relWidth_cm,//Math.min(widthPixel, 1000) / relWidth_cm,
        s, 
        sTA = ['start', 'middle', 'end'],  //string Text Align
        sTAV = ['hanging', 'middle', 'auto'],  //vertical
        ik = 0,
        //hh = 0,     // total heigh cm
        hf = 0.35,
        //ipage = 0,
        L,l,
        i,j,
        t, t1,
        ii,
        wmt9 = 0,
        canvas, context,
        v,
        Wx = 0,W = 0,
        Hy = 0,H = 0,
        el,
        attr,

        RGB = function (rgb3) { return 'rgb(' + rgb3[1] + ',' + rgb3[2] + ',' + rgb3[3] + ')'},
        DP = function(dim){return Math.round(dim*coef)}, //dimention cm to pixel
        DPx = function(dim){v = DP(dim); if (v > Wx) Wx = v; return v + x}, //to get W max
        DPy = function(dim){v = DP(dim); if (v > Hy) Hy = v; return v + y}, //to get H max
        DPw = function(dim){v = DP(dim); if (W < (Wx + v)) W = (Wx + v); return v}, //to get W max
        DPh = function(dim){v = DP(dim); if (H < (Hy + v)) H = (Hy + v); return v}, //to get H max
        DPwh = function(dim){v = DP(dim); if (W < (Wx + v)) W = (Wx + v);  if (H < (Hy + v)) H = (Hy + v);; return v}, //to get H max
        DPm1 = function (dim) { return Math.max(dim * coef, 0.1).toFixed(2) }, //dimention cm to pixel minimum 1
        
        tt = [Math.round(DP(hf)), 0,   0, 0],//text: 0-font height, 1-text font weight 0/1,  2-tha, 3-tva text align hor/vert
        ar = function (a, i, c, coDP) {let aa = []; for (let j = 0; j < c; j++) {if (coDP) aa.push(DP(a[i + j])); else aa.push(a[i + j])}; return aa},
        gk = function(){return kgg + id + "_" + ik++},  //key, id etc
        rgbL = RGB([-1,0,0,0]),
        rgbF = RGB([-2, 255, 255, 255]),
        rgbT = RGB([-2, 0,0,0]),
        lt = DPm1(0.1),   //line thickness

        aP = function(s, cl){  //path, cl = coLine or closed
            attr = ['d', s,    "shape-rendering", "crispEdges",   'id', gk(),  "key", 'k' + gk()];
            if (cl) attr.push('fill','none',  'stroke',rgbL,  'stroke-width',lt);
            else attr.push('fill',rgbF);
            el = createElsvg('path');
            setAttributes(true, el, attr);
            SV.appendChild(el);
        },
        aC = function(cl, a, ip){  //circle, cl = co only Line or filled, ip - initial index
            attr = ['cx',DPx(a[ip]), 'cy',DPy(a[ip+1]), 'r',DPwh(a[ip+2]),    "shape-rendering", "crispEdges",   'id', gk(),  "key", 'k' + gk()];
            if (cl) attr.push('fill','none',  'stroke',rgbL,  'stroke-width',lt);
            else attr.push('fill',rgbF);
            el = createElsvg('circle');
            setAttributes(true, el, attr);
            SV.appendChild(el);
        },
        tW = function(s){ //text width, cd - check dim
            if (!canvas){
                canvas = document.createElement("canvas"); 
                context = canvas.getContext("2d"); 
            }
            context.font = tt[0] + "px Arial";//normal " + (tt[0] + 8)  + " px arial"; 
            return context.measureText(s).width; 
        },
        aT = function(s, a, ip, dtv){  //text
            let y = DPy(a[ip+1]);
            if (dtv) y += DPh(0.4*hf);
            attr = ['x', DPx(a[ip]), 'y', y, 'text-anchor', sTA[tt[2]], 'dominant-baseline', sTAV[tt[3]], 'text-overflow', 'ellipsis',
                     "padding", "0px 0px 0px 0px", 'cursor', 'default',  'fill', rgbT, 'id', gk(),  "key", 'k' + gk()];
            if (dtv) attr.push('data-tv', ii);
            el = createEltext(s);     
            setAttributes(true, el, attr);
            el.style.fontFamily = 'Arial';
            el.style.fontSize = tt[0];    
            el.style.fontWeight = tt[1] === 0 ? 'normal' : 'bold';
            SV.appendChild(el);   
            
            v = 0;
            if (tt[3] === 0) v = tt[0];
            else if (tt[3] === 1) v = tt[0]/2;
            if (H < (Hy + v)) H = (Hy + v);
            v = tW(s);
            if (W < (Wx + v)) W = (Wx + v);
        };
        
        
    gg.forEach(page => {  //gg - arrays
        //ipage++;
        page.forEach(a =>  {  //page also array, a - array
            L = a.length;
            t = a[0];
            i = 1;
            if (t < 0){
                if (t === -1) rgbL = RGB(a);  //color line
                else if (t === -2) rgbF = RGB(a);  //color fill
                else if (t === -3) lt = DPm1(a[1]); //line thickness
                else if (t === -4) {  //text: 0-font height, 1-text font weight 0/1,  2-text align hor, 3-tva text align vert
                    hf = a[i];
                    tt = ar(a,i,4,false);   
                    tt[0] = Math.round(DP(tt[0]));  
                }
                //else if (t === -5) hh += a[1];  //total page height
            }
            else {
                s = ''; 
                if (t === 1){  //lines 2 - points
                    while (i < L) { 
                        s += 'M' + DPx(a[i++]) + ' ' + DPy(a[i++]) +  //moveto x,y
                             'L' + DPx(a[i++]) + ' ' + DPy(a[i++]);   //lineto x,y
                    }            
                    aP(s,true);
                }
                if (t === 11){  //lines 2 - points
                    s += 'M' + DPx(a[i++]) + ' ' + DPy(a[i++]);  //moveto x,y
                    while (i < L) { 
                        s += 'L' + DPx(a[i++]) + ' ' + DPy(a[i++]);   //lineto x,y
                    }            
                    aP(s,true);
                }
                else if (t === 2){  //rects
                    while (i < L) {
                        t1 = a[i++];
                        ii = i + 2;
                        s += 'M' + DPx(a[i++]) + ' ' + DPy(a[i++]) +
                             'h' + DPw(a[i++]) + 'v' + DPh(a[i++]) +
                             'h' + DP(-a[ii]) + 'Z';
                    } 
                    if (t1 === 2 || t1 === 3) aP(s,false);
                    if (t1 === 1 || t1 === 3) aP(s,true);
                }
                else if (t === 3){  //circles                    
                    while (i < L) {
                        t1 = a[i++];
                        if (t1 === 2 || t1 === 3) aC(false,a,i);  //filled
                        if (t1 === 1 || t1 === 3) aC(true,a,i); //not filled
                        i += 3;                        
                    }
                }
                else if (t === 4) { //texts
                    while (i < L){
                        

                        aT(a[i+2], a, i, false);
                        i += 3;          
                    }
                }
                else if (t === 5){ //polylines/closed polygons  [5,     type,count , points....,   type,count points], type = 1 if only polylines                    
                    while (i < L) { 
                        t1 = a[i++]; //type, if negative closed polygon
                        l = a[i++]; //count, 
                        s += 'M' + DPx(a[i++]) + ' ' + DPy(a[i++]);  //moveto x,y
                        j = 1;
                        while (j < l) {
                            s +=  'L' + DPx(a[i++]) + ' ' + DPy(a[i++]);   //lineto x,y
                            j++;
                        }
                        if (t1 < 0) {
                            s += 'Z';
                            if (t1 === -2 || t1 === -3)  aP(s, false);  // filled closed
                        } 
                        t1 = Math.abs(t1);
                        if (t1 === 1 || t1 === 3) aP(s, true);  //not filled not closed
                    }          
                }  
                else if (t === 9) { //AddData(x, y, h,    key,erkey,    nrTV,obj,id) 
                    ii = 0;
                    let iii = 0,
                        //aa,
                        aLoc,
                        nrTV,
                        tvn,
                        checkW = true,
                        sn; 
                        
                     
                        
                    while (iii < 2) {//iii = 0 = measure textwidth
                        i = 1;
                        ii = 0;
                        while (i < L){
                            sn = "";

                            aLoc = a[i + 5];
                            nrTV = aLoc[0];
                            tvn = app.TV[0].getOtn(nrTV);  
                            if (tvn) sn = tvn.getName() + ", ";   
                            sn += a[i+4] + 2;

                            if (checkW){
                                v = tW(sn,false);//context.measureText(sn).width;  //console.log("SSSSSSSSSSSSSSSSSSS",sn);
                                if (v > wmt9) wmt9 = v;  
                            }
                            else {
                                if (ErLoc) ErLoc.push(aLoc);

                                SV.appendChild(createElsvg('rect',
                                    'x',DPx(a[i]),
                                    'y',DPy(a[i+1]),
                                    'width',wmt9,
                                    'height',DPh(a[i+2]),  
                                    'stroke',"rgb(220,220,220)",
                                    'fill',"rgb(230,230,230)",
                                    'data-tv',ii,
                                    'id', gk(), "key", 'k' + gk()
                                )); 
                               aT(sn, a, i, true);
                            }
                            i += 6; 
                            ii++;
                        }
                        iii++;
                        wmt9 += 10;
                        if (checkW){if (W < (Wx + wmt9)) W = Wx + wmt9};
                        checkW = false;
                    }
                }
            }
        });
    });
    if (Wx > W) W = Wx;
    if (Hy > H) H = Hy;
    W += x + 3;//ofset
    H += y + 3;//ofset
    if (W > 10 && H > 10){
        SV.style.width = W+'px';
        SV.style.height = H+'px';
        gg.WH = {W:W,H:H}
    }
    
 
    //test-------------------------------------------------------------------------------   
    /*SV.appendChild(createElsvg('rect',
                                    'x',0,
                                    'y',0,
                                    'width',W,
                                    'height',H,  
                                    'stroke',"red",
                                    'fill',"none",
                                    'id', gk(), "key", 'k' + gk()
                                )); 
    console.log("WH", W,H);*/
    //----------------------------------------------------------------------------------
    
} 

/* react version (not delete
    
export function ReportGener(gg, widthPixel, relWidth_cm, ErLoc) //ErLoc - array of array
{
    if (!oOK(gg)) return "No gg defined TEST ONLY !";
    let res = [],
        subRes = [],//: any[],
        //subRes1 = [],
        coef = 750/relWidth_cm,//Math.min(widthPixel, 1000) / relWidth_cm,
        s, 
        
        sTA = ['start', 'middle', 'end'],  //string Text Align
        sTAV = ['hanging', 'middle', 'auto'],  //vertical
        
        DP = function(dim){return Math.round(dim*coef)}, //dimention cm to pixel
        DPm1 = function (dim) { return Math.max(dim * coef, 0.1).toFixed(2) }, //dimention cm to pixel minimum 1
        RGB = function (rgb3) { return 'rgb(' + rgb3[1] + ',' + rgb3[2] + ',' + rgb3[3] + ')'},
        ar = function (a, i, c, coDP) {let aa = []; for (let j = 0; j < c; j++) {if (coDP) aa.push(DP(a[i + j])); else aa.push(a[i + j])}; return aa},

        rgbL = RGB([-1,0,0,0]),
        rgbF = RGB([-2, 255, 255, 255]),
        rgbT = RGB([-2, 0,0,0]),
        lt = DPm1(0.1),    //line thickness
        hh = 0,     // total heigh cm
        hf = 0.35,
        tt = [Math.round(DP(hf)), 0,   0, 0],//text: 0-font height, 1-text font weight 0/1,  2-tha, 3-tva text align hor/vert
        ipage = 0,
        L,l,
        i,j,
        key = 0,
        t, t1,
        ii,
        wmt9 = 0,

        textAttr = function(a,i,k){return { fontSize: tt[0],
                                            fontFamily: 'arial',
                                            fill: rgbT,
                                            fontWeight: tt[1] === 0 ? 'normal' : 'bold',
                                            textAnchor: sTA[tt[2]],
                                            dominantBaseline: sTAV[tt[3]],
                                            x: DP(a[i]), y: DP(a[i+1]),
                                            cursor: 'default', 
                                            key: k}};                          

    ipage = 0;

    gg.forEach(page => {  //gg - arrays
        ipage++;
        page.forEach(a =>  {  //page also array, a - array
            L = a.length;
            t = a[0];
            i = 1;
            if (t < 0){
                if (t === -1) rgbL = RGB(a);  //color line
                else if (t === -2) rgbF = RGB(a);  //color fill
                else if (t === -3) lt = DPm1(a[1]); //line thickness
                else if (t === -4) {  //text: 0-font height, 1-text font weight 0/1,  2-text align hor, 3-tva text align vert
                    hf = a[i];
                    tt = ar(a,i,4,false);   
                    tt[0] = Math.round(DP(tt[0]));  
                }
                else if (t === -5) hh += a[1];  //total page height
            }
            else 
            {
                s = ''; 
                if (t === 1){  //lines 2 - points
                    while (i < L) { 
                        s += 'M' + DP(a[i++]) + ' ' + DP(a[i++]) +  //moveto x,y
                                'L' + DP(a[i++]) + ' ' + DP(a[i++]);   //lineto x,y
                    }            
                    subRes.push(React.createElement('path', { d: s, stroke: rgbL, strokeWidth: lt, key: key++ })); 
                }
                else if (t === 2){  //rects
                    while (i < L) {
                        t1 = a[i++];
                        ii = i + 2;
                        s += 'M' + DP(a[i++]) + ' ' + DP(a[i++]) +
                                'h' + DP(a[i++]) + 'v' + DP(a[i++]) +
                                'h' + DP(-a[ii]) + 'Z';
                    }   
                    if (t1 === 2 || t1 === 3) subRes.push(React.createElement('path', { d: s, fill: rgbF, key: key++ }));         
                    if (t1 === 1 || t1 === 3)  subRes.push(React.createElement('path', { d: s, stroke: rgbL, strokeWidth: lt, fill:'none', key: key++ }));  
                }
                else if (t === 3){  //circles                    
                    while (i < L) {
                        t1 = a[i++];
                        let aa = ar(a,i,3,true);
                        i += 3;
                        if (t1 === 2 || t1 === 3) subRes.push(React.createElement('circle', {cx: aa[0],cy: aa[1],r: aa[2], fill: rgbF, key: key++ }));
                        if (t1 === 1 || t1 === 3) subRes.push(React.createElement('circle', {cx: aa[0],cy: aa[1],r: aa[2], stroke: rgbL, strokeWidth: lt, fill:'none', key: key++ }));                        
                    }
                }
                else if (t === 4) { //texts
                    while (i < L){
                        subRes.push(React.createElement('text',  textAttr(a,i,key++), a[i+2])); 
                        i += 3;               
                    }
                }
                else if (t === 5){ //polylines/closed polygons  [5,     type,count , points....,   type,count points], type = 1 if only polylines                    
                    while (i < L) { 
                        t1 = a[i++]; //type, if negative closed polygon
                        l = a[i++]; //count, 
                        s += 'M' + DP(a[i++]) + ' ' + DP(a[i++]);  //moveto x,y
                        j = 1;
                        while (j < l) {
                            s +=  'L' + DP(a[i++]) + ' ' + DP(a[i++]);   //lineto x,y
                            j++;
                        }
                        if (t1 < 0) {
                            s += 'Z';
                            if (t1 === -2 || t1 === -3) subRes.push(React.createElement('path', { d: s, stroke: rgbL, strokeWidth: lt, fill: rgbF, key: key++ }));
                        } 
                        t1 = Math.abs(t1);
                        if (t1 === 1 || t1 === 3) subRes.push(React.createElement('path', { d: s, stroke: rgbL, strokeWidth: lt, fill: 'none', key: key++ }));
                    }          
                }
                else if (t === 9) { //AddData(x, y, h,    key,erkey,    nrTV,obj,id) 
                    let ii = 0,
                        iii = 0,
                        w,
                        aa,
                        aLoc,
                        nrTV,
                        tvn,
                        checkW = true,
                        ta,
                        sn; 
                        
                    const canvas = document.createElement("canvas"),  
                          context = canvas.getContext("2d"); 

                    context.font = "normal " + tt[0] + "px arial";   
                        
                    while (iii < 2) {
                        i = 1;
                        ii = 0;
                        while (i < L){
                            aa = ar(a,i,3,true);
                            aLoc = a[i + 5];
                            nrTV = aLoc[0];
                            sn = "";

                            tvn = app.TV[0].getOtn(nrTV);  
                            if (tvn) sn = tvn.getName() + ", ";   
                            sn += a[i+4];

                            if (checkW){
                                w = context.measureText(sn).width;
                                if (w > wmt9) wmt9 = w;  
                            }
                            else{
                                a[i+1] += 0.5*(a[i + 2] - hf);
                                if (ErLoc) ErLoc.push(aLoc);
                                subRes.push(React.createElement('rect', {x:aa[0], y:aa[1], height:aa[2], width:wmt9,stroke:"rgb(220,220,220)",fill:"rgb(230,230,230)", 
                                                                key: key++, 'data-tv':ii})); //,'data-obj':nrObj
                                ta = textAttr(a,i,key++);
                                ta['data-tv'] = ii;
                                subRes.push(React.createElement('text', ta, sn));//app.def[a[i+3]].n));
                            }
                            i += 6; 
                            ii++;
                        }
                        iii++;
                        checkW = false;
                        wmt9 += 10;
                    }
                }
            }
        });
    });
    wmt9 = Math.max(wmt9, DP(15.5));
    let node = React.createElement('svg', {
        x: '0', y: '0', padding: '0', margin: '0', height: DP(hh), width: wmt9, position: 'absolute', key: 'reportXCZ',
        shapeRendering: 'crispEdges',
    },  subRes);
    //res.push(node);  
    //return res;
    return node;
}    
    )  */                      
