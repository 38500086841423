/* eslint-disable @typescript-eslint/no-unused-expressions */
import { objectGL } from "./ObjectGL";
import { BorderGL } from "./BorderGL";
import earcut from "earcut";
import * as THREE from "three";
import {app} from "../ahome/App";
//import { ColorHexToRGB, app.utils.app.utils.ColorRGBtoString, roundVal, app.utils.getValueFromSel, oOK, nIsafe,vecLength, crossProduct,dotProduct } from "../data/Utils"


export const colorLineBasicGroupSelected = [
	[150, 150, 150],
	[150, 150, 150],
	[255, 133, 15],
	[255, 20, 20]];



export class PolygonGL {
	
	listVertex = [];
	listInnerPolygonGL = [];
	triangles2Dindexe = [];
	uv = []; //for texture

	mM2 = [];  //minMax 2D, first x,y, second min/max/max distance 
	mM3 = [];  //minMax 3D, first  x,y,z,  second min/max distance

	needTriagulation = true;
	colorPick = new Uint8Array(4);
	stateLineBasicGroupSelected = 1; //0 - only line contour, 1 - opaque , 2-opaque group yellow , 3 - opaque selected red contour
	
	//not explicite-----------------------------------------
	//polyJSON;
	//oGL;//: objectGL;
	//componentJSON;// = undefined;
	//d;//: GeomPolygonGL;
	//countPositionsWithoutHoles int of this.triangles2Dindexe
	//ite,ete - inner/extern texture
	//pVar;//: any;
	//webGL;//: any;



	//___________________________________________________________________________________________
	constructor(polyJSON, pVar, webGL, coAddBorder) {
		this.polyJSON = polyJSON;   
		this.pVar = pVar;
		this.webGL = webGL;
		
		for (let i = 0; i < 3; i++){
			if (i < 2) this.mM2.push([0,0,0]);  //min,max,distance
			this.mM3.push([0,0,0]);  //min. max, distance
		}

		let o;
		this.polyJSON.idVert.forEach((id) => {
			o = pVar.geom.lIDXYZ.find((v) => v[0] === id);
			if (o) {
				//o[2] = -o[2];
				this.listVertex.push(o);
			}
		});
		this.componentJSON = pVar.building.lComponent.find((el) => {
			if (el.visC)
				return el.idPolyC.includes(this.polyJSON.id);
			else return false;
		});
		this.d = {
			countNVnotZero: 0,
			indexNVnotZero: 0,
			normalVN: [3],
			coVN0: false,
			coVN1: false,
			coVN2: false,
			translation: [3],
			sinNxy: 0,
			cosNxy: 0,
			sinNyz: 0,
			cosNyz: 0
		};
		this.oGL = new objectGL(4); //0 - picking, 1- face 2 - face grouped (orange), 3 - face selected (red)
		this.webGL.listPolygonGL.push(this);

		if (coAddBorder){		
			let L = this.listVertex.length;
			   // L1 = L - 1,
                //i1;

			for (var i = 0; i < L; i++) {
				let i1 = app.utils.nIsafe(true, this.listVertex, i),
					p1 = this.listVertex[i],
					p2 = this.listVertex[i1];
				var bgl = this.webGL.listBorderGL.find((b) => { return b.equivalentToVertices(p1, p2); });
				if (bgl) {
					bgl.addPol(this, i, i1);	
				}
				else {
					bgl = new BorderGL(p1, p2, this, i,i1);
					this.webGL.listBorderGL.push(bgl);
				}
			}
		}
	}
	//------------------------------------------------------------------------------
	setVNdata() {
		this.d.countNVnotZero = 0;
		this.d.indexNVnotZero = 0;
		this.d.normalVN = this.getNormal(false);
		for (let i = 0; i < 3; i++) {
			if (this.d.normalVN[i] !== 0) {
				this.d.countNVnotZero++;
				this.d.indexNVnotZero = i;
			}
		}
		this.d.coVN0 = false;
		this.d.coVN1 = false;
		this.d.coVN2 = false;
		if (this.d.countNVnotZero === 1){
			(this.d.coVN0 = this.d.indexNVnotZero === 0);
			(this.d.coVN1 = this.d.indexNVnotZero === 1);
			(this.d.coVN2 = this.d.indexNVnotZero === 2);
		}

		if (this.polyJSON.idVert.length > 2) {
			let vertex3D = this.listVertex[0];
			this.d.translation = [
				vertex3D[1],
				vertex3D[2],
				vertex3D[3]
			];
			let lxyz = app.utils.vecLength(this.d.normalVN),
				lxy = Math.sqrt(this.d.normalVN[0] * this.d.normalVN[0] + this.d.normalVN[1] * this.d.normalVN[1]);
			this.d.sinNxy = -this.d.normalVN[2] / lxyz;
			this.d.cosNxy = lxy / lxyz;
			if (lxy < 0.000000000001) {
				this.d.sinNyz = 0;
				this.d.cosNyz = 1;
			} else {
				this.d.sinNyz = this.d.normalVN[0] / lxy;
				this.d.cosNyz = this.d.normalVN[1] / lxy;
			}
			/*let inclination = 90 - (Math.asin(-this.d.sinNxy) * 180 / Math.PI),
				Azimuth_Y = 0;
		
			if (lxy < 0.000000000001) {
				Azimuth_Y = -99999;  //horizontal
			}
			else {
				Azimuth_Y = Math.asin(Math.abs(this.d.sinNyz)) * 180 / Math.PI;
				let vn = this.d.normalVN;
				if (vn[0] >= 0 && vn[1] < 0) Azimuth_Y = 180 - Azimuth_Y;
				else if (vn[0] <= 0 && vn[1] <= 0) Azimuth_Y = 180 + Azimuth_Y;
				else if (vn[0] < 0 && vn[1] > 0) Azimuth_Y = 360 - Azimuth_Y;
			}
			this.polyJSON.incl = inclination;

			if (Azimuth_Y < -99) this.polyJSON.azimN = 0;   
            else  
			{
				let az = Azimuth_Y + app.actVariantJSON.building.azimN - 180;
				if (az > 360) az -= 360;
                else if (az < 0) az += 360;
				this.polyJSON.azimN = az;
			}
			this.componentJSON.inclC = roundVal(inclination,1);
			*/
		}
	}

	//--------------------------------------------------------------------------
	getNormal(minusY) {
		let y = this.polyJSON.nVec[1];
		if (minusY) y = -y;
		return [
			this.polyJSON.nVec[0],
		    y,
			this.polyJSON.nVec[2]
		];
	}

	//________________________________________________________________________
	reverseNormal(){
		this.polyJSON.nVec[0] = -this.polyJSON.nVec[0];
		this.polyJSON.nVec[1] = -this.polyJSON.nVec[1];
		this.polyJSON.nVec[2] = -this.polyJSON.nVec[2];

		this.oGL.removeFromSceneGL(this.webGL.threeScene); 
		this.oGL.disposeGL();
	
		this.setVNdata();
		this.calc();	
	}

	//--------------------------------------------------------------------------
	getPos3(includeFirstVertixAtEnd, includeHoles, minusY) {  //list x,y,z idNr vertext omitted
		let pos3 = [];
		this.listVertex.forEach(polVertex => {
			pos3.push(polVertex[1]);
			if (minusY)	pos3.push(-polVertex[2]);  //must be cleared ??!! to clockwise winding ?
			else pos3.push(-polVertex[2]);
			pos3.push(polVertex[3]);
		});
		this.countPositionsWithoutHoles = pos3.length;
		if (includeFirstVertixAtEnd) {
			for (let j = 0; j < 3; j++) pos3.push(pos3[j]);
		}
		if (includeHoles) {
			this.listInnerPolygonGL.forEach(poly => {
				let pp = poly.getPos3(false, false,minusY);
				if (pp) {
					pp.forEach((n) => {
						pos3.push(n);
					});
				}
			});
		}
		return pos3;
	}

	//--------------------------------------------------------------------------
	getPos2(dd, getMinMax) {  
		let pos2 = [];//[this.listVertex.length * 2];  
		//let i = 0;
		if (dd.countNVnotZero === 1) {
			//pararell to axis
			this.listVertex.forEach(polVertex => {
				if (dd.coVN0) {
					pos2.push(polVertex[2]);
					pos2.push(polVertex[3]);
				}
				else if (dd.coVN1) {
					pos2.push(polVertex[1]);
					pos2.push(polVertex[3]);
				}
				else if (dd.coVN2) {
					pos2.push(polVertex[1]);
					pos2.push(polVertex[2]);
				}
			});
		} else {
			//rotate/translate
			let x1, y1, z1, x2, y2, z2;
			this.listVertex.forEach(polVertex => {
				//translate
				x1 = polVertex[1] - dd.translation[0];
				y1 = polVertex[2] - dd.translation[1];
				z1 = polVertex[3] - dd.translation[2];
				//rotate at vertical axis
				x2 = x1 * dd.cosNyz - y1 * dd.sinNyz;
				y2 = x1 * dd.sinNyz + y1 * dd.cosNyz;
				z2 = z1;
				//rotate to vertical
				x1 = x2;
				y1 = y2 * dd.cosNxy - z2 * dd.sinNxy;
				z1 = y2 * dd.sinNxy + z2 * dd.cosNxy;

				pos2.push(x1);
				pos2.push(z1);
			});
		}
		if (getMinMax) {
			if (this.listVertex.length > 0) {
				let i = 0;
				let L = pos2.length;

				this.mM2[0][0] = pos2[i]; // x min
				this.mM2[0][1] = pos2[i++]; //x max
				this.mM2[1][0] = pos2[i];  //y min
				this.mM2[1][1] = pos2[i++]; //y max


				while (i < L) {
					if (this.mM2[0][0] > pos2[i]) this.mM2[0][0] = pos2[i]; //x
					if (this.mM2[0][1] < pos2[i]) this.mM2[0][1] = pos2[i];  //x
					i++;
					if (this.mM2[1][0] > pos2[i]) this.mM2[1][0] = pos2[i]; //y
					if (this.mM2[1][1] < pos2[i]) this.mM2[1][1] = pos2[i]; //y

					i++;
				}
				this.mM2[0][2] = this.mM2[0][1] - this.mM2[0][0];
				this.mM2[1][2] = this.mM2[1][1] - this.mM2[1][0];
			}
			this.polyJSON.horW = app.utils.roundVal(this.mM2[0][2],3);
			this.polyJSON.vertH = app.utils.roundVal(this.mM2[1][2],3);
		}
		return pos2;
	}

	//--------------------------------------------------------------------------
	getDataTesselation() {
		let pos2 = this.getPos2(this.d, true);  
		let posHoles = [];
		if (pos2.length > 5) {
			let ihole = this.listVertex.length;
			this.listInnerPolygonGL = [];
			this.polyJSON.idPolyI.forEach((id) => {
				var o = this.webGL.listPolygonGL.find((el) => el.polyJSON.id === id);
				if (o) this.listInnerPolygonGL.push(o);
			});
			this.listInnerPolygonGL.forEach(poly => {
				posHoles.push(ihole);
				let pos2Hole = poly.getPos2(this.d, false);
				pos2Hole.forEach(el => {
					pos2.push(el);
				});
				ihole += poly.listVertex.length;
			});
		}
		return { pos2, posHoles };
	}



	//--------------------------------------------------------------------------
	triangulate() {  
		if (this.needTriagulation) {
			this.setVNdata();
			let res = this.getDataTesselation();
			if (res.pos2.length > 5) {
				this.triangles2Dindexe = earcut(res.pos2, res.posHoles, 2);
				this.uv = [];
				let i = 0;
				while (i < res.pos2.length) {
					let u = (res.pos2[i++] - this.mM2[0][0]) / this.mM2[0][2],   //this.xMMD[0]) / this.xMMD[2],
						v = (res.pos2[i++] - this.mM2[0][1]) / this.mM2[1][2];   //this.yMMD[2];
					this.uv.push(u, v); 
				};
			} 
			
			this.needTriagulation = false;
		}
	}

	//__________________________________________________________________________
	calc() {  
		this.triangulate();
		let pos = this.getPos3(false, true, false),  //list x,y,z idNr vertext omitted
			//L1 = this.countPositionsWithoutHoles - 1,
			tii = this.triangles2Dindexe,
			Lind = tii.length,
			ii = 0, i3,iii,
			p0,p1,p2,
			v1, v2,
			vn = this.getNormal(true),
			vc;

		function vec(){
			i3 = tii[ii++]*3;
			return [pos[i3], pos[i3 + 1], pos[i3 + 2]];
		}
		function vecc(v1,v2){
			return [v2[0] - v1[0], v2[1] - v1[1], v2[2] - v1[2]];
		}

		ii = 0;
		while (ii < Lind) {
			p0 = vec();
			p1 = vec();
			p2 = vec();
			v1 = vecc(p0,p1);
			v2 = vecc(p0,p2);

			vc = app.utils.crossProduct(v1, v2);  //sin
			if (app.utils.dotProduct(vn, vc) > 0) { //cos

				iii = tii[ii - 1];
				tii[ii - 1] = tii[ii - 2];
				tii[ii - 2] = iii;
				//this.vol += val;
			}
		}
	}

//__________________________________________________________________________
	calcMM3() {  
		let pos = this.getPos3(false, false, true);
		if (pos.length > 0) {
			let i = 0,j;
			let L = pos.length;

			for (j = 0; j < 3; j++) {
				this.mM3[j][0] = pos[i]; // x min
				this.mM3[j][1] = pos[i++]; //x max
			}
			while (i < L) {
				for (j = 0; j < 3; j++) {
					if (this.mM3[j][0] > pos[i]) this.mM3[j][0] = pos[i]; //x,y,z
					if (this.mM3[j][1] < pos[i]) this.mM3[j][1] = pos[i];  //x,y,z
					i++;
				}
			}
			for (j = 0; j < 3; j++) this.mM3[j][2] = this.mM3[j][1] - this.mM3[j][0];
		}
	}

	//__________________________________________________________________________
	createCanvasTexture(outer, colorFace, typeR) { //createCanvasTexture(outer: boolean, colorFace: string, colorTexture: number) {
		var canvas = document.createElement('canvas'),
			ctx = canvas.getContext('2d');

		canvas.width = 10;
		canvas.height = 10;
		if (ctx) {
			let col = colorLineBasicGroupSelected[typeR],
				suDiff = 0,
				colF = app.utils.ColorHexToRGB(colorFace);
            for (let i = 0; i < 3; i++)   suDiff += Math.abs(col[i] - colF[i]);  

			if (suDiff < 60){
				col = [255 - col[0], 255 - col[1], 255 - col[2]];
			} 


			ctx.fillStyle = app.utils.ColorRGBtoString(colF);  //ColorHexToRGB(colorI)
			ctx.fillRect(0, 0, canvas.width, canvas.height);

			if (outer) {
				ctx.fillStyle = ctx.strokeStyle = app.utils.ColorRGBtoString(col);
				ctx.fillRect(2, 2, 3, 3);
			}
			else {
				ctx.strokeStyle = app.utils.ColorRGBtoString(col);
				ctx.lineWidth = 1;

				ctx.moveTo(0, 0);
				ctx.lineTo(canvas.width, canvas.height);

				ctx.moveTo(canvas.width, 0);
				ctx.lineTo(0, canvas.height);

				ctx.stroke();
			}
		}
		var texture = new THREE.Texture(canvas);
		texture.needsUpdate = true;
		texture.wrapS = THREE.RepeatWrapping;
		texture.wrapT = THREE.RepeatWrapping;

		texture.offset.set(0, 0);

		let rep = 0.06*this.webGL.scalar*this.webGL.mWH, //repeat 
            iw = rep *Math.abs(this.mM2[0][2] )/this.webGL.maxXYZ,
            ih = rep*Math.abs(this.mM2[1][2])/this.webGL.maxXYZ; 

		if (outer) texture.repeat.set(iw , ih); 
		else texture.repeat.set(0.8*iw , 0.8*ih);

		return texture;

	};

	//__________________________________________________________________________
	removeT() {
		  this.oGL.removeT(this.webGL.threeScene);
	}

	//________________________________________________________________________________________________
	addToGLscene(coPicking) { //stateLineBasicGroupSelected = 1; //0 - only line contour, 1 - opaque , 2-opaque group yellow , 3 - opaque selected red contour

		

		let iTypeRender = 0; //0 - picking, 1- face 2 - face grouped (yellow), 3 - face selected (red)
		if (!coPicking) iTypeRender = this.stateLineBasicGroupSelected;
		if (this.oGL.existsInScene[iTypeRender]) return; 

		if (this.webGL.timing){
			if (iTypeRender > 1) iTypeRender = 1;
		}

		if (!app.utils.oOK(this.oGL.oRender[iTypeRender])) {  //null or undefined - not created yet
			var geometry = new THREE.BufferGeometry(),
				materialFace;

			if (iTypeRender === 0) {
				materialFace = new THREE.MeshBasicMaterial(
					{
						color: "rgb(" + this.colorPick[0].toString() + "," + this.colorPick[1].toString() + "," + this.colorPick[2].toString() + ")",
						side: THREE.DoubleSide
					});
				geometry.setAttribute("position", new THREE.BufferAttribute(Float32Array.from(this.getPos3(false, true, true)), 3));
				geometry.setIndex(this.triangles2Dindexe);
				this.oGL.oRender[iTypeRender] = new THREE.Mesh(geometry, materialFace);
			}
			else {
				let materialFaceBack = null,
					colorE = app.utils.getValueFromSel("colorSurf", this.componentJSON.idColorEC, 2),   // this.webGL.getColorDefault(this.componentJSON.idColorEC),
					colorI = app.utils.getValueFromSel("colorSurf", this.componentJSON.idColorIC, 2);
				if (this.componentJSON.typeC >= 2) //transparent
				{
					if (iTypeRender === 1) {
						materialFace = new THREE.MeshBasicMaterial({
							color: colorE,
							transparent: true,
							opacity: 0.5,
							side: THREE.FrontSide
						});
						materialFaceBack = new THREE.MeshBasicMaterial({
							color: colorI,
							transparent: true,
							opacity: 0.5,
							side: THREE.BackSide
						});
					}
					else {
						materialFace = new THREE.MeshBasicMaterial({
							map: this.createCanvasTexture(true, colorE, iTypeRender),
							transparent: true,
							opacity: 0.9,
							side: THREE.FrontSide
						});
						materialFaceBack = new THREE.MeshBasicMaterial({
							map: this.createCanvasTexture(false, colorI, iTypeRender),
							transparent: true,
							opacity: 0.9,
							side: THREE.BackSide
						});
					}
				}
				else {
					if (iTypeRender === 1) {
						materialFace = new THREE.MeshBasicMaterial({
							color: colorE,
							side: THREE.FrontSide
						});
						materialFaceBack = new THREE.MeshBasicMaterial({
							color: colorI,
							side: THREE.BackSide
						});
					}
					else {
						materialFace = new THREE.MeshBasicMaterial({
							map: this.createCanvasTexture(true, colorE, iTypeRender),
							side: THREE.FrontSide
						});
						materialFaceBack = new THREE.MeshBasicMaterial({
							map: this.createCanvasTexture(false, colorI, iTypeRender),
							side: THREE.BackSide
						});
					}
				}
				var group = new THREE.Group();  
				geometry.setAttribute("position", new THREE.BufferAttribute(Float32Array.from(this.getPos3(false, true, true)), 3));
				geometry.setIndex(this.triangles2Dindexe);
				geometry.setAttribute('uv', new THREE.BufferAttribute(Float32Array.from(this.uv), 2));//if texture
				group.add(new THREE.Mesh(geometry, materialFace));
				group.add(new THREE.Mesh(geometry, materialFaceBack));
				this.oGL.oRender[iTypeRender] = group;
			}
		}
		this.webGL.threeScene.scene.add(this.oGL.oRender[iTypeRender]);
		this.oGL.existsInScene[iTypeRender] = true;
	}
}