import {app} from "../ahome/App";
import "./ContextMenu.css";


//_______________________________________________________________________________________
export class ContextMenu 
{
    mdiv;
    onItemSelected;

    //____________________________________________________________________________
	constructor(loc, onItemSelected) {
        this.loc = loc;
        this.onItemSelected = onItemSelected;  ;
        this.mdiv = document.createElement('div');
        this.mdiv.className = "contextmenu-container";
    }

    //_______________________________________________________________________________________
    onMouseDown(e){
        let rect = this.mdiv.getBoundingClientRect();     
        if (e.clientX > rect.left && e.clientX < (rect.left + rect.width) && e.clientY > rect.top && e.clientY < (rect.top + rect.height)){    
        if (e.buttons === 1){
                this.hide();
                this.onItemSelected(e.target.id);
            }     
        }
        else this.hide(); 
    }

    //_______________________________________________________________________________________
    setItems(items){
        if (this.mdiv){
            while (this.mdiv.firstChild) { this.mdiv.removeChild(this.mdiv.firstChild);}
        }
        items.forEach(sk => {
            let divItem = document.createElement('div'),
                txt = document.createTextNode(app.utils.nK(sk));
            divItem.id = sk;
            txt.className = "contextmenu-text";
            divItem.className = "contextmenu-item";
            divItem.appendChild(txt);
            this.mdiv.appendChild(divItem);
        });
    }

    //_______________________________________________________________________________________
    show(e){
        this.hide();
        this.mdiv.style.left = e.clientX + 'px';
        this.mdiv.style.top = e.clientY + 'px';
        if (this.mdiv) this.loc.appendChild(this.mdiv); 

        window.addEventListener('mousedown', (e) => this.onMouseDown(e));
    }

    //_______________________________________________________________________________________
    hide(){
        window.removeEventListener('mousedown', (e) => this.onMouseDown(e));
        if (this.mdiv && this.mdiv.parentNode) this.mdiv.parentNode.removeChild(this.mdiv);

    }
}