//import { app.utils.nK, app.utils.app.utils.phh, daysMonth, sUnit } from "../data/Utils";
//import { app.utils.settings } from "../data/app.utils.settings";
import {app} from "../ahome/App";
/*/____________________________________________________________________________
interface diagrAxis {
    Vmin: number;
    Vmax: number;
    nDiv: number;
    dA: number;
    Amin: number;
    Amax: number;
    key: string;
    name: string;
    labels: string[],
    mDpx: number,  //min distance labels + name in pixel
    mLpx: number,  //min length in pixel
    log10: boolean,
    nAfterComma: number,
    divMonth: boolean,
    setMinMax: boolean
}

interface diagrCurve {
    xy: any;  //[[0,0]]
    color: any;
    lineWidth: number;
}*/

//______________________________________________________________________________________
export class Odiagr {
    curves = [];  //diagrCurve[]
    Xaxis = {};// as diagrAxis;
    Yaxis = {};// as diagrAxis;

    //______________________________________________________________________________________
    getAxis(coXY) {
      //: boolean  //: diagrAxis
      if (coXY) return this.Xaxis;
      else return this.Yaxis;
    }

    //______________________________________________________________________________________
    setAxis(coXY, key, nDiv,  log10, divMonth) {  //coXY: boolean, key: string, nDiv: number,  log10: boolean, divMonth: boolean
        let Axis = this.getAxis(coXY);
        Axis.key = key;
        Axis.nDiv = nDiv;
        Axis.log10 = log10;
        Axis.divMonth = divMonth;
        if (Axis.divMonth) {
            this.setAxisMinMax(true, 0, 365, 365 / 12, 0);
            Axis.labels = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII",""];
            Axis.setMinMax = false;
        }
        else Axis.setMinMax = true;
    }

//______________________________________________________________________________________
    setAxisN(coXY, name, nDiv,  log10, divMonth) {
      this.setAxis(coXY, "", nDiv,  log10, divMonth);
      let Axis = this.getAxis(coXY);
      Axis.name = name;
    }

    //______________________________________________________________________________________
    setAxisMinMax(coXY, aMin, aMax, dA, nAfterComma) {  //coXY: boolean, aMin: number, aMax: number, dA: number, nAfterComma: number
        let a = this.getAxis(coXY);
        a.Amin = aMin;
        a.Amax = aMax;
        a.dA = dA;
        a.nAfterComma = nAfterComma;
        a.setMinMax = false;
    }

    //______________________________________________________________________________________
    addCurve(color, lineWidth){//color: any, lineWidth: number
        var curve = {};// as diagrCurve;
        curve.color = color;
        curve.lineWidth = lineWidth;
        curve.xy = [];
        this.curves.push(curve);
        return curve;
    }

    //______________________________________________________________________________________
    addCurveXY(indexCurve, x, y){
        this.curves[indexCurve].xy.push([x, y]);  
    }

    //______________________________________________________________________________________
    prepare() {
        let vmin = 0,
            vmax = 0,
            A,//: diagrAxis,
            coFirstVal = true;  

        for (let i = 0; i < 2; i++) {
            if (i === 0) A = this.Xaxis;
            else A = this.Yaxis;
            
            let def = app.def[A.key];
            coFirstVal = true;
            if (def) {
                A.name = app.utils.nK(A.key);
                if (def.hasOwnProperty("u")) A.name += " " + app.utils.sUnit(app.def[A.key]["u"], true);
            }
            //find min max---
            for (let ic = 0; ic < this.curves.length; ic++) {
                for (let j = 0; j < this.curves[ic].xy.length; j++) {
                    let v = this.curves[ic].xy[j][i];
                    if (coFirstVal) {
                        vmin = v;
                        vmax = v;
                        coFirstVal = false;
                    }
                    else {
                        if (v < vmin) vmin = v;
                        if (v > vmax) vmax = v;
                    }
                }
            };
            //---------------
            A.Vmin = vmin;
            A.Vmax = vmax; 
            let co = A.setMinMax,
            coef = 1;
            while (co) {
                A.nAfterComma = 0;
                let da = coef * (vmax - vmin) / A.nDiv;
                if (da <= Number.MIN_VALUE) A.dA = 1;
                else if (da > 0.8 && da <= 1) A.dA = 1;
                else if (da >= 1 && da <= 10) {
                    A.dA = Math.trunc(da);
                    let dfrac = da - A.dA;
                    if (dfrac > 0.4) A.dA++;
                    else if (dfrac > Number.MIN_VALUE) {
                        A.dA += 0.5;
                        A.nAfterComma = 1;
                    }
                }
                else {
                    let daN = Math.trunc(da),
                        sda = da.toString(),
                        splitE = sda.split("e"),
                        splitDot = splitE[0].split("."),
                        splitComma = splitE[0].split(","),
                        s = "";
                    if (splitComma.length > splitDot.length) splitDot = splitComma;
                    if (daN > 0) sda = splitDot[0];
                    else {
                        sda = splitDot[1];
                        s = "0.";
                    }
                    let l = sda ? sda.length : 0,
                      numbers = [], //number[]
                      iposFD = -1, //posfirstDigit
                      iposFDp1 = -1,
                      ipos = 0,
                      s1 = "";
                    for (ipos = 0; ipos < l; ipos++) {
                        s1 = sda[ipos];
                        if (s1 !== "0" && iposFD < 0) iposFD = ipos;
                        numbers.push(Number(s1));
                    }
                    iposFDp1 = iposFD + 1;
                    if (numbers.length > iposFDp1) {
                        if (numbers[iposFDp1] > 4) {
                            numbers[iposFD]++;
                            numbers[iposFDp1] = 0;
                        }
                        else numbers[iposFDp1] = 5;
                                //if (daN < 1) A.nAfterComma = iposFDp1;
                    }
                    if (numbers[iposFD] === 10) {
                        iposFD--;
                        numbers[iposFD] = 1;
                        iposFDp1--;
                        numbers[iposFDp1] = 0;
                    }
                    if (daN < 1) A.nAfterComma = iposFDp1 + 1;

                    l = numbers.length;
                    for (ipos = 0; ipos < l; ipos++) {
                        if (ipos > iposFDp1) numbers[ipos] = 0;
                        s += numbers[ipos].toString();
                    }
                    A.dA = Number(s);

                            //if (daN < 1) A.nAfterComma = l;
                            //let sp = s.split(".");
                            //if (sp[1]) A.nAfterComma = sp[1].length;
                }
                if (da <= Number.MIN_VALUE) {
                    A.Amin = Math.trunc(vmin - A.nDiv / 2) * A.dA;
                    A.nAfterComma = 0;
                }
                else {
                    let n = Math.trunc(A.Vmin / A.dA);
                    A.Amin = n * A.dA;
                    if (A.Vmin < 0.9999 * A.Amin) A.Amin -= A.dA;
                }
                A.Amax = A.Amin + A.nDiv * A.dA; 
                if (A.Amax < A.Vmax && coef < 2) coef += 0.1;
                else co = false;
            }   
        }
    }

    //______________________________________________________________________________________
    measureGrid(ctx) {
      //ctx: any
      let A = this.Xaxis,
        stext,//: string,
        val,//: number,
        coH = true, //horizontal first
        mt;//: any;

      ctx.font = app.utils.settings.Diagr.sFont;
      for (let ihv = 0; ihv < 2; ihv++) {
        if (!A.divMonth) A.labels = [];
        A.mDpx = 0;
        val = A.Amin;
        for (let ival = 0; ival <= A.nDiv; ival++) {
          if (A.divMonth) stext = A.labels[ival];
          else {
            stext = val.toFixed(A.nAfterComma); //removePrec(roundVal(val,A.nAfterComma).toString());//removePrec(val.toString());
            A.labels.push(stext);
          }
          mt = ctx.measureText(stext);
          if (mt.width > A.mDpx) A.mDpx = mt.width;
          val += A.dA;
        }
        if (coH) {
          A.mLpx = 2 * A.nDiv * A.mDpx;
          A.mDpx = 2 * app.utils.settings.Diagr.minDist + app.utils.settings.Diagr.heightBoxText;
          if (A.name) A.mDpx += app.utils.settings.Diagr.heightBoxText;
        } else {
          A.mLpx =
            2 *
            A.nDiv *
            (app.utils.settings.Diagr.minDist + app.utils.settings.Diagr.heightBoxText);
          A.mDpx += 2 * app.utils.settings.Diagr.minDist;
          if (A.log10) A.mDpx += ctx.measureText("10").width;
          if (A.name) A.mDpx += app.utils.settings.Diagr.heightBoxText;
        }
        A = this.Yaxis;
        coH = false;
      }
    }

    //______________________________________________________________________________________
    paintGrid(x0pix, y0pix, wPix, hPix, ctx) {
      //x0pix: number, y0pix: number, wPix: number, hPix: number, ctx: any
      let A = this.Xaxis,
        wh = wPix,
        coH = true, //horizontal first
        xy = 0,
        xyPrev = 0,
        dxy = x0pix,
        dm = app.utils.daysMonth(); //auf alle faelle

      ctx.beginPath();
      ctx.textAlign = "left";
      ctx.textBaseline = "top";
      ctx.font = app.utils.settings.Diagr.sFont;

      for (let ihv = 0; ihv < 2; ihv++) {
        let dVal = A.Amax - A.Amin,
          val = A.Amin;

        for (let ival = 0; ival <= A.nDiv; ival++) {
          if (coH) {
            //horizontal

            xy = app.utils.phh(dxy + (wh * (val - A.Amin)) / dVal);
            ctx.moveTo(xy, y0pix);
            ctx.lineTo(xy, y0pix - hPix); //vertical lines

            if (A.divMonth) {
              if (ival > 0)
                ctx.fillText(
                  A.labels[ival - 1],
                  (xy + xyPrev) / 2,
                  y0pix + app.utils.settings.Diagr.minDist
                );
            } else {
              if (ival === A.nDiv) ctx.textAlign = "end"; //the last before end
              ctx.fillText(A.labels[ival], xy, y0pix + app.utils.settings.Diagr.minDist);
            }
            if (ival === 0) ctx.textAlign = "center";
          } else {
            //vertical
            xy = app.utils.phh(dxy - (wh * (val - A.Amin)) / dVal);
            ctx.moveTo(x0pix, xy);
            ctx.lineTo(x0pix + wPix, xy); //horizontal lines

            if (ival === A.nDiv) ctx.textBaseline = "top"; //the last before end
            ctx.fillText(A.labels[ival], x0pix - app.utils.settings.Diagr.minDist, xy);
            if (A.log10)
              ctx.fillText(
                "10",
                x0pix -
                  app.utils.settings.Diagr.minDist -
                  ctx.measureText(A.labels[ival]).width,
                xy + app.utils.settings.Diagr.heightBoxText / 2
              );
          }
          if (A.divMonth) val += dm[ival];
          else val += A.dA;
          xyPrev = xy;
        }
        if (A.name) {
          ctx.save();
          ctx.textAlign = "center";
          if (coH) {
            ctx.fillText(
              A.name,
              x0pix + wPix / 2,
              y0pix + 2 * app.utils.settings.Diagr.minDist + app.utils.settings.Diagr.heightBoxText
            );
          } else {
            ctx.translate(
              app.utils.settings.Diagr.minDist,
              hPix / 2 - app.utils.settings.Diagr.minDist
            );
            ctx.rotate(-Math.PI / 2);
            ctx.fillText(A.name, 0, 0);
          }
          ctx.restore();
        }
        A = this.Yaxis;
        wh = hPix;
        coH = false;
        dxy = y0pix;
        x0pix = app.utils.phh(x0pix);

        ctx.textAlign = "end";
        ctx.textBaseline = "middle";
      }
      ctx.lineWidth = 1;
      ctx.strokeStyle = "gray";
      ctx.stroke();
    }

    //______________________________________________________________________________________
    paint(wPix, hPix, ctx) {
      //wPix: number, hPix: number, ctx: any
      this.measureGrid(ctx);

      let x0 = this.Yaxis.mDpx,
        y0 = hPix - this.Xaxis.mDpx,
        w = wPix - x0 - app.utils.settings.Diagr.minDist,
        h = y0 - app.utils.settings.Diagr.minDist;

      //if (w < dd.X.mLpx || h < dd.Y.mLpx) return;
      this.paintGrid(x0, y0, w, h, ctx);

      //curves-----------
      let dx = this.Xaxis.Amax - this.Xaxis.Amin,
        dy = this.Yaxis.Amax - this.Yaxis.Amin,
        x,//: number,
        y,//: number,
        xp,//: number,
        yp,//: number,
        coFirst = true;

      for (let ic = 0; ic < this.curves.length; ic++) {
        ctx.beginPath();
        coFirst = true;
        for (let j = 0; j < this.curves[ic].xy.length; j++) {
          x = this.curves[ic].xy[j][0];
          y = this.curves[ic].xy[j][1];

          xp = x0 + (w * (x - this.Xaxis.Amin)) / dx;
          yp = y0 - (h * (y - this.Yaxis.Amin)) / dy;
          if (coFirst) {
            ctx.moveTo(xp, yp);
            coFirst = false;
          } else ctx.lineTo(xp, yp);
        }
        ctx.lineWidth = this.curves[ic].lineWidth;
        ctx.strokeStyle = this.curves[ic].color;
        ctx.stroke();
      }
    }
}