import {app} from "../ahome/App";

export class FileInput {
    //____________________________________________________________________________________________________
    constructor(fileType, form) {  //FE - formEdit

        this.fileType = fileType;   
        this.form = form;
        this.onChangeFile = this.onChangeFile.bind(this);
        this.onLoadFile = this.onLoadFile.bind(this);
    }

    //____________________________________________________________________________________________________
    onChangeFile(e) {
        if (app.utils.oOK(e.target)){
            if (app.utils.oOK(e.target.files)){
                if (e.target.files.length > 0){
                    this.fReader.onload = this.onLoadFile;
                    this.fReader.readAsText(e.target.files[0]);
                }
            }
        }
    }

    //____________________________________________________________________________________________________
    onLoadFile(e){
        this.form.FileLoaded(this.fReader.result);
    }


    //_____________________________________________________________________________________________________
    Click(e){
        if (!app.utils.oOK(this.fReader)) this.fReader = new FileReader();

        let sid = "InputFile" + this.fileType;
        this.inF = document.getElementById(sid);

        if (!app.utils.oOK(this.inF)){
            this.inF=document.createElement('input');
            this.inF.type="file";
            this.inF.accept = this.fileType; 
            this.inF.id = sid;
            this.inF.style.display = 'none';
            document.getElementById(app.FE[0].skdiv).appendChild(this.inF);
        }
        this.inF.onchange=this.onChangeFile;
        this.inF.click();
    }
}