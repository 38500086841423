import React, { Component } from "react";
import * as THREE from "three";
import {ContextMenu } from "../editors/ContextMenu"
import { WebGLStore } from "./WebGLStore";
//import {Settings} from "../data/Settings";
//import {eqalToOneOf} from "../data/Utils"
import {app} from "../ahome/App";

//=============================================================================================
export class ThreeScene extends Component {
	maxXYZ = 10;
	displayWidth = 0;
	displayHeight = 0;
	
	mouseDown = false;
	mouseX = 0;
	mouseY = 0;
	scalar = 1;
	dXYTranslationPerPixel = 0;
	sumXTranslated = 0;
	sumYTranslated = 0;
	//coefRotate = 1;
	vectorCheck = new THREE.Vector3();

	//controlRotationH = true;

	widthPanelGL = 0;
	heightPanelGL = 0;

	
	//not explicite--------------------------------
	//divel;//: HTMLElement;
	//scene;//?: THREE.Scene;
	//camera;//?: THREE.OrthographicCamera;
	//renderer;//?: THREE.WebGLRenderer;
	//canvas;//?: HTMLCanvasElement;
	//webGL  WebGLStore();
	//cm; - context menu


	//============================================================================================
	constructor(props) {
		super(props);
		this.onDocumentMouseDown = this.onDocumentMouseDown.bind(this);
		this.onDocumentMouseMove = this.onDocumentMouseMove.bind(this);
		this.onDocumentMouseUp = this.onDocumentMouseUp.bind(this);
		this.onDocumentMouseWheel = this.onDocumentMouseWheel.bind(this);
		this.onCMSelected = this.onCMSelected.bind(this);
		this.wResized1 = this.wResized1.bind(this);



		app.TS[props.inr] = this;

		this.webGL = new WebGLStore();
		this.webGL.inr = this.props.inr;  //console.log("THREE scene created");
	}

	newVariant(varJS){
		this.webGL.newVariant(varJS);
	}

//________________________________________________________________________________________
    Wait()
    {
        if (this.props.wait) return this.props.wait;
        else return false;
    }


	//============================================================================================
	componentDidMount() {
		this.scene = new THREE.Scene();
		this.camera = new THREE.OrthographicCamera(-10, 10, 10, -10, 1000, -1000);

		this.renderer = new THREE.WebGLRenderer({ antialias: true });
		this.renderer.setClearColor(app.utils.settings.TS.colorBKG);
		this.checkGeometry();
		this.renderer.setSize(this.displayWidth, this.displayHeight - 7);

		this.renderer.domElement.addEventListener(
			"mousedown",
			this.onDocumentMouseDown,
			false
		);
		this.renderer.domElement.addEventListener(
			"mouseup",
			this.onDocumentMouseUp,
			false
		);
		this.renderer.domElement.addEventListener(
			"mousemove",
			this.onDocumentMouseMove,
			false
		);
		this.renderer.domElement.addEventListener(
			"wheel",
			this.onDocumentMouseWheel,
			{ passive: true }
		);
		this.renderer.domElement.addEventListener("contextmenu",  (e) => this.ShowcontextMenu(e));

		this.webGL.threeScene = this;
	
		this.divel.appendChild(this.renderer.domElement);
		this.canvas = this.renderer.domElement;

		this.cm = new ContextMenu(this.divel, this.onCMSelected);
		//this.cm.setItems(["s_changeOr", "s_Va"]);

		this.setDefRot();
	}


	//____________________________________________________________________________________________
	ShowcontextMenu(e){
		if (this.Wait()) return;
		e.preventDefault();

		let k = [];
		if (this.webGL.listPolygonGLsel.length > 0) k.push("s_changeOr");
		if (app.pref.lastChoice > 0){
			if (app.pref.showAllCompsZone) k.push("s_Vd"); else k.push("s_Va"); 
		}

		if (k.length > 0){
			this.cm.setItems(k);
			this.cm.show(e);
		}
	}

	//____________________________________________________________________________________________
	onCMSelected(sk){   
		if (sk === "s_changeOr"){
			this.webGL.listPolygonGLsel.forEach(pol => {  
				pol.reverseNormal();
				if (this.props.inr === 0) app.TV[0].selPOC = app.TV[0].selP;
			});
			this.webGL.createNewSceneGL(false);
    		this.reRender(); 
			if (this.props.inr === 0) app.FE[0].ReInitForm();//reRender();

			app.projectJSON.iniCalc = true;  //must be set context depending
            app.SD.cTimer();
		}
		else if (app.utils.eqalToOneOf(sk,["s_Vd","s_Va"])){
			app.pref.showAllCompsZone = !app.pref.showAllCompsZone; 

			if (app.pref.lastChoice === 1) app.TV[0].objectSelectedTreeViewInformWbGL(app.lastDataChoice);
			if (app.pref.lastChoice === 2) {
				this.webGL.polygonGLPicked(app.lastDataChoice);
				this.webGL.createNewSceneGL(false);
				this.reRender();
			}

			// this.webGL.createNewSceneGL(false);
			// this.reRender();
		}
	} 

	//============================================================================================
	componentWillUnmount() {//console.log(this.webGL);   //must be checked !!!!
		this.webGL.disposeAllGL();
		if (this.startModalCB < 0){
			this.canvas.removeChild(this.renderer.domElement);
		}
	}

	

	//============================================================================================
	checkGeometry()  { 
		if (this.divel) {

			if (this.widthPanelGL !== this.mW || this.heightPanelGL !== this.mH || app.LOChanged) {

				this.displayWidth = this.mW;
				this.displayHeight = this.mH;

				let aspectRatioX = this.displayWidth / this.displayHeight;
				let aspectRatioY = 1 / aspectRatioX;

				if (this.displayWidth >= this.displayHeight) aspectRatioY = 1;
				else aspectRatioX = 1;

				this.maxXYZ = this.webGL.maxXYZ * 1.41;  

				this.camera.left = -this.maxXYZ * aspectRatioX - this.sumXTranslated;
				this.camera.right = this.maxXYZ * aspectRatioX - this.sumXTranslated;
				this.camera.top = this.maxXYZ * aspectRatioY + this.sumYTranslated;
				this.camera.bottom = -this.maxXYZ * aspectRatioY + this.sumYTranslated;
				this.camera.near = this.maxXYZ * 10;
				this.camera.far = -this.maxXYZ * 10;

				WebGLStore.pixelSize_m =  this.webGL.maxXYZ / Math.min(this.displayWidth, this.displayHeight);  

				this.webGL.mWH = Math.min(this.displayWidth, this.displayHeight);

				this.renderer.setSize(this.displayWidth, this.displayHeight);
				this.camera.updateProjectionMatrix();

				let i = 0;
				this.dXYTranslationPerPixel = 0;
				if (this.displayWidth > 1) {
					this.dXYTranslationPerPixel += (this.camera.right - this.camera.left) / this.displayWidth;
					i++;
				};
				if (this.displayHeight > 1) {
					this.dXYTranslationPerPixel += (this.camera.top - this.camera.bottom) / this.displayHeight;
					i++;
				}
				if (i > 0) this.dXYTranslationPerPixel /= i;  
				
			}
		}
	};


	//------------------------------------------------------------------------
	onDocumentMouseDown(e) {
		//e.preventDefault();

		if (this.Wait()) return;
		this.mouseDown = true;
		this.mouseX = e.clientX;
		this.mouseY = e.clientY;

		if (e.buttons === 1) {

			this.webGL.createNewSceneGL(true);
			this.reRender();
			var rect = e.target.getBoundingClientRect();
			var x = e.clientX - rect.left; //x position within the element.
			var y = rect.height - e.clientY + rect.top; //y
			var gl = e.target.getContext("webgl") || e.target.getContext("webgl2");   
			var pixel = new Uint8Array(4);
			if (gl) gl.readPixels(x, y, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, pixel);

			let polyChosen = this.webGL.listPolygonGL.find(
        	(poly) =>
          		poly.colorPick[0] === pixel[0] &&
          		poly.colorPick[1] === pixel[1] &&
          		poly.colorPick[2] === pixel[2]
      		);

			if (polyChosen) {
				this.webGL.polygonGLPicked(polyChosen); 
			}
			this.webGL.createNewSceneGL(false);
			this.reRender();
			
			
		}
	}

	//==================================================================================
	onDocumentMouseUp(e) {
		//e.preventDefault();
		this.mouseDown = false;
		//this.controlRotationH = false;
	}

	//__________________________________________________________________________________
	setDefRot(){
		this.scene.rotation.z = 0.3;
      	this.scene.rotation.y = 0;
      	this.scene.rotation.x = (-1.2 * Math.PI) / 2;
	}

	//==================================================================================
	onDocumentMouseMove(e) {
		//e.preventDefault();
		if (this.Wait()) return;
		if (this.mouseDown) {
			let deltaX = e.clientX - this.mouseX,
				deltaY = e.clientY - this.mouseY;
			this.mouseX = e.clientX;
			this.mouseY = e.clientY;

			if (e.buttons === 4) {
				if (this.scene) {

					

					let rotX = this.scene.rotation.x -= deltaY / 150;
					if (rotX > -0.001) rotX = -0.001;
					else if (rotX < -3.1414) rotX = -3.1414; 


					this.scene.rotation.z -= deltaX  / 150;
					this.scene.rotation.x = rotX;

					this.reRender();

					this.webGL.Orient(true);
				}
			}
			else if (e.buttons === 1) {
				if (this.camera) {
					let dx = this.dXYTranslationPerPixel * deltaX,
						dy = this.dXYTranslationPerPixel * deltaY;  
					this.camera.left -= dx;
					this.camera.right -= dx;
					this.camera.top += dy;
					this.camera.bottom += dy;
					this.camera.updateProjectionMatrix();
					this.reRender();

					this.sumXTranslated += dx;
					this.sumYTranslated += dy;
				}
			}
		}
	}

	//==================================================================================
	onDocumentMouseWheel(e) {
		//e.preventDefault();
		if (this.Wait()) return;
		if (e.deltaY < 0) this.scalar -= 0.25; else this.scalar += 0.25;  //0.05
		if (this.scalar < 0.45) this.scalar = 0.45;
		this.webGL.scalar = this.scalar;
		//this.webGL.listPolygonGL.forEach(p => p.removeT());
		this.scene.scale.setScalar(this.scalar);

		//this.webGL.createNewSceneGL(false);

		this.reRender();
		this.wResized();
	}

	//____________________________________________________________________________________________
	wResized(){
		if (this.tim) clearTimeout(this.tim); 
		this.webGL.timing = true;
		this.webGL.createNewSceneGL(false);
		this.reRender();
		this.tim = setTimeout(this.wResized1, 300);
	}

//____________________________________________________________________________________________
	wResized1(){
		if (this.tim){
			clearTimeout(this.tim);
			this.tim = undefined;
		}
		this.checkGeometry();
		this.webGL.listPolygonGL.forEach(p => p.removeT());
		this.webGL.timing = false;
		this.webGL.createNewSceneGL(false);
		this.reRender();
	}

	//============================================================================================
	reRender = () => {
		//this.checkGeometry();
		if (this.renderer && this.camera) {
			this.renderer.render(this.scene, this.camera);
		}
	};

	//---------------------------------------------------------------------------------------------
	render() {
		return (
			<div
				style={{
					width: "100%",
					height: "100%",
					padding: 0,
					borderWidth: 0,
					margin: 0,
					overflow: "hidden"
				}}
				ref={el => { this.divel = el; }}
			/>
		);
	}
}

export default ThreeScene;