import * as React from "react"
import "./Tools.css"
//import { messageBox } from "../data/Utils"
import {EdtDef} from "../editors/EdtDef";
import {ButtonDropDown} from "../editors/ButtonDropDown"
//import {FormEdit} from "../../editors/FormEdit";
//import {  nK, oOK, SetKeyValueIfNotExists,DBGeneric,getSubSelection } from "../data/Utils"  

//import { newProJSON, scopeMETR} from "../data/UtilsProj"

import {app } from "./App"

//____________________________________________________________________________________________________
export default class Header extends React.Component {

  constructor(props) {
    super(props);

    app.Header = this;
    this.refFileJSON = React.createRef(); //must be here
    this.refFileIMG = React.createRef(); //must be here
    this.fReaderJSON = new FileReader();
    this.fReaderIMG = new FileReader();

    this.onLoadLoc = this.onLoadLoc.bind(this);
    //this.onLoadDB = this.onLoadDB.bind(this);
    this.buttonClick = this.buttonClick.bind(this);
    this.handleBtnDBClick = this.handleBtnDBClick.bind(this);
    this.onChangeFileJSON = this.onChangeFileJSON.bind(this);
    this.onChangeFileIMG = this.onChangeFileIMG.bind(this);
    this.onSavedGlob = this.onSavedGlob.bind(this);
  }

//____________________________________________________________________________________________________
  onChangeFileJSON(e) {
    this.fn = e.target.files[0].name;
    this.fReaderJSON.readAsText(e.target.files[0]);
  }
  //____________________________________________________________________________________________________
  onChangeFileIMG(e) {
    this.fn = e.target.files[0];
    this.fReaderIMG.readAsDataURL(e.target.files[0]);
  }

  //____________________________________________________________________________________________________
  onSavedGlob(joRes){
    //if (oOK(joRes) && oOK(joRes.messA)) alert(joRes.messA);
    //else alert("Unknown error");
    if (app.utils.oOK(joRes) && app.utils.oOK(joRes.uuid)) app.projectJSON.uuid = joRes.uuid;
  }

  //____________________________________________________________________________________________________
  checkWarnNewProj()
  {
    let co = true;
    if (app.state.pSS) app.DBGeneric("Gen/DelJob", {pSS: true, uuid:app.projectJSON.uuid}, null);
    else {
      if (app.utils.messageBox(1, "warNewP")) app.DBGeneric("Gen/DelJob", {pSS: false, uuid:app.projectJSON.uuid}, null);
      else co = false;
    }
    return co;
  }

  //____________________________________________________________________________________________________
  buttonClick(ls) {
    /*if (ls === 3){//[3,"Load/Fill database"],
      this.fReaderJSON.onload = this.onLoadDB;
      this.refFileJSON.current.value = "";
      this.refFileJSON.current.click();
      return;
    }*/

    if (ls === 1){// [1, "Load locally"],
      if (this.checkWarnNewProj()){
        this.fReaderJSON.onload = this.onLoadLoc;
        this.refFileJSON.current.value = "";
        this.refFileJSON.current.click();
      }
    }
    else if (ls === 2){// [2, "Save locally"],
      var FileSaver = require('file-saver');
      var blob = new Blob([JSON.stringify(app.projectJSON)], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, "project.json");
    }
    else if (ls === 3){// [3, "New project"]
      if (this.checkWarnNewProj()) app.setActProject(false, app.utils.newProJSON(), false); //copy
    }
    else if (ls === 4){  //  [4,"Load globally"], only json project is being downloaded !!!!!
      if (this.checkWarnNewProj()) this.props.handleOpenToolModal(2, "Stored projects", null);  
    }

   /*else if (ls === 5){  //[5,"Save globally"],
      app.projectJSON.jobNr = 1;
      DBGeneric("Gen/DCCalc", app.projectJSON, this.onSavedGlob);
    }*/
    
  }

  /*/____________________________________________________________________________________________________
  onLoadDB(event) {   //erase later??
    let co = true;
    if (this.fReaderJSON.result) {
      try{
      var jo = JSON.parse(this.fReaderJSON.result);
      } catch(er){ 
        co = false;
        messageBox(0, "s_erProjF"); 

      }
      if (co){
        
        DBGeneric("Gen/AddDB",  jo, null);
      } 
    }
    else messageBox(0, "s_elf"); 
  }*/

  //____________________________________________________________________________________________________
  onLoadLoc(event) {
    let co = true;
    if (this.fReaderJSON.result) {
      try{
      var o = JSON.parse(this.fReaderJSON.result);
      } catch(er){ 
        co = false;
        app.utils.messageBox(0, "s_erProjF"); 
      }
      if (co){
        
        //SubstKeysProj(o); moved to App

        app.allV = true;
        if (this.props.setActProject(true, o, false)){  //error if not correct
          
          o.uuid = null;  
          app.utils.SetKeyValueIfNotExists(o.projD, "pN", "Project",  o.projD, "imgT",null, o.projD, "img", null);
          if (!o.projD.pN) o.projD.pN = this.fn;

          //this.props.setActPJlocalLoad(o);
          this.forceUpdate();

          //o.jobNr = 1;
          //DBGeneric("Gen/DCCalc", o, this.onSavedGlob);
        }
      } 
    }
    else app.utils.messageBox(0, "s_elf"); 
  }

  //____________________________________________________________________________________________________
  handleBtnDBClick(idDB){
    this.props.handleOpenDBModal(idDB, false, null);
  }

  //____________________________________________________________________________________________________
  render() {
    let selDB;
    if (app.utils.scopeMETR()) selDB = app.utils.getSubSelection("dbCategory", 1,2,3,6,7);
    else selDB = app.def["dbCategory"].sel;

    return (
      <div className="tool-header" id={"headerDIV"}>
        <input
          type="file"
          accept={".json"}
          ref={this.refFileJSON}
          onChange={this.onChangeFileJSON}
          style={{ display: "none" }}
        />
        <input
          type="file"
          accept={"image/*"}
          ref={this.refFileIMG}
          onChange={this.onChangeFileIMG}
          style={{ display: "none" }}
        />

<div style={{
        backgroundColor: 'rgb(230,230,230)',
        position: 'relative',
        marginLeft: '3px',
        padding: '0px',
        width: 'auto',
        height: 'auto',
        display:"flex",
        alignItems:"center"
      }}>

        <ButtonDropDown
         skey={"projLS"}
         handleBtnClick = {this.buttonClick}    
        />

        <EdtDef
          ojs={app.state}
          skey={"pSS"}
          disabled = {this.props.isCalc}
          onChange={this.props.pSSchanged}
          ml = {"13px"}
        />

</div>

        <EdtDef
          ojs={app.projectJSON}
          skey={"SIIP"}
          disabled = {this.props.isCalc}
          onChange={this.props.siipChanged}
          ml = {"18px"}
        />
        {
app.utils.scopeMETR()?null:
        <EdtDef
          ojs={app.projectJSON}
          skey={"calcScope"}
          disabled = {this.props.isCalc}
          onChange={this.props.scopeChanged} 
        />
  }

        <ButtonDropDown
         skey={"dbCategory"}
         sel={selDB}
         disabled = {this.props.isCalc}
         handleBtnClick = {this.handleBtnDBClick}
        />

        <label style={{marginLeft:'auto', marginRight: 5}}>{app.utils.nK("scu") + ": " + this.props.un}</label>

      </div>
    );
  }
}

/*{ <EdtDef
          ojs={app.projectJSON}
          skey={"progVer"}
          disabled = {this.props.isCalc}
          onChange={this.props.scopeChanged}
          ml = {"18px"}
        /> }*/

