export class cUtils {  

    //___________________________________________________________________________
    constructor(app) {

        this.app = app;

        this.settings = {
            Tree: {
                //nr: 0, //used to giv nr to nodes
                y: 2,
                //sRectPlus: "",
                //sRectMinus: "",
                heightTreeNode: 20,
                distTreeNode: 3,
                heightIcon: 18,
                xyIcon: 1,
                whRect: 12,
                xyRect: 4,
                xRect: 10,
                yRect: 5,
                fontSize: 13,
                sfontSize: "13px",
                sLinesPath: ""
            },
            Table: {
                heightRow: 24,
                heightTitleHalf: 10,
                heightTitle1Row : 25,
                heightTitle2Rows: 38,
                heightTitle3Rows: 58,
                heightTitle4Rows: 72,
                heightTitle5Rows: 88,
                witdhColNr: 28,
                widthColSelCombi: 20,
                widthColumnButtons: 50,
                distOtherTable: 2,
                widthScroll: 13,
                fontSize: 13,
                sFontSize: "13px",
                colorBKG: "rgb(245,245,245)",
                colorArBKG:[245,245,245],
                colorLines: "rgb(220,220,220)",
                colorRectSel: "rgb(125,50,50)",
                colorText: "rgb(0 ,0, 0)",
                colorTextTitle: "rgb(50, 50, 100)",
                colorTextRowTitle: "rgb(70, 70, 180)",
                whTick: 15
            },
        
            Tab: {
                nr: 0, //used to giv nr to nodes
                levelMax: -1,
                heightRow: 25,
                heightTopRectSel: 4,
                heightBetweenRows: 4,
                
                fontSize: 13,
                colorBKG: "rgb(255,250,240)",//"rgb(250,240,190)",
                colorBKGrest: "rgb(230,230,230)",
                colorBKGSel: "rgb(255,250,240)",
                colorTopLineSel: "rgb(240,190,80)",
                colorLines: "rgb(200, 200, 200)",
                colorTextNotSel: "rgb(110, 110, 110)"},
            
            Button: {
                heightButton: 24,
                textMargin: 4,
                fontSize: 13,
                sFontSize: "13px",
            },    
            form: {
                defaultLeft: 5,
                defaultDist: 6  //between edits
            },
            Diagr: {
                sFont: "12px Arial",
                heightBoxText: 12,
                minDist: 3,
                heightRow: 15
            },
            TS:{
                colorBKG: "rgb(217,216,205)"
            },
            SR:{h:30}  //StatusResult
        }

        this.dType = {   //this.dType
            text: 0,
            number:1,     //1
            listChoice:2, //2
            boolean:3,    //3
            color:4 ,     //4
            date: 5,       //5
            time: 6,       //6
            datetime:7,   //7
        
            countSimpleData:8,
        
            text_listChoice:9, //edit text with preselection, so far not predefined in keys ?!
            menu: 10,
        
            rowTitle: 101,
            textInfo: 102,
            undef: 9999
        }

        this.treeVType = {  //TreeViewNode type
            project: "tvP", 
            setWufi: "tvSs",
            sharedObj: "tvSo",
            cases: "tvCs",
            case: "tvC",
            loc: "tvLC",
            building: "tvB",
            simZones: "tvSz",
            attZones: "tvAz",
            zone: "tvZ",
            zoneTB: "tvZTb", //thermal bridges
            zoneLoads: "tvZIl",
            zoneDC: "tvZDc",
            zoneVent: "tvZV",
            zoneOP: "tvZTc", //other params
            visComp: "tvZVc",
            nvisComp: "tvZNvc",
            comp: "tvCo",
            obj3Ds: "tv3Ds",
            obj3D: "tv3D",
            hvac: "tvHSs",
            hvacS: "tvHS",
            hvacD: "tvHD",
            rEs: "tvRe",
            rE: "tvE",
          
            casesPH: "tvPHCs",
            casePH: "tvPHC",
            zoneVRPH: "tvZVr",
          
            cat: ""  //Catalogue
          }; 

        this.Unit_ID = {
            not_defined: 0,
          
            kg_m3__lb_ft3: 1, // [kg/m³]	   [lb/ft³]
            m3_m3__ft3_ft3: 2, // [m³/m³]      [ft³/ft³]
            J_kgK__Btu_lbF: 3, // [J/kgK]      [Btu/lb°F]
            W_mK__Btu_hftF: 4, // [W/mK]       [Btu/h ft °F]
            dash__dash: 5, // [-]          [-]
            m__in: 6, // [m]          [in]
            kg_m2s05__lb_in2s05: 7, // [kg/m²s^0.5] [lb/in²s^0.5]
            percent_Mpercent__percent_Mpercent: 8, // [%M%]        [%M%]
            dash__perm_inch: 9, // [-]		   [perm in]
            m2K_W__hft2F_Btu: 10, // [m²K/W]      [hft²F/Btu]
          
            W_mK2__Btu_hftF2: 11, // [W/mK²]      [Btu/h ft °F²]
            m2_s__ft2_s: 12, // [m²/s]       [ft²/s]
            W_m2K__Btu_hft2F: 13, // [W/m²K]      [Btu/hft²F]
            C__F: 14, // [°C]         [°F]
            kg_m2__lb_ft2: 15, // [kg/m²]      [lb/ft²]
            cm__in: 16, // [cm]         [in]
            K__F__Interval: 17, // [K]          [°F]
            m__ft: 18, // [m]          [ft]   Height,
            hPa__psi: 19, // [hPa]        [psi]
            mm__in: 20, // [mm]         [in]
          
            W_m2__Btu_hft2: 21, // [W/m²]       [Btu/hft²]
            percent__percent: 22, // [%]          [%]
            Ltr_m2h__in_h: 23, // [Ltr/m²h]    [in/h]
            Mpercent__Mpercent: 24, // [M%]         [M%]
            kg_sm2__lb_hft2: 25, // [kg/sm²]     [lb/hft²]
            m3__ft3: 26, // [m³]         [ft³]
            g_m3__lb_ft3: 27, // [g/m³]       [lb/ft³]
            kg_s__lb_s: 28, // [kg/s]       [lb/s]
            _1_h__1_h: 29, // [1/h]        [1/h]
            h__h: 30, // [h]          [h]
          
            m__perm: 31, // [m]		   [perm]
            s_m__s_ft: 32, // [s/m]        [s/ft]
            Wh__Btu: 33, // [Wh]         [Btu]
            ng_smPa__permin: 34, // [ng/smPa]    [perm in]
            ng_sm2Pa__perm: 35, // [ng/sm²Pa]   [perm]
            kWh__kBtu: 36, // [kWh]        [10³Btu]
            m2__in2: 37, // [m²]         [in²]
            m2__ft2: 38, // [m²]         [ft²]
            kg__lb: 39, // [kg]         [lb]
            kWh_m2a__kBtu_ft2a: 40, // [kWh/m²a]    [Btu/ft²a]
          
            mm_a__in_a: 41, // [mm/a]       [in/a]
            kg_sm2Pa__lb_hft2psi: 42, // [kg/sm²Pa]   [lb/hft²psi]
            Angle__Angle: 43, // [°]          [°]
            kg_kg__lb_lb: 44, // [kg/kg]      [lb/lb]
            MJ_m2__Btu_ft2: 45, // [MJ/m²]      [Btu/ft²]
            J_kg__Btu_lb: 46, // [J/kg]       [Btu/lb]
            mg_m3__mg_m3: 47, // [mg/m³]      [mg/m³]
            ppmV__ppmV: 48, // [ppmV]       [ppmV]
            ppmm__ppmm: 49, // [ppmm]       [ppmm]
            m3_s__ft3_s: 50, // [m³/s]       [ft³/s]
          
            m3_h__cfm: 51, // [m³/h]       [cfm]
            Ltr_s__Ltr_s: 52, // [L/s]		   [Ltr/s]
            W__Btu_h: 53, // [W]          [Btu/hr]
            kW__kBtu_h: 54, // [kW]         [kBtu/hr]
            kg_h__lb_h: 55, // [kg/h]       [lb/h]
            g_h__g_h: 56, // [g/h]		   [g/h]
            Emission_g_s: 57, // [g/s]		   [g/s]
            Emission_mg_h: 58, // [mg/h]       [mg/h]
            Emission_mg_s: 59, // [mg/s]	   [mg/s]
            met: 60, // [met]        [met]
          
            clo: 61, // [clo]			[clo]
            m_s__ft_s: 62, // [m/s]         [ft/s]
            g_hm2__lb_hft2: 63, // [g/hm²]       [lb/hft²]
            kWh__BTU: 64, // [kWh]         [kBtu]
            dm3_s_m2_Pan_IP: 65, // [dm³/(s•m²•Pan)] [dm³/(s•m²•Pan)]
            kJ_m2K__Btu_ft2K: 66, // [kJ/m2K]      [Btu/ft²K]
            kW__Btu_h: 67, // [kW]          [10³Btu/h]
            Wh_m2__Btu_ft2: 68, // [Wh/m²]       [Btu/ft²]
            mm_h__in_h: 69, // [mm/h]        [in/h]
            kg_hm2__lb_hft2: 70, // [kg/hm²]      [lb/hft²]
          
            Pa__psi: 71, // [Pa]          [psi] = [lb/in2]
            cm2__in2: 72, // [cm2]         [in2]
            MJ_m3K__Btu_ft3F: 73, // [MJ/m³K]      [Btu/ft³K]
            kWh_m2a__Btu_ft2a: 74, // [kWh/m²K]     [Btu/ft²a]
            m_d__ft_d: 75, // [m/d]         [ft/d]
            Wh_m2K__Btu_ft2F: 76, // [Wh/m²K]      [Btu/m²F]
            Wh_m3__Btu_ft3: 77, // [Wh/m³]       [Btu/ft³]
            J__Btu: 78, // [J]           [Btu]
            kJ__Btu: 79, // [kJ]          [Btu]
            Ltr__Ltr: 80, // [Liter]       [Liter]
          
            W_K__Btu_h_F: 81, // [W/K]         [Btu/hF]
            g_kg__lbw_lbda: 82, // [g/kg]        [lbw/lbda]
            g_m2h__g_m2h: 83, // [g/m²h]       [lb/(ft²hr)]
            g_g_kg_m2__g_g_kg_m2: 84, // [g/(g/kg)/m²] [lb/(lbw/lbda) ft²]
            kWh_kWh__Btu_Btu: 85, // [kWh/kWh]     [Btu/Btu]
            g_kWh__g_kBtu: 86, //[g/kWh]       [g/Btu]
            Wh_m3__W_cfm: 87, // [Wh/m³]       [W/cfm]
            m3_h__ft3_h: 88, // [m³/h]        [ft³/h]
            L_P_d__g_P_d: 89, // [L/Person/day] [gal/Person/day]
            kh_a__khr_yr: 90, // [kh/a]        [khr/yr]
          
            kWh_m2Month__kBtu_ft2Month: 91, //[kWh/m²Month]	[kBtu/ft²Month]
            dm3_s_m_Pan_IP: 92, //[dm³/(s•m•Pan)] [dm³/(sm Pan)]
            kWh__kWh: 93, //[kWh]          [kWh]
            Liter__gal: 94, //[Liter]        [gal]
            kWh_a__kBtu_a: 95, //[kWh/a]        [kBtu/yr]
            kWh_m2__kBtu_ft2: 96, //[kWh/m²]       [kBtu/ft²]
            g_kg__kgw_kga: 97, //[g/kg]         [kgw/kga]
            kJ_kgK__kBtu_lbF: 98, //[kJ/kgK]	    [kBtu/lb°F]
            d_a__d_a: 99, //[days/a]       [days/yr]
            month__month: 100, // [mths]        [mths]
          
            hour__hour: 101, // [hrs]         [hrs]
            kg_a__lb_a: 102, // [kg/a]        [lb/yr]
            Wh_m3K__Btu_ft3F: 103, // [Wh/m³K]      [Btu/ft³F]
            kKh_d__kFh_yr: 104, // [kKh/a]       [kFh/a]
            lux__lux: 105, // [lux]         [lux]
            m2_person__ft2_person: 106, // [Wh/m³K]      [Btu/ft³F]
            hrs_d__hrs_d: 107, // [hrs/d]       [hrs/d]
            hrs_a__hrs_yr: 108, // [hrs/a]       [hrs/yr]
            kWh_d__kBtu_d: 109, // [kWh/d]       [kBtu/d]
            kWh_a__kWh_a: 110, // [kWh/a]       [kWh/yr]
          
            W_m2__W_m2: 111, // [W/m²]        [W/m²]
            W__W: 112, // [W]			[W]
            kWh_d__kWh_d: 113, // [kWh/d]		[kWh/d]
            _1_m__1_ft: 114, // [1/m]			[1/ft]
            W_m2K2__Btu_hft2F2: 115, // ["W/m²K²]     [Btu/hr ft²°F²]
            a__yr: 116, // [a]			[yr]
            Kh_a__Fh_yr: 117, // [Kh/a]        [Fh/a]
            Wh_m2d__Btu_ft2d: 118, // [Wh/m²d]      [Btu/ft²d]
            s__s: 119, // [s]			[s]
            Liter_h__gal_h: 120, // [Ltr/h]		[gal/h]
          
            m3_m2h__cfm_ft2: 121, // [m³/m²h]      [cfm/ft²]
            m3_hPa__cfm_Pa: 122, // [m³/h Pa]     [cfm/Pa]
            Person__Person: 123, // [Person]		[Person]
            W_Person__Btu_hrPerson: 124, // [W/Person]	[Btu/hr Person]
            g_hPerson__g_hrPerson: 125, // [g/h Person]	[g/hr Person]
            g_m2h__g_ft2hr: 126, // [g/m²h]       [g/ft²hr]
            met_Person__met_Person: 127, // [met/Person]  [met/Person]
            met_m2__met_ft2: 128, // [met/m²]      [met/ft²]
            kWh_Wp__kWh_Wp: 129, // [kWh/Wp]      [kWh/Wp]
            kg_Wp__kg_Wp: 130, // ["kg/Wp]      [kg/Wp]
          
            A__A: 131, //  [A]				[A]
            V__V: 132, //  [V]				[V]
            Percent_K: 133, //  [%/K]				[%/K]
            g_kg__grain_lb: 134, //  [g/kg]			[grains/lb]
            kWh_Person_a__kWh_Person_yr: 135, //  [kWh/Person a]	[kWh/Person yr]
            g_kg__lbw_lba: 136, //  [g/kg]			[lbw/lba]
            W_m2__W_ft2: 137, //  [W/m²]			[W/ft²]
            kWh_m2_Person_a__kWh_m2_Person_yr: 138, //  [kWh/m² Person a] [kWh/m² Person yr]
            kWh_m2_Person_a__kBtu_ft2_Person_yr: 139, // [kWh/m² Person a] [kBtu/ft² Person yr]
            kWh_m2a__kWh_ft2yr: 140, // [kWh/m²a]         [kWh/ft²yr]
          
            Ns_m2__lbfs_ft2: 141, // [Ns/m²]			[lbf s/ft²]
            Ns_m2K__lbfs_ft2F: 142, // [Ns/m²K]           [lbf s/ft²F]
            J_kgK2__Btu_lbF2: 143, // [J/kgK²]	        [Btu/lb°F²]
            kg_kMol__lb_kMol: 144, // [kg/kMol]          [lb/kMol]
            J_K__Btu_F: 145, // [J/K]              [Btu/°F]
            dollar_kWh__dollar_kWh: 146, // [$/kWh]            [$/kWh]
            dollar__dollar: 147, // [$]				[$]
            dollar_Therm__dollar_Therm: 148, // [$/Therm]			[$/Therm]
            Ltr__oz: 149, // [Liter]			[oz]
            C_d__F_d: 150, // [°C/d]				[°F/days]
          
            d_w__d_w: 151, //151,
            w_y__w_y: 152, //152
            kWh_Use__kWh_Use: 153, //153
            min_use__min_use: 154, //
            kW__kW: 155, //155
            sec__sec: 156,
            //here add new units...
          
            CountUnits: 157, //remain, fiktive count of units
          };
    }

    


    //___________________________________________________________________________
    isValidProject(pr){

    function checkAr(js, ks){
      c = 0;
      ks.forEach(k => {
        if (js.hasOwnProperty(k)){
          let a = js[k];
          if (Array.isArray(a)){
            /*if (a.length > 0)*/ c++;
          }
        }
      })
      return (c === ks.length);
    }

    let co = false,
        c = 0;
    if (pr){
        if (typeof pr == 'object'){
            if (checkAr(pr, ["lMaterial","lAssembly","lWindow","lVariant"])){
            if (pr.lVariant.length > 0){
                let s = "building",
                js = pr.lVariant[0];
                 if (js.hasOwnProperty(s)){
                    let jsb = js[s];
                    if (checkAr(jsb, ["lComponent","lZone"])) co = true; 
                }
            }
            else co = false;
            }
        }
        }
        return co;
    }

    //To be used in zone loads___________________________________________________________________________
    getPHUPrv() {
    let lChoiceUP = [],//JSON.parse(JSON.stringify(app.def.idUPat));
        s = this.nK("s_Pat"),
        i = 0;
        this.app.projectJSON.lUtilVentPH.forEach((e) => {
        i++;
        let sn = s + " " + i.toString();
        if (e.n !== "") sn += ": " + e.n;
        lChoiceUP.push([e.id, sn]);
    });
    return lChoiceUP;
}

    //To be used in zone loads___________________________________________________________________________
    getPHUPloads(){
    let lChoiceUP = [],//JSON.parse(JSON.stringify(this.app.def.idUPat));
        s = this.nK("s_Pat"),
        i = 0;
        this.app.projectJSON.lUtilNResPH.forEach((e) => {
            i++;
            let sn = s + " " + i.toString();
            if (e.n !== "") sn += ": " + e.n;
            lChoiceUP.push([e.id, sn]);
        });
        return lChoiceUP;
    }

//__________________________________________________________________________________________
    newProJSON() {
        var pj = JSON.parse(JSON.stringify(this.app.tem.projJSON)); //copy
        pj.lVariant.push(this.newVariantJSON(false));
        pj["lVariant"][0]["building"]["lZone"].push(this.newZoneJSON(false));
        return pj;
    }

    //__________________________________________________________________________________________
    newVariantJSON(coInsert) {
        let js = JSON.parse(JSON.stringify(this.app.tem.variantJSON)); //copy 
        let jsPH = JSON.parse(JSON.stringify(this.app.tem.casePH_JSON)); //copy
        jsPH.id = 1;

        if (this.app.projectJSON) {
            js.id = this.getUniqueID(this.app.projectJSON.lVariant);
            if (coInsert) this.app.projectJSON.lVariant.push(js); 
        }
        else {
            js.id = 1;
        }
        js.PHIUS.lCase.push(jsPH);
        return js;
    }

    //__________________________________________________________________________________________
    newZoneJSON(coInsert) {
        let js = JSON.parse(JSON.stringify(this.app.tem.zoneJSON)); //copy 
        if (this.app.projectJSON) {
            js.id = this.getUniqueID(this.app.actVariantJSON.building.lZone);
            if (coInsert) this.app.actVariantJSON.building.lZone.push(js);
        }
        else js.id = 1;
        return js;
    }



    //__________________________________________________________________________________________
    newComponentJSON(coInsert) {
        let js = JSON.parse(JSON.stringify(this.app.tem.componentJSON)); //copy 
        js.id = this.getUniqueID(this.app.actVariantJSON.building.lComponent);
        if (coInsert) this.app.actVariantJSON.building.lComponent.push(js);
        return js
    }

    //__________________________________________________________________________________________
    setCompRsiRse(jsC) {
        this.dCheck.init(); 
        let alfa = 0;
        if (this.equalsTo(jsC, "selSurfE", 1, true) || this.equalsTo(jsC, "selSurfE", 3, true)) {
            
            if (this.dCheck.checkJS(jsC, "", ["inclC"])){
                let Rsi = 0,
                    Rse = 0,
                    incl = jsC.inclC,
                    coInner = (jsC.idEC >= 0 && jsC.idIC >= 0),
    
                    R = 0,
                    alfar = 0,  
                    alfac = 0,
                    coI = true,
                    kk = ["alfaCiC","alfaRiC","Rsi"];
    
                if (incl >= 60 && incl <= 120) {
                    Rsi = 0.13;
                    Rse = 0.04;
                }
                else if (incl > 120) {
                    Rsi = 0.17;
                    Rse = 0.04;
                }
                else {
                    Rsi = 0.1;
                    Rse = 0.04;
                } 
                if (coInner) Rse = Rsi;
                jsC.Rsi = Rsi;
                jsC.Rse = Rse;
    
                R = Rsi;
                for (let ie = 0; ie < 2; ie++){
                    if (coI) alfar = 4.5;
                    else alfar = 6.5;
    
                    if (R > 1E-6) {
                        alfa = 1 / R;
                        if (alfa <= 2.5) {
                            alfac = alfa;
                            alfar = 0;
                        }
                        else {
                            alfac = Math.max(2.5, alfa - alfar);
                            alfar = alfa - alfac;
                        }
                        jsC[kk[0]] = this.roundVal(alfac,4);
                        jsC[kk[1]] = this.roundVal(alfar,4)
                    }
                    else {
                        jsC[kk[0]] = 99999;
                        jsC[kk[1]] = 0;
                    }
                    jsC[kk[2]] = R;
    
                    R = Rse;
                    kk = ["alfaCeC", "alfaReC", "Rse"];
                    coI = coInner;
    
                }
                jsC.alfaWinwardC = 1.6;
                jsC.alfaLeewardC = 0.33;
                jsC.alfaWindBC = 4.5;
            }
        }
        else{
            jsC.Rsi = null;
            jsC.Rse = null;
            if (this.dCheck.checkJS(jsC, "", ["alfaCiC", "alfaRiC"])) {
                alfa = jsC.alfaCiC + jsC.alfaRiC;
                if (alfa > 1e-10) jsC.Rsi = this.roundVal(1/alfa,4);
            }
            if (this.dCheck.checkJS(jsC, "", ["alfaCeC", "alfaReC"])) {
                alfa = jsC.alfaCeC + jsC.alfaReC;
                if (alfa > 1e-10) jsC.Rse = this.roundVal(1 / alfa, 4);
            }
        }
    }

    //______________________________________________________________________________
    scopePlus() {
        return this.app.apType === "PLUS" && this.app.projectJSON.calcScope === 3;
    }
    //______________________________________________________________________________
    scopeMETR() {
        return this.app.apType === "METR";
        //return this.app.projectJSON.calcScope === 4;
    }
    //______________________________________________________________________________
    scopeDIN() {
        return this.app.apType === "PLUS" && this.app.projectJSON.calcScope === 5;
    }
    //______________________________________________________________________________
    scopeEP() {
        return this.app.apType === "PLUS" && this.app.projectJSON.calcScope === 10;
    }

    //__________________________________________________________________________________________
    getMatJS(id){
        return this.app.projectJSON.lMaterial.find((el) => { return (el.id === id) });
    }

//__________________________________________________________________________________________
    getAssembly(id){
        return this.app.projectJSON.lAssembly.find((el) => { return (el.id === id) });
    }

//___________________________________________________________________________
    CheckVersionSetVal(...jsKeyValp){//export function SetIfNotExists(js : any, key: string, jsToCopy:any){
    let L = jsKeyValp.length,
        i = 0,
        sk,
        js,
        valp,
        p;

    while (i < L){  
        js = jsKeyValp[i];  //json object
        sk = jsKeyValp[i+1];  //key
        valp = jsKeyValp[i+2]; //int, eg. program version 'progVer'
        const value = js[sk];

        //console.log(sk);

        if (this.app.def[sk].t === this.dType.listChoice || this.app.def[sk].hasOwnProperty("psel")) {  //list choice
            let l = this.app.def[sk]['p'];
            p = l.find(e => e[0] === value);  //if is limited
            if (p) {
                if (p.includes(valp, 2)) js[sk] = p[1];  
            }

        }
        else{
            p = this.app.def[sk]['p'];
            if (p.includes(valp, 1)) js[sk] = p[0];  
        }
        i += 3;
    }
    }

    //___________________________________________________________________________
    createListZoneAttachement(coExtern, idIntern, kAdd) {//kAdd key - additional elements in sel
    let lz = this.app.actVariantJSON.building.lZone,
        res = [],
        co = [true, true];

    if (coExtern){
        co[1] = (idIntern >= 0);
        co[0] = co[1] && this.isSimulatedZone(idIntern);
    }

    for (let i = 1; i < 3; i++) {
        if (co[i-1]){
            let nr = 0,
                s = "";
            lz.forEach((el) => {
                if (el.typeZ === i) {
                    nr++;
                    if (i === 1) s = this.app.def["tvZ"]["n"];
                    else s = this.app.def["s_attZ"]["n"];
                    s = s + " " + nr.toString();
                    if (el.n) s += ": " + el.n;
                    res.push([el.id, s]);
                }
            });
        }
    }
    for (var j = 0; j < this.app.def[kAdd]["sel"].length; j++) {   //"idEC"
        let el = this.app.def[kAdd]["sel"][j];
        if (el[0] === -3) {
            if (coExtern && co[0]) res.push(el);
        }
        else res.push(el);
    }
    return res;  
    }

    //____________________________________________________________________________
    isSimulatedZone(id) {//function isSimulatedZone(id: number) {
    let co = false,
        dz = this.app.actVariantJSON.building.lZone.find((el) => el.id === id);
    if (dz) {
        if (dz.typeZ === 1) co = true;
    }
    return co;
    }

    //____________________________________________________________________________
    isAttachedToPHcase(c, idPHc) {//function isSimulatedZone(id: number) {
    let co = false,
        dz = this.app.actVariantJSON.building.lZone.find((el) => el.id === c.idIC || (c.idEC === el.id && this.app.pref.showAllCompsZone));
    if (dz) {
        if (dz.typeZ === 1){
            if (idPHc > 0) { if (dz.idPHcase === idPHc) co = true;  }
            else { if (dz.idPHcase >= 0) co = true;  }
        }
    }
    return co;
    }

    //___________________________________________________________________________
    SelExch(list, k, ktn) { //k - def key, ktn - key type name, exchanes sel with act data
    let jo = this.app.def[k];
    if (jo.hasOwnProperty("sel")){
        let res = [];
        let i,o,s,
            stn = this.app.def[ktn].n;  
        for (i = 0; i < list.length; i++){
            o = list[i]; 
            s = stn + " " + (i + 1);
            if (this.oOK(o.n)) s += ": " + o.n;
             res.push([o.id, s]);
        }
        jo.sel = res; 
        }
    }
    

    //Generell===================================================================================================================================================================
    //_____________________________________________________________________________
    saveLoc(jo, fn){
    var FileSaver = require('file-saver');
      var blob = new Blob([JSON.stringify(jo)], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, fn);
}

    //_____________________________________________________________________________
    messageBox(type, skey) {//export function messageBox(type: number, key: string) {
    let s = this.nK(skey);
    if (type === 0){
        window.alert(s);
        return true;
    } 
    else if (type === 1){
        return window.confirm(s);
    }
}

    //____________________________________________________________________________________________________________________
    getEDT(idNr, edt) {   //EDT(idNr: number){
        let ee = undefined;
        if (this.oOK(edt)){
            if (idNr >= 0 && idNr < edt.length) {
                ee = edt[idNr];
                if (ee) {
                    if (ee.idNr !== idNr) ee = undefined;
                }
            }
            if (ee === undefined){
            edt.forEach((e) => {if (e.idNr === idNr) ee = e; });
            }
        }
        return ee;
    }


    //__________________________________________________________________________________________
    getKkd(sk){  
    let d = this.app.def[sk];
    if (d.hasOwnProperty('kd')) return d['kd'];
    else return sk; 
}


    //___________________________________________________________________________
    nameOK(s){
    let co = false;
    if (s){
        if (typeof s === 'string' || s instanceof String){
            if (s.length > 0){
                for (let i = 0; i < s.length; i++) {
                    if (s[i] !== " ") co = true;
                }  
            }
        }
    }
    return co;
}

    //___________________________________________________________________________
    Last(ja){
    return ja[ja.length - 1];
    }


    //___________________________________________________________________________
    nIsafe(coPlus, a, i){ //a- array
    if (coPlus){
        if (i === (a.length - 1)) return 0;
        else return i+1;
    }
    else
    {
        if (i > 0) return i-1;
        else return a.length - 1;
    }
    }

    //___________________________________________________________________________
    nextO(coPlus,a,i){
    return a[this.nIsafe(coPlus, a, i)];
    }

    //___________________________________________________________________________________________
    convertSIIP(toSI, iu, val){  //converts to IP
    let vres = val,
        nDigit = 8;
    if (toSI){
        if (iu === this.Unit_ID.C__F) vres = (val - 32)/1.8;
        else vres = this.app.un[iu][3] * val;
    }
    else {
        nDigit = 6;    
        if (iu === this.Unit_ID.C__F) vres = 1.8*val + 32;
        else vres = val / this.app.un[iu][3];
    }
    if (nDigit > 0) vres = this.roundVal(vres, nDigit);
	return vres; 
}


    //___________________________________________________________________________________________
    sUnit(iu, brackets){
	let s = this.app.un[iu][this.app.projectJSON.SIIP].toString();
	if (brackets && s) s = "[" + s + "]";
	return s;
    }

    //_______________________________________________________________________________
    valOK(val){
        return !(val === undefined || val === null || isNaN(val));
    }

    //_______________________________________________________________________________
    oOK(...args) {
    var co = true;
    for (let i = 0; i < args.length; i++){
        if (co){
            if (args[i] === undefined) co = false;
            else if (args[i] === null) co = false; 
        }
    }
    return co;
}

    //_______________________________________________________________________________
    dOK() {
    var co = true;
    for (let i = 0; i < arguments.length; i++){
        if (co){
            if (arguments[i] === undefined) co = false;
            else if (arguments[i] === null) co = false;
            if (co){
                if (typeof(arguments[i]) === 'number'){
                    if (Number.isNaN(arguments[i])) co = false;
                }
                else co = false;
            } 
        }
    }
    return co;
}

    //_______________________________________________________________________________
    nextPick(c, ct){  //next rgb color pick, c - base/source, ct - target optional
    c[2]++;
    if (c[2] === 256) {
        c[1]++;
        c[2] = 1;
    }
    if (c[1] === 256) {
        c[0]++;
        c[1] = 1;
        c[2] = 1;
    }
    if (ct){
        ct[0] = c[0];
        ct[1] = c[1];
        ct[2] = c[2];
    }
}

    //_______________________________________________________________________________
    dOKAr(ar) {
    let co = true;
    ar.forEach(e => {if (co) co = this.dOK(e)});
    return co;
    }

    //__________________________________________________________________________________________
    getUniqueID(lc) {
    let idNr = 1,
        coExists = true,
        res;//: undefined;
    while (coExists) {
        let ii = idNr; //because of warning: unsafe reference to idNr
        res = lc.find(el => { return (ii === el.id) });
        if (res) idNr++;
        else coExists = false;
    }
    return idNr;
    }

    //_______________________________________________________________________________
    createChoiceList(lc, exceptId) {
    let res = [],
        nr = 0,
        s = "";

    lc.forEach((el) => {
        nr++;
        if (el["id"] !== exceptId) {
            s = el["n"];
            s = s + " " + nr.toString();
            if (el["n"]) s += ": " + el["n"];
            res.push([el["id"], s]);
        }
    });
    return res;
}

    //_______________________________________________________________________________
    getSubSelection(skey, ...nrs){//export function getSubSelection(skey:string, ...nrs:number[]){
    let res = [];
    let d = this.app.def[skey];
    if (d){
        let sel = d.sel;
        if (sel){
            nrs.forEach((nr) => {let l = d.sel.find((el) => el[0] === nr);  if (l) res.push(l)});
        }
    };
    return res;
}

    //_____________________________________________________________________________
    getValueFromSel(key, value, index) {  //get object from list item//export function getValueFromSel(key:string, value: number, index: number)
    var lJsonSel = this.getSel(key), //array
        item = lJsonSel.find((el) => { return (el[0] === value) }),
        res = undefined;   
    if (item){ 
        if (item.length > index) res = item[index];   
    }
    return res;
}

    //_______________________________________________________________________________
    getSubSelectionAr(skey, nrsAr){//nrsAr - is array
    let res = [];
    if (this.oOK(nrsAr)){
        let d = this.app.def[skey];
        if (d){
            let sel = d.sel;
            if (sel){
                nrsAr.forEach((nr) => {let l = d.sel.find((el) => el[0] === nr);  if (l) res.push(l)});
            }
        };
    }
    return res;
}

    //_______________________________________________________________________________
    getSubSelectionAr2(key1, value, index,valCriteria,     key2){//console.log("getSubSelectionAr2(key1, value, index,valCriteria,key2)",key1, value, index,valCriteria,     key2)//nrsAr - is array in array, val must be found in array before, otherwise default=last,  key2-if should find in key2 sel, otherwise null
    let nrsAr2 = this.getValueFromSel(key1, value, index), //first find the relevant array
        cAr = nrsAr2.length - 1, //count ar in nrsAr2
        res = nrsAr2[cAr];  //last = default
    if (cAr > 0){  //more than 1
        let iar = 0,
            co = true;
        while(iar < cAr && co){
            if (nrsAr2[iar].includes(valCriteria)){
                res = nrsAr2[iar+1];  //next array
                co = false;
            }
            iar += 2;
        } 
    }
    if (key2) return this.getSubSelectionAr(key2, res);
    else return res;
    }

    //_______________________________________________________________________________
    ColorRGBToHex(jsA) {//export function ColorRGBToHex(jsA: number[]): string {
    if (!this.oOK(jsA)) jsA = this.settings.Table.colorArBKG;
    let s = "#",
        s1 = "";
    for (var i = 0; i < 3; i++) {
        s1 = jsA[i].toString(16);
        if (s1.length < 2) s1 = "0" + s1;
        s += s1;
    }
    return s;
    }

    //__________________________________________________________________________
    ColorHexToRGB(hexColor) {//export function ColorHexToRGB(hexColor: number) {
    return [((hexColor >> 16) & 0xFF),((hexColor >> 8) & 0xFF),(hexColor & 0xFF)];
    }

    //__________________________________________________________________________
    ColorRGBtoString(rgb) {  //export function ColorRGBtoString(rgb:number[]) {
        return "rgb(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + ",255)";
    }


    //____________________________________________________________________________
    px(v) {//export function px(v: number) {
    return v + "px";
    }

    //____________________________________________________________________________
    phh(v) {//export function phh(v: number) {
    return Math.round(v) + 0.5;
    }

    //_____________________________________________________________________________
    ph(v) {//export function ph(v: number) {
        return Math.round(v);
    }


    //_____________________________________________________________________________
    textWidth(text, fontSize) {//export function textWidth(text: string, fontSize: number) {
    if (!this.oOK(fontSize)) fontSize = this.settings.Table.fontSize;

    const canvas = document.createElement("canvas");  
    const context = canvas.getContext("2d");  
    context.font = fontSize + "px Arial";//"normal " + fontSize + "px arial";   
    return  context.measureText(text).width;
    }

    //_____________________________________________________________________________
    eqalToOneOf(val, vals) {//export function eqalToOneOf(skey: string, skeys: string[]) {
    let co = false;
    for (var i = 0; i < vals.length; i++) {
        if (val === vals[i]) co = true;
    }
    return co;
    }

    //_____________________________________________________________________________
    setValtoAll(oJson, val, skeys) {//export function setValtoAll(oJson: any, val: any, skeys: string[]) {
    for (var i = 0; i < skeys.length; i++) oJson[skeys[i]] = val;
    }

//_____________________________________________________________________________
    getSel(key) {//export function getSel(key: string) {
    return this.app.def[key]["sel"];
    }

    //____________________________________________________________________________________________________________________
    isUserDef(oJson, key) {  //if data has a "user defined" = ud property//export function isUserDef(oJson: any, key: string) {
    let udNr = NaN,
        val = NaN,
        cUD = false;
    if (oJson.hasOwnProperty(key) && this.app.def.hasOwnProperty(key)) {
        
        let jsDef = this.app.def[key];
        if (jsDef.hasOwnProperty("ud")){
            udNr = jsDef.ud; 
            if (typeof (udNr) === "number" && !isNaN(udNr)) {
                
                if (jsDef.hasOwnProperty("psel")){ //variant
                    if (oJson[key].hasOwnProperty("sel")) val = oJson[key].sel;
                }
                else val = oJson[key]; 
                if (typeof(val) === "number" && !isNaN(val)) {
                    cUD = (val === udNr);   
                }
            }
        }
    }
    return cUD;
    }



    //____________________________________________________________________________
    getOrientFromAzim(azim){//export function getOrientFromAzim(azim:number){
    let iop = -1,
        i = 1,
        az1,//: number,
        az2;//: number;
    while (iop < 0 && i < 8) {
        let az = this.getValueFromSel("orient", i, 2);
        az1 = az - 22.5;
        az2 = az + 22.5;
        if (azim > az1 && azim <= az2) iop = i;
        i++;
    }
    if (iop < 0) iop = 8;
    return iop;
    }

    //_____________________________________________________________________________
    nK(skey) {   
    if (this.app.def) return this.app.def[skey]["n"];
    else return "xx";
    }



    //____________________________________________________________________________
    findIndexLocation(xy, wxy){//export function findIndexLocation(xy: number, wxy:number[]){
    let su1 = 0,
        su2 = 0,
        ires = -1;
    for (var i = 0; i < wxy.length; i++) {
        su2 = su1 + wxy[i];
        if (xy > su1 && xy <= su2) ires = i;
        su1 = su2;
    }
    return ires;
    }

    //____________________________________________________________________________
    roundVal(val, nDigit){//export function roundVal(val:number, nDigit:number){
    let coef = Math.pow(10, nDigit);
    return Math.round(val*coef)/coef;
    }

    //____________________________________________________________________________
    daysMonth(){
    return [31,28,31,30,31,30,31,31,30,31,30,31, 0]; //0 - for array access
    }

    //____________________________________________________________________________
    dayYear(dt) { //eg.: [2020, 6, 3, 0, 0, 0]//export function dayYear(dt: any):number {
    let d = 0,
        m = dt[1] - 1,
        dm = this.daysMonth();
    if (m > 0){
        for (let im = 0; im < m; im++) d += dm[im];
    }
    return d + dt[2] + dt[3]/24 + dt[4]/1440 + dt[5]/86400;
    }

    //___________________________________________________________________________
    getPolyData(jsComp, pol0, addOrient) { //visualised component//xport function getPolyData(jsComp: any, pol0:any, addOrient: boolean) { //visualised component
    let //L = jsComp.idPolyC.length,
        ig = 1,
        pol,//: any,//json
        polMain = pol0,
        i = 0;

    for (i = 0; i < ig; i++) {
        let idpol = jsComp.idPolyC[i]; 
        pol = this.app.actVariantJSON.geom.lPoly.find((p) => p.id === idpol);
        if (polMain === undefined) polMain = pol; 
    }
    if (polMain){ 
        return {
            areaTMP: this.roundVal(polMain.areaP, 3), perTMP: polMain.perim, hAGrC: polMain.hAGr,
                              wTMP: polMain.horW, hTMP: polMain.vertH, orientTMP: jsComp.orientTMP, 
                              azimTMP: polMain.azimN,
                              inclTMP: this.roundVal(polMain.incl,1) };
    }
    else return {};
    }

    //___________________________________________________________________________
    SetIfNotExists(js, key, jsToCopy){//export function SetIfNotExists(js : any, key: string, jsToCopy:any){
    if (!js.hasOwnProperty(key)){
        js[key] = JSON.parse(JSON.stringify(jsToCopy));
    }
    }

    //___________________________________________________________________________
    SetIfNotExists1(js, jsToCopyParent, ...keys){
    for (let key of keys) this.SetIfNotExists(js, key, jsToCopyParent[key])
    }

    // //___________________________________________________________________________
    // SetIfNotExists2(js, jsToCopyParent, ...keys){
    //     for (let key of keys) js[key] = JSON.parse(JSON.stringify(jsToCopyParent[key]));
    // }

    //___________________________________________________________________________
    SetKeyValueIfNotExists(...jsKeyValue){//export function SetIfNotExists(js : any, key: string, jsToCopy:any){
    let L = jsKeyValue.length,
        i = 0;
    while (i < L){  
        if (this.oOK(jsKeyValue[i])){
            if (!jsKeyValue[i].hasOwnProperty(jsKeyValue[i+1])) jsKeyValue[i][jsKeyValue[i+1]] = jsKeyValue[i+2];
            i += 3;
        }
    }
    }

    //___________________________________________________________________________
    Copy(js,jsS, ...jsKeyValue){//copy from jsS (source) into js
    let L = jsKeyValue.length,
        i = 0;
    while (i < L) js[jsKeyValue[i]] = jsS[jsKeyValue[i++]];
    }

    //___________________________________________________________________________
    SetKeyArIfNotExists(...jsKeyCountVal){//export function SetIfNotExists(js : any, key: string, jsToCopy:any){
    let L = jsKeyCountVal.length,
        co,
        js,
        key,
        count,
        val,
        i = 0;
    while (i < L){  
        co = true;
        js = jsKeyCountVal[i++];
        key = jsKeyCountVal[i++];
        count = jsKeyCountVal[i++];
        val = jsKeyCountVal[i++];     //console.log("err", js,key,count,val);  //any object
        if (js.hasOwnProperty(key)){
            if (Array.isArray(js[key])) {
                if (js[key].length >= count) co = false;
            }
        }
        if (co){
            js[key] = [];
            for (let j = 0; j < count; j++) js[key].push(val);
        }
    }
    }

        //___________________________________________________________________________
    SubstitudeKeys(...jsk1k2){
    let L = jsk1k2.length,
        i = 0;
    while (i < L) {
        let js = jsk1k2[i++],
            k1 = jsk1k2[i++],
            k2 = jsk1k2[i++];
        if (js.hasOwnProperty(k2)){
            js[k1] = js[k2];
            delete js[k2];
        }    
    }
    }

//___________________________________________________________________________
    SubstKeysProj(jproj){
    jproj.lVariant.forEach(v => {
        this.SubstitudeKeys(v.building, "OrAzim", "tOrAzim");
        v.building.lComponent.forEach(c => this.SubstitudeKeys(c, "orient", "orientC",   c,"OrAzim","OrAzimC"));
        v.cliLoc.lOptCli.forEach(c => this.SubstitudeKeys(c, "n","cn"));
      });
}

//____________________________________________________________________________
    dateString(js, dt){//function dateString(js: any, dt:any){
    let s = "",
        mh,//: any,  //month or hour
        dm,//:any,  //day or minutes
        smh = "",
        sdm = "";  
    if (dt === this.dType.date || dt === this.dType.datetime){
        mh = js[1]; 
        dm = js[2];      
        if (mh < 10) smh = "0";
        if (dm < 10) sdm = "0";
        s =  js[0].toString() + "-" + smh + js[1].toString() + "-" + sdm + js[2].toString();
        if (dt === this.dType.datetime){
            mh = js[3];
            dm = js[4];
            if (mh < 10) smh = "0"; else smh = "";
            if (dm < 10) sdm = "0"; else sdm = "";

            s += "T" + smh + mh.toString() + ":" + sdm + dm.toString();
        }
    }
    else if (dt === this.dType.time){
        mh = js[4];
        dm = js[5];
        if (mh < 10) smh = "0";
        if (dm < 10) sdm = "0";
        s = smh + mh.toString() + ":" + sdm + dm.toString();
    }
    return s;
}

//____________________________________________________________________________
    equalsTo(js, key, val, setIfNotExists) {//function equalsTo(js: any, key:string, val:any, setIfNotExists:boolean) {
    let co = false;
    if (js.hasOwnProperty(key)) co = (js[key] === val);
    else if (setIfNotExists){
        js[key] = val;
        co = true;
    }
    return co;
}

//____________________________________________________________________________
    equal(a,b, prec) {//function equalsTo(js: any, key:string, val:any, setIfNotExists:boolean) {
    return (Math.abs(a-b) < prec);
}

//___________________________________________________________________________________________
    addAr(ar, n, o){ //adds object o n-times to ar
    if (!ar) ar = [];
    for(let i = 0; i < n; i++) ar.push(o);
    return ar;
}
//___________________________________________________________________________________________
     newVecVert(p) //of Vertex
{
    return [p[1], p[2],p[3]];
}

//___________________________________________________________________________________________
    vecLength(v) {//vectorLength(v: number[])
    return Math.sqrt(v[0] * v[0] + v[1] * v[1] + v[2] * v[2]);
}

//___________________________________________________________________________________________
    crossProduct(v1, v2) {//export function crossProduct(v1: number[], v2: number[]) {
    return [
        v1[1] * v2[2] - v1[2] * v2[1],
        v1[2] * v2[0] - v1[0] * v2[2],
        v1[0] * v2[1] - v1[1] * v2[0]]
}

//___________________________________________________________________________________________
    dotProduct(v1, v2) {//export function dotProduct(v1: number[], v2: number[]) {
    return v1[0] * v2[0] + v1[1] * v2[1] + v1[2] * v2[2];
}

    //___________________________________________________________________________________________
    vecNorm(v){
        let l = this.vecLength(v);
        v[0] /=l;
        v[1] /=l;
        v[2] /=l;
    }

    //___________________________________________________________________________________________
    vecMove(v0, v, mult){
        return [v0[0] + v[0]*mult, v0[1] + v[1]*mult, v0[2] + v[2]*mult];
    }

    //___________________________________________________________________________________________
    strToSvg(s){
        let blob = new Blob([s], {type: 'image/svg+xml'});
        return URL.createObjectURL(blob);
    }
}