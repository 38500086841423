import React, { Component } from "react";
import { ObjTreeNode} from "./TreeViewNode";
//import { nK, oOK } from "../data/Utils"
//import { isSimulatedZone, scopeMETR, isAttachedToPHcase } from "../data/UtilsProj"
//import { Settings } from '../data/Settings';
import { createPath, createElsvg} from "./EditHelpers";
import {ContextMenu } from "../editors/ContextMenu"
import { DataType, TreeViewType } from "../data/KeyDefJSON";
import { EditorInput } from './Editor';

import catYR from "../Img/catYR.svg"
import catYRt from "../Img/catYRt.svg"
import {app} from "../ahome/App"; 

//_____________________________________________________________________________________
export class TreeView extends Component {
	pO;//: any;  //main ObjTreeNode;
	selP = null;//: any = null; //ObjTreeNode selected
	pOTN = null;//: any = null;//ObjTreeNode used for searching
	refLines;//: any;
		  
	refSVG;//: React.RefObject<SVGSVGElement>;
	refDIV;//: React.RefObject<HTMLDivElement>;

	maxWidth = 0;

	//not explicit----------------------
	//cm  - context menu
	//FE
	//edit - editor eg. catalogue name
	//coEditing - Catalog name is edited


	//_________________________________________________________________________________
	constructor(props) {
		super(props); 
		this.inr = props.inr;
		app.TV[props.inr] = this;
	
		this.onMouseEvent = this.onMouseEvent.bind(this);
		this.findOTNtvp = this.findOTNtvp.bind(this);
		this.findOTN = this.findOTN.bind(this);
		this.onCMSelected = this.onCMSelected.bind(this);
		this.editChanged = this.editChanged.bind(this);
        this.MoveToErr = this.MoveToErr.bind(this);

		this.refSVG = React.createRef();
		this.refDIV = React.createRef();

		this.coEditing = false;  //console.log("Tree View created");
		
		this.lastSelected = null;

		//let dxy = Settings.Tree.whRect - 4; 
		/*Settings.Tree.sRectMinus = createPath('M', Settings.Tree.xyRect, Settings.Tree.xyRect, 
										  'h', Settings.Tree.whRect, 'v', Settings.Tree.whRect,
										  'h', -Settings.Tree.whRect, 'v', -Settings.Tree.whRect, 
			                              'M', Settings.Tree.xyRect + 2, Settings.Tree.heightTreeNode/2 + 0.8, 'h', dxy);
		Settings.Tree.sRectPlus = Settings.Tree.sRectMinus + createPath('M', Settings.Tree.xyRect - 1 + Settings.Tree.whRect/2 + 1, Settings.Tree.xyRect + 2, 'v' + dxy);	*/
	}


	//_________________________________________________________________________________
	addDBres(){
		this.pO.addDBres(this.FE.DBres);
	}


	

	//_________________________________________________________________________________
	componentDidMount() {
		this.FE = app.FE[this.inr];

		if (this.FE.coMain){
			this.pickedInWebGL = this.pickedInWebGL.bind(this);
			app.TS[this.inr].webGL.pickedInWebGL = this.pickedInWebGL;
		}
		else {
			if (this.FE.coDB && !this.FE.coDBget){
				this.edit = new EditorInput(false);  
				this.edit.valChanged = this.editChanged;
			}	
		}
		
		this.refSVG.current.addEventListener("mousedown", this.onMouseEvent);
		// this.createNewNodes();  moved to FormEdit didMount

		if (this.FE.coDB && !this.FE.coDBget) {
			this.refSVG.current.addEventListener("contextmenu",  (e) => this.ShowcontextMenu(e));
			this.cm = new ContextMenu(this.refDIV.current, this.onCMSelected);
			//this.cm.setItems(["s_nCat","s_rCat","s_dCat"]);
		}
		//actData.tv = this;
		//if (this.FE.idFE === 0) actData.tvM = this;
	}

	//____________________________________________________________________________________________
	ShowcontextMenu(e){
		e.preventDefault();	
		let ii = [];
		if (this.selP){
			let id = this.selP.oJSON.id; 
			if (id !== -2){
				ii.push("s_nCat");
				if (id !== -1) ii.push("s_rCat");
				if (this.selP.children.length === 0) ii.push("s_dCat");
			}	
		}
		if (ii.length > 0){
			this.cm.setItems(ii);
			this.cm.show(e);
		} 
		else this.cm.hide();	
	}

	//____________________________________________________________________________________________
	editChanged(val){
		this.selP.oJSON.n = val;
		this.selP.oJSON.nC = true;  
	}

	//____________________________________________________________________________________________
	InformFENodeChanged(){
		let co = false;
		if (this.FE.coDB){  
			if (this.lastSelected) co = (this.selP.oJSON.id !== this.lastSelected.oJSON.id);
			else co = true;
		}
		else co = (this.selP !== this.lastSelected);
		if (co){
			this.lastSelected = this.selP;  
			this.FE.treeNodeChanged(this.selP);
		}
	}


	//____________________________________________________________________________________________
	onCMSelected(sk){
		if (sk === "s_nCat"){
			if (this.selP) {
				this.selP.oJSON.chC = true;  //children list changed
				//this.idMinus--;
				this.selP.refRect.setAttributeNS(null, 'fill', 'white');
				this.selP.coExpanded = true;
			
				this.selP = this.appendNewNode(true, false, TreeViewType.cat, {id: this.FE.getIDminus(), n:"New", nC: true, co:[]}, false);
				this.selP.refRect.setAttributeNS(null, 'fill', 'lightblue');
				//this.FE.treeNodeChanged(this.selP);
				this.InformFENodeChanged();
				let xx = this.selP.refText.getBoundingClientRect().x -  this.refSVG.current.getBoundingClientRect().x;
				this.coEditing = true;
				this.edit.setTextEdt(this,  DataType.text, this.refSVG.current,  this.selP.refText,  xx,  this.selP.y,  200, this.selP.oJSON.n);
			}
		}
		else if (sk === "s_rCat"){
			let xx = this.selP.refText.getBoundingClientRect().x -  this.refSVG.current.getBoundingClientRect().x;
			this.coEditing = true;
			this.edit.setTextEdt(this,  DataType.text, this.refSVG.current,  this.selP.refText,  xx,  this.selP.y,  200, this.selP.oJSON.n);
			
		}
		else if (sk === "s_dCat"){
			
			if (window.confirm(app.utils.nK("s_dCatC"))){
				if (this.selP.oJSON.id > 0) this.FE.DBres.IdDelCats.push(this.selP.oJSON.id);
				let indexN = this.selP.nrLocal - 1;
				if (this.refSVG.current.contains(this.selP.refSVGN)) this.refSVG.current.removeChild(this.selP.refSVGN);
				if (this.selP.parentO){
					this.selP = this.selP.parentO;
					this.selP.oJSON.chC = true;
				}
				else this.selP = this.pO;
				this.selP.refRect.setAttributeNS(null, 'fill', 'lightblue');

				this.deleteSubNode(indexN);

				//this.FE.treeNodeChanged(this.selP);
				this.InformFENodeChanged();
    		}
		}
	}
	

	//_________________________________________________________________________________
	removeAllsvg()
	{
		if (this.refDIV.current && this.refSVG.current){
			while (this.refSVG.current.firstChild) { this.refSVG.current.removeChild(this.refSVG.current.firstChild); }
		}
	}

	//_________________________________________________________________________________
	createMainNode() {  
		if (this.FE.coMain){
			return new ObjTreeNode(false, true, TreeViewType.project, null, app.projectJSON, this.FE, false);
		}
		else{
			let po =  new ObjTreeNode(true, true, TreeViewType.cat, null, {n:"All", id:-1}, this.FE, false);
			this.pNC = new ObjTreeNode(true, true, TreeViewType.cat, po, {n:"Not cataloged", id: -2}, this.FE, false);
			return po;
		}
	}

	

	//_________________________________________________________________________________
	CObjectSelected(id){    
		ObjTreeNode.idOsel = id;
		this.pO.CheckIconDB(this.FE.coDBget);
		this.setIconNotCataloged();
	}


	//_________________________________________________________________________________
	createNewNodes(){

		if (this.FE.coDB && !this.FE.DB) return;  

		//actData.webGL.listTreeNodesComponents = [];
		if (this.refDIV.current && this.refSVG.current) this.refDIV.current.removeChild(this.refSVG.current);

		this.removeAllNodesRef(this.pO);
		this.removeAllsvg();
		/*if (this.refSVG.current && this.refLines) {
			if (this.refSVG.current.contains(this.refLines)) this.refSVG.current.removeChild(this.refLines);
		}*/
		

		app.utils.settings.Tree.nr = 0;
		app.utils.settings.Tree.y = 2;
		this.pO = this.createMainNode();

		if (this.FE.coMain) this.pO.createSubNodes(this.FE);
		else this.pO.createSubNodesCatalogues(this.FE.DB.LC, this.FE);  //console.log("this.FE.DB.LC",this.FE.DB.LC);}

		this.setAttrNodes(this.pO);
		this.addLines();
		this.appendNodes(this.pO);

		if (this.refSVG.current) {
			this.refSVG.current.setAttributeNS(null, 'height', (app.utils.settings.Tree.y + app.utils.settings.Tree.heightTreeNode + 5).toString());
			
		}
		if (this.refDIV.current && this.refSVG.current) this.refDIV.current.appendChild(this.refSVG.current);

		this.selP = this.pO;
		this.selP.refRect.setAttributeNS(null, 'fill', 'lightblue');
		this.treeNodeSelected();
		//??actData.treeNodeSelectedInTreeView();

		/*this.refSVG.current!.appendChild(createElsvg('rect',  //TEST
			'x', 0,
			'y', 0,
			'width', '100%', //store.uiStateStore.formContainerDimensions.width - 2,
			'height', '100%',//store.uiStateStore.formContainerDimensions.height - 90,  
			'fill', 'none',
			'stroke', 'red',
			'strokeWidth', 1));*/
	}

	//_________________________________________________________________________________
	removeAllNodesRefChildren(otn){  //removeAllNodesRef(otn: ObjTreeNode){
		if (otn){
			if (otn.children.length > 0) {
				otn.children.forEach(el => {
				this.removeAllNodesRef(el);
				});
			}
		}
	}

	//_________________________________________________________________________________
	removeAllNodesRef(otn){  //removeAllNodesRef(otn: ObjTreeNode){
		if (otn){
			if (this.refSVG.current && otn.refSVGN) {
				if (this.refSVG.current.contains(otn.refSVGN)) this.refSVG.current.removeChild(otn.refSVGN); 
				if (otn.children.length > 0) {
					otn.children.forEach(el => {
						this.removeAllNodesRef(el);
					});
				}
			}
		}
	}

	//_________________________________________________________________________________
	setAttrNodes(otn){  //should always started with main !  //setAttrNodes(otn: ObjTreeNode){
		if (!(this.refSVG.current)) return;

		let xlok = 0;
		if (otn.coPM){
			if (otn.svgN.contains(otn.refPlus)) otn.svgN.removeChild(otn.refPlus);
			if (otn.svgN.contains(otn.refMinus)) otn.svgN.removeChild(otn.refMinus);
			if (otn.children.length > 0){
				if (otn.coExpanded) otn.svgN.appendChild(otn.refMinus);
				else otn.svgN.appendChild(otn.refPlus);
				
			}	
			xlok += app.utils.settings.Tree.heightTreeNode;
		}
		xlok += app.utils.settings.Tree.xyIcon;
		otn.actualizeText();
		otn.refIcon.setAttributeNS(null, 'x', xlok.toString());  

		xlok += app.utils.settings.Tree.heightIcon + 3;
		otn.refRect.setAttributeNS(null, 'x', xlok.toString());
		otn.refText.setAttributeNS(null, 'x', xlok.toString());  
		//let w = textWidth(otn.getName(), Settings.Tree.fontSize) + 2;
		
		
		
		//otn.refRect.setAttributeNS(null, 'width', w.toString());

		if (otn.parentO){
			app.utils.settings.Tree.y += app.utils.settings.Tree.heightTreeNode + app.utils.settings.Tree.distTreeNode; 
			if (otn.parentO.coPM) otn.x = otn.parentO.x + app.utils.settings.Tree.heightTreeNode;	
		}
		otn.y = app.utils.settings.Tree.y;
		otn.width = xlok + otn.wtext + 1; 

		if (otn.children.length > 0 && otn.coExpanded){
			otn.children.forEach(el => {
				this.setAttrNodes(el);
			});
		}						
	}

	//_________________________________________________________________________________
	appendNodes(otn) {  //should always started with main ! //appendNodes(otn: ObjTreeNode)
		if (!(this.refSVG.current)) return;
		otn.refSVGN = this.refSVG.current.appendChild(otn.svgN);
		otn.refSVGN.setAttributeNS(null, 'width', otn.width.toString());
		otn.refSVGN.setAttributeNS(null, 'x', otn.x.toString());
		otn.refSVGN.setAttributeNS(null, 'y', otn.y.toString());
		
		let nrlok = 0;
		if (otn.children.length > 0 && otn.coExpanded) {
			otn.children.forEach(el => {
				nrlok++;
				el.nrLokal = nrlok;
				this.appendNodes(el);
			});
		}
	}

	//_________________________________________________________________________________
	findOTN(otn, nr) { //findOTN(otn: ObjTreeNode, nr: number)
		if (!(this.selP)){
			if (otn.nr === nr) this.selP = otn;
			else {
				otn.children.forEach(el => this.findOTN(el, nr));
			}
		}
	}

	//_______________________________________________________________________________
	getOtn(nr){
		let sP = this.selP;
		this.selP = null;
		this.findOTN(this.pO, nr);
		let fotn = this.selP;
		this.selP = sP;
		return fotn;
	}



	//_________________________________________________________________________________
	findOTNtvp(tvp, id, otn) {  //findOTNtvp(tvp: TreeViewType, id: number, otn: ObjTreeNode) {  //findOTNtvp(tvp: any, id: number, otn: ObjTreeNode) 
		this.pOTN = undefined;
		if (otn.type === tvp){
			if (otn.oJSON.hasOwnProperty("id")){
				if (otn.oJSON.id === id) this.pOTN = otn;
			}
			else this.pOTN = otn;
		}
		otn.children.forEach(el => { if (!(this.pOTN)) this.findOTNtvp(tvp, id, el)});
	}

	//_________________________________________________________________________________
	findOTNjs(js, otn) { //findOTNjs(js: any, otn: ObjTreeNode) {
		this.pOTN = null;
		if (otn.oJSON === js) {
			this.pOTN = otn;
		}
		otn.children.forEach(el => { if (!(this.pOTN)) this.findOTNjs(js, el) });
	}

	//_________________________________________________________________________________
	checkMaxWidth(otn){  //checkMaxWidth(otn: ObjTreeNode){
		//let rect = otn.refRect.getBoundingClientRect(),
		//    w = rect.x + rect.width;

		if (this.refSVG.current.contains(otn.refSVGN)){
			let w = otn.getPosRight();
			if (w > this.maxWidth) this.maxWidth = w;
			otn.children.forEach(el => this.checkMaxWidth(el));  
		}
	}

	//_________________________________________________________________________________
	actualizeWidth() {
		if (this.refDIV){
			this.maxWidth = 0;
			this.checkMaxWidth(this.pO);
			this.refSVG.current.setAttributeNS(null, "width", this.maxWidth.toString()); 
		}
	}

	//_________________________________________________________________________________
	treeNodeSelected(){
		if (this.FE.coMain){
			//this.FE.treeViewtype = this.selP.type;
			//this.FE.keyJson = this.selP.keyName;
			//actData.oJson = this.selP.oJSON;
			//this.FE.actTreeViewNode = this.selP;

			//actData.treeNodeSelectedInTreeView();
			//??this.FE.treeNodeChanged();
		}
		else {
			
			if (this.pOTN) {
				this.pOTN.actualizeText(); 
				this.actualizeWidth();  
				this.pOTN = null;
			}
			if (this.selP.oJSON.id === -2){
				this.pNC.oJSON.co = [];
				let idco = ObjTreeNode.idOsel;
				this.FE.DB.LO.forEach(c => {
					ObjTreeNode.idIcluded = false;
					ObjTreeNode.idOsel = c.id;
					this.pO.checkNotCataloged();
					if (!ObjTreeNode.idIcluded) this.pNC.oJSON.co.push(c.id);
				});
				

				ObjTreeNode.idOsel = idco;
			};
			//??this.FE.reRender();
		}
		//this.FE.treeNodeChanged(this.selP);
		this.InformFENodeChanged();
	}

	//_________________________________________________________________________________
	rearrangeNodes(){
		if (this.refDIV.current && this.refSVG.current) this.refDIV.current.removeChild(this.refSVG.current);

		this.removeAllNodesRef(this.pO);
		if (this.refSVG.current && this.refLines) {
			if (this.refSVG.current.contains(this.refLines)) this.refSVG.current.removeChild(this.refLines);
		}

		app.utils.settings.Tree.y = 2;
		this.setAttrNodes(this.pO);
		this.addLines();
		this.appendNodes(this.pO);

		if (this.refDIV.current && this.refSVG.current) {
			this.refDIV.current.appendChild(this.refSVG.current);

			//actData.refDivTreeView.current.scrollTop = scTop;
			this.refSVG.current.setAttributeNS(null, 'height', (app.utils.settings.Tree.y + app.utils.settings.Tree.heightTreeNode + 5).toString());
		}
		this.actualizeWidth();
	}

	//_________________________________________________________________________________
	setIconNotCataloged(){

			ObjTreeNode.idIcluded = false;
			this.pO.checkNotCataloged();
			if (ObjTreeNode.idIcluded) this.pNC.refIcon.setAttributeNS(null, "href", catYR);
			else this.pNC.refIcon.setAttributeNS(null, "href", catYRt);
	
	}
	//_________________________________________________________________________________
	onMouseEvent(e) {
		if (!e.target) return;
		//e.preventDefault();
		if (e.target.hasAttribute("treeN")){  
			
			let selPop = this.selP,
				nrTreeNode = Number(e.target.getAttribute("treeN"));

			this.selP = null;
			this.findOTN(this.pO, nrTreeNode);
			if (this.selP){ 

				if (this.FE.DB){	
					if (e.target.hasAttribute("key")){
						let satt = e.target.getAttribute("key");
						if (satt === this.selP.getID("I")){
							if (this.FE.coDBget) return;
							let parent0 = this.selP;
							this.selP = selPop;
							if (parent0.oJSON.id === -1 || parent0.oJSON.id === -2) return;
							let coIncludes = parent0.oJSON.co.includes(ObjTreeNode.idOsel);
							if (coIncludes) parent0.RemoveCOidsDB(); //if parent not includes all children also
							else{
								parent0.AddRemoveIdCODB(true, ObjTreeNode.idOsel);//  parent0.oJSON.co.push(ObjTreeNode.idOsel);
								while (parent0.parentO.oJSON.id !== -1) {
									parent0 = parent0.parentO;
									if (!parent0.oJSON.co.includes(ObjTreeNode.idOsel)) parent0.AddRemoveIdCODB(true, ObjTreeNode.idOsel);//parent0.oJSON.co.push(ObjTreeNode.idOsel);
								};
							}
							parent0.CheckIconDB(this.FE.coDBget); 
							this.setIconNotCataloged();
							return;
						}
					}
				}

				if (e.target.hasAttribute('pm') && this.props.refDIVparent && this.props.refDIVparent.current) {
					this.selP.coExpanded = !this.selP.coExpanded;
					var scTop = this.props.refDIVparent.current.scrollTop; 
					this.rearrangeNodes();
					//??actData.refDivTreeView.current.scrollTop = scTop;
					this.props.refDIVparent.current.scrollTop = scTop;
				}
				this.selP.refRect.setAttributeNS(null, 'fill', 'lightblue');
				
				
				this.objectSelectedTreeViewInformWbGL(this.selP);
				this.treeNodeSelected();
			}
			if (selPop && selPop !== this.selP) {	
				selPop.refRect.setAttributeNS(null, 'fill', 'white');
			}
			if (this.coEditing){
				if (this.edit) this.edit.hide();
				this.coEditing = false;
				this.selP.actualizeText();
				this.actualizeWidth();
			}
			
		}

	}

	

	//_________________________________________________________________________________
	addLinesLok(otn){   //addLinesLok(otn: ObjTreeNode){
    if (otn.parentO.coExpanded) {
      var x1 = otn.parentO.x + app.utils.settings.Tree.heightTreeNode / 2, //parent
        y1 = otn.parentO.y + app.utils.settings.Tree.heightTreeNode / 2,
        dx = otn.x - otn.parentO.x,
        dy = otn.y - otn.parentO.y;
      if (otn.parentO.coPM) x1 += app.utils.settings.Tree.heightTreeNode;
      else dx = app.utils.settings.Tree.heightTreeNode;
      app.utils.settings.Tree.sLinesPath += createPath("M", x1, y1, "v", dy, "h", dx);
      otn.children.forEach((el) => this.addLinesLok(el));
    }
  }

	//_________________________________________________________________________________
	addLines(){
		if (this.refSVG.current){
			app.utils.settings.Tree.sLinesPath = "";
			this.pO.children.forEach((el) => this.addLinesLok(el));
			this.refLines = this.refSVG.current.appendChild(createElsvg('path',
				'd', app.utils.settings.Tree.sLinesPath,
				'stroke', 'lightgray',
				'stroke-width', '1px',
				'fill', 'none',
				'key', "conLines",
				"shape-rendering", "crispEdges"
			));	
		}
	}

	//_________________________________________________________________________________
	actualizeNodes() {  //after local change  add/remove
		//actData.webGL.listTreeNodesComponents = [];
		if (this.refDIV.current && this.refSVG.current) this.refDIV.current.removeChild(this.refSVG.current);

		this.removeAllNodesRef(this.pO);
		if (this.refSVG.current && this.refLines) {
			if (this.refSVG.current.contains(this.refLines)) this.refSVG.current.removeChild(this.refLines);
		}
		app.utils.settings.Tree.y = 2;

		this.setAttrNodes(this.pO);
		this.addLines();
		this.appendNodes(this.pO);

		if (this.refSVG.current) {
			this.refSVG.current.setAttributeNS(null, 'height', (app.utils.settings.Tree.y + app.utils.settings.Tree.heightTreeNode + 5).toString());

		}
		if (this.refDIV.current && this.refSVG.current) this.refDIV.current.appendChild(this.refSVG.current);

		/*this.refSVG.current!.appendChild(createElsvg('rect',  //TEST
			'x', 0,
			'y', 0,
			'width', '100%', //store.uiStateStore.formContainerDimensions.width - 2,
			'height', '100%',//store.uiStateStore.formContainerDimensions.height - 90,  
			'fill', 'none',
			'stroke', 'red',
			'strokeWidth', 1));*/
	}

	//_________________________________________________________________________________
	actualizaNames() {   //called from extern without redrawing of all tree
		let nr = 1;
		this.selP.children.forEach((el) => {
			el.nrLocal = nr; 
			el.actualizeText();
			nr++;
		});
		this.actualizeWidth();
	}
	//_________________________________________________________________________________
	appendNewNode(coPM, coExpanded, type, js, createSubNodes) {  //called from extern without redrawing of all tree  //appendNewNode(coPM: boolean, coExpanded:boolean,type: TreeViewType, js: any, createSubNodes: boolean
		let nn = new ObjTreeNode(coPM, coExpanded, type, this.selP, js, this.FE, createSubNodes);
		//if (createSubNodes) nn.createSubNodes();
		
		this.actualizeNodes();
		this.actualizaNames();

		return nn;
	}

	//_________________________________________________________________________________
	deleteSubNode(indexN) {   //called from extern without redrawing of all tree
		if (this.selP.children.length > indexN){
			this.selP.children.splice(indexN, 1); 
			this.actualizeNodes();
			this.actualizaNames();
		}
		
	}

	//_________________________________________________________________________________
	TreeNodeSelectedWebGL(otn, forceReArrange){ //TreeNodeSelectedWebGL(otn: ObjTreeNode, forceReArrange:boolean){
		if (this.FE.coDB) return;
		let selPop = this.selP,
			coParentClosed = false,
			otp = otn.parentO;

		this.selP = otn; 
			
		while (otp){
			if (otp.coPM){
				if (!otp.coExpanded) { 
					coParentClosed = true;
					otp.coExpanded = true;
				}
			}
			otp = otp.parentO;
		}
		if (coParentClosed || forceReArrange){
			this.rearrangeNodes();
		}

		this.selP.refRect.setAttributeNS(null, 'fill', 'lightblue');
		if (selPop && selPop !== this.selP) {
			selPop.refRect.setAttributeNS(null, 'fill', 'white');
		}
		let y = Math.max(0, this.mH + this.refDIV.current.scrollTop - app.utils.settings.Tree.heightTreeNode - app.utils.settings.Tree.distTreeNode);
		if (otn.y > y) {
			y = Math.round(Math.max(0, otn.y - this.mH/2));
			this.props.refDIVparent.current.scrollTop = y;
		}
		else{
			y = this.refDIV.current.scrollTop + app.utils.settings.Tree.heightTreeNode + app.utils.settings.Tree.distTreeNode;
			if(otn.y < y){
				y = Math.round(Math.max(0, otn.y - this.mH / 2));
				this.props.refDIVparent.current.scrollTop = y;
			}
		} 
		this.treeNodeSelected();
	}

    //_________________________________________________________________________________
	pickedInWebGL(id, type){  //pickedInWebGL(id : number, type: number){
		if (this.FE.coDB) return;
		this.pOTN = null;
		this.findOTNtvp(TreeViewType.comp, id, this.pO);
		if (this.pOTN){
			//actData.treeNodeSelectedInWebGL_(this.pOTN);
			this.TreeNodeSelectedWebGL(this.pOTN, false);
		}
		/*var ot = this.listTreeNodesComponents.find(
			(otn) => otn.oJSON["id"] === polC.componentJSON["id"]
		);
		if (actData.startModalCB < 0) {
			if (ot) actData.treeNodeSelectedInWebGL_(ot);*/
	}

	//_________________________________________________________________________________
	RemoveSel(o){
		let ta;
		if (o.hasOwnProperty("ta")) ta = o.ta;
		else if (app.utils.scopeMETR()){
			if (o.hasOwnProperty("thp")) ta = o.thp;
		}
		else{
			if (o.hasOwnProperty("tw")) ta = o.tw;
		}
		if (app.utils.oOK(ta)){
			ta.forEach(k => {
			 	let po = app.def[k];
			 	po.sel = false;
				this.RemoveSel(po);			
			});
		}
	}

	//_________________________________________________________________________________
	MoveToErr(navi){  //pickedInWebGL(id : number, type: number){
		if (this.FE.coDB) return;
		let selPop = this.selP;
		this.selP = null;
		this.findOTN(this.pO, navi[0]);
		if (this.selP){

			let tvo = app.def[this.selP.type]; 
            this.RemoveSel(tvo);  
			for (let i = 1; i < navi.length; i++) app.def[navi[i]].sel = true;  

			this.TreeNodeSelectedWebGL(this.selP, false); //TreeNodeSelectedWebGL(otn, forceReArrange)
			this.treeNodeSelected();
			this.objectSelectedTreeViewInformWbGL(this.selP);
			if (selPop && selPop !== this.selP) {	
				selPop.refRect.setAttributeNS(null, 'fill', 'white');
			}
		}
	}

	//_________________________________________________________________________________
	objectSelectedTreeViewInformWbGL(otn) {  //objectSelectedTreeViewInformWbGL(otn: ObjTreeNode)
		if (this.FE.coDB) return;
		let lGroupId = [];
		lGroupId.push([]);   // 1 - opaque 
		lGroupId.push([]);   // 2-opaque group yellow 
		lGroupId.push([]);   //  3 - opaque selected red contour

		app.pref.lastChoice = 0;  

		let lc = app.actVariantJSON.building.lComponent;

		if (
			otn.type === TreeViewType.project ||
			otn.type === TreeViewType.cases ||
			otn.type === TreeViewType.case ||
			otn.type === TreeViewType.loc ||
			otn.type === TreeViewType.building ||
			//otn.type === TreeViewType.hvac ||
			//otn.type === TreeViewType.hvacD ||
			//otn.type === TreeViewType.hvacS ||
			otn.type === TreeViewType.obj3Ds ||
			otn.type === TreeViewType.obj3D ||
			otn.type === TreeViewType.sharedObj ||
			otn.type === TreeViewType.setWufi
		) {
			lc.forEach((c) => {	lGroupId[0].push(c.id)});
		} 
		else if (otn.type === TreeViewType.casesPH) {
			lc.forEach((c) => {	if (app.utils.isAttachedToPHcase(c, -1)) lGroupId[0].push(c.id);});  
		} 
		else if (otn.type === TreeViewType.casePH) {
			lc.forEach((c) => {	if (app.utils.isAttachedToPHcase(c, otn.oJSON.id)) lGroupId[0].push(c.id);});  //console.log("otn.oJSON",otn.oJSON);
			app.pref.lastChoice = 1;  
			app.lastDataChoice = otn;
		} 
		else if (otn.type === TreeViewType.simZones) {
				lc.forEach((c) => {	if (app.utils.isSimulatedZone(c.idIC) || (app.utils.isSimulatedZone(c.idEC) && app.pref.showAllCompsZone)) lGroupId[0].push(c.id);});
				app.pref.lastChoice = 1;  
			app.lastDataChoice = otn;
		} 
		else if (otn.type === TreeViewType.attZones) {
			lc.forEach((c) => {	if (!app.utils.isSimulatedZone(c.idIC) && c.idIC > 0) lGroupId[0].push(c.id);	});
		}
		else if (otn.type === TreeViewType.rEs) {
			lc.forEach((c) => {	if (c.idIC < 0 && c.idEC < 0) lGroupId[0].push(c.id);});
		}
		else if (otn.type === TreeViewType.zone ||	otn.type === TreeViewType.visComp) {
			let idnr = 0;
			if (otn.type === TreeViewType.zone) idnr = otn.oJSON["id"];
			else idnr = otn.parentO.oJSON["id"];

			lc.forEach((c) => {	if (c.idIC === idnr || (c.idEC === idnr && app.pref.showAllCompsZone)) lGroupId[0].push(c.id);
				app.pref.lastChoice = 1;  
			app.lastDataChoice = otn;
			});
		} 
		else if (otn.type === TreeViewType.comp) {
			let idz = Math.max(-1, otn.oJSON["idIC"]);

			lc.forEach((c) => {
				if (c.id === otn.oJSON.id) lGroupId[2].push(c.id);
				else if (c.idIC === idz) lGroupId[0].push(c.id);
				else if (app.pref.showAllCompsZone){
					if (c.idEC === idz) lGroupId[0].push(c.id);
				}


			});
			app.pref.lastChoice = 1;  
			app.lastDataChoice = otn;
		} 
		else if (
			otn.type === TreeViewType.zoneTB ||
			otn.type === TreeViewType.zoneLoads ||
			otn.type === TreeViewType.zoneVent ||
			otn.type === TreeViewType.zoneDC ||
			otn.type === TreeViewType.zoneOP
		) {
			let idz = otn.findParentZoneID();  
			if (app.utils.isSimulatedZone(idz)){
			lc.forEach((c) => {	if (c.idIC === idz) lGroupId[0].push(c.id);	});
			}
		}
		app.TS[this.inr].webGL.ObjectSelectedExtern(lGroupId);
	}

	//_________________________________________________________________________________
	actualizeIcon(){
		if (this.selPOC) this.selPOC.actualizeIcon();
	}

	//_________________________________________________________________________________
	render = () => {
		return (
			<div style={{ margin: 0, padding: 0,  borderStyle:'none'}} key='divTreeView1' ref = {this.refDIV}>
				<svg xmlns="http://www.w3.org/2000/svg" version="1.1" ref={this.refSVG}
					preserveAspectRatio='none' x='0' y='0' 
					style={{ padding: 0,  margin: 0, position: 'absolute',  borderStyle:'none', width: '100%'}} />
			</div>
		);
	}
}

export default TreeView;
//'none'