import * as React from 'react';
import {app} from "./App";
import "./Tools.css";
import { GG } from "../report/ReportGener";
//import { nK, DBGeneric,  oOK, px, getValueFromSel, eqalToOneOf} from "../data/Utils";


//const SUBMIT_URL = process.env.NODE_ENV === "development" ? process.env.REACT_APP_JSON_SUBMIT_URL : window.JSON_SUBMIT_URL;
//const SUBMIT_METHOD = window.SUBMIT_METHOD;

export class StatusData extends React.Component {
  ref; //: React.RefObject<HTMLDivElement>;
  //cols = ['green','yellow','cyan','red','white'];
  ws = [0,0,0,0,0];
  //inputarea; //: any;
  c1;
  c2; //timer id

  //_________________________________________________________________________
  constructor(props) {
    super(props);
    this.state = {data:undefined}; //data = returned from server

    this.mW = 0;
    this.mH = 0;
    this.isCalc = false;
    this.dataStatus = 5;
    this.completed = 0;
    //this.cs = 0;  //calcstatus
    
    this.ref = React.createRef();

    this.newProj = this.newProj.bind(this);
    this.afterStopCancel = this.afterStopCancel.bind(this);
    this.cTimer = this.cTimer.bind(this);//Timer
    this.checkData = this.checkData.bind(this);
    this.checkDataRep = this.checkDataRep.bind(this);
    this.startCalc = this.startCalc.bind(this);
    this.checkDataCalc = this.checkDataCalc.bind(this);
    this.onClickButton = this.onClickButton.bind(this);

    // Ryans legacy -this.onSubmit = this.onSubmit.bind(this); 
    //this.CRes = this.CRes.bind(this);
    this.onMouseEvent = this.onMouseEvent.bind(this);  
  }

  //__________________________________________________________________________
  componentDidMount() { 
    app.SD = this;  console.log("app.SD = this; ");
    this.ref.current.addEventListener("mousedown", this.onMouseEvent);
    //this.cTimer();
    app.SDmounted();
  }

  //__________________________________________________________________________
  afterStopCancel(){
    clearTimeout(this.c2);
    clearTimeout(this.c1);
    this.props.setCalc(false);   
    this.checkData();
  }

  //__________________________________________________________________________
  cTimer(){
    clearTimeout(this.c1); //check timer
    this.c1 = setTimeout(this.checkData, 1000);
  }

  //__________________________________________________________________________
  newProj(data){  //data not null if is calculating
    if (app.utils.oOK(data) && app.utils.oOK(data.iCalc)){  //is calculating
      this.setState({data: data});
      app.SR.checkDataRep(data);
      this.checkDataCalc(data.iCalc); 
    }
    else{
      this.isCalc = false;                // console.log("newProj(), uuid",  app.projectJSON.uuid);
      this.props.setCalc(false);  
      this.setState({});
      app.SR.checkDataRep({});
      this.checkData();
    }
  }

  //__________________________________________________________________________
  checkData(){   //always called from extern 
    this.isCalc = false;   
    let pjs;
    if (app.allV || app.projectJSON.lVariant.length === 1) {
      pjs = app.projectJSON;
      pjs.allV = true;
      app.allV = false;
    }
    else{
      pjs = { ...app.projectJSON };  //shallow clonning
      pjs.lVariant = [];
      pjs.lVariant.push(app.actVariantJSON);
      pjs.allV = false;
      app.allV = false;
    }
    pjs.jobNr = 3; 
    pjs.pSS = app.state.pSS;
    app.DBGeneric("Gen/CheckData", pjs, this.checkDataRep);  
  }

  //__________________________________________________________________________
  checkDataRep(data){  //data sent back from api
    if (app.utils.oOK(data.uuid)) app.projectJSON.uuid = data.uuid;   
  
    if (app.utils.oOK(data) && app.utils.oOK(data.tfe) && app.utils.oOK(data.tfe.p))  this.checkDataCalc(data);  
    if (this.isCalc) return;   

    app.insertPartRes(data);   
      
    app.TV[0].actualizeIcon();
    //?????if (app.FE[0]) app.FE[0].AfterCalc(); //alert(data.jobStatus, data.dataStatus);

    this.setState({data: data});
    app.SR.checkDataRep(data);  
  }

  //__________________________________________________________________________
  checkDataCalc(data){   
    this.isCalc = false;
    if (app.utils.oOK(data)){
      if (app.utils.oOK(data.tfe) && app.utils.oOK(data.tfe.p) && app.utils.oOK(data.uuid)){
        //if (data.uuid != app.projectJSON.uuid) {
        //  return;
        //}
        this.isCalc = true;
        data.calcProg = data.tfe.p;
      } 
    }
    this.props.setCalc(this.isCalc);

    if (this.isCalc){
      this.completed = data.calcProg;
      //this.cs = data.calcStatus;
      this.setState({data: data}); 
      this.c2 = setTimeout(() => {app.DBGeneric("Gen/GetProgress", {jobNr: 6, uuid:data.uuid, idVar:app.actVariantJSON.id, 
                                                           calcScope:app.projectJSON.calcScope}, this.checkDataCalc)}, 2000);
    }
    else this.checkDataRep(data);
    
  }

  //__________________________________________________________________________
  startCalc(){
    if (this.isCalc) return;
    //??this.state.data = null;//to rerender
    app.projectJSON.jobNr = 5;  
    this.isCalc = true;
    //this.cs = 10;  //start calculation
    this.completed = 0;
    this.props.setCalc(this.isCalc);
    app.DBGeneric("Gen/CheckCalc", app.projectJSON, this.checkDataCalc);
  }

  //__________________________________________________________________________
  componentDidUpdate(){    //console.log("componentDidUpdate()");
    if (this.isCalc) return;
    if (app.utils.oOK(this.state.data) && app.utils.oOK(this.state.data.DD) && app.utils.oOK(this.state.data.DD.gg) && this.ref.current) {
      this.ErLoc = [];

      while (this.ref.current.firstChild) {  this.ref.current.removeChild(this.ref.current.lastChild);}
      GG(1,1,999, "im.kgg", this.state.data.DD.gg, this.ref.current, this.ErLoc);
    }
  }

  //__________________________________________________________________________
  resized(){
    this.setState({h:this.mH}); 
  }
  //________________________________________________________________________________________
  onMouseEvent(e) {
    if (e.target.hasAttribute("data-tv")){
      let ii = parseInt(e.target.getAttribute("data-tv"));
      if (app.utils.oOK(ii)){
        let navi = this.ErLoc[ii];  
        app.TV[0].MoveToErr(navi);  
      }
    }
  }

  //__________________________________________________________________________
  renderProgress(){
    if (this.state.data == null || !app.utils.oOK(this.state.data.tfe)) return null;

    if (this.isCalc){
      let sk = this.state.data.tfe.n;
      //if (oOK(sk)) sk = " " + sk;
      //else sk = "";
      const containerStyles = {height: 18, width: '100%', backgroundColor: "#e0e0de", borderRadius: 5, margin: 5}
    
      const fillerStyles = {whiteSpace: "nowrap", height: '100%', width: `${this.completed}%`, backgroundColor: "#ef6c00",  borderRadius: 'inherit', textAlign: 'right'}

      //const preStyles = {float:"right", verticalAlign: "middle", paddingRight: 5, color: 'black',  fontWeight: 'bold'}
    
      const labelStyles = {padding: 5,  color: 'black',  fontWeight: 'bold'}

    return (
      
      <div style={containerStyles}>   
        
        <div style={fillerStyles}>
        
          <span style={labelStyles}>{`${this.completed}%` + ' ' + sk}</span>
        </div>
      </div>
    )}
    else return null;
  }

  //__________________________________________________________________________
  RenderCalcButtons(){  //console.log("btnSk",this.state.data);
    let co1 = app.utils.oOK(this.state.data) && app.utils.oOK(this.state.data.tfe) && app.utils.oOK(this.state.data.tfe.btnSk),
        co2 = app.utils.oOK(this.state.data) && app.utils.oOK(this.state.data.DD) && 
              this.state.data.DD.tfe && app.utils.oOK(this.state.data.DD.tfe.btnSk);

    if (co1 || co2){  //btnSk is array now !  "jobCst" - stop, "jobCc" - cancel
      let sk = co1 ? this.state.data.tfe.btnSk : this.state.data.DD.tfe.btnSk;
      if (sk.length === 1) {
        return(<button style={{marginLeft: 10, whiteSpace:'nowrap'}} onClick={() => this.onClickButton(sk[0])} > {app.utils.nK(sk[0])}  </button>)
      }
        else if  (sk.length === 2) {
          return(
          <>
          <button style={{marginLeft: 10, whiteSpace:'nowrap'}} onClick={() => this.onClickButton(sk[0])} > {app.utils.nK(sk[0])}  </button>  
          <button style={{marginLeft: 10, whiteSpace:'nowrap'}} onClick={() => this.onClickButton(sk[1])} > {app.utils.nK(sk[1])}  </button>
          </>
          )
        }
    }
    return null;
  }

  //__________________________________________________________________________
  onClickButton(sk) {
    //let sk = this.state.data.tfe.btnSk[inr];
    
    if (sk === "jobCsMMS" || sk === "jobCs") this.startCalc();
    else{
      let uuid = this.state.data.uuid;
      if (sk === "jobCc") app.DBGeneric("Gen/CancellJob", {jobNr:8, uuid:uuid}, this.afterStopCancel);//cancell
      else if (sk === "jobCst") app.DBGeneric("Gen/StopJob", {jobNr:7, uuid:uuid}, this.afterStopCancel);//stop
    }
    //??this.state.data = null;//to rerender

    //console.log("onClickButton(inr)", sk);
  }
  

  //__________________________________________________________________________
  render = () => {
    let sh = app.utils.px(Math.max(this.mH - 31,1));
    //1px solid red
    return (
      <div>
        <div className="tool-header" style = {{padding: 6}}> 
          {app.utils.nK("dataStatus") }
          {this.RenderCalcButtons()}
          {this.renderProgress()}
        </div>

        <div  style = {{height: sh, fontFamily: 'arial', fontSize: 13, overflow: 'auto', border:'none'}}> 
          <svg xmlns="http://www.w3.org/2000/svg"	version="1.1"  ref={this.ref} ></svg>
          
        </div>
      </div>
    );
  };
}
export default StatusData;




//import { apiUrl } from './../../shared/apiConsts';
//declare const window: any;
//const SUBMIT_URL = process.env.REACT_APP_JSON_SUBMIT_URL || '/';
//require ('isomorphic-fetch');



  /*/__________________________________________________________________________
  CalcRes(){
    app.projectJSON.nrJob = 2;
    DBGeneric("Gen/DCCalc", app.projectJSON, this.CRes);  //so far
  }*/

  /*/__________________________________________________________________________
  CRes(data){
    var FileSaver = require('file-saver');
      var blob = new Blob([JSON.stringify(data)], {type: "text/plain;charset=utf-8"});
      FileSaver.saveAs(blob, "results.json");
  }*/


  //original  Ryans legacy
  /*/__________________________________________________________________________
  onSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    var sjs = JSON.stringify(app.projectJSON);
    data.append("data", sjs);  
    fetch(SUBMIT_URL, {
      method: SUBMIT_METHOD,
      body: data,
    });
  }
 

  //isomrphic  
  onSubmit(e) {
    e.preventDefault();
    const data = new FormData(e.target);
    var sjs = JSON.stringify(actData.projectJSON);
    data.append("data", sjs);
    fetch(SUBMIT_URL, {
      method: SUBMIT_METHOD,
      body: data,
    }).then((responce) => {
      console
        .log(responce.json())
        .then((data) => {
          console.log("Success:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }); 
  }

  //__________________________________________________________________________
  defRender() {
    return (
      <form id="fakeForm" method={SUBMIT_METHOD} onSubmit={this.onSubmit}>
        <input type="submit" value="Submit" />
      </form>
    );
  }*/


