import * as React from "react";

import "./WaitSpin.css";

export function WaitSpin(props) {
    let whWait = Math.min(window.innerWidth, window.innerHeight)*props.size + "px";
    return (<div 

        style={{width : whWait, height : whWait, 
            display: 'flex',

            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',

            justifyContent: 'center',
            alignItems: 'center' }} >

    <div className="spinner-3"></div>
    </div> );
}   


export function WaitText() {

    return (<div 

        style={{ 
            display: 'flex',

            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',

            justifyContent: 'center',
            alignItems: 'center' }} >

            <div className="waitT">Loading...</div>
    </div> );
}   