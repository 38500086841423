import React, { Component,Suspense } from 'react';

//import {actData} from "../data/ActData"
import {EdtDef} from "../editors/EdtDef"
//import {app.utils.app.utils.nK, getValueFromSel} from "../data/Utils";
import {WaitText} from "../editors/WaitSpin"; 
import SplitPane from "react-split-pane"
import {TreeView} from "../editors/TreeView";
import {FormEdit} from "../editors/FormEdit"; 
import {app} from "../ahome/App";
import Modal from "react-modal";
//import { ClimateDB } from '../modalClimate/ClimateDB';
import "./DBTool.css";

const ClimateDB = React.lazy(() => import("../modalClimate/ClimateDB"));

export class DBTool extends Component {
  
  refFE;
  refTV;
  startModal = -1;
  //not explicit------------------------------------------------
  //selScope - to select db catalogue
  //aM - act aMode in KeyDefJSON, purpose definition
  //FE

  //___________________________________________________________________________
  constructor(props) {
    super(props);  
    this.state = {
      showModal: false,
      disA: true,
      disAccept: true,
      layO:undefined
    };
    this.selSCope = {dbScope : app.def.dbScope.sel[0][0]};


    //this.state = {disA: true};
    //this.aM = actData.aM;
 
    this.coU = false;

    this.refTV = React.createRef();
    this.refFE = React.createRef();

    this.updateSplit = this.updateSplit.bind(this);
    this.windowResized = this.windowResized.bind(this);
    this.buttonAcceptClicked = this.buttonAcceptClicked.bind(this);
    this.buttonCancelClicked = this.buttonCancelClicked.bind(this);
    this.setActive = this.setActive.bind(this);
    this.EnDisAccept = this.EnDisAccept.bind(this);
    this.dragStarted = this.dragStarted.bind(this);
    this.scopeChanged = this.scopeChanged.bind(this);
    this.dataAccepted = this.dataAccepted.bind(this);

    this.handleOpenDBModal = this.handleOpenDBModal.bind(this);
    this.handleCancelModal = this.handleCancelModal.bind(this);

    if (window.innerWidth > window.innerHeight) this.state.layO = 1;
		  else this.state.layO = 2;
	  
  }

  //___________________________________________________________________________
  componentDidMount() {
    this.FE = FormEdit.FEs;
    window.addEventListener("resize", this.windowResized); 

    this.updateSplit();
  }


  //___________________________________________________________________________
  componentWillUnmount(){
    window.removeEventListener("resize", this.windowResized); 
  }

  //___________________________________________________________________________
  buttonAcceptClicked(e) {
    if (this.FE.coDBget) {
      var js = this.FE.form.getJSDBdata(this.props.t); //current json
    }
    else {
      this.FE.createDBres();
      this.props.cancelModal();
    }
    
    this.props.cancelModal(this.props.t,js);
                      
  }

  //___________________________________________________________________________
  buttonCancelClicked(e) {//console.log("buttonCancelClicked(e)>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    this.props.cancelModal();
  }

  //____________________________________________________________________________
  windowResized() {
    let layOP = this.state.layO,
        layA;
		if (window.innerWidth > window.innerHeight) layA = 1;
		else layA = 2;  
		if (layOP !== layA) {
			this.setState({layO:layA}); 
		}
		else{
			this.updateSplit();
		}
  }

  //____________________________________________________________________________
	updateSplit() 
	{
      let i = this.props.inr;   
      app.FE[i].mW = this.refFE.current.offsetWidth;
      app.FE[i].mH = this.refFE.current.offsetHeight;
      app.TV[i].mW = this.refTV.current.offsetWidth;
      app.TV[i].mH = this.refTV.current.offsetHeight;    
	}

  //____________________________________________________________________________
  setActive(){
    this.updateSplit();
    this.setState({disA: false});
    this.coU = true;
  }

  //____________________________________________________________________________
  EnDisAccept(co){
    this.setState({disAccept: co});
  }


  //____________________________________________________________________________
  scopeChanged() {
    app.FE[this.props.inr].getFromDB();
  }

  //DB second floor___________________________________________________________________________
//___________________________________________________________________________
  //___________________________________________________________________________
  handleOpenDBModal(idDB, coGet, t){   
    this.FE.prevForm = this.FE.form;
    this.FE.prevEdt = this.FE.edt;
    this.FE.ignoreForm = true; 

    if (idDB === 7) {
      this.startModal = 0;
      this.din = t.getActJson();
    }
    else this.startModal = 2;

    this.idDB = idDB;
    this.coDBget = coGet;
    this.t = t;  // callback
    this.setState({ showModal: true });
  }

  //___________________________________________________________________________
  handleCancelModal(t,js) {
    this.setState({ showModal: false });
    
    setTimeout(() => {//console.log("canceledModal");
                      this.FE.ignoreForm = false;
                      this.FE.ReForm(this.FE.prevForm);
                      if (t && js) t.getDBData(js,1);
              });
  }

  //____________________________________________________________________________
	dragStarted(){

    app.FE[this.props.inr].reScaling = true
	}

  //_________________________________________________________________________________________
  dataAccepted(jsD){
    this.din.lat = jsD.clat;
    this.din.long = jsD.clong;
    this.din.PropsC = true;
}

  //___________________________________________________________________________
  render() {
    const style = 
  		{
			  width: '100%',
			  height: '100%',
			  overflow : 'auto',
        border:'none'
  		};
    let sn = app.utils.nK("dbCategory") + ": " + app.utils.getValueFromSel("dbCategory", this.props.idDB, 1)  + ":     ";
    const sL = {
      marginLeft: 5
     
    };
    return (
      <>
      <div className="db-container">

      <div className="db-header"> 
        <label style = {sL}>{sn}</label>
        <EdtDef
          ojs={this.props.coDBget && this.props.inr < 2 ? this.props.dbsel : this.selSCope}
          skey={"dbScope"}
          onChange={ this.scopeChanged}
          disabled={this.state.disA || !this.props.coDBget || this.props.inr === 2}
        />
        <button className = "db-edt" onClick={this.buttonAcceptClicked} disabled={this.state.disA || this.state.disAccept}>   {this.props.coDBget ? app.utils.nK("b_Acc") : app.utils.nK("b_sDB")}  </button>
        <button className = "db-edt" onClick={()=>this.buttonCancelClicked()} disabled={this.state.disA}>   {app.utils.nK("b_Canc")}  </button>
      </div>

      <div className="db-main"  >
      <SplitPane  
					split = {this.state.layO === 2 ? "horizontal" : "vertical"}
					minSize="30%"
					defaultSize="35%"	
          onDragStarted = {this.dragStarted}
					onDragFinished= {this.updateSplit}	
					key={'splitVdb222' + this.props.inr}
          
				>
          <div ref={this.refTV} style={style} key={'divFormTreeView2' + this.props.keynr}>						
				  <TreeView refDIVparent = {this.refTV} inr = {this.props.inr} />
			  </div>
        <div ref={this.refFE} style={style} key={'divFormEdit' + this.props.inr} inr = {this.props.inr}>
							<FormEdit inr = {this.props.inr} coDB = {true} coDBget = {this.props.coDBget} 
                        t = {this.props.t}
                        idDB = {this.props.idDB} cancelModal={this.props.cancelModal}  
                        handleOpenDBModal = {this.handleOpenDBModal} 
                        setActive = {this.setActive}
                        dbSC = {this.props.coDBget && this.props.inr < 2 ? this.props.dbsel : this.selSCope}
                        EnDisAccept = {this.EnDisAccept}/>
				</div>  
      </SplitPane>
    </div>


    <Modal
            isOpen={this.state.showModal}
            ariaHideApp={false}
            contentLabel="DB2"
            style={{content: { top: '40px',  left: '40px',  right: '2px', bottom:'2px'   }}}
          >
          
            {this.startModal === 2 ? (
              <DBTool  
                handleOpenDBModal = {this.handleOpenDBModal}
                cancelModal={this.handleCancelModal}
                t = {this.t}
                coDBget = {true}
                idDB = {1}
                inr = {2}
              />
            ) : null}

{this.startModal === 0 ? (
  <Suspense fallback={<WaitText/>}>
              <ClimateDB
                cancelModal={this.handleCancelModal}
                dataAccepted={this.dataAccepted} 
                getLLCS={true}
                din = {this.din}
                app= {app}
              />
              </Suspense>
            ) : null}
          </Modal>



 </div>
  
</>
    );
  }
}



