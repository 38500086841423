//import { textWidth, app.utils.nK, sUnit, getKkd, DBGeneric,dateString,ColorRGBToHex,app.utils.app.utils.oOK,dOK,isUserDef,nameOK,messageBox } from "../data/Utils";
//import { app.utils.dType,TreeViewType } from "../data/KeyDefJSON";
import { ObjTreeNode} from "../editors/TreeViewNode";
import { TableScroll } from "./EditScroll";
import { createPath, createElsvg, createEltext, createElinput } from "./EditHelpers";
import FormEdit from "./FormEdit";
//import { setIconBT } from "../Img/Icons";


import iC from "../Img/check.svg";
import iCC from "../Img/checkC.svg";
import plusDB from "../Img/plusDB.svg"

import imgP from "../Img/plus.svg"
import imgM from "../Img/minus.svg"
import imgPD from "../Img/plus_disabled.svg"
import imgMD from "../Img/minus_disabled.svg"
import idbFromD from "../Img/dbFromD.svg"
import idbTo from "../Img/dbTo.svg"
import idbToD from "../Img/dbToD.svg"
import idbFrom from "../Img/dbFrom.svg"
import idbA0 from "../Img/assign0.svg"
import idbA1 from "../Img/assign1.svg"

import {app} from "../ahome/App";


//_______________________________________________________________________________________
export class EditTable {
    //setting from extern (previous pt{}--------------------

    keys = [];  //mostly string[], but also string[[]]
    partHeightContainer = 0.98;
    partWidthContainer = 0.98;
    heightTitleHalf = app.utils.settings.Table.heightTitleHalf;
    heightHeading = 0;
    heightRow = app.utils.settings.Table.heightRow;
    minRows = 3;
    maxRows = 0; //not limit
    minDataCount = 0;
    maxDataCount = 0;
    minWidth = 300;
    maxWidth = 700;
    maxHeight = 0;
    titleKey = "undef";
    titleKeyNr = "";
    titleNr = -1;
    elNameKey = "undef";  //to be displayed in title in sub Table; eg. "material"
    widthCols = [200, 1];
    titleColsKeys = [];
    alwaysSelectedData = false;
    widthColumnButtons = 0;//app.utils.app.utils.settings.Table.widthColumnButtons;
    NoTextBoolean = false;
    RectSelected = true;
    firstColNr = false;
    scroller = true;

    firstRowImmutable = false;
    widthScroll = 0;//app.utils.settings.Table.widthScroll;//2 * Math.round(0.22 * heightRow); //must be even number
    firsRowCanNotBeRemoved = false;
    rowDataArr = [];  //number[]
    colDataArr = false;
    cTV = false;  //connected with TV
    subTables = [];//EditTable[]
    //end setting from extern-------------------------------

    left = app.utils.settings.form.defaultLeft;
    top = -1;
    right = 0;
    bottom = 0;
    width = 0;
    height = 0;
    idNr = -1;
    oJson;//: any;

    sTitle = "";
    elName = "";
    yLineTitle = 0;
    leftTable = 0;
    topTable = 0;
    widthAllColumns = 0;
    topRows = 0;
    dhLineTitle = 0;
    bottomAllRows = 0;
    leftRows = 0;
    rightRows = 0;

    bottomTable = 0;
    rightTable = 0;

    actIndexData = -1;
    actIndexRow = -1;
    actIndexColumn = -1;
    actScrolled = 0;
    actIndexRowSelected = -1;

    maxIndexRows = -1;

    refButtons = [];
    tableFocusedMouseDown = false;

    coItalic = false;  //short term setting when calling gettext

    minusWIDTH = 0;

    main = false;// main DB - icludes list of DB js objects
    propDB = false;// - should get property from db on RowSel


    //not explicite----------------------------------
    //typeTable = 0;   //0 - array of simple data (2 or more columns), 1- array of variant data (3 columns), 2 - list add/copy/remove, 3 - 2D array   
    //svgTable;//: any;
    //edit;//: EditorInput; 
    //widthColumns;// = []; 
    //rightColumns;// = []; 
    //bottomRows;// = [];  
    //RectRowSelected;//?: React.RefObject<HTMLDivElement>; 
    //scroll;//: TableScroll;
    //refRectSelected;//: any;
    //refRectTableSel  whole tableselected
    //jsArg;

    //form - set before createObj()
    //FE
    //bt [] of buttons
    //inr - global from FormEdit at adding -> FormBase.addEdt(o)
    //jsDBprops, jsDBget - for exchange when props is getting from server
    //idDBGS - to get set from/into DB
    //pT - parent table, used for DB so far


    //___________________________________________________________________________________
    constructor(idNr, typeTable, oJson, ...args) //0 - array of simple data, 1- list variant, 2 - list add/copy/remove  , 3 - 2D array //(idNr: number, typeTable: number, oJson: any)
    {
        this.idNr = idNr;
        this.noBT = false;//no buttons for typeTable 2
        this.ro = false;
        this.oJson = oJson;

        this.typeTable = typeTable;
        this.edit = FormEdit.FEs.editT;

        this.scroll = new TableScroll(this.idNr);
        this.scroll.parentTable = this;

        this.buttonClicked = this.buttonClicked.bind(this);
        //this.buttonNewClicked = this.buttonNewClicked.bind(this);
        //this.buttonDeleteClicked = this.buttonDeleteClicked.bind(this);
        //this.buttonCopyClicked = this.buttonCopyClicked.bind(this);
        //this.bGetDB = this.bGetDB.bind(this);
        //this.bSetDB = this.bSetDB.bind(this);
        this.getDBData = this.getDBData.bind(this);
        this.gotPropsFromDB = this.gotPropsFromDB.bind(this);
        this.getNewObject = this.getNewObject.bind(this);

        let L = args.length;
        if (L > 0) {
            let js = args[0];
            this.jsArg = js;
            if (js.hasOwnProperty('def')) {
                let jsDef = app.def[js.def];
                if (jsDef) {  
                    if (jsDef.hasOwnProperty('mc')) this.minDataCount = jsDef.mc;
                    if (jsDef.hasOwnProperty('MC')) this.maxDataCount = jsDef.MC;
                    if (jsDef.hasOwnProperty('p')) {
                        let p = jsDef.p;
                        if (this.typeTable === 2) {
                            if (p.includes(this.PJ.progVer, 1)) {
                                let MC = jsDef.p[0];
                                this.maxDataCount = MC;
                                if (MC === 1) {
                                    this.minRows = 1;
                                    this.maxRows = 1;
                                    this.firsRowCanNotBeRemoved = true;
                                    this.bt = [];
                                    this.scroller = false;
                                }
                            }
                        }
                    }
                }
            }
            function h(k) { return js.hasOwnProperty(k); }

            if (h('main')) this.main = js.main;
            if (h('propDB')) this.propDB = js.propDB;

            if (h('t')) this.titleKey = js.t;
            if (h('top')) this.top = js.top;
            if (h('left')) this.left = js.left;
            if (h('wcs')) this.widthCols = js.wcs;
            if (h('hH')) this.heightHeading = js.hH;
            if (h('tc')) this.titleColsKeys = js.tc;
            if (h('k')) this.keys = js.k;
            if (h('ra')) this.rowDataArr = js.ra;
            if (h('sc')) this.scroller = js.sc;

            if (h('w')) this.width = js.w;
            if (h('MW')) this.maxWidth = js.MW;
            if (h('mw')) this.minWidth = js.mw;
            if (h('mr')) this.minRows = js.mr;
            if (h('MR')) this.maxRows = js.MR;
            if (h('phc')) this.partHeightContainer = js.phc;
            if (h('pwc')) this.partWidthContainer = js.pwc;
            if (h('fcn')) this.firstColNr = js.fcn;
            if (h('asd')) this.alwaysSelectedData = js.asd;

            if (h('noBT')) this.noBT = js.noBT;
            if (h('ro')) this.ro = js.ro; //all readonly
            if (h('rs')) this.RectSelected = js.rs;
            if (h('ntb')) this.NoTextBoolean = js.ntb;

            if (h('pT')) this.pT = js.pT;
        }


    }

    //____________________________________________________________________________________________________
    createObj() {
        this.createTable();
        this.setButtonsEnabled();
    }

    //____________________________________________________________________________________________________________________
    getActJson() {  
        return this.getJson(this.actIndexData,0,  1);
    }

    //____________________________________________________________________________________________________________________
    variantData(indexData, indexColumn) { //0 - not variant, 1 - value, 2 - selection
        let vd = 0;
        if (this.typeTable === 1) {
        if (indexColumn === 1) vd = 2;
        else if (indexColumn === 2) vd = 1;
        }
        return vd;
    }

    //___________________________________________________________________________________________________________________
    dataIsRawArray(indexData, indexColumn) {  
        let co = false;
        if (!this.colDataArr && this.rowDataArr.length > 1)
        co = this.rowDataArr[0] <= indexColumn && this.rowDataArr[1] >= indexColumn;
        return co;
    }

    

    //________________________________________________________________________________________
    setKifNe(js, key){  //set key if not exists
    if (app.utils.app.utils.oOK(js)){
      if (Array.isArray(js)) return;
      if (!js.hasOwnProperty(key)){
        let jsd = app.def[key];
        if (app.utils.app.utils.oOK(jsd)){
            if (jsd.hasOwnProperty('ar')){
                js[key] = [];
            }
            else if (jsd.hasOwnProperty['psel']){
              let iv = 0,
                  val = jsd.psel.sel[0];
              if (jsd.psel.ud){
                let o = jsd.psel.sel.find(e => e[0] === jsd.psel.ud);
                if (app.utils.oOK(o)) {
                  val = o[0];
                  iv = jsd.psel.sel.indexOf(o);
                }
              }
              js[key] = { sel: val, val: new Array(jsd.psel.sel.length), iV: iv };
            }
            else{
              let val = undefined;
              if (jsd.hasOwnProperty('t')){
                if (jsd.t === app.utils.dType.rowTitle) return;
                if (jsd.t === app.utils.dType.listChoice) {if (jsd.sel.length > 0) val = jsd.sel[0][0]; else val = -1}
                else if (jsd.t === app.utils.dType.text) val = "";
                else if (jsd.t === app.utils.dType.color) val = [255, 255, 255, 255];
                else if (jsd.t === app.utils.dType.boolean) val = false;
                else if (jsd.t === app.utils.dType.date || jsd.t === app.utils.dType.time || jsd.t === app.utils.dType.datetime) val = new Date();
              }
              js[key] = val;
            }
          }
      }
    } 
  }

  //___________________________________________________________________________________________________________________
  getMinMaxStep(indexData, indexColumn, indexEditor, MinMaxStep) {  
    //MinMaxStep = {"min": undefined, "max": undefined, "step": undefined};
    let sk = this.getKey(indexData, indexColumn,1);
    if (app.def.hasOwnProperty(sk)) {
      let js = app.def[sk];
      if (js.hasOwnProperty("m")) MinMaxStep.min = js.min;
      if (js.hasOwnProperty("M")) MinMaxStep.max = js.max;
      //if (js.hasOwnProperty("st")) MinMaxStep.step = js.st;
    }
  };

  //___________________________________________________________________________________________________________________
  setValueEdit(indexData, indexColumn, indexEditor, value) {  //after input changed in editor//
    //for app.utils.dType.TextlistChoice should be overriden
    let oj = this.getJson(indexData, indexColumn,1),
        sk = this.getKey(indexData, indexColumn,1),
        vD = this.variantData(indexData, indexColumn),
        dt = this.getDataType(indexData, indexColumn,1);

    if (dt === app.utils.dType.number) value = this.FE.convertValue(true, sk, indexData, indexColumn, indexEditor, value); //value = convertIfNecessary(true, sk, value);

    if (this.form.coDB){
      if (this.main) oj.NC = true; 
      else {
        if (this.pT) this.pT.getActJson().PropsC = true;  
      }
    }

    if (dt === app.utils.dType.TextlistChoice){
      if (indexEditor > 0) return; //must be overriden
    }

    if (vD > 0) {
      
      if (vD === 1) {
        let i = oj[sk]["iV"];
        oj[sk]["val"][i] = value;
      } 
      else if (vD === 2) {
        let ival = parseInt(value);
        oj[sk]["sel"] = ival;
        let lc = this.getListChoice(indexData, indexColumn,1),
            iV = 0;
        for (let i = 0; i < lc.length; i++) {
          if (lc[i][0] === ival) iV = i;
        }
        oj[sk]["iV"] = iV;
      }
    } 
    else if (dt === app.utils.dType.date) {
      let js = oj[sk],
        date = new Date(value);
      js[0] = date.getFullYear();
      js[1] = date.getMonth() + 1;
      js[2] = date.getDate();
      //js[3] = 0;
      //js[4] = 0;
      //js[5] = 0;
    } 
    else if (dt === app.utils.dType.time) {
      let js = oj[sk],
        arr = value.toString().split(":");
      js[3] = parseInt(arr[0]);
      js[4] = parseInt(arr[1]);
      js[5] = 0;
    } 
    else if (dt === app.utils.dType.datetime) {
      let js = oj[sk],
        date = new Date(value);
      js[0] = date.getFullYear();
      js[1] = date.getMonth() + 1;
      js[2] = date.getDate();
      js[3] = date.getHours();
      js[4] = date.getMinutes();
      js[5] = 0;
    } 
    else if (dt === app.utils.dType.color) {
      let js = oj[sk];
      js[0] = parseInt(value.substr(1, 2), 16);
      js[1] = parseInt(value.substr(3, 2), 16);
      js[2] = parseInt(value.substr(5, 2), 16);
      js[3] = 255;
    } 
    else if (dt === app.utils.dType.listChoice) {
      let dtt = app.utils.dType.number;
      if (app.def[sk].hasOwnProperty("tid")) dtt = app.def[sk].tid;
      if (dtt === app.utils.dType.number) oj[sk] = parseInt(value);
      else oj[sk] = value;
    }
    else if (this.dataIsRawArray(indexData, indexColumn)) {
      if (!(oj instanceof Array)) oj = oj[sk];
      oj[indexColumn - this.rowDataArr[0]] = value;
    } 
    else if (this.colDataArr) {
      if (!Array.isArray(oj)) oj = oj[sk];
      oj[indexData] = value;
    } 
    else oj[sk] = value; 

    if (this.typeTable === 1 && indexColumn === 1) this.updateAll();

    this.InputChanged(oj, indexData, indexColumn, sk, dt, vD, value,1);
  }

  //____________________________________________________________________________________________________________________
  isReadOnlyKeyDef(key) {
    let ro = false,
      o = app.def[key];
    if (o) {
      if (o.hasOwnProperty("o")) {
        ro = o.o.indexOf("+ro") !== -1;
      }
      else if (o.hasOwnProperty('p')) { 
        let p = o.p;
        if (p.includes(app.projectJSON.progVer, 1)) ro = true;
      }
    }
    return ro;
  }

  //___________________________________________________________________________________________________________________
  getKeyDef(indexData, indexColumn){  
        let k = this.getKey(indexData, indexColumn,1); 
        if (k === undefined) return "undef";
        if (app.def[k].hasOwnProperty("kd")) return app.def[k].kd;
        else return k;
    }

  //---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  getTextColor(indexData, indexColumn, own) { //own is optional, in form not used = always undefined !!!
    if (own && this.form.getTextColor)  return this.form.getTextColor(this, indexData, indexColumn); //overrriding
    else{  
        return app.utils.settings.Table.colorText;
    }
  }

  //____________________________________________________________________________________________________________________
  RowSelected(own) {//own is optional, in form not used = always undefined !!!
    if (own && this.form.RowSelected)  return this.form.RowSelected(this); //overrriding
    else{  
        return null;
    }
  } 

  //____________________________________________________________________________________________________________________
  RowDeleted(arDel,own) { //own is optional, in form not used = always undefined !!! //should be overriden arDel - array deleted
    if (own && this.form.RowDeleted)  return this.form.RowDeleted(this,arDel); //overrriding, arDel - array deleted
    else{  
        return null;
    }
  }

  //____________________________________________________________________________________________________________________
  getNewObject(d,own) {  //own is optional, in form not used = always undefined !!!
    if (own && this.form.getNewObject)  return this.form.getNewObject(this, d); //overrriding
    else return null;
  }

  //____________________________________________________________________________________________________________________
  InputChanged(ojson, indexData, indexColumn, key, dt, variantData, value, own ){  //own is optional, in form not used = always undefined !!!
    if (own && this.form.InputChanged)  return this.form.InputChanged(this,ojson, indexData, indexColumn, key, dt, variantData, value); //overrriding
    else{  //nothing so far
    }
  } 

  //___________________________________________________________________________________
  inputChangedAfter(indexData, indexColumn,  indexEditor, own){ 
    if (own && this.form.inputChangedAfter)  return this.form.inputChangedAfter(this, indexData, indexColumn,  indexEditor); //overrriding
    else{  //nothing so far
    }   
  }

    //___________________________________________________________________________________________________________________
    getKey(indexData, indexColumn, own) {  //own is optional, in form not used = always undefined !!!
        if (own && this.form.getKey)  return this.form.getKey(this, indexData, indexColumn); //overrriding
        else{
            if (indexData < 0 || indexColumn < 0) return "undef";

            if (this.typeTable === 3) {
            return this.keys[indexData][indexColumn];
            } 
            else {
                if (this.typeTable === 2) {
                if (this.oJson) {
                    if (this.oJson.length > indexData) {
                        let d = this.oJson[indexData];
                        if (typeof d === "string") return d;
                    }
                }
            }
            var ik = this.typeTable < 2 ? indexData : indexColumn;
            if (ik < this.keys.length) return this.keys[ik];
            else return "undef";
            }
        }
    }


    //___________________________________________________________________________________
    getDataType(indexData, indexColumn, own) { //own is optional, in form not used = always undefined !!!
        if (own && this.form.getDataType)  return this.form.getDataType(this, indexData, indexColumn); //overrriding
        else{
            let dt = app.utils.dType.text,
            vD = this.variantData(indexData, indexColumn);
                if (vD > 0) {
                if (vD === 1) return app.utils.dType.number;
                else if (vD === 2) return app.utils.dType.listChoice;
            }

            if (indexColumn === 0) {
                if (this.firstColNr) return dt;
                else if (this.typeTable < 2) {
                    let ddtt1 = app.def[this.getKeyDef(indexData, indexColumn)]["t"];
                    if (ddtt1 && (ddtt1 === app.utils.dType.rowTitle || ddtt1 === app.utils.dType.textInfo)) return ddtt1;
                    else return dt;
                }
            }
            let jsdt = app.def[this.getKeyDef(indexData, indexColumn)];  
            if (jsdt) {
            let ddtt = jsdt["t"];
            if (ddtt) dt = ddtt;
            }
            return dt;
        }
    }

    //___________________________________________________________________________________________________________________
    getJson(indexData, indexColumn,own) { //own is optional, in form not used = always undefined !!!
        if (own && this.form.getJson)  return this.form.getJson(this, indexData, indexColumn); //overrriding
        else{
            if (this.typeTable < 2 || this.typeTable === 3) return this.oJson;
            else if (this.colDataArr) return this.oJson;
            else { //1 d array
                if (this.oJson) {
                    if (this.oJson.length > indexData && this.oJson.length > 0)   return this.oJson[indexData];     //array
                else return undefined;
                } 
                else return undefined;
            }
        }
    }

    
    //___________________________________________________________________________________________________________________
    getValueEdit(indexData, indexColumn, indexEditor, own) {//own is optional, in form not used = always undefined !!!, for app.utils.dType.TextlistChoice should be overriden
        if (own && this.form.getValueEdit)  return this.form.getValueEdit(this, indexData, indexColumn, indexEditor); //overrriding
        else{
            if (indexData < 0) return undefined;

            let oj = this.getJson(indexData, indexColumn,1),
                sk = this.getKey(indexData, indexColumn,1),
                vD = this.variantData(indexData, indexColumn),
                dt = this.getDataType(indexData, indexColumn,1),
                val;

            if (vD > 0) {
                if (vD === 1) {
                    let i = oj[sk]["iV"];
                    val = oj[sk]["val"][i];
                } 
                else if (vD === 2) return oj[sk]["sel"];  //to avoid conversion
            }
            else if (this.dataIsRawArray(indexData, indexColumn)) {
                if (!Array.isArray(oj)) oj = oj[sk];
            val = oj[indexColumn - this.rowDataArr[0]];
            } 
            else if (this.colDataArr) {
                if (!Array.isArray(oj)) oj = oj[sk];
                val = oj[indexData];
            } 
            else val =  oj[sk];

            if (dt === app.utils.dType.number){
            val = this.FE.convertValue(false, sk, indexData, indexColumn, indexEditor, val); //convertIfNecessary(false, sk, oj[sk]);
            }
            else if (dt === app.utils.dType.date) {
                val = app.utils.dateString(oj[sk], dt);
            } 
            else if (dt === app.utils.dType.time) {
                val =  app.utils.dateString(oj[sk], dt);
            } 
            else if (dt === app.utils.dType.datetime) {
                val = app.utils.dateString(oj[sk], dt);
            } 
            else if (dt === app.utils.dType.color) {
                val = app.utils.ColorRGBToHex(oj[sk]);
            }
            return val;
        }
    }

    //___________________________________________________________________________________
    getText(indexData, indexColumn, own) { //own is optional, in form not used = always undefined !!!
        let sk = this.getKey(indexData, indexColumn,1);
        if (own && this.form.getText)  return this.form.getText(this, indexData, indexColumn, sk); //overrriding
        else{
            this.coItalic = false;
            if (indexData >= this.getCountData(1)) return "";
            if (this.firstColNr && indexColumn === 0) return (indexData + 1).toString();

            let dt = this.getDataType(indexData, indexColumn,1),
                vD = this.variantData(indexData, indexColumn),
                oj = this.getJson(indexData, indexColumn,1),
                coRowTitle = dt === app.utils.dType.rowTitle,
                s = "";

            this.setKifNe(oj, sk);

            if (indexColumn === 0) {
                if (this.typeTable < 2 || coRowTitle) {
                    let def = app.def[sk];
                    if (def) {
                        s = def["n"];
                        if (dt === app.utils.dType.rowTitle){
                        if (def.hasOwnProperty('u')) s += " " + app.utils.sUnit(def["u"], true);
                        }
                        this.coItalic = this.isOptional(oj, indexData,  indexColumn, sk, dt,  vD,1);//console.log("was here",t.coItalic);
                        return s;
                    } 
                    else return "";
                }
            } 
            else if (coRowTitle) return "";

            if (dt === app.utils.dType.textInfo) return app.def[sk]["v"];
            let val;

            if (oj) {
            if (vD > 0) {
                if (vD > 0) {
                if (vD === 1) {
                    let i = oj[sk]["iV"];
                    if (oj[sk]["val"][i]){
                    val = oj[sk]["val"][i];
                     //if (dt === app.utils.dType.number) val = this.xconvertValue(false, t, sk, indexData, indexColumn, 0, val); //convertIfNecessary(false, sk, val);
                    //return val.toString();
                    }
                    else return "";
                } 
                else if (vD === 2) {
                    let v = oj[sk]["sel"];
                    let lc = this.getListChoice(indexData, indexColumn,1);
                    if (lc) {
                    let li = lc.find((el) => {  return el[0] === v; });
                    if (li) return li[1];
                    else return "";
                    }
                }
                }
            }
            else val = this.getValue(sk, indexData, indexColumn,1);//undefined;

            if (dt === app.utils.dType.number) {
                val = this.FE.convertValue(false, sk, indexData, indexColumn, 0, val);//convertIfNecessary(false, sk, val);   
            }

            /*???if (this.dataIsRawArray(t, indexData, indexColumn)) {
                if (!(oj instanceof Array)) oj = oj[sk];
                val = oj[indexColumn - t.rowDataArr[0]];
            } 
            else if (t.colDataArr) {
                if (!Array.isArray(oj)) oj = oj[sk];
                val = oj[indexData];
            } 
            else val = oj[sk];*/

            if (!app.utils.oOK(val)) return "";

            if (app.utils.oOK(val)) {
                if (dt === app.utils.dType.number) {
                if (isNaN(val)) s = "";
                else s = val.toString();
                } 
                else if (dt === app.utils.dType.listChoice) {
                let lc = this.getListChoice(indexData, indexColumn,1);
                if (lc) {
                    let li = lc.find((el) => {
                    return el[0] === val;
                    });  
                    if (li) s = li[1];  
                }
                } 
                else if (dt === app.utils.dType.boolean) {
                if (val === true) s = "x";
                else s = "";
                if (this.NoTextBoolean) s = "";
                } 
                else if (dt === app.utils.dType.date) {
                let js = val,
                    date = new Date(js[0], js[1] - 1, js[2]);
                s = date.toLocaleDateString();
                } 
                else if (dt === app.utils.dType.time) {
                let js = val,
                    date = new Date(js[0], js[1] - 1, js[2], js[3], js[4], 0);
                return date.toLocaleTimeString();
                } 
                else if (dt === app.utils.dType.datetime) {
                let js = val,
                    date = new Date(js[0], js[1] - 1, js[2], js[3], js[4], 0);
                s = date.toLocaleString();
                } 
                else if (dt === app.utils.dType.color) {
                s = app.utils.ColorRGBToHex(val);
                } 
                else s = val;
            } 
            else s = val;
            }

            return s;
        }  
    } 
    
    //____________________________________________________________________________________________________________________
    getListChoice(indexData, indexColumn,own) {//own is optional, in form not used = always undefined !!!
        let sk = this.getKey(indexData, indexColumn,1);
        if (own && this.form.getListChoice)  return this.form.getListChoice(this, indexData, indexColumn,sk); //overrriding
        else{
            let vD = this.variantData(indexData, indexColumn),
                ll;
            if (vD === 2) ll = app.def[sk]["psel"]["sel"];
            else ll = app.def[sk]["sel"];
            if (app.def[sk].hasOwnProperty('p')){
                let l = app.def[sk]['p'];
                ll = ll.filter(e => !(l.find(ee => ee[0] === e[0] && ee.includes(app.projectJSON.progVer, 2))));
            }
            return ll;
        }
    }

    //____________________________________________________________________________________________________________________
    getCountData(own) {//own is optional, in form not used = always undefined !!!
        if (own && this.form.getCountData)  return this.form.getCountData(this); //overrriding
        else{
            var L = 0;
            if (this.typeTable < 2) {
                L = this.keys.length;
            } 
            else if (this.typeTable === 2) {
                if (this.oJson) L = this.oJson.length;
            } 
            else if (this.typeTable === 3) {
                L = this.keys.length; //array of array
            }
            return L;
        }
    }

    //____________________________________________________________________________________________________________________
    isOptional(ojson, indexData, indexColumn, key, dt, variantData, own) { //own is optional, in form not used = always undefined !!!, //for app.utils.dType.TextlistChoice should be overriden
        if (own && this.form.isOptional)  return this.form.isOptional(this, ojson, indexData, indexColumn, key, dt, variantData); //overrriding
        else{
            let opt = false,
                o = app.def[key];
            if (o) {
                if (o.hasOwnProperty("o")) {
                    let s = o.o,
                    L = s.length;
                    if (L > 1) {
                        if (s.substr(L - 2, 2) === "+o") opt = true;
                        //last two digits
                        else if (s.indexOf("+o+") >= 0) opt = true;
                        // else {
                        //     if (s.includes("+oW")) opt = scopePlus();
                        //     if (s.includes("+oP")) opt = scopeMETR();
                        // }
                    }
                }
            }
            return opt;
        }
    }

    //___________________________________________________________________________________
    getValue(skey, indexData, indexColumn,own) { //own is optional, in form not used = always undefined !!!
        if (own && this.form.getValue)  return this.form.getValue(this, skey, indexData, indexColumn); //overrriding
        else{
            var val = undefined,
            vD = this.variantData(indexData, indexColumn),
            oj = this.getJson(indexData, indexColumn,1);

            if (!app.utils.oOK(oj)) return undefined;

            if (app.utils.oOK(skey)) {
                if (vD > 0) {
                    if (vD === 1) {
                        let i = oj[skey]["iV"];
                        val = oj[skey]["val"][i];
                    } 
                    else if (vD === 2) val = oj[skey]["iV"];
                } 
                else {
                    if (this.dataIsRawArray(indexData, indexColumn)) {
                        if (!(oj instanceof Array)) oj = oj[skey];
                        val = oj[indexColumn - this.rowDataArr[0]];
                    } 
                    else if (this.colDataArr) {
                        if (!Array.isArray(oj)) oj = oj[skey];  
                        return oj[indexData];
                    } 
                    else val = oj[skey];
                }
            } 
            else if (app.utils.dOK(indexData, indexColumn)) {
                let sk = this.getKey(indexData, indexColumn,1);
                if (vD > 0) {
                    if (vD === 1) {
                        //let i = oj[sk]["iV"];
                        val = oj[sk]["sel"];
                    } 
                    else if (vD === 2) val = oj[sk]["iV"];
                } 
                else {
                    if (this.dataIsRawArray(indexData, indexColumn)) {
                        if (!(oj instanceof Array)) oj = oj[sk];
                        val = oj[indexColumn - this.rowDataArr[0]];  
                    } 
                    else if (this.colDataArr) {
                        if (!Array.isArray(oj)) oj = oj[skey];    
                        return oj[indexData];  
                    } 
                    else val = oj[sk];
                }
            }
            return val;
        }
    }

    //___________________________________________________________________________________
    getTextUnit(indexData, indexColumn, own) {//own is optional, in form not used = always undefined !!!
        if (own && this.form.getTextUnit)  return this.form.getTextUnit(this, indexData, indexColumn, this.getKey(indexData, indexColumn,1)); //overrriding
        else{  
            if (this.firstColNr && indexColumn === 0) return "";

            let sk = app.utils.getKkd(this.getKey(indexData, indexColumn,1)),
            su = "",
            ukey = app.def[sk]["u"];
            if (ukey) su = app.utils.sUnit(ukey, true);
            return su;
        } 
    }

    //___________________________________________________________________________________
    isReadOnly(indexData, indexColumn, indexEditor, own) {//own is optional, in form not used = always undefined !!!
        if (this.form.FE.props.isCalc) return true;
        if (this.form.coDBget) return true;
        if (this.ro) return this.ro;

        let sk = this.getKey(indexData, indexColumn,1);
        if (own && this.form.isReadOnly)  return this.form.isReadOnly(this, indexData, indexColumn, indexEditor, sk); //overrriding
        else{  
            let nro = true;
            if (indexColumn === 0) {
                if (this.firstColNr || this.typeTable < 2) nro = false;
            }
            if (nro) {
                let dt = this.getDataType(indexData, indexColumn,1);
                if (dt === app.utils.dType.rowTitle || dt === app.utils.dType.textInfo) nro = false;
                }
            if (nro) {
                if (this.isReadOnlyKeyDef(sk))
                nro = false;
            }
            if (nro) {
                if (this.typeTable === 1) {
                    if (indexColumn === 2) {
                        if (!app.utils.isUserDef(this.getJson(indexData, indexColumn,1), sk))  nro = false;
                    }
                }
            }
            return !nro;
        } 
    }

    // //___________________________________________________________________________________
    // AfterGotDBdata(own){//own is optional, in form not used = always undefined !!!
    //     if (own && this.form.AfterGotDBdata)  return this.form.AfterGotDBdata(this); //overrriding
    //     else{ 
    //     }
    // }

    //____________________________________________________________________________________________________________________
    AfterGotPropsDB(own) {//own is optional, in form not used = always undefined !!!
        if (own && this.form.AfterGotPropsDB)  return this.form.AfterGotPropsDB(this); //overrriding
        else{ 
        }
    };

    //___________________________________________________________________________________
    getDBData(jsDBdata,own) {//own is optional, in form not used = always undefined !!!
        if (own && this.form.getDBData) return this.form.getDBData(this,jsDBdata); //overrriding
        else{ 
            this.jsDBget.gotDB = true;
            if (this.actIndexData > -1 && this.oJson.length > this.actIndexData && this.oJson.length > 0) {
                Object.assign(this.jsDBget, jsDBdata); 
                //this.updateAll();
                
                this.updateActRow();
                this.RowSelected(1);
                setTimeout(() =>this.reFocus());
            }
            //this.cTimer();
        }

        if (this.form.coDB){
            if (this.pT) this.pT.getActJson().PropsC = true;  
        }
    }

    //____________________________________________________________________________________________________________________
    btClicked(btn, own) { //own is optional
        if (own && this.form.btClicked)  return this.form.btClicked(this,btn); //overrriding
        else{
            if (btn === 0) { 
                //add--------------------------------------------------
                let oj = this.oJson,
                    d = { tvt: app.utils.treeVType.notDef, coPM: false, coExp: false },
                    js = this.getNewObject(d,1);

                if (this.cTV){
                    if (d.tvt !== app.utils.treeVType.notDef) {
                        if (this.FE.coMain) {
                            this.FE.TV.selP.coExpanded = true;   this.FE.TV.rearrangeNodes();
                            this.FE.TV.appendNewNode(d.coPM, d.coExp, d.tvt, js, true);
                        }
                    }
                } 

                if (this.FE.coDB && this.main){  
                    if (this.FE.TV && this.FE.TV.selP){
                        ObjTreeNode.idOsel = js.id;
                        //this.TV.pO.AddRemoveIdCODB(false, js.id);  
                        let parent0 = this.FE.TV.selP;
                        parent0.AddRemoveIdCODB(true, js.id);        
                        if (parent0.oJSON.id !== -1) this.form.lOdb.push(js); 

                        while (parent0.parentO && parent0.parentO.oJSON.id !== -1) {
					        parent0 = parent0.parentO;
					        parent0.AddRemoveIdCODB(true, js.id);//parent0.oJSON.co.push(ObjTreeNode.idOsel);
				        };
                        this.FE.TV.pO.CheckIconDB();
                    }
                }

                oj.push(js);
                let irow = 0;

                this.scroll.scrollMax = Math.max(oj.length - this.maxIndexRows - 1, 0);
                this.scroll.scrolled = 0;

                if (this.actIndexColumn < 0) this.actIndexColumn = 0;
                irow = oj.length - 1;
                if (irow > this.maxIndexRows) {
                    irow = this.maxIndexRows;
                    this.scroll.scrolled = Math.max(0, oj.length - this.maxIndexRows - 1);
                }
                this.scroll.adjustToScrolled();
                this.updateAll();
                this.findEditAbleAndSetEdit(irow, this.actIndexColumn, true);
            } 
            else if (btn === 1) {
                //delete----------------------------------------------
                let oj = this.oJson;  
                if (oj.length === 0 || this.actIndexRow < 0 || this.actIndexData < 0) return;

                if (this.actIndexColumn < 0) this.actIndexColumn = 0;

                let i = this.actIndexData,
                irow = this.actIndexRow;

                 let js = oj[i];
                if (js) {    
                    //check if it has a TV node

                    if (this.form.coDB) {
                        if (this.main) {
                            this.FE.DBres.IdDelOs.push(js.id);
                            this.form.lOdb.splice(this.form.lOdb.indexOf(js),1);
                        }  
                        else {
                            if (this.pT) {
                                let jsM = this.pT.getActJson();
                                jsM.PropsC = true;
                                if (this.form.lOdb.indexOf(jsM) < 0) this.form.lOdb.push(jsM); 
                            }
                        };
          
                    }
                    else if (this.cTV){
                        this.FE.TV.findOTNjs(js, this.FE.TV.pO);
                        if (this.FE.TV.pOTN) {
                            this.FE.TV.removeAllNodesRef(this.FE.TV.pOTN);

                            this.FE.TV.selP.children.splice(i, 1);

                            this.FE.TV.selP.renumChildren();  this.FE.TV.actualizeWidth();
                            this.FE.TV.actualizeNodes();
                            this.FE.TV.actualizaNames();
                        }
                    }
                }

                let arDel = oj.splice(i, 1);
                this.scroll.scrollMax = Math.max(oj.length - this.maxIndexRows - 1, 0);

                if (this.scroll.scrolled > 0) {
                    this.scroll.scrolled--;
                } 
                else {
                    irow--;
                    if (irow < 0 && oj.length > 0) irow = 0;
                }
                this.scroll.adjustToScrolled();
                this.edit.hide();
                this.updateAll();
                if (oj.length === 0) {
                    this.setRectSelected(-1);
                    this.scroll.scrollMax = 0;
                    this.scroll.scrolled = 0;
                    this.actIndexRow = -1;
                    this.actScrolled = 0;
                    this.actIndexData = -1;
                } 
                else {
                    this.actIndexData = -1; //to force select row
                    this.findEditAbleAndSetEdit(irow, this.actIndexColumn, true);
                }
                this.RowDeleted(arDel,1);
            }
            if (btn === 0 || btn === 1) {
                let oj = this.getJson(this.actIndexData, this.actIndexColumn,1),
                sk = this.getKey(this.actIndexData, this.actIndexColumn,1),
                vD = this.variantData(this.actIndexData, this.actIndexColumn),
                dt = this.getDataType(this.actIndexData, this.actIndexColumn,1),
                indexEditor = 0,
                value = this.getValueEdit(this.actIndexData, this.actIndexColumn, indexEditor,1);
                this.InputChanged(oj,  this.actIndexData,  this.actIndexColumn, sk,  dt, vD,  value,1);
                this.inputChangedAfter(this.actIndexData, this.actIndexColumn,0,1);

                if (this.form.coDB){ 
                    if (this.pT){
                        this.pT.getActJson().PropsC = true;  
                         console.log("xcxcxcxcxcx",this.pT.getActJson())
                    }
                }
            }
        }
    }

    //____________________________________________________________________________________________________________________
    isButtonDisabled(nrBT, actIndex, countData, own) {  //own is optional, in form not used = always undefined !!!
        if (own && this.form.isButtonDisabled)  return this.form.isButtonDisabled(this, nrBT, actIndex, countData); //overrriding
        else{
            let coDisabled = false;
            if (nrBT === 0) {  //plus
                coDisabled = countData >= this.maxDataCount && this.maxDataCount > 0;
            } 
            else if (nrBT === 1) { //minus
                coDisabled = countData <= this.minDataCount;
                if (this.firstRowImmutable || this.firsRowCanNotBeRemoved) {
                    if (actIndex === 0 || countData === 1) coDisabled = true;
                } 
            }
            else coDisabled = (countData === 0);
            return coDisabled;
        }
    }

    //____________________________________________________________________________________________________________________
    setBt(own) { //own is optional, in form not used = always undefined !!!
        if (own && this.form.setBt)  return this.form.setBt(this); //overrriding
        else{
            let bt = [];
            if (!this.FE.coDBget){
                if (this.typeTable === 2){
                    bt.push(0,1);
                }
                if (this.coDB){
                    if (this.idDBGS) bt.push(2);
                }
                else {
                    if (this.idDBGS) {
                     bt.push(2);
                        if (this.main) bt.push(3);
                    }
                }
            }
            return bt;
        }
    }

    
    

    // //___________________________________________________________________________________________________________________
    // setValueEdit(indexData, indexColumn, indexEditor, value) { //own is optional, in form not used = always undefined !!!
    //     this.form.setValueEdit(this, indexData, indexColumn, indexEditor, value);
    // }

    //=========================================================================================================================================================================================================================================

    //___________________________________________________________________________________
   setButtonsEnabled() {
        if (this.typeTable === 2 && this.bt.length > 0) {
            let countData = this.getCountData();
            this.refButtons.forEach(rb => {
                rb.disabled = this.isButtonDisabled(rb.nrBT, this.actIndexData, countData,1);
                this.setIconBT(rb);
            });
        }
    }

    //____________________________________________________________________________________________________________________
    saveToDB(){
        let js = this.getActJson();
        if (js){
            if (app.utils.nameOK(js.n)){
                app.DBGeneric("Gen/AddCO",  {CategoryId: this.idDBGS, OwnerId:app.OwnerId, Lan: app.Lan, PropsJS: js}, null); 
            }
            else app.utils.messageBox(0, "s_nOK");
        }
    }

    //___________________________________________________________________________________________________________________
    // getMinMaxStep(indexData, indexColumn, indexEditor, MinMaxStep) {  //only for numbers  //indexData: number, indexColumn: number, indexEditor: number,  MinMaxStep: any
    //     return this.form.getMinMaxStep(this, indexData, indexColumn, indexEditor, MinMaxStep);
    // }

    //___________________________________________________________________________________
    isTitle(irow, icol) {
        return (this.getDataType(irow + this.scroll.scrolled, icol, 1) === app.utils.dType.rowTitle)
    }

    //___________________________________________________________________________________
    getCursor(irow, icol) {
        let sCursor = 'default';
        if (this.typeTable === 0) {
            //if (icol > 0) sCursor = 'alias';
        }
        return sCursor;
    }

    //___________________________________________________________________________________
    getIDRowCol(irow, icol, stype)  //stype R - rectangle, T - text //irow: number, icol: number, stype: string
    {
        return this.form.idKey + 't' + this.idNr.toString() + "r" + irow.toString() + "c" + icol.toString() + stype;
    }

    //___________________________________________________________________________________
    ID(st) {
        return this.form.idKey + 't' + this.idNr.toString() + st;
    }

    //___________________________________________________________________________________
    IDnr(st, nr) {
        return this.ID(st) + nr;
    }

    //___________________________________________________________________________________
    isColor(irow, icol) {
        var dt = this.getDataType(irow + this.scroll.scrolled, icol,1);
        return (dt === app.utils.dType.color);
    }

    //___________________________________________________________________________________
    addRectRowSelected() {
        this.refRectSelected = createElsvg('rect',
            'x', this.leftRows + 1,
            'y', -20 * this.heightRow,
            'width', this.widthAllColumns - 3.5,
            'height', this.heightRow + 1,
            'stroke', app.utils.settings.Table.colorRectSel,
            'stroke-width', 2,
            'fill', 'none',
            'id', this.ID('selcol'),
            'key', 'selcol' + this.inr,
            "shape-rendering", "crispEdges");
        this.svgTable.appendChild(this.refRectSelected);
    }

    //___________________________________________________________________________________
    addRectRowCol(irow, icol, colorFill, colorLine, width)//irow: number, icol: number, colorFill: string, colorLine: string, width: number
    {
        this.svgTable.appendChild(createElsvg('rect',
            'x', this.rightColumns[icol] - this.widthColumns[icol],
            'y', this.bottomRows[irow] - this.heightRow,
            'width', width,
            'height', this.heightRow,
            "table", this.idNr,
            'fill', colorFill,
            'stroke', colorLine,
            'stroke-width', '1px',
            'row', irow,
            'col', icol,
            'id', this.getIDRowCol(irow, icol, "R"),
            'key', this.getIDRowCol(irow, icol, "R"),
            "shape-rendering", "crispEdges"
        ))
    }

    //___________________________________________________________________________________
    updateRectRowCol(irow, icol, colorFill, colorLine, width) //irow: number, icol: number, colorFill: string, colorLine: string, width: number
    {
        let ref = document.getElementById(this.getIDRowCol(irow, icol, "R"));
        if (ref) {
            ref.setAttributeNS(null, 'fill', colorFill);
            ref.setAttributeNS(null, 'stroke', colorLine);
            ref.setAttributeNS(null, 'width', width.toString());
        }
    }

    //___________________________________________________________________________________
    addTextRowColDx(irow, icol, s, alignH, scolor, width, dx) //irow: number, icol: number, s: string, alignH: number, scolor: string, width: number
    {
        var sTextAlignH = ['start', 'middle', 'end'],
            x = this.rightColumns[icol] - this.widthColumns[icol] + 3 + dx,
            y = this.bottomRows[irow] - Math.round(0.28 * this.heightRow), //check ??
            id = this.getIDRowCol(irow, icol, "T"),
            key = id;//this.getIDRowCol(irow, icol, "T");
        if (alignH === 1) {
            x = this.rightColumns[icol] - this.widthColumns[icol] / 2 + dx;
            y -= 2;
        }

        if (alignH === 2) {
            x = this.rightColumns[icol] - 3 + dx;
            id += 'u';
            key += 'u';
        }
        var el = this.svgTable.appendChild(createEltext(s,
            'x', x,
            'y', y,
            'width', width,
            'text-anchor', sTextAlignH[alignH],
            'text-overflow', 'ellipsis',
            'overflow', 'hidden',
            'white-space', 'nowrap',
            'font-size', app.utils.settings.Table.fontSize,
            //'- ms - text - overflow', 'ellipsis', causes error ?

            'cursor', this.getCursor(irow, icol),
            "table", this.idNr,
            'row', irow,
            'col', icol,
            'id', id,
            'key', key));
        el.style.fontFamily = 'Arial';
        if (this.coItalic) el.style.fontStyle = 'italic'; // console.log("draw",this.coItalic, icol,irow);
        else el.style.fontStyle = 'normal';

        el.style.fill = scolor;
        //el.style.fontSize = app.utils.settings.Table.fontSize;
    }

    //___________________________________________________________________________________
    setTick(irow, icol) //irow: number, icol: number, iTick - svg
    {
        let x = this.rightColumns[icol] - (this.widthColumns[icol] + app.utils.settings.Table.whTick) / 2,
            y = this.bottomRows[irow] - (this.heightRow + app.utils.settings.Table.whTick) / 2, //check ??
            id = this.getIDRowCol(irow, icol, "Tick"),
            key = id,
            iTick,
            val = this.getText(irow + this.scroll.scrolled, icol,1);

        if (val === "x") iTick = iCC; else iTick = iC;

        this.svgTable.appendChild(createElsvg('image',
            "href", iTick,
            'x', x,
            'y', y,
            'width', app.utils.settings.Table.whTick,
            'height', app.utils.settings.Table.whTick,
            "table", this.idNr,
            'row', irow,
            'col', icol,
            'id', id,
            'thick', true,
            'zIndex', 0,
            'key', key));

    }

    //___________________________________________________________________________________
    addTextRowCol(irow, icol, s, alignH, scolor, width) { //irow: number, icol: number, s: string, alignH: number, scolor: string, width: number
        this.addTextRowColDx(irow, icol, s, alignH, scolor, width, 0);
    }


    //___________________________________________________________________________________
    updateTextRowCol(irow, icol, s, alignH, scolor, width) //irow: number, icol: number, s: string, alignH: number, scolor: string, width: number
    {
        let id = this.getIDRowCol(irow, icol, "T"),
            ref;
        if (alignH === 2) id += "u";
        ref = document.getElementById(id);
        if (ref) {
            ref.textContent = s;
            ref.style.fill = scolor;
            ref.style.width = width.toString();

            ref.style.fontFamily = 'Arial';
            if (this.coItalic) ref.style.fontStyle = 'italic';  //console.log("draw",this.coItalic, icol,irow); 
            else ref.style.fontStyle = 'normal';
        }
    }

    //___________________________________________________________________________________
    addRowCol(irow, icol) {
        let colorFill = "white",
            colorLine = "white",
            colorText = this.getTextColor(irow, icol,1), //app.utils.settings.Table.colorText,
            width = this.widthColumns[icol],
            isTitle = false,
            lmr = 0,
            dw = 0,
            dt = this.getDataType(irow + this.scroll.scrolled, icol,1),
            co = (irow + this.scroll.scrolled) < this.getCountData(1);

        if (dt === app.utils.dType.boolean) lmr = 1;

        if (co) {
            if (this.isColor(irow, icol)) colorFill = this.getText(irow, icol,1);
            else if (this.isReadOnly(irow + this.scroll.scrolled, icol, 0,1)) {
                colorFill = app.utils.settings.Table.colorBKG;
            }
            if (dt === app.utils.dType.TextlistChoice) {
                if (!this.form.isReadOnly(this, irow + this.scroll.scrolled, icol, 1)) {
                    //colorFill = app.utils.settings.Table.colorBKG;
                    dw = app.utils.settings.Table.widthColSelCombi;
                    this.addTextRowColDx(irow, icol, "v", 1, app.utils.settings.Table.colorLines, dw, (width - dw) / 2);
                }
            }
            colorLine = app.utils.settings.Table.colorLines;
            isTitle = this.isTitle(irow, icol);
            if (isTitle) colorText = app.utils.settings.Table.colorTextRowTitle;
        }
        if (icol === 0 && isTitle) width = this.widthAllColumns - 1;
        else if (isTitle && icol > 0) width = 0;

        this.addRectRowCol(irow, icol, colorFill, colorLine, width - dw);

        if (dt === app.utils.dType.boolean) {
            if (co) this.setTick(irow, icol);
            return;
        }

        if (this.isColor(irow, icol)) return;
        this.addTextRowCol(irow, icol, this.getText(irow + this.scroll.scrolled, icol,1), lmr, colorText, width);
        if (this.typeTable < 2) {
            if (icol === 0 && dt !== app.utils.dType.rowTitle) {
                let s = this.getTextUnit(irow, icol);
                this.addTextRowCol(irow, icol, s, 2, colorText, width);
            }
        }
    }

    //___________________________________________________________________________________
    updateRowCol(irow, icol) {
        let countData = this.getCountData(1),
            colorFill = "white",
            colorLine = "white",
            colorText = this.getTextColor(irow + this.scroll.scrolled, icol,1),//app.utils.settings.Table.colorText,
            width = this.widthColumns[icol],
            isTitle = false,
            lmr = 0,
            dt = this.getDataType(irow + this.scroll.scrolled, icol,   1),
            ref = this.svgTable.getElementById(this.getIDRowCol(irow, icol, "Tick")),
            co = (irow + this.scroll.scrolled) < countData;

        //if (dt === app.utils.dType.text_listChoice) dt = app.utils.dType.text;   ?? 
        if (ref) this.svgTable.removeChild(ref);



        if (dt === app.utils.dType.boolean) lmr = 1;

        if (co) {
            if (this.isColor(irow, icol)) colorFill = this.getText(irow + this.scroll.scrolled, icol,1);
            else if (this.isReadOnly(irow + this.scroll.scrolled, icol, 0,1)) colorFill = app.utils.settings.Table.colorBKG;
            else colorFill = 'white';
            colorLine = app.utils.settings.Table.colorLines;
            isTitle = this.isTitle(irow, icol);
            if (isTitle) colorText = app.utils.settings.Table.colorTextTitle;
        }
        if (icol === 0 && isTitle) width = this.widthAllColumns - 1;
        else if (isTitle && icol > 0) width = 0;

        this.updateRectRowCol(irow, icol, colorFill, colorLine, width);

        if (dt === app.utils.dType.boolean) {

            if (co) this.setTick(irow, icol);
            return;
        }

        this.updateTextRowCol(irow, icol, this.getText(irow + this.scroll.scrolled, icol), lmr, colorText, width,1);
        if (this.typeTable < 2) {
            if (icol === 0) {
                let s = this.getTextUnit(irow, icol);
                this.updateTextRowCol(irow, icol, s, 2, colorText, width);
            }
        }
    }

    //___________________________________________________________________________________
    updateActRowCol(icol) {
        this.updateRowCol(this.actIndexRow, icol);
    }

    //___________________________________________________________________________________
    updateActRow() {
        if (this.actIndexRow > -1) {
            for (let icol = 0; icol < this.rightColumns.length; icol++) this.updateRowCol(this.actIndexRow, icol);
        }
    }

    //___________________________________________________________________________________
    updateTitle(title) {
        let refRect = this.svgTable.getElementById(this.ID('titleRect')),
            refText = this.svgTable.getElementById(this.ID('title')),
            textwidth = app.utils.textWidth(title, app.utils.settings.Table.fontSize);

        if (refRect && refText) {
            refRect.style.width = textwidth;
            refText.textContent = title;

            //refText.style.fontWeight = "900";
        }
    }

    /*/____________________________________________________________________________________________________
    makeUnselectable(){   //not working not select texts
        for (var irow = 0; irow < this.bottomRows.length; irow++) {
            for (var icol = 0; icol < this.rightColumns.length; icol++) {
                let id = this.getIDRowCol(irow, icol, "T"),
                    ref = document.getElementById(id);
                if (ref) ref.setAttribute("unselectable", "on");  
            }
        }
    }*/

    //____________________________________________________________________________________________________
    updateAll() {
        let countData = this.getCountData(1);
        for (var irow = 0; irow < this.bottomRows.length; irow++) {
            for (var icol = 0; icol < this.rightColumns.length; icol++) {
                this.updateRowCol(irow, icol);
            }
        }
        if (countData > 0) this.edit.updataData();
        //if (countData > 0) this.findEditAbleAndSetEdit(this.actIndexRow, this.actIndexColumn); error
    }

    //___________________________________________________________________________________
    createTitleClosingLine(title) {
        var s,//: string,
            dscr = -1,
            dwb = 0;
        if (this.typeTable === 2 && this.bt.length > 0) dwb = this.widthColumnButtons;

        if (this.scroller) dscr = this.widthScroll

        if (title.length > 0) {
            let textwidth = app.utils.textWidth(title, app.utils.settings.Table.fontSize);

            if (this.typeTable === 2) s = createPath('M', this.leftRows, this.yLineTitle + this.dhLineTitle,
                'v', -this.dhLineTitle,
                'h', this.rightRows + dscr + dwb,// - 1,
                'V', this.bottomAllRows,
                'H', this.leftRows,
                'V', this.topRows);

            else s = createPath('M', this.leftRows, this.yLineTitle + this.dhLineTitle, 'v', -this.dhLineTitle,
                'h', this.rightRows + dscr,
                'V', this.bottomAllRows,
                'H', this.leftRows,
                'V', this.topRows);

            this.svgTable.appendChild(createElsvg('path',
                "d", s,

                "fill", 'none',
                "stroke", app.utils.settings.Table.colorLines,
                "table", this.idNr,
                "shape-rendering", "crispEdges"
            ));


            this.svgTable.appendChild(createElsvg('rect',
                'x', this.leftRows + 4,
                'y', this.yLineTitle - 2,
                'width', textwidth + 3,
                'height', 4,
                "table", this.idNr,
                'fill', 'white',
                'id', this.ID('titleRect')
            ));

            let el = this.svgTable.appendChild(createEltext(title,
                'x', this.leftRows + 5,
                'y', this.yLineTitle + Math.round(0.5 * this.dhLineTitle),
                'text-anchor', 'start',
                'text-overflow', 'ellipsis',

                "padding", "0px 0px 0px 0px",
                //"margin", "0px",

                'width', this.rightRows + dscr - 2,
                "table", this.idNr,
                'cursor', 'default',
                'fill', app.utils.settings.Table.colorTextTitle,//,'gray',
                'id', this.ID('title')));
            el.style.fontFamily = 'Arial';
            el.style.fontSize = app.utils.settings.Table.sFontSize;
            el.style.fontWeight = "600";
            //el.syle.marginLeft = 0;
            //el.syle.marginTop = 0;
        }
        else {
            if (this.typeTable === 2) s = createPath('M', this.rightRows, this.topRows - this.heightHeading,
                'h', dscr + dwb,
                'V', this.bottomAllRows,
                'H', this.leftRows,
                'V', this.topRows);


            else s = createPath('M', this.rightRows, this.topRows - this.heightHeading,
                'h', this.widthScroll + 1,
                'V', this.bottomAllRows,
                'H', this.leftRows,
                'V', this.topRows);

            if (this.scroller) this.svgTable.appendChild(createElsvg('path',
                'd', s,
                'fill', 'none',
                'stroke', app.utils.settings.Table.colorLines,
                "table", this.idNr,
                "shape-rendering", "crispEdges",
                'id', this.ID('l')
            ));
        }
    }

    //___________________________________________________________________________________
    createHeading() {
        let L = Math.min(this.rightColumns.length, this.widthCols.length, this.titleColsKeys.length),
            sn,
            itop,//: number,
            itopm1,//: number,
            dy = 0,
            x = 0,
            y = 0,
            s,//: string,
            ss;//: string[];

        for (var icol = 0; icol < L; icol++) {

            this.svgTable.appendChild(createElsvg('rect',
                'x', this.rightColumns[icol] - this.widthColumns[icol],
                'y', this.topRows - this.heightHeading,
                'width', this.widthColumns[icol],
                'height', this.heightHeading,
                "table", this.idNr,
                'fill', app.utils.settings.Table.colorBKG,
                'stroke', app.utils.settings.Table.colorLines,
                'stroke-width', '1px',
                'key', this.getIDRowCol(-1, icol, "R"),
                'id', this.getIDRowCol(-1, icol, "R"),
                "shape-rendering", "crispEdges"
            ));

            sn = app.def[this.titleColsKeys[icol]]["n"];
            itop = 1;
            itopm1 = 0;
            dy = 0;
            x = this.rightColumns[icol] - this.widthColumns[icol] / 2;
            y = this.topRows - this.heightHeading / 2 + 0.4 * app.utils.settings.Table.fontSize;
            s = "";
            ss = [];

            if (typeof (sn) === "string") {
                s = sn;
                let iu = app.def[app.utils.getKkd(this.titleColsKeys[icol])]["u"];
                if (iu) s += " " + app.utils.sUnit(iu, true);
            }
            else {
                for (var j = 0; j < sn.length; j++) ss.push(sn[j]);
                let iu = app.def[app.utils.getKkd(this.titleColsKeys[icol])]["u"];
                if (iu) ss.push(app.utils.sUnit(iu, true));
                s = ss[0];
                itop = ss.length;
                itopm1 = itop - 1;
                dy = app.utils.settings.Table.fontSize + 3;
                y -= itopm1 * dy / 2;
                y -= 2;

            }

            for (var i = 0; i < itop; i++) {
                let el = this.svgTable.appendChild(createEltext(s,
                    'x', x,
                    'y', y,
                    'text-anchor', 'middle',
                    'text-overflow', 'ellipsis',
                    'width', this.widthColumns[icol],
                    "table", this.idNr,
                    'cursor', 'default',
                    'fill', 'gray',
                    'key', this.getIDRowCol(-1, icol, "T" + i.toString()),
                    'id', this.getIDRowCol(-1, icol, "T")));
                el.style.fontFamily = 'arial';
                el.style.fontSize = app.utils.settings.Table.sFontSize;

                y += dy;
                if (itop > 1 && i < itopm1) s = ss[i + 1];
            }

            let w = this.right - this.rightRows;
            if (w > 2) {
                this.svgTable.appendChild(createElsvg('rect',
                    'x', this.rightRows,
                    'y', this.topRows - this.heightHeading,
                    'width', w,
                    'height', this.heightHeading,
                    "table", this.idNr,
                    'fill', app.utils.settings.Table.colorBKG,
                    'stroke', app.utils.settings.Table.colorLines,
                    'stroke-width', '1px',
                    'key', this.getIDRowCol(-1, icol, "Radd"),
                    'id', this.getIDRowCol(-1, icol, "Radd"),
                    "shape-rendering", "crispEdges"
                ));

            }
        }
    }

    //___________________________________________________________________________________
    createButtons() {
        if (this.bt.length === 0) return;

        let sNames = ['b_New', 'b_Del', 'b_getDB', 'b_saveDB', 'b_NewDB', 'b_Ass', 'b_AssW'],
            //iconNames = ['fa-plus-circle', 'fa-minus-circle', 'fa-copy'],
            fk, el,// : any,
            heightButton = 0.85 * this.heightRow,
            widthButton = this.widthColumnButtons - 14,
            leftButton = this.rightRows + this.widthScroll + 8,
            top = this.topRows + 0.2 * heightButton - 1,
            dh = 1.2 * heightButton;



        this.svgTable.appendChild(createElsvg('rect',
            'x', this.widthScroll + this.rightRows,
            'y', this.topRows + 1,
            'width', this.widthColumnButtons + 1,
            'height', this.bottomAllRows - this.topRows,
            "table", this.idNr,
            'fill', app.utils.settings.Table.colorBKG,
            //'stroke', app.utils.settings.Table.colorLines,
            //'stroke-width', '1px',
            "shape-rendering", "crispEdges",
            'id', this.ID('btnArea')
        ));

        this.bt.forEach(nrBT => {
            let sn = app.utils.nK(sNames[nrBT]);

            if (nrBT > 4) top = this.topRows - this.heightHeading/2 - heightButton/2;

            fk = createElsvg('foreignObject',
                'x', leftButton,
                'y', top,
                'width', widthButton,
                'height', heightButton,
                'position', 'relative',
                "table", this.idNr,
                'key', this.ID('buttonFO'),
                'id', this.ID('btFO'));

            el = createElinput('button',
                'font-family', 'Arial',
                'tooltip', 'New/Add Element',
                "table", this.idNr,
                'title', sn,
                'alt', sn,
                'id', this.IDnr('bt', nrBT));
            el.style.width = '100%';
            el.style.height = '100%';
            el.nrBT = nrBT;

            el.onclick = this.buttonClicked;
            //el.style.fontFamily = 'arial';
            //el.style.fontSize = this.fontHeight();  

            /*if (i === 0) el.onclick = this.buttonNewClicked;
            else if (i === 1) el.onclick = this.buttonDeleteClicked;
            else if (i === 2) el.onclick = this.bGetDB;
            else if (i === 3) el.onclick = this.bSetDB;
            else el.onclick = this.buttonCopyClicked; */



            /*let img = document.createElement('img');
            img.nrBT = i;
            if (i === 0) img.src = imgP;
            else if (i === 1) img.src = imgM;
            else if (i === 2) img.src = idbFrom;
            else if (i === 3) img.src = idbTo;

            img.style.display = 'block';
            if (i > 1) {
                img.style.height = '14px';
                img.style.width = '23px';
            }
            else{
                img.style.height = '14px';
                img.style.width = '14px';
            }
            img.style.margin = 'auto';
            
            el.appendChild(img); */

            fk.appendChild(el);
            this.svgTable.appendChild(fk);

            this.refButtons.push(el);
            //el.img = img;

            top += dh;


        });
    }

    //___________________________________________________________________________________
    buttonClicked(e) {
        this.sM();
        if (this.form) {
            let nrBT = Number(e.target.nrBT);
            if (nrBT < 2) {
                this.btClicked(nrBT,1);
                this.setButtonsEnabled();
                this.cTimer();
            }
            if (nrBT === 1) {
                if (this.typeTable === 2 && this.oJson.length === 0) this.RowSel();
            }
            else if (nrBT === 2 || nrBT === 4) {
                this.jsDBget = this.oJson[this.actIndexData];
                this.FEedt = this.FE.edt;
                this.FE.props.handleOpenDBModal(this.idDBGS, true, this);
            }
            else if (nrBT === 3) this.saveToDB();
            if (nrBT > 4) this.btClicked(nrBT,1);
        }
    }



    //____________________________________________________________________________________________________________________
    gotPropsFromDB(o) {   //called after row selected and o.propsJS got from database
        if (o) {
            Object.assign(this.jsDBprops, o.PropsJS);
            this.jsDBprops.gotDB = true;
            this.AfterGotPropsDB(1);
        }
    }

    //___________________________________________________________________________________
    RowSel() {
        if (this.FE.coDB && this.main) {
            if (this.actIndexData > -1 && this.oJson.length > this.actIndexData && this.oJson.length > 0) {
                this.jsDBprops = this.oJson[this.actIndexData];
                if (this.FE.coDB || (this.form.TmainSel && this.idNr === this.form.TmainSel.idNr)) this.FE.TV.CObjectSelected(this.jsDBprops.id);
                if (this.propDB) {
                    if (!this.jsDBprops.hasOwnProperty("gotDB") && this.jsDBprops.id > 0) {
                        app.DBGeneric("Gen/GetCOprops", { id: this.jsDBprops.id }, this.gotPropsFromDB);
                        return;
                    }
                }
            }
        }
        this.RowSelected(1);
    }

    //___________________________________________________________________________________
    cTimer() {
        if (this.form.inr === 0) app.SD.cTimer();
    }

    

    

    //-----------------------------------------------------------------------------------
    setTitleSubTables() {
        if (this.subTables.length > 0) {
            if (this.typeTable === 2) {
                if (this.actIndexData > -1) {
                    this.subTables.forEach(t => {
                        let s = t.sTitle;
                        if (s !== "") t.updateTitle(t.sTitle + ", " + this.elName + ": " + (this.actIndexData + 1).toString());
                    });
                }
            }
        }
    }

    //___________________________________________________________________________________
    geom() {
        if (this.top < 0) this.top = this.form.defaultTop;

        if (this.typeTable === 2 && this.bt.length > 0) this.widthColumnButtons = app.utils.settings.Table.widthColumnButtons; else this.widthColumnButtons = 0;
        if (this.scroller) this.widthScroll = app.utils.settings.Table.widthScroll; else this.widthScroll = 0;

        let L = this.getCountData(1),
            widthContainer = this.FE.mW - 2 - this.minusWIDTH,
            heightContainer = this.FE.mH - this.FE.tabH - 1 - this.top,
            height = 0,//this.height, 
            width = 0,//this.width, 
            su = 0,
            suPixel = 0;

        if (this.width === 0) width = Math.max(Math.min(this.partWidthContainer * widthContainer, this.maxWidth), this.minWidth) + 1;
        else width = this.width;

        if (this.typeTable === 2) {
            if (this.maxHeight > 0) height = Math.max(this.maxHeight, this.minRows * this.heightRow + this.topRows) + 1;
            else height = Math.max(this.partHeightContainer * heightContainer, this.minRows * this.heightRow + this.topRows) + 1;
        }
        else height = Math.max(Math.min(L * this.heightRow + this.topRows, this.partHeightContainer * heightContainer), this.minRows * this.heightRow + this.topRows) + 1;

        if (this.maxRows > 0) height = Math.min(height, this.topRows + this.maxRows * app.utils.settings.Tab.heightRow + 1);
        if (this.minRows === this.maxRows) height = this.maxRows * app.utils.settings.Tab.heightRow + this.topRows + 1;

        if (this.scroller) width -= this.widthScroll;
        if (this.typeTable === 2 && this.bt.length > 0) width -= this.widthColumnButtons;

        this.widthColumns = [];//.length = 0;
        this.rightColumns = [];//.length = 0;
        this.bottomRows = [];//.length = 0;

        this.widthCols.forEach(el => { if (el < 1.0001) su += el; else suPixel += el });
        this.widthCols.forEach(el => { if (el < 1.0001) this.widthColumns.push(Math.max(Math.round(el * (width - suPixel) / su), 1)); else this.widthColumns.push(el) });

        su = this.leftRows;
        this.widthColumns.forEach(el => { su += el; this.rightColumns.push(su) });
        this.rightRows = su;
        this.widthAllColumns = su;

        su = this.topRows + this.heightRow;
        while (su <= height) { this.bottomRows.push(su); this.bottomAllRows = su; su += this.heightRow };

        this.bottomTable = this.bottomAllRows;
        this.rightTable = this.rightRows;
        if (this.scroller) this.rightTable += this.widthScroll;
        if (this.typeTable === 2 && this.bt.length > 0) this.rightTable += this.widthColumnButtons;

        this.width = this.rightTable;
        this.height = this.bottomTable;
        this.right = this.left + this.width;
        this.bottom = this.top + this.height;
        this.countAllRows = this.bottomRows.length;
    }

    //___________________________________________________________________________________
    createTable() {

        if (this.noBT || this.form.FE.props.isCalc) this.bt = [];
        else this.bt = this.setBt(1);

        if (this.typeTable < 2 && !this.scroller) {
            this.minRows = this.keys.length;
            this.maxRows = this.keys.length;
        };

        this.sTitle = app.utils.nK(this.titleKey);
        if (this.titleNr > 0) {
            if (this.titleKeyNr.length > 0) this.sTitle += ", " + app.utils.nK(this.titleKeyNr);
            this.sTitle += " " + this.titleNr.toString();
        }
        this.elName = app.utils.nK(this.elNameKey);

        if (this.sTitle.length > 0) {
            this.dhLineTitle = this.heightTitleHalf;//Math.round(0.75 * app.utils.settings.Table.fontSize);
            this.topRows = 2 * this.dhLineTitle + 0.5;
            this.yLineTitle = this.dhLineTitle;
        }
        else this.topRows = 1;
        this.leftRows = 1;

        this.topRows += this.heightHeading;


        this.geom();
        let countData = this.getCountData(1);

        this.scroll.scrollMax = Math.max(countData - this.bottomRows.length, 0);
        //if (this.scroll.scrolled > this.scroll.scrollMax) 
        //{
        //    this.scroll.scrolled = this.scroll.scrollMax;
        //}


        //gener------------------   

        this.svgTable = createElsvg('svg',
            //'x', this.left + 11,
            //'y', this.top + 7,
            //'width', this.rightTable + 1,
            //'height', this.bottomTable + 2,
            "id", "objTop" + this.idNr.toString(),
            'overflow', 'hidden',
            //'position', "relative",

            //'borderStyle', 'solid'

        );
        this.svgTable.style.position = "absolute";
        this.svgTable.style.top = this.top + 'px';
        this.svgTable.style.left = this.left + "px";
        this.svgTable.style.height = this.bottomTable + 2 + 'px';
        this.svgTable.style.width = this.rightTable + 1 + 'px';
        this.svgTable.style.margin = 0;
        this.svgTable.style.padding = 0;

        this.svgTable.style.border = 'none';//solid red 1px';
        this.maxIndexRows = this.bottomRows.length - 1;

        let conPrev = true;
        if (this.prevO) {  // intial this.scroll.scrolled = 0
            conPrev = false;
            //let countData = this.getCountData(1);


            if (this.prevO.RectSelected && this.prevO.actIndexData >= this.maxIndexRows) {
                this.prevO.actScrolled = this.prevO.actIndexData - this.maxIndexRows;
                this.prevO.actIndexRow = this.maxIndexRows;
                this.prevO.actIndexRowSelected = this.maxIndexRows;
            }
            else if ((this.maxIndexRows + 1) > (countData - this.prevO.actScrolled)) {
                this.prevO.actScrolled = Math.max(0, countData - this.maxIndexRows - 1);
                this.prevO.actIndexRow = this.prevO.actIndexData - this.prevO.actScrolled;
                this.prevO.actIndexRowSelected = this.prevO.actIndexData - this.prevO.actScrolled;
            }

            this.actIndexData = this.prevO.actIndexData;
            this.actIndexRow = this.prevO.actIndexRow;
            this.actIndexColumn = this.prevO.actIndexColumn;
            this.actScrolled = this.prevO.actScrolled;

            this.actIndexRowSelected = this.prevO.actIndexRowSelected;
            this.scroll.scrolled = this.actScrolled;

            //this.RectSelected = this.prevO.RectSelected;



            //this.scrolledChanged();
        }

        for (var irow = this.bottomRows.length - 1; irow > -1; irow--) {
            for (var icol = 0; icol < this.rightColumns.length; icol++) {
                this.addRowCol(irow, icol);
            }
        }

        if (this.titleColsKeys.length > 0) this.createHeading();

        this.createButtons();

        this.createTitleClosingLine(this.sTitle);

        this.addRectRowSelected();
        this.scroll.createScroll(this.svgTable, this.rightRows + 1, this.topRows, this.widthScroll, this.bottomAllRows - this.topRows, app.utils.settings.Table.colorBKG, app.utils.settings.Table.colorLines);
        this.scroll.adjustToScrolled();
        this.setButtonsEnabled();


        if (conPrev) {
            if (this.alwaysSelectedData) {
                this.findEditAbleAndSetEdit(0, 0, false);
            }
        }
        else if (this.RectSelected && this.actIndexRowSelected > -1) this.refRectSelected.setAttributeNS(null, 'y', (this.bottomRows[this.actIndexRow] - this.heightRow).toString());

        if (this.main) {
            this.refRectTableSel = createElsvg('rect',
                'x', 0,
                'y', 0,
                //'width', '100%', //store.uiStateStore.formContainerDimensions.width - 2,
                //'height', '100%',//store.uiStateStore.formContainerDimensions.height - 90, 

                'width', this.rightTable + 1,
                'height', this.bottomTable + 1,

                'id', this.ID('selT'),
                'key', this.ID('selT'),

                'fill', 'none',
                'stroke', 'black',
                'strokeWidth', 1,
                'stroke-dasharray', "2,2"
            );
            if (!this.form.TmainSel) {
                this.svgTable.appendChild(this.refRectTableSel);
                this.form.TmainSel = this;
            }
        }
    }

    //___________________________________________________________________________________
    sM() { //set main

        if (this.main) {
            let ts = this.form.TmainSel;
            if (ts) {
                if (!ts.idNr !== this.idNr) {
                    ts.svgTable.removeChild(ts.refRectTableSel);
                    this.svgTable.appendChild(this.refRectTableSel);
                    this.form.TmainSel = this;
                    if (this.FE.coDB) {
                        let js = this.oJson[this.actIndexData];
                        if (js) this.FE.TV.CObjectSelected(js.id);
                    }
                }
            }
            else {
                this.svgTable.appendChild(this.refRectTableSel);
                this.form.TmainSel = this;
            }
        }
        else {
            if (this.pT) this.pT.sM();
        }
    }

    //___________________________________________________________________________________
    reFocus() {
        let irow = this.actIndexRow,
            i = this.actIndexColumn;
        this.edit.setDataTable(this, this.actIndexData, irow, i, this.svgTable,
                                document.getElementById(this.getIDRowCol(irow, i, "T")),
                                document.getElementById(this.getIDRowCol(irow, i, "R")),
                                this.rightColumns[i] - this.widthColumns[i],
                                this.bottomRows[irow] - this.heightRow,
                                this.widthColumns[i]);
    }

    //___________________________________________________________________________________
    scrolledChanged() {  //only from scroller!
        for (var irow = 0; irow < this.bottomRows.length; irow++) {
            for (var icol = 0; icol < this.rightColumns.length; icol++) {
                this.updateRowCol(irow, icol);
            }
        }
        if (this.actIndexRowSelected > -1 && this.actIndexRow > -1) {
            if (this.actScrolled !== this.scroll.scrolled) {
                let dRow = this.scroll.scrolled - this.actScrolled,
                    iRow = this.actIndexRow - dRow;

                if (iRow < 0) iRow = 0;
                if (iRow > this.maxIndexRows) iRow = this.maxIndexRows;

                let dt = this.getDataType(iRow + this.scroll.scrolled, 0,1);
                if (dt === app.utils.dType.rowTitle) {
                    if (iRow === 0) iRow++;
                    else if (iRow === this.maxIndexRows) iRow--;
                }
                this.findEditAbleAndSetEdit(iRow, this.actIndexColumn, true);
            }
        }
    }

    //___________________________________________________________________________________
    setRectSelected(irow) {
        //if (this.RectSelected) {
        //if (this.actIndexRowSelected !== irow) {
        this.actIndexRowSelected = irow;
        if (this.actIndexRowSelected < 0) {
            this.refRectSelected.setAttributeNS(null, 'y', (- 3 * this.heightRow).toString());
            this.RectSelected = false;
        }
        else {
            this.refRectSelected.setAttributeNS(null, 'y', (this.bottomRows[irow] - this.heightRow).toString());
            this.RectSelected = true;
        }

        //}            
        //}
    }

    //___________________________________________________________________________________
    findEditAbleAndSetEditIndexData(idata, icol, coSetEditor) {
        //let countData = this.getCountData(1);
        if (idata >= (this.countAllRows - this.scroll.scrolled)) {
            this.scroll.scrollTo(idata - this.countAllRows + 1);
        }

        this.findEditAbleAndSetEdit(idata - this.scroll.scrolled, icol, coSetEditor);
    }

    //___________________________________________________________________________________
    findEditAbleAndSetEdit(irow, icol, coSetEditor) {  //only this will be used to set row col
        let countData = this.getCountData(1);
        if ( countData === 0 || irow >= countData) return;

        let indexD = irow + this.scroll.scrolled,
            dt = this.getDataType(indexD, icol,1);

        if (dt === app.utils.dType.rowTitle) return;

        let co = this.isReadOnly(indexD, icol, 0,1),  //should be false
            i = icol;
        if (dt === app.utils.dType.TextlistChoice) {
            if (co) co = this.isReadOnly(indexD, icol, 1,1);
        }
        if (co) {
            i = icol + 1;
            while (co && i < this.rightColumns.length) {
                co = this.isReadOnly(indexD, i, 0,1);
                if (co) i++;
            }
        }
        if (co) {
            i = icol - 1;
            if (co && i > 0) {
                co = this.isReadOnly(indexD, i, 0,1);
                if (co) i--;
            }
        }
        if (co) {
            this.FE.idNrFocused = -1;
            this.edit.hide();
            this.setRectSelected(irow);
            this.actIndexData = indexD;
        }
        else {
            if (coSetEditor) {
                this.FE.idNrFocused = this.idNr;
                this.edit.setDataTable(this, indexD, irow, i, this.svgTable,
                    document.getElementById(this.getIDRowCol(irow, i, "T")),
                    document.getElementById(this.getIDRowCol(irow, i, "R")),
                    this.rightColumns[i] - this.widthColumns[i],
                    this.bottomRows[irow] - this.heightRow,
                    this.widthColumns[i]);
            }
            else this.actIndexData = indexD;

            icol = i;
            this.setRectSelected(irow);
        }
        this.actIndexColumn = icol;

        if (this.actIndexData !== indexD || this.actIndexRow !== irow || this.actScrolled !== this.scroll.scrolled) {
            this.actIndexData = indexD;
            this.actIndexRow = irow;
            this.actScrolled = this.scroll.scrolled;
            this.RowSel();
            this.setButtonsEnabled();

            this.setTitleSubTables();
        }
    }

    //___________________________________________________________________________________
    onMouseEvent(e) {


        if (e.type !== 'wheel') e.preventDefault();  // 9.08.2022

        // if (this.FE.reScaling) return;

        if (e.target.hasAttribute('scroll')) {
            this.scroll.mouseEvent(e);
            return;
        }


        let coOverThisTable = (e.target.hasAttribute("table") && !e.target.hasAttribute('scroll'));
        if (coOverThisTable) coOverThisTable = (e.target.getAttribute("table") === this.idNr.toString());
        if (coOverThisTable) {

            if (e.type === 'mousedown') {
                this.tableFocusedMouseDown = true;
                this.sM();
            }
            if (this.tableFocusedMouseDown) {
                if (e.type === 'mouseup' || e.type === "mousedown" || (e.type === 'mousemove' && e.buttons === 1)) {
                    let cd = this.getCountData(1);
                    if (e.target.hasAttribute('row') && e.target.hasAttribute('col')) {
                        this.scroll.timerZeroUpDown = 0;

                        let irow = Number(e.target.getAttribute('row')),
                            icol = Number(e.target.getAttribute('col'));
                        if ((irow + this.scroll.scrolled) < cd) {
                            this.findEditAbleAndSetEdit(irow, icol, true);
                            return;
                        }
                    }
                    if (cd > 0) {
                        if (this.actIndexData < 0) this.actIndexData = 0;
                        if (this.actIndexColumn < 0) this.actIndexColumn = 0;
                        this.findEditAbleAndSetEditIndexData(this.actIndexData, this.actIndexColumn, true);
                    }
                }
            }
            if (this.scroller) this.scroll.mouseEvent(e);
        }
        else {
            if (e.type === 'mouseup' || e.type === 'mousedown') {
                this.tableFocusedMouseDown = false;
                if (!this.alwaysSelectedData) this.setRectSelected(-1);
                return;
            }
        }
    }

    //____________________________________________________________________________________________________
    getObjToAppend() {
        return this.svgTable;
    }

    //_____________________________________________________________________________________________________
    afterMounted() {
        //this.makeUnselectable(); doesnt work !!
    }

    //___________________________________________________________________________________
    trbl(i) {
        let d = app.utils.settings.form.defaultDist;
        if (i === 0) return this.top;
        else if (i === 1) return this.right + d;
        else if (i === 2) return this.bottom + d;
        else return this.left - d;
    }

    //__________________________________________________________________________________
    setIconBT(bt){
    let nrBT = bt.nrBT,
        img = bt.img,
        s;
    if (!img){
        img = document.createElement('img');
        img.nrBT = nrBT;
        img.style.display = 'block';
        img.style.margin = 'auto';

        if (nrBT === 4){
            img.style.height = '15px';
            img.style.width = '28px';
        }
        else if (nrBT > 1) {
            img.style.height = '14px';
            img.style.width = '23px';
        }
        else{
            img.style.height = '14px';
            img.style.width = '14px';
        }
            
        bt.appendChild(img);
        bt.img = img;
    }
    if (bt.disabled){
        if (nrBT === 0) s = imgPD;
        else if (nrBT === 1) s = imgMD;
        else if (nrBT === 2) s = idbFromD;
        else if (nrBT === 3) s = idbToD;
        else if (nrBT === 4) s = plusDB;
        else if (nrBT === 5) s = idbA0;
    }
    else {
        if (nrBT === 0) s = imgP;
        else if (nrBT === 1) s = imgM;
        else if (nrBT === 2) s = idbFrom;
        else if (nrBT === 3) s = idbTo;
        else if (nrBT === 4) s = plusDB;
        else if (nrBT === 5) s = idbA1;

    }
    if (img.src !== s) {
        img.src = s;
    }
}
}