import * as React from "react";
import { EditorInput } from './Editor';
import { TabView } from "./TabView";
import { CreateForm } from "../data/FormInfo"
import { EditTable } from "../editors/EditTable"
import { Button } from "../editors/Button"
import { GraphEl} from "../editors/GraphEl"
import { Odiagr} from "../editors/Odiagr"
import { FileInput} from "../editors/FileInput"
import { DataCheck } from "../editors/DataCheck"
import { Otext } from "../editors/Otext"
import {app} from "../ahome/App";

// import { DBTool } from "../modalDB/DBTool"

// import { MyWindowPortal } from '../editors/WindowPortal';
// import Modal from "react-modal";


//________________________________________________________________________________________
export class FormEdit extends React.Component {

    countDivs = 2;
    tabView = undefined;//: TabView;
    refDIV = [];//: React.RefObject<SVGSVGElement>;  
    static FEs;  //to inform new forms of the FE instance
    prevEdt = [];

    ignoreForm = false;
    
    //refDIV = [];
    


    //not explicit-------------------------------------------------------------------------
    //editT editor used here
    //form - current in this area added before its createObj();
    //coDB, conDB, coDBget : bool if databse
    //coTool,
    //idDB : number
    //keynr : various purposes, eg. number for key
    //mW, mH - max Width, maxH, 
    //loDB - list DB objects
    //idMinus set if coDB
    //nrTN - cosecutive nr for tree view nodes
    //TV
    //DB - catalogues/objects from database
	//DBres - changes made at frontend
    //tabH - height of TabView
    //inr - int, to set app.FE[this.inr] and to avaid ambiguious ids
    //reScaling - bool if just rescaling
    //DBpropsAtStart - bool if all data should be transfered at start, eg. daily time patterns
    //tvp - last tvp
    
    //________________________________________________________________________________________
    constructor(props) 
    {
        super(props);  //console.log("FormEdit CREATING XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX ",props.inr)

        
        this.tabH = 0;
        this.inr = props.inr;
        this.keynr = this.inr;

        app.FE[this.inr] = this;
        FormEdit.FEs = this;
        this.idNrFocused = -1;
        this.nrTN = 0;

        this.utils = app.utils;
        
        if (this.utils.oOK(props.nrTool)){
            this.coTool = true;
            this.nrTool = props.nrTool;
        }
        else this.coTool = false;
        
        if (this.inr === 0) {
            this.coMain = true;
            this.coDB = false;
            this.coDBget = false;
            this.idDB = -1;
            this.tabView = new TabView();  
            this.tabView.FE = this;
        }
        else {
            this.coMain = false;
            this.coDB = props.coDB;
            if (this.coDB){
                this.AfterGotListOP = this.AfterGotListOP.bind(this);
                this.DBloaded = this.DBloaded.bind(this); 
                this.idMinus = -9;
                this.coDBget = props.coDBget;
                this.idDB = props.idDB;
                this.DBpropsAtStart = (this.idDB === 5); 
                this.DBres = {CategoryId: this.idDB, OwnerId: app.OwnerId, Lan: app.Lan, Cats:[], IdDelCats: [], COs:[], IdDelOs:[]}; 
            }
            else this.coDBget = false;
        }
        this.conDB = !this.coDB;
        this.editT = new EditorInput(true);
        if (this.inr === 0) this.editT.cTimerimer = true;   //  this.editT.cTimerimer = true;
        this.onMouseEvent = this.onMouseEvent.bind(this);  
        this.tabNodeChanged = this.tabNodeChanged.bind(this); 
        this.ReInitForm = this.ReInitForm.bind(this);
                        
        for (let i = 0; i < this.countDivs; i++) {
            this.refDIV.push(React.createRef()); 
        }
    }

    //________________________________________________________________________________________
    initForm(f){     // console.log("initForm",f.coDB);
        
        if (this.ignoreForm){
            this.deleteAllEdt(1);
            return;
        }
        this.form = f;  //console.log("initForm",this.coDB,f);
        f.FE = this;
        f.refDIV = this.refDIV;//array now 7.06.2022
        f.inr = this.inr;
        f.TV = this.TV;//actData.tv; 
        f.app = app;
        f.utils = app.utils;
        f.dCheck = app.dCheck;
        f.PJ = app.projectJSON;
        f.AV = app.actVariantJSON;
        //f.B = app.actVariantJSON.building;
        f.C = f.AV.cliLoc;
        f.idKey = 'f' + this.inr;
        f.DBGeneric = app.DBGeneric;
        //f.TS = app.TS;
        //f.IMG = app.IMG;
        //f.SD = app.SD;
    
        f.defaultTop = 5;    
        
        f.coDB = this.coDB;
        f.conDB = !this.coDB;
        f.coDBget = this.coDBget;

        f.TmainSel = undefined;

        if (this.coDB){
            f.lOdb = this.lOdb;
            f.lO = this.lOdbFiltered;  
            f.oJson =  this.lOdbFiltered;  

            if (!f.lO) return; //??
        }
        else{
            f.LO = null;
            if (this.TV) {
                f.oJson = this.TV.selP.oJSON;//actData.oJson;

                //console.log("initForm++++++++++++++++++++++++",f.coDB, f.oJson);
            }  
        }

        this.prevEdt = null;
        if (!this.coDB){
            if (this.form){
                if (this.form.constructor === f.constructor){  
                    if (this.prevTreeNode === this.actTreeNode) {
                        this.prevEdt = this.edt;  //console.log("act edt taken -----------------------",this.coDB, this.mW,this.mH)
                    }
                }
            }
        }
        
        this.deleteAllEdt(1);  //console.log(this.mH);
        if (this.mH > 0 && this.mW > 0){
            this.form.init();
            this.prevTreeNode = this.actTreeNode;
        }
    }

    //________________________________________________________________________________________
    ReInitForm(){ 
        setTimeout(()=>{
            this.deleteAllEdt(1);
            this.form.init();
        });

    }

    //________________________________________________________________________________________
    ReForm(f){  //insert original edts after modal windows overflowed and exchanged form
        
        if (this.refDIV[1].current){
            this.form = f;
            this.edt = this.prevEdt;
            for (let i = 0; i < this.edt.length; i++) {
                this.refDIV[1].current.appendChild(this.edt[i].getObjToAppend()); 
            }
            if (this.form.afterReform) this.form.afterReform();
        }
    }
    

    //________________________________________________________________________________________
    createTable(idNr, typeTable, oJson, ...args){//createTable
        return new EditTable(idNr, typeTable, oJson, ...args);
    }

    //________________________________________________________________________________________
    createButton(idNr, sKey, imgS, js){
        return new Button(idNr, sKey, imgS, js);
    }

    //________________________________________________________________________________________
    createGraphEL(idNr, type, createCanvas, p) {  //p - params is json object including value pairs)
        return new GraphEl(idNr, type, createCanvas, p);
    }

    //________________________________________________________________________________________
    createDiagr(){
        return new Odiagr();
    }

    //________________________________________________________________________________________
    createOtext(idNr, sKey){
        return new Otext(idNr, sKey);
    }

    //________________________________________________________________________________________
    createDataCheck(){
        return new DataCheck(app); 
    }

    //________________________________________________________________________________________
    createFileInput(fileType){
        return new FileInput(fileType, this.form);
    }

    //________________________________________________________________________________________
    deleteAllEdtRef(i){
        if (this.refDIV[i].current){
            while (this.refDIV[i].current.firstChild) {
                this.refDIV[i].current.removeChild(this.refDIV[i].current.firstChild);
            }
        }
    }

    //________________________________________________________________________________________
    deleteAllEdt(i){
        this.edt = [];
        this.deleteAllEdtRef(i);
    }

    //________________________________________________________________________________________
    deleteEdtNr(idNr){
        //if (this.refDIV[0].current && this.refDIV[1].current) {
        let elem = null,
            ii = -1;
        for (let i = 0; i < this.refDIV.length; i++){
            if (this.refDIV[i].current){
                if (!elem) {
                    //???elem = this.refDIV[i].current.getElementById("objTop" + idNr);
                    elem = document.getElementById("objTop" + idNr);
                    if (elem) ii = i;                
                }
            }
        }
        let iRem = -1;
        if (elem && ii > -1) /*this.refDIV[ii].current*/elem.parentNode.removeChild(elem);  
        for (var i = 0; i < this.edt.length; i++) {if (this.edt[i].idNr === idNr) iRem = i;}
        if (iRem > -1) this.edt.splice(iRem, 1);
        
    }

    //________________________________________________________________________________________
    deleteEdt(...edts){
        for (let edt of edts){
            if (edt){
                this.deleteEdtNr(edt.idNr);
                edt = null;
            } 
        }
    }

    //________________________________________________________________________________________
    deleteEdtScope(idNrMin, idNrMax) {
        for (let i = idNrMin; i < idNrMax; i++) this.deleteEdtNr(i);
    }

    //____________________________________________________________________________________________________________________
    EDT(idNr) {   
        return this.utils.getEDT(idNr, this.edt);
    }

    //____________________________________________________________________________________________________________________
    getIDminus() {
      this.idMinus--;
      return this.idMinus;
    }

    //_________________________________________________________________________________
	getFromDB(){ // console.log("getFromDB() this.dbScope",this.props.dbSC);
		app.DBGeneric("Gen/GetCO", {CategoryId: this.idDB, OwnerId: this.props.dbSC.dbScope, Lan: app.Lan}, this.DBloaded);
	}

    //___________________________________________________________________________________________________________________
    convertValue(toSI, sk, indexData, indexColumn, indexEditor, val){
        if (app.projectJSON.SIIP === 1) return val;
        else if (isNaN(val) || val === null) return val;
        else return this.utils.convertSIIP(toSI, this.getUnit(sk, indexData, indexColumn, indexEditor), val);
    }

    //___________________________________________________________________________________________________________________
    convertValueU(toSI, u, val){
        if (app.projectJSON.SIIP === 1) return val;
        else if (isNaN(val) || val === null) return val;
        else return this.utils.convertSIIP(toSI, u, val);
    }

    //___________________________________________________________________________________________________________________
    getUnit(sk, indexData, indexColumn, indexEditor){
        let u = this.utils.Unit_ID.not_defined;
        if (app.def.hasOwnProperty(sk)){
            if (app.def[sk].hasOwnProperty('u'))  u = app.def[sk]["u"];
            else if (app.def[sk].hasOwnProperty('kd')){
                let k = app.def[sk].kd;
                if (app.def.hasOwnProperty(k)){
                    if (app.def[k].hasOwnProperty('u'))  u = app.def[k]["u"];
                }
            }
        }
        return u;
    }

    //_________________________________________________________________________________
    AfterGotListOP(l){  //list of properties  if DBpropsAtStart
        //if (this.coStat) return;
        if (l){
            if (this.DB.LO.length === l.length){
                for (let i = 0; i < this.DB.LO.length; i++){
                    if (this.DB.LO[i].id === l[i].id) Object.assign(this.DB.LO[i], l[i].propsJS);
                }
                this.TV.createNewNodes();  
                //??this.props.setActive();
                return;
            }
        }
        
        this.props.cancelModal();
    }

    //_________________________________________________________________________________
	DBloaded(db){//console.log("DBloaded(db)",db);
        //if (this.coStat) return;
        if (db){   //console.log("db1",db);
		    this.DB = db; 

            // if (this.DBpropsAtStart){ //e.g. day profiles
            //     let ii = [];  // console.log("db2",db);
            //     this.DB.LO.forEach(e => ii.push(e.id)); //list objects
            //     app.DBGeneric("Gen/GetCOs",  {Idd: ii, Lan: app.Lan}, this.AfterGotListOP);
            //     return;
            // }

		    this.TV.createNewNodes();  
            this.props.setActive();//console.log("db5",db);
            
        }
        else this.props.cancelModal();  
	}

    //_________________________________________________________________________________
	createDBres(){
		this.TV.addDBres();
		this.addDBres(this.DBres);  //DBres - created here

		if (this.DBres.Cats.length > 0 || this.DBres.IdDelCats.length > 0 || this.DBres.COs.length > 0 || this.DBres.IdDelOs.length > 0){
			app.DBGeneric("Gen/SetCO", this.DBres, undefined);// {CategoryId: 1, OwnerId:2, Lan:"en", DBres: this.DBres});
		}
	}

    //________________________________________________________________________________________
    componentDidMount() 
    {
            this.TV = app.TV[this.inr]; 
            //this.TV.createNewNodes();
        
            for (let i = 0; i < this.refDIV.length; i++){
             if (this.refDIV[i].current) {
                    this.refDIV[i].current.addEventListener('click', this.onMouseEvent);
                    this.refDIV[i].current.addEventListener("mousedown", this.onMouseEvent);
                    this.refDIV[i].current.addEventListener("mouseup", this.onMouseEvent);
                    this.refDIV[i].current.addEventListener("mousemove", this.onMouseEvent);
                    this.refDIV[i].current.addEventListener("wheel", this.onMouseEvent,  { passive: true }); //dont remove !
                    //?this.refDIV.current.addEventListener("mouseleave", this.onMouseEvent, { passive: true }); 
                }
            }
            window.addEventListener("contextmenu", function (e) { e.preventDefault(); }, false);
            window.addEventListener('selectstart', function (e) { e.preventDefault(); });
           
            if (this.coDB) this.getFromDB();
            //else if (this.coTool) {};//this.props.setActive();
       
            if (!this.coTool && this.conDB) {
                //this.TV = app.TV[this.inr]; 
                this.TV.createNewNodes(); 
            }

           // console.log("FE componentDidMount nr", this.inr, "dimesions ",
                        // this.refDIV[1].current.offsetWidth,this.refDIV[1].current.offsetHeight,
                        // "-------------------------------------------------------------------------------");
    }
    //________________________________________________________________________________________
    tabNodeChanged(tabNode){  
        this.createTabs();       
        if (this.tabView){ 
            this.refDIV[0].current.appendChild(this.tabView.svgTabView);
            this.setState({ct:this.contextTab});//rendering  
        }     
    }

    //________________________________________________________________________________________
    treeNodeChanged(treeNode) {//console.log("FE treeNodeChanged(treeNode)zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz ",this.TV.selP.oJSON.id);
        if (this.coDB){         
            if (this.DB.LO && this.form){
                this.lOdb = this.DB.LO; //LO - list objects
                if (this.TV.selP.oJSON.id === -1) this.lOdbFiltered = this.lOdb;
                else this.lOdbFiltered = this.lOdb.filter(o => this.TV.selP.oJSON.co.includes(o.id));
                
                if (this.coDBget && this.lOdbFiltered) this.props.EnDisAccept(this.lOdbFiltered.length === 0);
                else this.props.EnDisAccept(false);
                this.setState({ctn:this.contextTab, tn:treeNode});//rendering    
            }
            return;
        }
        if (this.tabView) {
            this.createTabs();
            this.refDIV[0].current.appendChild(this.tabView.svgTabView);
        }
        this.actTreeNode = treeNode;
        this.setState({ctn:this.contextTab, tn:treeNode});//rendering    
    }

    //________________________________________________________________________________________
    Wait()
    {
        if (this.props.wait) return this.props.wait;
        else return false;
    }

    //________________________________________________________________________________________
    onMouseEvent(e) 
    {
        if (this.Wait() ) return;
        let co = true; 
        if (e.target.id === 'input')
        {           
            co = false;
        }
        if (co)
        {
            //if (this.form) this.form.onMouseEvent(e);
            if (this.edt){
                this.edt.forEach((el) => {el.onMouseEvent(e);  });
            }
            if (this.tabView) this.tabView.onMouseEvent(e);   
        }    
    }

    

    //________________________________________________________________________________________
    createTabs(){
        this.tabH = 0;
        this.deleteAllEdt(0);  
        if (this.tabView) {
            this.tabView.createTabView();
            this.tabH += 2;
        }
        
    }


    //________________________________________________________________________________________
    AfterCalc() {

        if (this.form.AfterCalc) this.form.AfterCalc();
    };

    //____________________________________________________________________________________________________________________
    addDBres(){
        this.DB.LO.forEach(CO =>{  //all db objects/components
            if (CO.id < 0 || CO.NC || CO.PropsC){  //PropsC - property changed,  NC - name changed
                var co = {Id: CO.id};   //component
                if (CO.NC  || CO.id < 0) co.N = CO.n;
                if (CO.PropsC || CO.id < 0){
                    if (app.utils.scopeMETR()) {
                        CO.ds2="METR";
                    }
                    co.PropsJS = JSON.parse(JSON.stringify(CO));
                }
                this.DBres.COs.push(co);
            }
        });
    }

    //____________________________________________________________________________________________________________________
    actualizeTVtw(iChild)  {  //textwidth
        if (iChild < 0) this.TV.selP.actualizeText();  
        else {
            if (this.TV.selP.children.length > iChild) this.TV.selP.children[iChild].actualizeText();
        }
        this.TV.actualizeWidth();
    }

    //____________________________________________________________________________________________________________________
    formAttached() {
        if (this.form.formAttached) this.form.formAttached();
    }

    //____________________________________________________________________________________________________________________
    ButtonClicked(skey, e) {
        if (this.form.ButtonClicked) this.form.ButtonClicked(skey,e);
    }

    //____________________________________________________________________________________________________________________
    GraphElMouseEvent(g, e) {
        if (this.form.GraphElMouseEvent) this.form.GraphElMouseEvent(g,e);
    }

    

    //_________________________________________________________________________________________
    addEdt(o, iref) { 
        if (!this.utils.oOK(iref)) iref = 1; 
        o.form = this.form;
        o.FE = this;//.FE;
        o.pJ = this.pJ;
        o.aV = this.aV;
        o.inr = this.inr;
        o.iref = iref;

        o.prevO = this.utils.getEDT(o.idNr, this.prevEdt); 
    
        o.createObj();

        this.deleteEdtNr(o.idNr); 

        if (this.refDIV[iref].current) {
            this.refDIV[iref].current.appendChild(o.getObjToAppend()); 
            this.edt.push(o); 
        }
    }

    //____________________________________________________________________________________________________________________
    getUniqueID(l) {
        if (this.coDB) {
            return this.getIDminus();
        }
        let ll,
            idNr = 1,
            coExists = true,
            res;//: undefined;
        if (l) ll = l; else ll = this.form.lO;
        while (coExists) {
            let ii = idNr; //because of warning: unsafe reference to idNr
            res = ll.find(el => { return (ii === el.id) });
            if (res) idNr++;
            else coExists = false;
        }
        return idNr;
    }

    //________________________________________________________________________________________
    checkWidthHeight(){
        let h1 = 0;
        for (let i = 0; i < this.countDivs; i++){
            let refsvg = this.refDIV[i];
            if (refsvg){
                let maxH = 0,
                    maxW = 0;
                if (this.form) {
                    this.edt.forEach(el => {
                        if (el.iref === i){
                            if (el.right > maxW) maxW = el.right;
                            if (el.bottom > maxH) maxH = el.bottom;
                        }
                    });  
                }
                let w = Math.max(maxW, 3),
                    h = Math.max(maxH + 2, this.tabH + 1);
                if (this.utils.oOK(this.mW)) w = Math.max(this.mW , w);
                
                refsvg.current.setAttributeNS(null, 'height', h);
                refsvg.current.setAttributeNS(null, 'width', w - 2);  
                if (i === 0) h1 = h;
            }
        }
        if (!this.utils.oOK(this.mH)) this.mH = h1 + 10;
        this.setState({h1:h1, h2: this.mH - h1 });
    }  


    //________________________________________________________________________________________
    render() 
    {
        const skdivH = "divFormContainerHeader" + this.keynr;
        this.skdiv = "divFormContainer" + this.keynr;
        //border: '1px solid green'
        //border:'n1px solid red'

        let h = 0;
        if (this.mH) h = Math.max(0,this.mH - this.tabH);  //console.log("FE render",this.inr,this.mH ,this.tabH,h);//this.inr,this.mW,h);

        //h = 200;

        return (<> 
        {this.inr === 0 ? (<div  style={{margin: 0, padding: 0,  border: 'none', width:'100%', height:this.tabH, position:'relative' }}	key={skdivH} ref={this.refDIV[0]}/>):null}
        
        <div style = {{margin: 0, padding: 0,  border:'none', width:'100%', height:h, position:'relative',  overflow:'auto'}} id={this.skdiv} key={this.skdiv} ref={this.refDIV[1]}>
            <CreateForm fe={this}/>
        </div>

        
        </>)
    }
}

export default FormEdit;