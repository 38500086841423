import * as React from 'react';
import {app} from "./App";
import "./Tools.css";
import { GG } from "../report/ReportGener";
//import { nK, DBGeneric,  oOK} from "../data/Utils";
import {ButtonDropDown} from "../editors/ButtonDropDown"

export class StatusRes extends React.Component {
  //ref; //: React.RefObject<HTMLDivElement>;


  //_________________________________________________________________________
  constructor(props) {
    super(props);
    this.state = {data:undefined}; //data = returned from server

    this.mW = 0;
    this.mH = 0;

    //this.dL = {downLoad : 12};

    app.SR = this;
    this.ref = React.createRef(); 
    this.downLoadRes = this.downLoadRes.bind(this);
    this.resDownloaded = this.resDownloaded.bind(this);
  }

  //__________________________________________________________________________
  checkDataRep(data){  //called from dataStatus
    this.setState({data:data});  
  }

  //__________________________________________________________________________
  componentDidUpdate(){    
    let SV = this.ref.current;
      if (app.utils.oOK(SV)) {while (SV.lastChild) {SV.removeChild(SV.lastChild);}

    //if (oOK(this.state.data) && oOK(this.state.data.CR) && oOK(this.state.data.CR.gg) && this.ref.current) {
    //  while (this.ref.current.firstChild) {  this.ref.current.removeChild(this.ref.current.lastChild);}

      //console.log("componentDidUpdate()", this.state.data.CR.gg, this.ref.current);

      if (app.utils.oOK(this.state.data.CR)) GG(1,1,99999, "imres.kgg", this.state.data.CR.gg, SV, null);
    }
  }

  //__________________________________________________________________________
  resized(){
    this.setState({h:this.mH});  
  }

  //__________________________________________________________________________
  downLoadRes(Nr)
  {
    if (Nr === 99) app.DBGeneric("Sika/test", {Nr: Nr, calcScope: app.projectJSON.calcScope, uuid:app.projectJSON.uuid}, null);
    else app.DBGeneric("Gen/GetRes", {Nr: Nr, calcScope: app.projectJSON.calcScope, uuid:app.projectJSON.uuid}, this.resDownloaded);  //console.log(jobNr);
  }

  //__________________________________________________________________________
  resDownloaded(data)
  {
    let fn;
    var blob;
    if (app.utils.oOK(data.file && app.utils.oOK(data.fn))) {
      fn = data.fn;
      //blob = new Blob([atob(data.file)], {type: "application/msword;charset=utf-8"});
      const binaryString = window.atob(data.file);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; ++i) {
    bytes[i] = binaryString.charCodeAt(i);
    
  }
  blob = new Blob([bytes], { type: 'application/msword' });
    }
    else {
      fn = "results.json";
      blob = new Blob([JSON.stringify(data)], {type: "text/plain;charset=utf-8"});
    }

    var FileSaver = require('file-saver');
    FileSaver.saveAs(blob, fn);
  }

  //__________________________________________________________________________
  resHeader(){
    let s = "Results",
        btnKey = "",
        anyRes = false;
    if (app.utils.oOK(this.state.data)) {
      if (app.utils.oOK(this.state.data.CR)){
        if (app.utils.oOK(this.state.data.CR.resBtn)) { btnKey = this.state.data.CR.resBtn; anyRes = true}//this.state.data.CR.info;
        //if (oOK(this.state.data.CR.info)) s += " " + this.state.data.CR.info;//this.state.data.CR.info;
      }
    }
    if (anyRes) s += "    ";
    return(
      <div  > 
        {s} 
        {anyRes ? <ButtonDropDown  skey={btnKey} handleBtnClick = {this.downLoadRes} style = {{padding: 6}}/>    : null }
      </div>
      )
  } 

  //__________________________________________________________________________
  render = () => {
    let sh = Math.max(this.mH - 31,1);
    //after div: <div className="app-header"> {this.defRender()} 
    return (
      <div>
        <div className="tool-header" style = {{padding: 6}}> 
          {this.resHeader()} 
        </div>

        <div  style = {{height: sh, fontFamily: 'arial', fontSize: 13, overflow: 'auto', borderStyle:'none'}}> 
          <svg xmlns="http://www.w3.org/2000/svg"	version="1.1"  ref={this.ref}  ></svg>
          
        </div>
      </div>
    );
  };
}
export default StatusRes;
