//import * as React from "react";
import React from 'react';
import SplitPane from "react-split-pane"
import "./Main.scss";
import {TreeView} from "../editors/TreeView";
import {FormEdit} from "../editors/FormEdit";
import ThreeScene from "../webGL/WebGLThreeScene";
import { StatusData } from "./StatusData";
import { StatusRes } from "./StatusRes";
import {Settings} from "../data/Settings";

import {app } from "./App"

//const FormEdit = React.lazy(() => import("../editors/FormEdit"));

//____________________________________________________________________________
export class Main extends React.Component {
	refFormTreeView;
	refFormGL;
	refFormEdit;
	refFormResults;

	// not explicit-------------------------------
	//coU - update editors after dimension change

	//____________________________________________________________________________
	constructor(props) {
		super(props);  

		this.state = {layO:undefined};
		
		this.checkDimensions = this.checkDimensions.bind(this);
		this.windowResized = this.windowResized.bind(this);
		this.dragStarted = this.dragStarted.bind(this);

		this.refFormEdit = React.createRef();
		this.refStatusData = React.createRef();
		this.refStatusRes = React.createRef();
		this.refFormTreeView = React.createRef();
		this.refFormGL = React.createRef();

		if (window.innerWidth > window.innerHeight) this.state.layO = 1;
		else this.state.layO = 2;
	}

	//____________________________________________________________________________
	checkDimensions(){

		let feW = this.refFormEdit.current.offsetWidth,
			feH = this.refFormEdit.current.offsetHeight,
			tvW = this.refFormEdit.current.offsetWidth,
			tvH = this.refFormTreeView.current.offsetHeight,
			tsW = this.refFormGL.current.offsetWidth,
			tsH = this.refFormGL.current.offsetHeight,
			srW = this.refStatusData.current.offsetWidth,
			srH = this.refStatusData.current.offsetHeight; 

		if (feW !== app.FE[0].mW || feH !== app.FE[0].mH){
			app.FE[0].mW = feW;
			app.FE[0].mH = feH;
			//app.FE[0].reInitForm();
			//app.FE[0].reScale(); //app.FE[0].reRender(); 
		}

		if (tvW !== app.TV[0].mW || tvH !== app.TV[0].mH){
			app.TV[0].mW = tvW;
			app.TV[0].mH = tvH; 
		}
		if (tsW !== app.TS[0].mW || tsH !== app.TS[0].mH){
			app.TS[0].mW = tsW;
			app.TS[0].mH = tsH; 
			app.TS[0].wResized();
		}
		if (srW !== app.SD.mW || srH !== app.SD.mH){
			app.SD.mW = srW;  
			app.SD.mH = srH; 
			app.SD.resized();
			app.SR.mH = srH;
			app.SR.resized();
		}
		this.setState({feH:feH, feW:feW}); 
	}

	//____________________________________________________________________________
	componentDidMount() 
	{								  
		window.addEventListener("resize", this.windowResized);	
		this.windowResized();
	}

	componentWillUnmount(){
    	window.removeEventListener("resize", this.windowResized); 
  	}


	//____________________________________________________________________________
	windowResized(){

		let layOP = this.state.layO,
            layA;
		if (window.innerWidth > window.innerHeight) layA = 1;
		else layA = 2;  
		if (layOP !== layA) {
			this.setState({layO:layA}); 
		}
		else{
			this.checkDimensions();
		}
	}

	//____________________________________________________________________________
	dragStarted(){
		app.FE[0].reScaling = true;
	}

	//____________________________________________________________________________
	render() 
	{
		const style = 
		{
			width: "100%",
			height: "100%",
			overflow: "hidden",
		};
		const styleTV = 
		{
			width: "100%",
			height: "100%",
			overflow: "auto",
			backgroundColor: Settings.TS.colorBKG
		};  
		

	
		
 return (
			<div className="main"  >
				
				<SplitPane   
					split = {this.state.layO === 2 ? "horizontal" : "vertical"}
					minSize="30%"
					defaultSize="38%"	
					onDragStarted = {this.dragStarted}
					onDragFinished={() =>  {this.checkDimensions(); }}	
					key='splitV'
				>
					<SplitPane   
						split = "horizontal"
						minSize="100%"
						defaultSize="100%"	
						allowResize={false}	
						key='splitVdummy'>

						<div ref={this.refFormGL} style={styleTV} key='divFormGL'>
							<ThreeScene inr = {0}/> 
						</div>
						<div></div>
					</SplitPane>

					<SplitPane   
						split = "horizontal"
						minSize="10%"
						defaultSize="80%"	
						onDragStarted = {this.dragStarted}
						onDragFinished={() =>  {this.checkDimensions(); }}	
						key='splitV'>

<SplitPane 
split="vertical" defaultSize="30%"	 
onDragStarted = {this.dragStarted}
onDragFinished={() => { this.checkDimensions(); }}
key='splitHR'> 
						<div ref={this.refFormTreeView} style={style} key='divFormTreeView'>
						<TreeView refDIVparent = {this.refFormTreeView} inr = {0}/> 
						</div>	
						
							

						<div ref={this.refFormEdit}  key='divFormEdit' style={style}>
						
						<FormEdit coMain = {true} inr = {0} 
							handleOpenModal = {this.props.handleOpenModal}  
							handleOpenDBModal = {this.props.handleOpenDBModal}
							handleOpenToolModal = {this.props.handleOpenToolModal}
							isCalc = {this.props.isCalc}/>
						
						</div>

						
			</SplitPane>


						<SplitPane 
							split="vertical" defaultSize="60%"	 
							onDragStarted = {this.dragStarted}
							onDragFinished={() => { this.checkDimensions(); }}
							key='splitHR'> 

						<div  ref={this.refStatusData} style={style} key='divStatusData'> 
							<StatusData setCalc = {this.props.setCalc}/>
						</div>
						
						<div ref={this.refStatusRes} style={style} key='divStatusRes'>
							<StatusRes/>
						</div>

						</SplitPane>
					</SplitPane>
				</SplitPane>	
			</div>
		);

	}
}

export default Main;