import React, { Component, Suspense } from 'react';
//import { createPortal } from 'react-dom';
import Main from './Main';
import Header from './Header';
import FormEdit from "../editors/FormEdit";
//import { ClimateDB } from '../modalClimate/ClimateDB';
import {Tools} from "./Tools";
import Modal from "react-modal";
import {WaitSpin,WaitText} from "../editors/WaitSpin"; 
import authService from "../auth/authService"

import {cUtils} from "../data/ClassUtils"
import { DataCheck } from "../editors/DataCheck"

import { GeometryTool } from "../modalGeometry/Geometry"
import { DBTool } from "../modalDB/DBTool"

//import { MyWindowPortal } from '../editors/WindowPortal';
//import { PortalTest } from '../editors/Test';

import "./App.css";

const apiUrl = process.env.REACT_APP_API_URL;
//const GeometryTool = React.lazy(() => import("../modalGeometry/Geometry"));
//const DBTool   = React.lazy(() => import("../modalDB/DBTool"));

const ClimateDB = React.lazy(() => import("../modalClimate/ClimateDB"));

export var app;

export class App extends Component {
  
  startModal = -1;

  FE = new Array(3);  //FormEdit, index = inr, 0 - main
  TV = new Array(3); //TreeView, index = inr, 0 - main
  TS = new Array(3);  //ThreeScene, index = inr, 0 - main
  Header;  //Header
  SD; //StatusData
  SR; //StatusRes - last results
  allV = false;  //all variants should be sent to backend to be stored in DB

  tem; //template objects
  un;
  def;

  projectJSON;
  actVariantJSON;
  IMG = {imgT:null, img:null, imgW:0, imgH:0, send: false};

  OwnerId = -1;
  Lan = "en";
  pref = {showAllCompsZone : false, lastChoice:0};  //lastChoice - where which element has been choosen
  lastDataChoice;  //data forlastChoice

  //_____________________________________________________________________________________
  constructor(props) {
    super(props);

    // console.log("process.env.REACT_APP_API_DBCLIMATE_URL:",process.env.REACT_APP_API_DBCLIMATE_URL);
    
    this.apType = process.env.REACT_APP_TYPE;   console.log("TYPE:",this.apType);
    
    this.state = {
      showModal: false,  
      wait: true,//this.props.auth,
      un: "",//nK("su"),
      dbScope: -1,
      calcScope: 3,
      progVer: 30,
      SIIP: 1,
      pSS:false,
      isCalc: false
    };

    this.handleOpenDBModal = this.handleOpenDBModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleOpenToolModal = this.handleOpenToolModal.bind(this);
    this.handleCancelModal = this.handleCancelModal.bind(this);

    this.setActProject = this.setActProject.bind(this); 
    this.scopeChanged = this.scopeChanged.bind(this);
    this.importVar = this.importVar.bind(this);
    this.insertPartRes = this.insertPartRes.bind(this);
    this.SDmounted = this.SDmounted.bind(this);
    this.pSSchanged = this.pSSchanged.bind(this);
    this.siipChanged = this.siipChanged.bind(this);

    this.UserD = this.UserD.bind(this);
    this.setCalc = this.setCalc.bind(this);

    app = this;
    this.utils = new cUtils(this);
    this.dCheck = new DataCheck(this);
    // this.dCheck.app = this;
    // this.dCheck.utils = this.utils;
  }

  //___________________________________________________________________________
  async  DBGeneric(sRoute,  jo, act) {

    console.log('to SERVER ' + sRoute + ":", jo);

    let urlDB = apiUrl + sRoute;//SetCO";//Gen",
        //js = {what : whatToDo, infoData: jo}
    let req = null;

    if(process.env.NODE_ENV === 'production') {
        req = authService.acquireToken().then(token => {

            return fetch(urlDB, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            Accept: 'text/plain',
            mode: 'no-cors'
        },
        body: JSON.stringify(jo),  
    })})
    } else {
        req = fetch(urlDB, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json',
            Accept: 'text/plain',
        },
        body: JSON.stringify(jo),  
    })
    }
    return req.then(response => response.json())
    .then(data => {
        console.log('from SERVER:', data);
        if (this.utils.oOK(data.messA)) alert(data.messA);
        if (act) act(data);
        app.ImCheck();
    })
    .catch((error) => {
        //if (act) act(null);
        alert(this.utils.nK("s_er") + ".\n\n" + error);
    });
}
  

  //__________________________________________________________________________
  UserD(data){
    this.tem = data.tem; //template objects
    this.un = data.un;
    this.def = data.def;   

    let s = data["n"];// + " " + data["sn"];
    this.OwnerId = data.Id;
    this.def.dbScope.sel.push([data.Id, data.n]);
    data.lO.forEach(d => this.def.dbScope.sel.push([d.id, d.n]));

    this.projectJSON = this.utils.newProJSON();  
    this.actVariantJSON = this.projectJSON.lVariant[0];

    this.setState({wait:false, un:s, dbScope:data.Id});
  }

  //__________________________________________________________________________
  componentDidMount() {  
    //componentWillMount(){
      window.onbeforeunload = (e) => {
        //const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = ''; // Legacy method for cross browser support
        }
        this.DBGeneric("Gen/DelJob", {pSS:app.state.pSS, uuid:app.projectJSON.uuid}, null);
  
        return ''; // Legacy method for cross browser support
      } 

    if (this.props.auth) this.DBGeneric("Gen/GetUD",  {}, this.UserD);

    let st,
        envT = process.env.REACT_APP_TYPE;
    if (envT === "PLUS") st = "C3rro Plus";
    else if (envT === "METR") st = "METr";
    else st = "Unknown";
    document.title=st;
  }

  //__________________________________________________________________________
  SDmounted(){  //called from status data
    this.setActProject(false, this.projectJSON, false); //copy
  }
  
  //__________________________________________________________________________
  CheckVersionSetVal(){
    let pv = this.projectJSON.progVer,
        jsNB = this.actVariantJSON.HaMT.numB,
        jsHMT = this.actVariantJSON.HaMT;
        this.utils.CheckVersionSetVal(jsHMT, "selCTH", pv,
                       jsHMT, "cAccur", pv, 
                       jsNB, "shadeNB", pv,
                       jsNB, "airFNB", pv,
                       jsNB, "explRadEs", pv,
                       jsNB, "htWDepNB", pv,
                       jsNB, "rainNB", pv,
                       jsNB, "enLRadIs", pv);
    this.projectJSON.lWindow.forEach(e => this.utils.CheckVersionSetVal(e, "detU", pv));
  }

  

  //__________________________________________________________________________
  setActProject(check, project, stored) {  //project is JSON !
    let co = true;  

    if (check) co = this.utils.isValidProject(project);  
    if (co){
      this.utils.SubstKeysProj(project);

      if (!stored){
        project.lVariant.forEach(v => {
          delete v["cSumS"];
          if (v.HaMT) delete v.HaMT["cSumS"];
          if (v.PHIUS && v.PHIUS.lCase) {
            v.PHIUS.lCase.forEach(c => {delete c["cSumS"];});   //to force new shadow calculation
          }
        });
      }
      this.projectJSON = project;   //console.log("new project uuid", this.projectJSON.uuid);
      
      //--------------set img
    let projD = this.projectJSON.projD;  
    this.IMG.imgT = null;
    this.IMG.img = null; 
    if (this.utils.oOK(projD)){
      if (this.utils.oOK(projD.imgT) && this.utils.oOK(projD.img)){
        if (!projD.img.startsWith("data")){
          projD.img = "data:image/" + projD.imgT + ";base64, " + projD.img; 
        }
      }
      this.IMG.imgT = projD.imgT;
      this.IMG.img = projD.img; 
      this.IMG.imgW = projD.imgW; 
      this.IMG.imgH = projD.imgH; 
      this.projectJSON.projD.img = null;
    }
    this.IMG.send = !stored;
    //----------------------------

      let pj = this.projectJSON;

      this.setActVariantJSON();
      this.utils.SetKeyValueIfNotExists(pj,"calcScope",3,
                            pj,"progVer",30,
                            pj,"SIIP",1,
                            this.actVariantJSON.HaMT,"selCTH", 1); 



      this.CheckVersionSetVal();
      if(this.TV[0]) this.TV[0].createNewNodes();

      this.setState({calcScope: pj.calcScope, progVer: pj.progVer,  SIIP: pj.SIIP, pSS:stored});//to redraw everything


      if (check) this.SD.newProj(project);
      else this.SD.newProj(null);
    }
    else this.utils.messageBox(0, "s_erProj");

    return co;
  }

  //__________________________________________________________________________
  setActVariantJSON() {
    let idVar;
    if (this.projectJSON.idVar) idVar = this.projectJSON.idVar;
    else this.projectJSON.idVar = this.projectJSON.lVariant[0].id;

    let variant = this.projectJSON.lVariant.find(v => v.id === idVar);
    if (!this.utils.oOK(variant)) {
      variant = this.projectJSON.lVariant[0];
      idVar = variant.id;
    }

    this.actVariantJSON = variant;
    if (this.TS[0]) this.TS[0].webGL.newVariant(this.actVariantJSON);
  }

  //_________________________________________________________________________
  insertPartRes(pRes)  {

    function sVs(o0, o1, noArObj, th){  //inner function, set Values from o1 into o0
      if (th.utils.oOK(o0) && th.utils.oOK(o1)){
        for (const key in o1) {
          if (noArObj) {
            let o = o1[key];
            if (o && !Array.isArray(o) && !(typeof o === 'object')) o0[key] = o1[key];
          }
          else o0[key] = o1[key]; 
        }
      }
    }

    //if (!oOK(pRes.CR)) return;
    if (!this.utils.oOK(this.projectJSON)) return;  
    if (this.projectJSON.uuid !== pRes.uuid) return;// && this.projectJSON.jobId > 0) return;
    if (!this.utils.oOK(this.projectJSON.lVariant)) return;
    
    if (this.utils.oOK(pRes) && this.utils.oOK(pRes.CR) && this.utils.oOK(pRes.CR.Cases)){
      if (this.utils.oOK(pRes.uuid)) this.projectJSON.uuid = pRes.uuid;
      //this.projectJSON.iniCalc = false;  
      //CR = JSON.parse(JSON.stringify(data.CR));

      for (let iv = 0; iv < pRes.CR.Cases.length; iv++){ 
        let pv = this.projectJSON.lVariant.find(v => v.id === pRes.CR.Cases[iv].id),
            prv = pRes.CR.Cases[iv],
            i = 0;

        if (this.utils.oOK(pv)){ 

          sVs(pv, prv, true,this);
          if (this.utils.oOK(prv) && this.utils.oOK(prv.SR)) pv.SR = prv.SR;
     
          let TF = [pv.geom.lPoly, prv.Pols,  //TF = ToFrom
                  pv.building, prv.Build,
                  pv.building.lComponent, prv.Comps,
                  pv.building.lZone, prv.Zones,
                  pv.cliLoc, prv.StdCli,
                  pv.PHIUS, prv.PHIUS,
                  pv.HaMT, prv.HaMT,
                  pv.cliLoc, prv.cliLoc,
                  
                  pv.PHIUS.lCase, prv.casesPH
                ];
          if (iv === 0) TF.push(this.projectJSON.lAssembly, pRes.CR.Ass);  

          while (i < TF.length){  //console.log(i,"-----------------------------------------------------------------");
            let TF0 = TF[i++],
               TF1 = TF[i++];  
            if (this.utils.oOK(TF0) && this.utils.oOK(TF1)){
              if (Array.isArray(TF1)){
                TF1.forEach(o1 => {
                let o0 = TF0.find(e => e.id === o1.id);
                sVs(o0, o1,false,this);
                });
              }
              else {
                sVs(TF0,TF1,false,this);
             }
            }
          }
        }
      }
    }
  }

  //___________________________________________________________________________
  scopeChanged(e) {
    this.setState({calcScope:this.projectJSON.calcscope});

    this.CheckVersionSetVal();
    this.TV[0].createNewNodes();  

    this.SD.cTimer();
  }

  //___________________________________________________________________________
  siipChanged(e) {  

   //this.FE[0].reScale();
    this.SD.cTimer();
  }

  //___________________________________________________________________________
  pSSchanged(e) {  //console.log("pSSchanged(e)",e, this.state.pSS);
    this.projectJSON.pSS = this.state.pSS;
    this.IMG.send = this.state.pSS;
     if (this.state.pSS){
      this.SD.cTimer();   
     }
   }

   //_____________________________________________________________________________________
  ImCheck(){  //called after send project
    if (this.IMG.send){
      if (this.projectJSON.uuid != null && this.state.pSS) {
        this.DBGeneric("Gen/SetJobInfo",  {uuid: this.projectJSON.uuid, IMG:this.IMG}, null);  console.log("img sent");
      }
      this.IMG.send = false;
    }
  }

  //___________________________________________________________________________
  BeforeOpenModal(){
    this.FE[0].prevForm = this.FE[0].form;
    this.FE[0].prevEdt = this.FE[0].edt;
    this.FE[0].ignoreForm = true;
  }

  //___________________________________________________________________________
  handleOpenModal(startModalNr, dataAccepted) {  //climateDB, Geometry ...
    this.BeforeOpenModal();  
    
    this.startModal = startModalNr;
    this.dataAccepted = dataAccepted;
    this.setState({ showModal: true });
  }

  //___________________________________________________________________________
  handleOpenDBModal(idDB, coGet, t){   //c3rro database, t - table sending request
    console.log("handleOpenDBModal----------------------------------------------------");

    this.BeforeOpenModal();   

    this.inr = 1;
    this.startModal = 2;
    this.idDB = idDB;
    this.coDBget = coGet;
    this.t = t;  
    this.setState({ showModal: true });
  }

  //___________________________________________________________________________
  handleOpenToolModal(nrTool, sT, t){  
    //1 - if (FE.nrTool ===  1) new FormBuildingWizzard();
    //2 - Load globally Project

    this.BeforeOpenModal();   
      console.log("open Tool Modal----------------------------------------------------");

    this.startModal = 3;
    this.nrTool = nrTool;
    this.t = t;  
    this.sT = sT;
    this.setState({ showModal: true });
  }

  //___________________________________________________________________________
  handleCancelModal(t,js) {
    this.inr = 0;
    this.setState({ showModal: false });

    if (this.FE[0].ignoreForm){
      setTimeout(() => {FormEdit.FEs = this.FE[0];
                        this.FE[0].ignoreForm = false;
                        this.FE[0].ReForm(this.FE[0].prevForm);
                        if (t && js){
                          t.getDBData(js,1);     //console.log("canceledModal",t,js);
                        }
              });
      }
  }

//__________________________________________________________________________
  importVar(varI) {  //importing from Geometry/Building wizzard to main
    
    let avar = this.actVariantJSON,
        //varI = this.idData.variant,
        countZ = varI.building.lZone.length,
        countC = varI.building.lComponent.length,
        countZA = avar.building.lZone.length,
        countCA = avar.building.lComponent.length, 
        merge = (avar.building.ibdm),  //merge data
        lz = [],
        lc = [];
    
    avar.geom = varI.geom;

    for (let iz = 0; iz < countZ; iz++){
      let z = undefined,
          zi = varI.building.lZone[iz];  //imported
      if (iz < countZA){
        if (merge){
          z = avar.building.lZone[iz];
        }
      }
      if (z === undefined) z = JSON.parse(JSON.stringify(this.tem.zoneJSON)); 
      z.id = zi.id;
      z.typeZ = zi.typeZ;
      z.tAttZ = zi.tAttZ;
      z.visVolZ = zi.visVolZ;

      z.grossVolZ = zi.grossVolZ;
      z.netVolZ = zi.netVolZ;
      z.flAreaZ = zi.flAreaZ;
    
      lz.push(z);
    }
      
    for (let ic = 0; ic < countC; ic++){
      let c = undefined,
          ci = varI.building.lComponent[ic];  //imported
      if (ic < countCA){
        if (merge){
          c = avar.building.lComponent[ic];
        }
      }
      if (c === undefined) c = JSON.parse(JSON.stringify(this.tem.componentJSON)); 
      c.id = ci.id;
      c.idIC = ci.idIC;
      c.idEC = ci.idEC;
      c.typeC = ci.typeC;
      c.inclC = ci.inclC;
      c.idPolyC = ci.idPolyC;
      c.idColorIC = ci.idColorIC;
      c.idColorEC = ci.idColorEC;
      lc.push(c);
    }
    avar.building.lZone = lz
    avar.building.lComponent = lc;
    
    this.TS[0].webGL.removeAllFromSceneGL();
    this.TS[0].webGL.newVariant(avar);
    this.TV[0].createNewNodes();

    this.projectJSON.iniCalc = true;  //must be set context depending

    this.SD.cTimer();
  }



  //__________________________________________________________________________
  setCalc(isCalc){
      this.setState({isCalc: isCalc});
      //this.FE[0].reScale();
  }



  //___________________________________________________________________________
  render() {//console.log("RERENDERING APP vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv");
//isOpen={this.state.showModal}
    return (
      <>
        {this.state.wait ? (<WaitSpin size = {0.4} />) : (
        <div className="app-container">
          <Header handleOpenDBModal = {this.handleOpenDBModal} 
                  setActProject = {this.setActProject}
                  //setActPJdownLoad = {this.setActPJdownLoad} 
                  //setActPJlocalLoad = {this.setActPJlocalLoad}
                  handleOpenToolModal = {this.handleOpenToolModal}
                  isCalc = {this.state.isCalc}
                  scopeChanged = {this.scopeChanged} 
                  siipChanged = {this.siipChanged}  
                  pSSchanged = {this.pSSchanged}
                  un = {this.state.un} />   
          <Main handleOpenModal = {this.handleOpenModal}  handleOpenDBModal = {this.handleOpenDBModal} 
            handleOpenToolModal = {this.handleOpenToolModal} 
            setCalc = {this.setCalc}  
            isCalc = {this.state.isCalc}  
            />


          <Modal
            isOpen={this.state.showModal}
            ariaHideApp={false}
            contentLabel="DB1"
            style={{content: { top: '20px',  left: '20px',  right: '2px', bottom:'2px'  }}}
          >
            {this.startModal === 0 ? (
              <Suspense fallback={<WaitText/>}>
              <ClimateDB
                cancelModal={this.handleCancelModal}
                dataAccepted={this.dataAccepted} 
                din = {this.actVariantJSON.cliLoc}
                selCli = {true}
                app = {this}
              />
              </Suspense>
            ) : null}
            {this.startModal === 1 ? (

              <GeometryTool
                cancelModal={this.handleCancelModal}
                importVar = {this.importVar}
              />
            ) : null}

            {this.startModal === 2 ? (
              <DBTool  
                dbsel = {this.state}
                cancelModal={this.handleCancelModal}
                t = {this.t}
                coDBget = {this.coDBget}
                idDB = {this.idDB}
                inr = {1}
              />
            ) : null}
            {this.startModal === 3 ? (
              <Tools  
                cancelModal={this.handleCancelModal}
                sT = {this.sT}
                t = {this.t}
                nrTool = {this.nrTool}
                importVar = {this.importVar}
                setActProject = {this.setActProject}
                inr = {1}
              />
            ) : null}
          </Modal>
        </div>
        )}      
    </>
    );
  }
}

export default App;


// {this.state.showModal ? (
         
//   <MyWindowPortal cancelModal={this.handleCancelModal}>
   
//    <ClimateDB
//         cancelModal={this.handleCancelModal}
//         dataAccepted={this.dataAccepted} 
//       />
//   </MyWindowPortal>
// ):null}