import React, { Component } from 'react';
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import authService from "../auth/authService";
//import { messageBox, DBGeneric } from "../data/Utils"

import {App } from "./App"


const ConditionalWrapper = ({ condition, wrapper, children }) => 
  condition ? wrapper(children) : children;


export class Ap extends Component {
  
  componentDidMount() {
    // window.onbeforeunload = (e) => {
    //   //const e = event || window.event;
    //   e.preventDefault();
    //   if (e) {
    //     e.returnValue = ''; // Legacy method for cross browser support
    //   }
    //   DBGeneric("Gen/DelJob", {pSS:app.state.pSS, uuid:app.projectJSON.uuid}, null);

    //   return ''; // Legacy method for cross browser support
    // } 
  }

  //___________________________________________________________________________
  render() {
    return (
      <>
      <ConditionalWrapper
        condition={process.env.NODE_ENV === 'production'}
        wrapper={children => 
          <MsalProvider instance={authService.msalClient}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{prompt: 'select_account'}}
          >
            {children}
            </MsalAuthenticationTemplate>
          </MsalProvider>}
        >
        <AuthenticatedTemplate>
            <App auth = {true}/>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <App auth = {true}/>
        </UnauthenticatedTemplate>

        </ConditionalWrapper>
    </>
    );
  }
}

export default Ap;


  